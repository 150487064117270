import moment from "moment";
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment);

export const DateFormat = date => {
  return moment(new Date(date), "DD/MM/YYYY").format("DD/MM/YYYY");
};

export const displayMonthNameWithDate = date => {
  return moment(`${date}`, "DD MM YYYY").format("DD MMM YYYY");
};

export const getCurrentDataAndTime = async date => {
  var mm = moment().utc(`${date}`, "MM-DD-YYYY");
 // console.log("mm", new Date(mm._d), mm.toString());
  return new Date(mm._d);
};

export const currentDateFormat = date => {
  //console.log(moment(`${date}`).format());
 // console.log(moment(`${date}`).format("MMMM Do YYYY, h:mm:ss a"));
  return moment(`${date}`).format("DD/MM/YYYY");
};
export const displayDateAndMonth = date => {
  return moment(`${date}`, "DD-MMM").format("DD-MMM");
};

export const dateToString =(value)=>{
  //console.log("dateToString", value);
  const date = moment(`${value}`,  'DD-MM-YYYY').format("ddd, MMM DD YYYY");
  return new Date(date);
}

export const getTodayDate=() =>{
  return moment(new Date()).format('DD MMMM YYYY');
}

export const getTimer = (value) => {
  return moment.duration(value, "seconds").format("h[h] mm[m] ss[s]");
}

