import React from "react";
import Diagnosis from "../../../customComponents/Diagnosis/Diagnosis";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../../constants/applicationContants";

class AtCampLE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosisType: applicationConstants.AtCampLE,
      title: applicationConstants.LEDiagnosis +' '+ applicationConstants.CampTitle,
    };
  }

  render() {
    return (
      <RenderPage
        id="patinet-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Diagnosis
          title={this.state.title}
          diagnosisType={this.state.diagnosisType}
        />
      </RenderPage>
    );
  }
}
export default AtCampLE;
