import React from "react";
import { Row, Col } from "reactstrap";
import applicationConstants from "../../../constants/applicationContants";

const RefractionReport = (props) => {
  return (
    <Row className="pt-8px pb-8px bg-white br-4px no-margin">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <p className="mb-0 internal-section__title opensans-semiBold title-theme-color">
          REFRACTION SUMMARY
        </p>
      </Col>
      {/* -------------RIGHT EYE-------------- */}
      {!props.isRE ? (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col className="mt-1">
              <p className="mb-1">RIGHT EYE</p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4px">
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA Distance
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.REBCVADistance ? props.REBCVADistance : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4px">
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA LogMAR Value
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.REBCVALogMar ? props.REBCVALogMar : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA Near
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.REBCVANear ? props.REBCVANear : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    Lens Status
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.RELensStatus ? props.RELensStatus : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col className="mt-1">
              <p className="mb-1">RIGHT EYE</p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4px">
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    Reason
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.REReason ? props.REReason : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {!props.isLE ? (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <p className="mb-0 mt-2">LEFT EYE</p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA Distance
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.LEBCVADistance ? props.LEBCVADistance : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA LogMAR Value
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.LEBCVALogMar ? props.LEBCVALogMar : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    BCVA Near
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.LEBCVANear ? props.LEBCVANear : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    Lens Status
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.LELensStatus ? props.LELensStatus : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <p className="mb-0 mt-2">LEFT EYE</p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="pt-4px pb-4px"
            >
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                    Reason
                  </p>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  className="no-mobile-plr"
                >
                  <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                    -
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    {props.LEReason ? props.LEReason : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default RefractionReport;
