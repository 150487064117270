import React from "react";
import memoizeOne from "memoize-one";
import Moment from 'moment';
import { DRDataOne,DRDataTwo,BCVADistanceData,BCVANearData } from "../../../utils/dropdownData";
const dropdown_data = [
  { value: 0, label: "Mild NPDR" },
  { value: 1, label: "Moderate NPDR" },
  { value: 2, label: "Servere NPDR" },
  { value: 3, label: "PDR" },
  { value: 4, label: "Uncertain" },
  { value: 5, label: "YES" },
  { value: 6, label: "NO" },
  { value: 7, label: "Uncertain" },
];
const fetchDrData = (value,id,dat=null) => {
  if(value != null){
  const data = JSON.parse(value);
  
  console.log(data);
  if(data.length > 0){
  if(id == 1 && data.length >= 1){
    console.log("fixes",dat);
    var REDR_arr_one = dropdown_data.filter(function (el) {
      return el.value === data[0];}
    );
   
    return REDR_arr_one[0].label;
  } 
  if(id == 2 && data.length == 2){
    
    var REDR_arr_two = dropdown_data.filter(function (el) {
      return el.value === data[1];}
    );
    return REDR_arr_two[0].label;
  }
}
  } else {
    return null;
  }

}


const HandleRDR = (value) => {
 
    if(value === 1){
      return "Yes";
    } else {
      return "No";
    }

}


const handleREShowImage = (diagPic) => {

  let RePreImaging = JSON.parse(diagPic.REPreDilationImaging);
  let RePostImaging = JSON.parse(diagPic.REPostDilationImaging);
  if (RePreImaging.length > 0 && (RePostImaging.length === 0 || RePostImaging === "[]")) {
    // Get the last image from REPreDilationImaging
    const lastImage = RePreImaging[RePreImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else if ((RePreImaging.length === 0 || RePreImaging === "[]") && RePostImaging.length > 0) {
    // Get the last image from REPostDilationImaging
    const lastImage = RePostImaging[RePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else if (RePreImaging.length > 0 && RePostImaging.length > 0) {
    // Get the last image from REPostDilationImaging
    const lastImage = RePostImaging[RePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } 
  // if(RePreImaging.length == 0 && RePostImaging.length > 0){
  //   return RePostImaging[0].signedUrl;
  // } else if (RePreImaging.length > 0 && RePostImaging.length == 0){
  //   return RePreImaging[0].signedUrl;
  // } else if (RePreImaging.length > 0 && RePostImaging.length > 0){
  //   return RePostImaging[0].signedUrl;
  // } else{
  //   return null;
  // }
}


const handleREShowPDF = (diagPic) => {

  let RePreImaging = JSON.parse(diagPic.REPreDilationImaging);
  let RePostImaging = JSON.parse(diagPic.REPostDilationImaging);
  if(RePreImaging.length == 0 && RePostImaging.length > 0){
    return RePostImaging[0].remidioReport;
  } else if (RePreImaging.length > 0 && RePostImaging.length == 0){
    return RePreImaging[0].remidioReport;
  } else if (RePreImaging.length > 0 && RePostImaging.length > 0){
    return RePostImaging[0].remidioReport;
  } else{
    return null;
  }
}

const handleLEShowImage = (diagPic) => {

  
  let LePreImaging = JSON.parse(diagPic.LEPreDilationImaging);
  let LePostImaging = JSON.parse(diagPic.LEPostDilationImaging);
  if (LePreImaging.length > 0 && (LePostImaging.length === 0 || LePostImaging.length === "[]")) {
    // Get the last image from REPreDilationImaging
    const lastImage = LePreImaging[LePreImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else if ((LePreImaging.length === 0 || LePreImaging.length === "[]") && LePostImaging.length > 0) {
    // Get the last image from REPostDilationImaging
    const lastImage = LePostImaging[LePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  }
  else if (LePreImaging.length > 0 && LePostImaging.length > 0) {
    // Get the last image from REPostDilationImaging
    const lastImage = LePostImaging[LePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else {
    return null;
  }
  // if(LePreImaging.length == 0 && LePostImaging.length > 0){
  //   return LePostImaging[0].signedUrl;
  // } else if (LePreImaging.length > 0 && LePostImaging.length == 0){
  //   return LePreImaging[0].signedUrl;
  // } else if (LePreImaging.length > 0 && LePostImaging.length > 0){
  //   return LePostImaging[0].signedUrl;
  // } else{
  //   return null;
  // }
}

const handleLEShowPDF = (diagPic) => {

  let LePreImaging = JSON.parse(diagPic.LEPreDilationImaging);
  let LePostImaging = JSON.parse(diagPic.LEPostDilationImaging);
  if(LePreImaging.length == 0 && LePostImaging.length > 0){
    return LePostImaging[0].remidioReport;
  } else if (LePreImaging.length > 0 && LePostImaging.length == 0){
    return LePreImaging[0].remidioReport;
  } else if (LePreImaging.length > 0 && LePostImaging.length > 0){
    return LePostImaging[0].remidioReport;
  } else{
    return null;
  }
}


const handleBVCADistance = (value) => {
  console.log("distance",value);
  let distance = BCVADistanceData.filter(function (el) {
    return el.value === value;}
  );
  return distance[0].label;
}

const handleBVCANear = (value) => {
  let near = BCVANearData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}



const TablesCatColumns = memoizeOne((handleAction, handleDelete,handleDownload) => [
  {
    name: "Date of Examination",
    selector: "dateOfExamination",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.dateOfExamination}</div>,
  },
  {
    name: "Patient Id",
    selector: "patientId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.patientId}</div>,
  },
  {
    name: "Patient Name",
    selector: "patientName",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.patientName}</div>,
  },
  {
    name: "Age",
    selector: "age",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.age}</div>,
  },
  {
    name: "Camp",
    selector: "campId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.campId !== "" && row.campName !== "") ? row.campId+'-'+row.campName : "" }</div>,
  },
  {
    name: "Vision Centre",
    selector: "visionCentreId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.visionCentreId !== "" && row.visionCenterName !== "") ? row.visionCentreId+'-'+row.visionCenterName : ""}</div>,
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.gender === 0 ? 'Male' : 'Female'}</div>,
  },
  {
    name: "Assigned Hospital",
    selector: "assignedHospital",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.hospitalName}</div>,
  },
  {
    name: "MocDoc PhId",
    selector: "mocDocPhId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.mocDocPhid !== "null" && row.mocDocPhid !== "" ? row.mocDocPhid : ""}</div>,
  },
  {
    name: <div className="container-fluid  " style={{width:"127%"}}>
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>RE BCVA</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-6 text-center">
        <span className="text-center ">Distance</span>
      </div>
      <div className="col-sm-6 text-center">
        <span className="text-right">Near</span>
      </div>
      
    </div>
  </div>,
    selector: "REBCVA",
    sortable: false,
    width: "260px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-6 " style={{width:"20px"}}>
              <span className="text-center">
              { (row.refractionInfo && row.refractionInfo.REBCVADistance !== -1) ? handleBVCADistance(row.refractionInfo.REBCVADistance) : ""}
              </span>
            </div>
            <div className="col-sm-6 " style={{width:"20px"}}>
              <span className="text-center">
              { (row.refractionInfo && row.refractionInfo.REBCVANear !== -1 ) ? handleBVCANear(row.refractionInfo.REBCVANear) : ""} 
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: <div className="container-fluid" style={{width:"127%"}}>
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>LE BCVA</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-6 text-center">
        <span className="text-center ">Distance</span>
      </div>
      <div className="col-sm-6 text-center">
        <span className="text-right">Near</span>
      </div>
      
    </div>
  </div>,
    selector: "LEBCVA",
    sortable: false,
    width: "260px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-6 " style={{width:"20px"}}>
              <span className="text-center">
              { (row.refractionInfo && row.refractionInfo.LEBCVADistance !== -1) ? handleBVCADistance(row.refractionInfo.LEBCVADistance) : ""}
              </span>
            </div>
            <div className="col-sm-6 " style={{width:"20px"}}>
              <span className="text-center">
              { (row.refractionInfo && row.refractionInfo.LEBCVANear !== -1 ) ? handleBVCANear(row.refractionInfo.LEBCVANear) : ""} 
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: "RE Images",
    selector: "REPreDilationImaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>
      {/* { row.optoREDiag ? handleREShowImage(row.optoREDiag) : ""} */}
    <img alt="" src={row.optoREDiag ? handleREShowImage(row.optoREDiag) : ""}/>
    </div>,
  },
  {
    name: "LE Images",
    selector: "LEPreDilationImaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>
       {/* {row.optoLEDiag ? JSON.stringify(row.optoLEDiag): ""}  */}
    <img alt="" src={ row.optoLEDiag ? handleLEShowImage(row.optoLEDiag) : ""}/>
    </div>,
  },  
  {
    name: <div className="container-fluid  ">
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>Camp Doc Grading RE</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-3 ">
        <span className="text-center ">DR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">DME</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">RDR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">Advice</span>
      </div>
    </div>
  </div>,
    selector: "campGradingRE",
    sortable: false,
    width: "350px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrREDiag !== null && row.campDrREDiag?.REDR !== "-1"
                  ? fetchDrData(row.campDrREDiag?.REDR, 1)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrREDiag !== null && row.campDrREDiag?.REDR !== "-1"
                  ? fetchDrData(row.campDrREDiag?.REDR, 2)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrREDiag !== null && row.campDrREDiag?.RERDR !== "-1"
                  ? HandleRDR(row.campDrREDiag?.RERDR)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrREDiag ? row.campDrREDiag?.REOther : ""}
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: <div className="container-fluid  ">
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>Camp Doc Grading LE</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-3 ">
        <span className="text-center ">DR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">DME</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">RDR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">Advice</span>
      </div>
    </div>
  </div>,
    selector: "campGradingLE",
    sortable: false,
    width: "350px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrLEDiag != null && row.campDrLEDiag?.LEDR != "-1"
                  ? fetchDrData(row.campDrLEDiag?.LEDR, 1)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrLEDiag != null && row.campDrLEDiag?.LEDR != "-1"
                  ? fetchDrData(row.campDrLEDiag?.LEDR, 2)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrLEDiag != null && row.campDrLEDiag?.LERDR != "-1"
                  ? HandleRDR(row.campDrLEDiag?.LERDR)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.campDrLEDiag ? row.campDrLEDiag?.LEOther : ""}
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: <div className="container-fluid  ">
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>Tele Grading RE</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-3 ">
        <span className="text-center ">DR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">DME</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">RDR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">Advice</span>
      </div>
    </div>
  </div>,
    selector: "teleGradingRE",
    sortable: false,
    width: "350px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrREDiag !== null && row.teleDrREDiag?.REDR !== "-1"
                  ? fetchDrData(row.teleDrREDiag?.REDR, 1)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrREDiag !== null && row.teleDrREDiag?.REDR !== "-1"
                  ? fetchDrData(row.teleDrREDiag?.REDR, 2)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrREDiag !== null && row.teleDrREDiag?.RERDR !== "-1"
                  ? row.teleDrREDiag_RERDR
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrREDiag ? row.teleDrREDiag?.REOther : ""}
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: <div className="container-fluid  ">
      <div className="row text-center p-2">
      <div className="col-sm-12">
        <span>Tele Grading LE</span>
      </div>
      </div>
    <div className="row text-center p-3">
      <div className="col-sm-3 ">
        <span className="text-center ">DR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">DME</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">RDR</span>
      </div>
      <div className="col-sm-3 ">
        <span className="text-center">Advice</span>
      </div>
    </div>
  </div>,
    selector: "teleGradingLE",
    sortable: false,
    width: "350px",
    cell: (row) => (
      <div className="container" onClick={() => handleAction(row)}>
          <div className="row text-center">
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrLEDiag !== null && row.teleDrLEDiag?.LEDR !== "-1"
                  ? fetchDrData(row.teleDrLEDiag?.LEDR, 1)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrLEDiag !== null && row.teleDrLEDiag?.LEDR !== "-1"
                  ? fetchDrData(row.teleDrLEDiag?.LEDR, 2)
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrLEDiag !== null && row.teleDrLEDiag?.LERDR !== "-1"
                  ? row?.teleDrLEDiag_LERDR
                  : ""}
              </span>
            </div>
            <div className="col-sm-3 " style={{width:"20px"}}>
              <span className="text-center">
                {row.teleDrLEDiag ? row?.teleDrLEDiag.LEOther : ""}
              </span>
            </div>
          </div>
        </div>
      
    ),
  },
  {
    name: "Info",
    selector: "",
    sortable: false,
    width: "460px" ,
    ignoreRowClick: true,
    allowOverflow: false,
    cell: (row) => (
      
      <div className="row ll-pa-dd patientSummaryReportTable" onClick={() => handleAction(row)}>
        <div className="col-auto pr-0">
          <p
            className={`mb-0 ${row.registrationStatus === 1
              ? "status-active status-text--small"
              : "status-inactive status-text--small"
              }`}
          >
            REG
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${row.healthInfoStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            HI
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${row.refractionStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            REF
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${row.REimagingStatus === 1 ||
              row.LEimagingStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            IMG
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${row.optoAdviceStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            OP-GR
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${(row.campDrREStatus === 1 ||
              row.campDrLEStatus === 1) &&
              row.campDrAdviceStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            DR-GR
            <font className="status-divider-plr">|</font>
          </p>
        </div>
        <div className="col-auto pr-0 pl-0">
          <p
            className={`mb-0 ${(row.teleDrREStatus === 1 ||
              row.teleDrLEStatus === 1) &&
              row.teleDrAdviceStatus === 1
              ? "status-active  status-text--small"
              : "status-inactive  status-text--small"
              }`}
          >
            TELE-GR
            <font className="status-divider-plr">|</font>
          </p>
        </div>

      </div>
    ),
  },


  {
    name: "Action",
    selector: "",
    // grow: 0.6,
    width:"200px",
    sortable: false,
    ignoreRowClick: true,
    allowOverflow: true,
    cell: (row) => (
      <div className="d-flex">
        <div onClick={() => handleDelete(row)}>
          <p className="mb-0 inactive-btn">Delete</p>
        </div>
        {((row.optoREDiag && handleREShowImage(row.optoREDiag) !== null) || (row.optoLEDiag && handleLEShowImage(row.optoLEDiag) !== null)) ? 
        <div onClick={() => handleDownload(row.patientId)} className="ml-2">
          <p className="mb-0 active-btn ">Download</p>
        </div> : ""}
      </div>
    ),
  },
]);

export default TablesCatColumns;
