import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const CampService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getCampAll: async (token, hospitalName) => {
    const { baseUrl, getCampAll } = ApiConfig;

    const url = baseUrl + getCampAll;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const params = JSON.stringify({
      hospitalName: `${hospitalName}`,
    });

    const campServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_CampService",
      true
    );
    return campServicePromise;
  },

  getCampList: async (token, hospitalName) => {
    const { baseUrl, getCamps } = ApiConfig;

    const url = baseUrl + getCamps;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const params = JSON.stringify({
      hospitalName: `${hospitalName}`,
    });

    const campServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_CampService",
      true
    );
    return campServicePromise;
  },

  addOrUpdateCamp: async (data, token) => {
    const { baseUrl, addOrUpdateCamp } = ApiConfig;

    const url = baseUrl + addOrUpdateCamp;

    const params = JSON.stringify({
      campCode: `${data.campCode}`,
      location: `${data.location}`,
      address: `${data.address}`,
      pinCode: `${data.pinCode}`,
      region: data.region,
      date: `${data.date}`,
      questionAnwer: '', 
      campStatus: data.campStatus, 
      currentCamp: data.currentCamp,
      visionCentreId: data.visionCenterId,
      hospitalName: `${data.hospitalName}`,
      state: `${data.state}`
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addOrUpdateCampServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addOrUpdateCampService",
      true
    );
    return addOrUpdateCampServicePromise;
  },

  deleteCamp: async (token, campCode) => {
    const { baseUrl, deleteCamp } = ApiConfig;

    const url = baseUrl + deleteCamp + `=${campCode}`;
    console.log(url)

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });


    const campDeleteServicePromise = await Fetch(
      url,
      "GET",
      headers,
      '',
      "services_CampDeleteService",
      true
    );
    return campDeleteServicePromise;
  },
};

export default CampService;
