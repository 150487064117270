const userRoleData = [
  { value: 0, label: "Admin" },
  { value: 1, label: "Optometrist" },
  { value: 2, label: "Camp Doctor" },
  { value: 3, label: "Tele-ophthalmologist" },
  { value: 4, label: "Junior Reader" },
  { value: 5, label: "Senior Reader" },
  { value: 6, label: "Project Reader" },
];

const videoCategoryData = [
  { value: 0, label: "Select Category" },
  { value: "C0001", label: "sample1" },
  { value: "C0002", label: "sample2" },
  { value: "C0003", label: "sample3" },
];

const optometristAdviceData = [
  { value: 0, label: "Immediate referral" },
  { value: 1, label: "Follow-up at 3 months" },
  { value: 2, label: "Follow-up at 6 months" },
  { value: 3, label: "Yearly review" },
];

const optometristReferralData = [
  { value: 0, label: "Cataract" },
  { value: 1, label: "Glaucoma" },
  { value: 2, label: "Retina" },
  { value: 3, label: "Others" },
];

const macEduma = [
  { value: 0, label: "Yes" },
  { value: 1, label: "No" },
  { value: 2, label: "Uncertain" },
];

const genderData = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Others" },
];

const statusData = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];

const YesOrNo = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

const regionData = [
  { value: 0, label: "Rural" },
  { value: 1, label: "Urban" },
];

const educationData = [
  { value: 0, label: "Primary" },
  { value: 1, label: "Secondary" },
  { value: 2, label: "Graduation" },
  { value: 3, label: "None" },
];

const lensStatusData = [
  { value: 0, label: "Phakic Clear" },
  { value: 1, label: "Phakic ELC" },
  { value: 2, label: "Cataract" },
  { value: 3, label: "Pseudophakia" },
  { value: 4, label: "Aphakia" },
  { value: 5, label: "IOL with PCO" },
];

const anteriorChamberData = [
  { value: 0, label: "Shallow" },
  { value: 1, label: "Normal" },
];

const YesOrNoUnknowData = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
  { value: 2, label: "Unknown" },
];

const durationData = [
  { value: 5, label: "NA" },
  { value: 0, label: "<1 Year" },
  { value: 1, label: "2-5 Years" },
  { value: 2, label: "6-10 Years" },
  { value: 3, label: "10-20 Years" },
  { value: 4, label: ">20 Years" },
  
];

const medicationData = [
  { value: 0, label: "None" },
  { value: 1, label: "Allopathy" },
  { value: 2, label: "AYUSH" },
];

const medicationTypeData = [
  { value: 0, label: "None" },
  { value: 1, label: "Tablets" },
  { value: 2, label: "Insulin" },
  { value: 3, label: "Both" },
];

const comorbiditiesData = [
  { value: 0, label: "Cardiac" },
  { value: 1, label: "Stroke" },
  { value: 2, label: "Renal" },
  { value: 3, label: "Foot UIcer" },
  { value: 4, label: "Peripheral Neuropathy" },
  { value: 5, label: "Others" },
];
const DRData = [
  { value: 0, label: "Mild NPDR" },
  { value: 1, label: "Moderate NPDR" },
  { value: 2, label: "Severe NPDR" },
  { value: 3, label: "PDR" },
  { value: 4, label: "Uncertain" },
  { value: 5, label: "DME" },
  { value: 6, label: "Uncertain" },
];
const DRDataOne = [
  { value: 0, label: "Mild NPDR" },
  { value: 1, label: "Moderate NPDR" },
  { value: 2, label: "Severe NPDR" },
  { value: 3, label: "PDR" },
  { value: 4, label: "Uncertain" },
];
const DRDataTwo = [
  { value: 5, label: "YES" },
  { value: 6, label: "NO" },
  { value: 7, label: "Uncertain" },
];

const unableToGradeData = [
  { value: 0, label: "Cataract" },
  { value: 1, label: "Quality Poor" },
  { value: 2, label: "Media Opacity" },
  { value: 3, label: "Others" },
];

const insufOrSufficient = [
  { value: 0, label: "Sufficient" },
  { value: 1, label: "Insufficient" },
];

const suggestedStrategyData = [
  { value: 0, label: "Laser treatment needed" },
  { value: 1, label: "Intravitreal Injection needed" },
  { value: 2, label: "Vitreoretinal surgery needed " },
  { value: 3, label: "Further investigation needed" },
  { value: 4, label: "Follow-up at 3 months" },
  { value: 5, label: "Follow-up at 6 months" },
  { value: 6, label: "Yearly review" },
];

const othersData = [
  { value: 0, label: "Vein occlusion" },
  { value: 1, label: "Artery occlusion" },
  { value: 2, label: "Hypertensive retinopathy" },
  {
    value: 3,
    label:
      "Age related macular degeneration / Polypoidal choroidal vasculopathy",
  },
  { value: 4, label: "Retinal Artery Macroaneurysm" },
  { value: 5, label: "Parafoveal Telengiectasia" },
  { value: 6, label: "Central Serous Chorioretinopathy" },
  { value: 7, label: "Macular dystrophy" },
  { value: 8, label: "Retinitis Pigmentosa" },
  { value: 9, label: "Pathological myopia" },
  { value: 10, label: "Disc suspect" },
  { value: 11, label: "Disc edema" },
  { value: 12, label: "Others" },
];

const imageQuadrant = [
  {
    value: 0,
    key: "DC",
    label: "Disc Centered",
  },
  {
    value: 1,
    key: "MC",
    label: "Macula Centered",
  },
  {
    value: 2,
    key: "TQ",
    label: "Temporal Quadrant",
  },
  {
    value: 3,
    key: "ST",
    label: "Superotemporal Quadrant",
  },
  {
    value: 4,
    key: "IT",
    label: "Inferotemporal Quadrant",
  },
  {
    value: 5,
    key: "SN",
    label: "Superonasal Quadrant",
  },
  {
    value: 6,
    key: "IN",
    label: "Inferonasal Quadrant",
  },
  {
    value: 7,
    key: "AS",
    label: "Anterior Segment",
  },
];

const filterData = [
  { value: 0, label: "All" },
  { value: 1, label: "Completed" },
  { value: 2, label: "Junior Reader" },
  { value: 3, label: "Senior Reader" },
  { value: 4, label: "Project Reader" },
];

const QuestionareData = [
  {
    value: 1,
    isCheck: false,
    name: "Very uncomfortable",
  },
  {
    value: 2,
    isCheck: false,
    name: "Slightly uncomfortable",
  },
  {
    value: 3,
    isCheck: false,
    name: "Slightly comfortable",
  },
  {
    value: 4,
    isCheck: false,
    name: "Very comfortable",
  },
];

const BCVADistanceData = [
  { value: 0, label: "6/6", logmar: "0" },
  { value: 1, label: "6/9", logmar: "0.18" },
  { value: 2, label: "6/12", logmar: "0.30" },
  // { value: 3, label: "6/18", logmar: "" },
  { value: 3, label: "6/24", logmar: "0.60" },
  { value: 4, label: "6/36", logmar: "0.78" },
  { value: 5, label: "6/60", logmar: "1" },

  { value: 6, label: "5/60", logmar: "1.07" },
  { value: 7, label: "4/60", logmar: "1.17" },
  { value: 8, label: "3/60", logmar: "1.30" },
  { value: 9, label: "2/60", logmar: "1.45" },
  { value: 10, label: "1/60", logmar: "1.77" },

  // { value: 7, label: "CF 5m", logmar: "" },
  // { value: 8, label: "CF 4m", logmar: "" },
  // { value: 9, label: "CF 3m", logmar: "" },
  // { value: 10, label: "CF 2m", logmar: "" },
  // { value: 11, label: "CF 1m", logmar: "" },
  // { value: 12, label: "CF 0.5m", logmar: "" },
  { value: 11, label: "CF close to face", logmar: "2" },
  { value: 12, label: "Hand movements", logmar: "3" },
  { value: 13, label: "PL +", logmar: "-" },
  { value: 14, label: "PL -", logmar: "-" },
];

const BCVANearData = [
  { value: 0, label: "N6" },
  { value: 1, label: "N8" },
  { value: 2, label: "N10" },
  { value: 3, label: "N12" },
  { value: 4, label: "N18" },
  { value: 5, label: "N24" },
  { value: 6, label: "N36" },
  { value: 7, label: "< N36" },
];

const StateList = [
  { value: 0, label: "Tamil Nadu" },
  { value: 1, label: "Kerala" },
  { value: 2, label: "Karnataka" },
];
const logMarArray = [
  {
    id: 0,
    letterScore: "5",
    LogMar: "1.6",
    snellenEquivalent: "20/800",
  },
  {
    id: 1,
    letterScore: "10",
    LogMar: "1.5",
    snellenEquivalent: "20/640",
  },
  {
    id: 2,
    letterScore: "15",
    LogMar: "1.4",
    snellenEquivalent: "20/500",
  },
  {
    id: 3,
    letterScore: "20",
    LogMar: "1.3",
    snellenEquivalent: "20/400",
  },
  {
    id: 4,
    letterScore: "25",
    LogMar: "1.2",
    snellenEquivalent: "20/320",
  },
  {
    id: 5,
    letterScore: "30",
    LogMar: "1.1",
    snellenEquivalent: "20/250",
  },
  {
    id: 6,
    letterScore: "35",
    LogMar: "1.0",
    snellenEquivalent: "20/200",
  },
  {
    id: 7,
    letterScore: "40",
    LogMar: "0.9",
    snellenEquivalent: "20/160",
  },
  {
    id: 8,
    letterScore: "45",
    LogMar: "0.8",
    snellenEquivalent: "20/125",
  },
  {
    id: 9,
    letterScore: "50",
    LogMar: "0.7",
    snellenEquivalent: "20/100",
  },
  {
    id: 10,
    letterScore: "55",
    LogMar: "0.6",
    snellenEquivalent: "20/80",
  },
  {
    id: 11,
    letterScore: "60",
    LogMar: "0.5",
    snellenEquivalent: "20/63",
  },
  {
    id: 12,
    letterScore: "65",
    LogMar: "0.4",
    snellenEquivalent: "20/50",
  },
  {
    id: 13,
    letterScore: "70",
    LogMar: "0.3",
    snellenEquivalent: "20/40",
  },
  {
    id: 14,
    letterScore: "75",
    LogMar: "0.2",
    snellenEquivalent: "20/32",
  },
  {
    id: 15,
    letterScore: "80",
    LogMar: "0.1",
    snellenEquivalent: "20/25",
  },
  {
    id: 16,
    letterScore: "85",
    LogMar: "0.0",
    snellenEquivalent: "20/20",
  },
  {
    id: 17,
    letterScore: "90",
    LogMar: "-0.1",
    snellenEquivalent: "20/15",
  },
  {
    id: 18,
    letterScore: "95",
    LogMar: "-0.2",
    snellenEquivalent: "20/12",
  },
];

const SearchByType = [
  { value: 1, label: "Camp Details" },
  { value: 2, label: "Vision Center" },
];

const MocDocType = [
  { value: 1, label: "Patient ID" },
  { value: 2, label: "Mobile No" },
];

export {
  userRoleData,
  videoCategoryData,
  genderData,
  statusData,
  YesOrNo,
  regionData,
  educationData,
  lensStatusData,
  anteriorChamberData,
  YesOrNoUnknowData,
  durationData,
  medicationData,
  medicationTypeData,
  comorbiditiesData,
  unableToGradeData,
  optometristAdviceData,
  optometristReferralData,
  imageQuadrant,
  insufOrSufficient,
  suggestedStrategyData,
  othersData,
  filterData,
  DRDataOne,
  DRDataTwo,
  DRData,
  QuestionareData,
  BCVADistanceData,
  BCVANearData,
  logMarArray,
  StateList,
  SearchByType,
  MocDocType,
  macEduma,
};
