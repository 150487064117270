import React from "react";
import CampService from "../../../api/services/CampService";
import applicationConstants from "../constants/applicationContants";

export const AppContext = React.createContext();

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patientName: "",
      token: "",
      campAddress: "",
      campDate: "",
      campId: "",
      patientReport: [],
      patientReportCount: [],
      patientReportStateData:[],
    };
  }

  updateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  logout = () => {
    this.setState({
      isLogin: false,
    });
  };

  getCampList = async () => {
    // console.log('________camp token', this.state.token);
    const hospitalName = await localStorage.getItem("hospitalName");
    const roleId = await localStorage.getItem("roleId");
    const data = roleId === applicationConstants.admin ? "" : hospitalName;
    await CampService.getCampList(this.state.token, data)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0 && result.data !== undefined) {
                const data = await result.data;
                const currentCamp = await data.find(
                  (item) => item.campStatus === 1 && item.currentCamp === 1
                );
                if (currentCamp) {
                  await this.setState({
                    campAddress: currentCamp.address,
                    campDate: currentCamp.date,
                    campId: currentCamp.campId,
                  });
                  await localStorage.setItem(
                    "campLocation",
                    currentCamp.location
                  );
                  await localStorage.setItem("campDate", currentCamp.date);
                  await localStorage.setItem(
                    "currentCamp",
                    currentCamp.campCode
                  );
                } else {
                  const setCurrentCamp = await data.find(
                    (item) =>
                      item.campStatus === 1 &&
                      item.hospitalName === hospitalName
                  );
                  if (setCurrentCamp) {
                    await this.setState({
                      campAddress: setCurrentCamp.address,
                      campDate: setCurrentCamp.date,
                      campId: setCurrentCamp.campId,
                    });
                    await localStorage.setItem(
                      "campLocation",
                      setCurrentCamp.location
                    );
                    await localStorage.setItem("campDate", setCurrentCamp.date);
                    await localStorage.setItem(
                      "currentCamp",
                      setCurrentCamp.campCode
                    );
                  }
                }
              } else {
                alert(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          updateValue: this.updateValue,
          getCampList: this.getCampList,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
