import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import FormValidator from "../../../utils/FormValidator";
import DataTable from "react-data-table-component";
import applicationConstants from "../../../constants/applicationContants";
import { dataTablesStyledTheme } from "../../../utils/dataTablesStyledTheme";
import { dataTablesMobileStyledTheme } from "../../../utils/dataTablesStyledTheme";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";
import TablesCatColumns from "./TablesCatColumns";
import Category from "../../../../../api/services/Category";
import windowSize from "react-window-size";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import IsInputInError from "../../../utils/isInputInError";
import BackArrow from "../../../customComponents/BackArrow/BackArrow";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


class CategoryList extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.validator = new FormValidator([
      // {
      //   field: "category_code",
      //   constraints: [
      //     {
      //       method: "isEmpty",
      //       validWhen: false,
      //       message: "Please enter category code",
      //     },
      //   ],
      //   touched: false,
      // },
      {
        field: "category_name",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter category name",
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
      catList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      isConfirmModal: false,
      deleteItem: '',
      id: '',
      catstatus: 0,
      // category_code: props.data
      //   ? props.data.category_code
      //     ? props.data.category_code
      //     : ""
      //   : "",
        category_name: props.data ? (props.data.category_name ? props.data.category_name : "") : "",       
    };
    this.categoryList = this.categoryList.bind(this);    
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
  }

  async componentDidMount() {
    const catstatus = await localStorage.getItem("catstatus");

    await this.setState({
      isLoading: true,
      catstatus: catstatus,
    })

    // if (this.state.category_code) {
    //   this.validator.touched("categoryCode");
    // }
    if (this.state.category_name) {
      this.validator.touched("categoryName");
    }

    this.categoryList();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isModal !== prevProps.isModal) {
      this.categoryList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.categoryList();
    }
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }  

  categoryList = async () => {
    const token = await localStorage.getItem("token");    

    await this.setState({
      isLoading: true,
    });

    Category.getVideoCategories(token)
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          console.log(res)
          try {           
            res.text().then(async (res) => {
              // console.log(res)
              let result = JSON.parse(res);
              console.log(result)
              if (result.code === 0) {
                const data = result.data;
                // console.log(data)

                this.setState({
                  catList: data,
                });                
                await this.setState({
                  isLoading: false,
                });
              } else {
                await this.setState({
                  isLoading: false,
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  
  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };  

  handleAction = (item) => {   
    console.log(item)
    this.setState({
      // category_code:item.category_code,
      category_name:item.category_name,
      id: item.id,
    })
    // console.log(this.state)
  };


  handleDelete = async (item) => {
    // console.log(item)
    this.setState({
      isConfirmModal: true,
      deleteItem: item,
    })
  }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }

  handleConfirmBtn = async () => {
    const id = this.state.deleteItem ? this.state.deleteItem.id : '';
    const token = await localStorage.getItem("token");
    if (id) {
      // console.log(id)
      Category.deleteVideoCat(token, id)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                console.log(res)
                let result = JSON.parse(res);
                console.log(result)
                if (result.code === 0) {
                  this.setState({
                    isConfirmModal: false
                  });
                  alertSuccessMessage(`Video Category deleted  successfully`)
                  this.categoryList();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } 

  clear = () =>{
    this.setState({
      category_name: " ",
    });
  }
  

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const data = {
        // category_code: this.state.category_code,
        category_name: this.state.category_name.trimStart(),
        id: this.state.id,       
      };           
        Category.addVideoCategory(data, token)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                console.log(res)
                let result = JSON.parse(res);
                console.log(result)
                if (result.code === 0) {            
                  await this.setState({
                    isLoading: false,
                  })
                  this.categoryList();
                  this.clear();         
                  alertSuccessMessage("Video Category Added Successfully");               
                } else {
                  alertDangerConfirmMessage(`${result.message}`);                  
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });   
      } 
      else {
        alertDangerConfirmMessage(`${errorMessage[0]}`);
      }
  } 

  handleBackArrow = () => {;}  
 

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <RenderPage
        id="registration-page"
        className="render-page top"
        containerType="container-fluid"
      >
        <Strip
          id="tst-registration"
          className="strip strip-no-padding video-table"
          containerType="container-fluid" 
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
            <Link
                      to={`/videoList`}                      
                    >
              <BackArrow  
                title="Manage Category"
                handleBackArrow={this.handleBackArrow}
              />{" "}
              </Link>
              <Row className="bg-light-white bg-white p-4">               
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="bg-white no-mobile--padding p-0"
                >
                  <Form className="form-fields field-col-4">
                    {/* <FormGroup>
                      <Label className="common-label">Category Code <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.category_code.isInvalid
                      )}
                      >
                        <Input
                          type="text"
                          placeholder="Category Code"
                          name="category_code"
                          id="category_code"
                          className="form-control common-form-control"
                          onChange={this.handleChange}
                          value={this.state.category_code}
                        />
                        <p className="p-small help-block">
                          {validation.category_code.message}
                        </p>
                      </div>
                    </FormGroup> */}

                    <FormGroup>
                      <Label className="common-label">Category Name <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.category_name.isInvalid
                      )}
                      >
                        <Input
                          type="text"
                          placeholder="Category Name"
                          name="category_name"
                          id="category_name"
                          className="form-control common-form-control"
                          onChange={this.handleChange}
                          value={this.state.category_name}
                        />
                        <p className="p-small help-block">
                          {validation.category_name.message}
                        </p>
                      </div>
                    </FormGroup>

                    <FormGroup className="list-btns">

                      <Button
                        className="btn custom-theme-btn save-btn"
                        onClick={this.handleSubmit}                       
                      >
                        SUBMIT
                      </Button>
                    </FormGroup>

                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="table-responsive no-margin">
            {this.state.catList.length > 0 ? (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <DataTable
                  title=""
                  columns={TablesCatColumns(this.handleAction, this.handleDelete)}
                  data={this.state.catList}
                  pagination
                  className="data-table"
                  persistTableHead={false}
                  customStyles={
                    this.props.windowWidth > applicationConstants.mobileBreakPoint
                      ? dataTablesStyledTheme
                      : dataTablesMobileStyledTheme
                  }
                  />
              </Col>
            ) : (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <div className="br-light-smoke bg-white p-5 br-4px">
                  {!this.state.isLoading ? (
                    <p className="mb-0 align-center internal-page__title">
                      No Category
                    </p>
                  ) : (
                    <Loader isLoader={false} />
                  )}
                </div>
              </Col>
            )}
          </Row>
          {this.state.isConfirmModal ? (
            <MessageConfirmation
              isModal={this.state.isConfirmModal}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
              message={`Are you sure you want to delete this category?`}
            />
          ) : null}
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
          {this.state.isLoading ? <Loader isLoader={false} /> : null}
        </Strip>
      </RenderPage>
    );
  }
}

export default windowSize(CategoryList);
