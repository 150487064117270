import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";
import applicationConstants from "../../import/ui/constants/applicationContants";

const PatientService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getPatientBasedOnId: async (token, patientId) => {
    const { baseUrl, getPatientById } = ApiConfig;

    const url = baseUrl + getPatientById + `=${patientId}`;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const patinetBasedOnIdServicePromise = Fetch(
      url,
      "GET",
      headers,
      "",
      "services_patientBasedOnIdService",
      true
    );
    return patinetBasedOnIdServicePromise;
  },

  getPatinetList: async (data, token) => {
    const { baseUrl, getPatientList } = ApiConfig;

    const url = baseUrl + getPatientList;

    const params = JSON.stringify({
      campId: `${data.campId}`,
      status: `${data.status}`,
      startDate: `${data.startDate}`,
      endDate: `${data.endDate}`,
      limit: `${data.limit}`,
      offset: `${data.offset}`,
      hospitalName: `${data.hospitalName}`,
      visionCentreId: `${data.visionCentreId}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const getPatinetListServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_getPatinetListService",
      true
    );
    return getPatinetListServicePromise;
  },

  addOrUpdateRegistration: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;
   

    const params = JSON.stringify({
      campId: `${data.campId}`,
      visionCentreId: (data.patientId === "") ? `${data.visionCenterId}` : data.visionCenters,
      patientId: `${data.patientId}`,
      mocDocPhid: `${data.mocDocPhid}`,
      assignedHospital: `${data.hospitalName}`,
      createdBy:`${data.loginId}`,
       updatedBy:`${data.loginId}`,
      registerInfo: {
        patientName: `${data.patientName}`,
        gender: data.gender,
        age: data.age,
        guardianOrParent: `${data.guardianOrParent}`,
        aadharNumber: `${data.aadharNumber}`,
        address: `${data.address}`,
        contactNo: `${data.contactNo}`,
        occupation: `${data.occupation}`,
        education: data.education,
        dateOfExamination: `${data.dateOfExamination}`,
        registrationDuration: `${data.registrationDuration}`,
      },
    });
    if(data.patientId === ""){
      delete params.updatedBy;
    } else {
      delete params.createdBy;
    }

    console.log("checking addOrUpdateRegistration params", params);

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateRegistrationServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateRegistration",
      true
    );
    return addORUpdateRegistrationServicePromise;
  },

  addOrUpdateHealthInfo: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    const params = JSON.stringify({
      campId: data.campId,
      patientId: data.patientId,
      visionCentreId: data.visionCenterId,
      healthInfo: {
        height: data.height,
        weight: data.weight,
        bloodPressure: data.bloodPressure,
        bloodSugar: data.bloodSugar,
        hypertension: data.hypertension,
        diabetes: data.diabetes,
        duration: data.duration,
        familyHistory: data.familyHistory,
        medication: data.medication,
        medicationType: data.medicationType,
        coMorbidities: `[${data.coMorbidities}]`,
        healthInfoDuration: `${data.healthInfoDuration}`,
      },
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateHealthInfoServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateHealthInfo",
      true
    );
    return addORUpdateHealthInfoServicePromise;
  },

  addOrUpdateRefraction: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    const params = JSON.stringify({
      campId: data.campId,
      patientId: data.patientId,
      refractionInfo: {
        REBCVADistance: data.REBCVADistance,
        REBCVADistanceLogmar: data.REBCVADistance,
        REBCVANear: data.REBCVANear,
        REBCVALensStatus: data.REBCVALensStatus,
        REAnteriorChamber: data.REAnteriorChamber,
        LEBCVADistance: data.LEBCVADistance,
        LEBCVADistanceLogmar: data.LEBCVADistance,
        LEBCVANear: data.LEBCVANear,
        LEBCVALensStatus: data.LEBCVALensStatus,
        LEAnteriorChamber: data.LEAnteriorChamber,
        refractionInfoDuration: `${data.refractionInfoDuration}`,
        REReason: data.REReason,
        LEReason: data.LEReason,
      },
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateRefractionServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateRefraction",
      true
    );
    return addORUpdateRefractionServicePromise;
  },

  addOrUpdateDiagnosis: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    let REDiagonsis = {
      REAbnormalDisc: data.abnormalDisc,
      REDR: `${data.DR}`,
      REUnableToGrade: data.unableToGrade,
      REOther: `${data.other}`,
      REOtherDiagnosis:`${data.otherdiagnosis}`,
      RERDR: data.RDR,
      REPreDilationImaging: data.preDilationImaging,
      REPostDilationImaging: data.postDilationImaging,
      REDuration: `${data.Duration}`,
      createdBy:`${data.loginId}`,
      updatedBy:`${data.loginId}`,
    };
    let LEDiagonsis = {
      LEAbnormalDisc: data.abnormalDisc,
      LEDR: `${data.DR}`,
      LEUnableToGrade: data.unableToGrade,
      LEOther: `${data.other}`,
      LEOtherDiagnosis:`${data.otherdiagnosis}`,
      LERDR: data.RDR,
      LEPreDilationImaging: data.preDilationImaging,
      LEPostDilationImaging: data.postDilationImaging,
      LEDuration: `${data.Duration}`,
      
    };

    let params = {};
    params = JSON.stringify({
      createdBy:`${data.loginId}`,
      updatedBy:`${data.loginId}`,
    });
    if (data.diagnosisType === applicationConstants.optoRE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        optoREDiag: REDiagonsis,
      });
    }
    if (data.diagnosisType === applicationConstants.optoLE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        optoLEDiag: LEDiagonsis,
      });
    }
    if (data.diagnosisType === applicationConstants.AtCampRE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        campDrREDiag: REDiagonsis,
      });
    }
    if (data.diagnosisType === applicationConstants.AtCampLE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        campDrLEDiag: LEDiagonsis,
      });
    }
    if (data.diagnosisType === applicationConstants.AtTeleRE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        teleDrREDiag: REDiagonsis,
      });
    }
    if (data.diagnosisType === applicationConstants.AtTeleLE) {
      params = JSON.stringify({
        patientId: `${data.patientId}`,
        teleDrLEDiag: LEDiagonsis,
      });
    }

    if(data.patientId === ""){
      delete params.updatedBy;
    } else {
      delete params.createdBy;
    }
    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateREServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateRE",
      true
    );
    return addORUpdateREServicePromise;
  },

  addOrUpdateAdvice: async (data, token, questionare) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    let advice = {
      advice: `[${data.advice}]`,
      comments: data.comments,
      referral: data.referral,
      adviceStatus: data.adviceStatus,
      adviceDuration: `${data.adviceDuration}`,
    };


    const optoAdvice = {
      advice: `[${data.advice}]`,
      comments: data.comments,
      referral: data.referral,
      adviceStatus: data.adviceStatus,
      adviceDuration: `${data.adviceDuration}`,
      questionnaries: JSON.parse(questionare),
    };

    let params = {};

    if (data.adviceType === applicationConstants.optoAdvice) {
      params = JSON.stringify({
        patientId: data.patientId,
        optoAdvice: optoAdvice,
      });
    }
    if (data.adviceType === applicationConstants.campAdvice) {
      params = JSON.stringify({
        patientId: data.patientId,
        campDrAdvice: advice,
      });
    }
    if (data.adviceType === applicationConstants.teleAdvice) {
      params = JSON.stringify({
        patientId: data.patientId,
        teleDrAdvice: advice,
      });
    }
    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateAdviceServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateAdvice",
      true
    );
    return addORUpdateAdviceServicePromise;
  },

  getAnnotationByPatientId: async (token, patientId) => {
    const { baseUrl, getAnnotationById } = ApiConfig;

    const url = baseUrl + getAnnotationById + `=${patientId}`;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const patinetBasedOnIdServicePromise = Fetch(
      url,
      "GET",
      headers,
      "",
      "services_patientBasedOnIdService",
      true
    );
    return patinetBasedOnIdServicePromise;
  },

  addORUpdateAnnotation: async (data, token) => {
    const { baseUrl, addORUpdateAnnotation } = ApiConfig;

    const url = baseUrl + addORUpdateAnnotation;

    const params = JSON.stringify({
      patientId: `${data.patientId}`,
      annotationPoint: data.annotationPoint,
      signedUrl: `${data.signedUrl}`,
      readerType: `${data.readerType}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateAnnotationServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateAnnotation",
      true
    );
    return addORUpdateAnnotationServicePromise;
  },

  addOrUpdateReader: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    const params = JSON.stringify({
      campId: `${data.campId}`,
      patientId: `${data.patientId}`,
      readerGrading: {
        readerType: `${data.readerType}`,
        saveAsSrReader: data.saveAsSrReader,
        REimageQuality: data.REImageQuality,
        REgradeDR: data.REGradeDR,
        REsuggestedStrategy: data.RESuggestedStrategy,
        REretinalDisease: data.RERetinalDiseases,
        REOthers: data.REOthers,
        LEimageQuality: data.LEImageQuality,
        LEgradeDR: data.LEGradeDR,
        LEsuggestedStrategy: data.LESuggestedStrategy,
        LEretinalDisease: data.LERetinalDiseases,
        LEOthers: data.LEOthers,
        userId: `${data.userId}`,
        userName: `${data.userName}`
      },
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateReaderServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateReader",
      true
    );
    return addORUpdateReaderServicePromise;
  },
  
  addOrUpdateProjectReaderGrading: async (data, token) => {
    const { baseUrl, updatePatient } = ApiConfig;

    const url = baseUrl + updatePatient;

    const params = JSON.stringify({
      campId: `${data.campId}`,
      patientId: `${data.patientId}`,
      prReaderGrading: {
        sharpness: data.sharpness,
        reflexArtifacts: data.reflexArtifacts,
        illuminationContrast: data.illuminationContrast,
        protocolComplianceGradingMacula: data.protocolComplianceGradingMacula,
        superiorTemporalArcade: data.superiorTemporalArcade,
        inferiorTemporalArcade: data.inferiorTemporalArcade,
        superiorNasalArcade: data.superiorNasalArcade,
        inferiorNasalArcade: data.inferiorNasalArcade,
        signedUrl: `${data.signedUrl}`,
        userId: `${data.userId}`,
      },
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addORUpdateProjectReaderServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addORUpdateProjectReader",
      true
    );
    return addORUpdateProjectReaderServicePromise;
  },

  deletePatient: async (token, patientId) => {
    const { baseUrl, deletePatient } = ApiConfig;

    const url = baseUrl + deletePatient + `=${patientId}`;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });


    const patientDeleteServicePromise = await Fetch(
      url,
      "GET",
      headers,
      '',
      "services_PatientDeleteService",
      true
    );
    return patientDeleteServicePromise;
  },
  getRemidioPatientInfo: async (data, token) => {
    const { baseUrl, getRemidioPatientInfo } = ApiConfig;

    const url = baseUrl + getRemidioPatientInfo;

    const params = JSON.stringify({
      patientId: `${data.patientId}`,
      visionCenter: `${data.visionCenter}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const getRemidioPatientServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_RemidioPatientListService",
      true
    );
    return getRemidioPatientServicePromise;
  },

};

export default PatientService;
