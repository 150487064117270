import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";
const FormData = require('form-data');
const MocDocService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getPatientList: async (data,token) => {
    const { baseUrl, getMocdocPatientInfo } = ApiConfig;

    const url = baseUrl + getMocdocPatientInfo;

    const headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });
    const val = {
      phid:`${data.PhId}`, 
      mobile:data.mobileNo,
  };
    const params = new URLSearchParams(val).toString();
  
  console.log(params);
    const MocDocServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_MocDocService",
      true
    );
    return MocDocServicePromise;
  },


};

export default MocDocService;
