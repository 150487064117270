import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import Select from "react-select";
import Strip from "../../../customComponents/Strip/Strip";
import DatePicker from "react-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DateFormat } from "../../../utils/displayFormatter";
import CampService from "../../../../../api/services/CampService";
import { YesOrNo, statusData, regionData } from "../../../utils/dropdownData";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import { isNumeric } from "../../../utils/customValidators";
import Loader from "../../../customComponents/Loader/Loader";
import applicationConstants from "../../../constants/applicationContants";

class AddCamp extends React.Component {
  constructor(props) {  
    console.log(props);
    super(props);
    this.validator = new FormValidator([
      {
        field: "location",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter location",
          }, 
        ],
        touched: false,
      },
      {
        field: "address",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter address",
          },
        ],
        touched: false,
      },
      {
        field: "pinCode",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter pincode",
          },
          {
            method: isNumeric,
            validWhen: true,
            message: "Please enter pincode",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "pincode should be  max 6 characters",
            args: [{ max: 6 }],
          },
        ],
        touched: false,
      },
      {
        field: "region",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select region",
          },
        ],
        touched: false,
      },
      {
        field: "campStatus",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select camp status",
          },
        ],
        touched: false,
      },
      {
        field: "currentCamp",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select current Camp",
          },
        ],
        touched: false,
      },
      // {
      //   field: "assignedHospital",
      //   constraints: [
      //     {
      //       method: "isEmpty",
      //       validWhen: false,
      //       message: "please select assigned Hospital option",
      //     },
      //   ],
      //   touched: false,
      // },
    ]);
    this.state = {
      location: props.data
        ? props.data.location
          ? props.data.location
          : ""
        : "",
      address: props.data ? (props.data.address ? props.data.address : "") : "",
      pinCode: props.data ? (props.data.pinCode ? props.data.pinCode : "") : "",
      campDate: props.data ? (props.data.date ? props.data.date : "") : "",
      date: props.data
        ? props.data.date
          ? props.data.date
          : new Date()
        : new Date(),
      region: props.data
        ? regionData[props.data.region].label
          ? regionData[props.data.region]
          : ""
        : regionData[0],
      // visionCenter: props.visionCenterList ? props.visionCenterList : [],
      visionCenter:[0],
      stateList: props.stateList ? props.stateList : [],
      stateListId: props.state_name ? props.state_name[0] : "",
      visionCenters: props.center_name ? props.center_name[0] : "",
      campStatus: props.data
        ? statusData[props.data.campStatus].label
          ? statusData[props.data.campStatus]
          : ""
        : statusData[1],
      modal: props.isModal ? props.isModal : false,
      currentCamp: props.data
        ? YesOrNo[props.data.currentCamp].label
          ? YesOrNo[props.data.currentCamp]
          : ""
        : YesOrNo[0],
      campCode: props.data
        ? props.data.campCode
          ? props.data.campCode
          : ""
        : "",
      isNewCamp: props.isNewCamp ? props.isNewCamp : false,
      hospitalName: props.hospitalName,
      isLoading: false,
      hospitalList: [0],
      assignedHospital: props.assignedHospital ? props.assignedHospital[0] : "",
      roleId: "",
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleStateList = this.handleStateList.bind(this);
    
  }

  async componentDidMount() {
    if (this.state.location) {
      this.validator.touched("location");
    }
    if (this.state.address) {
      this.validator.touched("address");
    }
    if (this.state.pinCode) {
      this.validator.touched("pinCode");
    }
    if (this.state.region) {
      this.validator.touched("region");
    }
    if (this.state.campStatus) {
      this.validator.touched("campStatus");
    }
    if (this.state.currentCamp) {
      this.validator.touched("currentCamp");
    }
    if (this.state.state) {
      this.validator.touched("state");
    }

    // if (this.state.assignedHospital) {
    //   this.validator.touched("assignedHospital");
    // }
    const roleId = await localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });
    if(this.props.state_name){
      this.handleStateList(this.props.state_name[0]);
    }
    
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleAssignedHospital = (item) => {
    this.validator.touched("assignedHospital");
    this.setState({
      assignedHospital: item,
    });
  };

  handleVisionCenter = (item) => {
    // console.log(item)
    this.validator.touched("visionCenter");
    this.setState({
      visionCenters: item,
    });
  };

  handleCurrentCamp = (item) => {
    this.validator.touched("currentCamp");
    
    this.setState({
      currentCamp: item,
    });
  };

  handleStateList = (item) => {
    console.log("camp_info",item)
    // alert(item);
    this.validator.touched("state");    
    const hostpitals = this.props.hospitalList.filter((el)=> {
      // console.log(el)
      return el.state === item.value;

    });
   
    const visionCenter = this.props.visionCenterList.filter((el)=> {
      return el.state === item.value;
    });

    this.setState({
      hospitalList:hostpitals,
      stateListId: item,
      visionCenter:visionCenter,
    });

    console.log(visionCenter);
  };

  handleCampStatus = (item) => {
    this.validator.touched("campStatus");
    if (item.value === 0) {
      this.setState({
        currentCamp: YesOrNo[0],
      });
    }
    if (item.label === statusData[0].label) {
      if (this.state.isNewCamp) {
        alertDangerConfirmMessage(
          `creating a new camp with status as inactive will be updated as active.`
        );
      }
    }
    this.setState({
      campStatus: item,
    });
  };

  handleRegion = (item) => {
    this.validator.touched("region");
    this.setState({
      region: item,
    });
  };

  handleDate = (date) => {
    this.setState({ date });
  };

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const data = {
        campCode: this.state.campCode,
        location: this.state.location,
        address: this.state.address,
        pinCode: this.state.pinCode,
        date: !this.state.isNewCamp
          ? this.state.campDate
          : await DateFormat(this.state.date),
        region: this.state.region.value,
        campStatus: this.state.campStatus.value,
        currentCamp: this.state.currentCamp.value,
        visionCenterId: this.state.visionCenters ? this.state.visionCenters.value : "",
        questionAnwer: "",
        state: this.state.stateListId.value,
        // hospitalName: this.state.assignedHospital ? this.state.assignedHospital.value : '',
        hospitalName:
          this.state.roleId === applicationConstants.admin
            ? this.state.assignedHospital
              ? this.state.assignedHospital.value
              : -1
            : this.state.hospitalName,
      };
      // console.log(data)
      await this.setState({
        isLoading: true,
      });
      CampService.addOrUpdateCamp(data, token)
        .then(async (res) => {    
          // console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                // console.log(res)
                let result = JSON.parse(res);
                if (result.code === 0) {
                  // console.log(result.code)
                  //console.log(JSON.stringify(result));
                  localStorage.setItem(
                    "campStatus",
                    this.state.campStatus.value
                  );
                  const message = this.state.campCode ? "Camp Updated Successfully" : "Camp Added Successfully"
                  alertSuccessMessage(message);
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.toggle();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.isNewCamp ? "Add Camp" : "Update Camp"}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                   
                    <Form>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Location*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.location.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Location"
                              id="location"
                              name="location"
                              className="form-control common-form-control"
                              value={this.state.location}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.location.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Address*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.address.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Address"
                              id="address"
                              name="address"
                              className="form-control common-form-control"
                              value={this.state.address}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.address.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Pin Code*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.pinCode.isInvalid
                            )}
                          >
                            <Input
                              type="number"
                              placeholder="Pin Code"
                              id="pinCode"
                              name="pinCode"
                              className="form-control common-form-control"
                              value={this.state.pinCode}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.pinCode.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Date*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          {this.state.campDate === "" ? (
                            <DatePicker
                              onChange={this.handleDate}
                              value={new Date(this.state.date)}
                              format="dd/MM/yyyy"
                              className="date-picker-container"
                              minDate={new Date()}
                            />
                          ) : (
                            <Input
                              type="text"
                              placeholder="camp date"
                              id="campDate"
                              name="campDate"
                              className="form-control common-form-control"
                              value={this.state.campDate}
                              readOnly
                            />
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">State:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            // className={IsInputInError(
                            //   validation.state.isInvalid
                            // )}
                          >
                            <Select
                              value={this.state.stateListId}
                              onChange={this.handleStateList}
                              options={this.state.stateList}
                              classNamePrefix="common-select-drop-down"
                              name="state"
                              // isDisabled={
                              //   this.state.campStatus.value === 0 ? true : false
                              // }
                              isSearchable={false}
                            />
                            {/* <p className="p-small help-block">
                              {validation.state.message}
                            </p> */}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">
                            Assigned Hospital*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          {this.state.roleId !== applicationConstants.admin ? (
                            <Input
                              type="text"
                              placeholder="hospitalName"
                              id="hospitalName"
                              name="hospitalName"
                              className="form-control common-form-control"
                              value={this.state.hospitalName}
                              readOnly
                            />
                          ) : (
                            <Select
                              value={this.state.assignedHospital}
                              onChange={this.handleAssignedHospital}
                              options={this.state.hospitalList}
                              classNamePrefix="common-select-drop-down"
                              name="status"
                              isSearchable={false}
                            />
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">
                            Vision Center:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          
                            <Select
                              value={this.state.visionCenters}
                              onChange={this.handleVisionCenter}
                              options={this.state.visionCenter}
                              classNamePrefix="common-select-drop-down"
                              name="visionCenter"
                              isSearchable={false}
                            />
                          
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Region:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.region.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.region}
                              onChange={this.handleRegion}
                              options={regionData}
                              classNamePrefix="common-select-drop-down"
                              name="region"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.region.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Camp Status:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.campStatus.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.campStatus}
                              onChange={this.handleCampStatus}
                              options={statusData}
                              classNamePrefix="common-select-drop-down"
                              name="campStatus"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.campStatus.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Current Camp:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.currentCamp.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.currentCamp}
                              onChange={this.handleCurrentCamp}
                              options={YesOrNo}
                              classNamePrefix="common-select-drop-down"
                              name="currentCamp"
                              isDisabled={
                                this.state.campStatus.value === 0 ? true : false
                              }
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.currentCamp.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={this.handleSubmit}
                              disabled={
                                this.state.location !== "" &&
                                this.state.address !== "" &&
                                this.state.pincode !== "" &&
                                this.state.date !== "" &&
                                this.state.stateListId !== "" &&
                                this.state.assignedHospital
                                  ? false
                                  : true
                              }
                            >
                              {this.state.isNewCamp ? "Add" : "Update"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default AddCamp;
