import React from "react";
import { Row, Col, Input, Form, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import Strip from "../../customComponents/Strip/Strip";
import PatientService from "../../../../api/services/PatientService";
import {
  optometristAdviceData,
  optometristReferralData,
  userRoleData,
  QuestionareData,
} from "../../utils/dropdownData";
import BackArrow from "../../customComponents/BackArrow/BackArrow";
import applicationConstants from "../../constants/applicationContants";
import ConfirmationBackArrow from "../confirmationMessage/ConfirmationBackArrow";
import FormValidator from "../../utils/FormValidator";
import { alertDangerConfirmMessage } from "../confirmationMessage/ConfirmationMessage";
import IsInputInError from "../../utils/isInputInError";
import { commentsValidation } from "../../utils/customValidators";
import { getTimer } from "../../utils/displayFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader/Loader";

class Advice extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.validator = new FormValidator([
      {
        field: "advice",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select advice option",
          },
        ],
        touched: false,
      },
      // {
      //   field: "referral",
      //   constraints: [
      //     {
      //       method: "isEmpty",
      //       validWhen: false,
      //       message: "Please select referral option",
      //     },
      //   ],
      //   touched: false,
      // },
    ]);

    this.state = {
      advice: "",
      referral: "",
      comments: "",
      title: props.title ? props.title : "",
      adviceType: props.adviceType ? props.adviceType : "",
      isConfirmation: false,
      isEdit: false,
      isReset: false,
      roleId: "",
      isComments: false,
      timer: 0,
      campStatus: 0,
      Questionare: "",
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
      q11: "",
      isYes: false,
      isNo: false,
      firstCheckBoxData: QuestionareData,
      secondCheckBoxData: QuestionareData,
      thirdCheckBoxData: QuestionareData,
      adviceDuration: 0,
      isLoading: false,
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const patientName = await localStorage.getItem("patientName");
    const roleId = await localStorage.getItem("roleId");
    const campStatus = await localStorage.getItem("campStatus");

    if (patientId) {
      await this.setState({
        isLoading: true,
        campStatus: campStatus,
      });
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if(data){
                    this.setState({
                      campStatus: data.campId || data.visionCentreId ? "1" : "0"
                    });
                  }
                  const adviceArrayList = [];
                  const referralArrayList = [];
                  // opto Advice
                  if (
                    this.state.adviceType === applicationConstants.optoAdvice
                  ) {
                    if (data.optoAdvice) {
                      const adviceItem = JSON.parse(data.optoAdvice.advice);
                      const referralItem = JSON.parse(data.optoAdvice.referral);

                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArrayList.push(optometristAdviceData[item]);
                        });
                      }
                      if (referralItem !== -1 && referralItem !== "-1") {
                        referralItem.map((item) => {
                          referralArrayList.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        patientId: patientId,
                        patientName: data.registerInfo.patientName,
                        advice:
                          adviceArrayList.length > 0
                            ? adviceArrayList
                            : optometristAdviceData[0],
                        referral:
                          referralArrayList.length > 0
                            ? referralArrayList
                            : "-1",
                        comments: data.optoAdvice.comments,
                        adviceDuration: parseInt(
                          data.optoAdvice.adviceDuration
                        ),
                      });

                      if (
                        data.optoAdvice.questionnaries !== null &&
                        data.optoAdvice.questionnaries !== "null" &&
                        data.optoAdvice.questionnaries !== "" &&
                        data.optoAdvice.questionnaries !== undefined
                      ) {
                        const questions = JSON.parse(
                          data.optoAdvice.questionnaries
                        );
                        if (
                          questions.q5 !== "" ||
                          questions.q5 !== undefined ||
                          questions.q5 !== -1
                        ) {
                          this.handleYesorNo(questions.q5);
                        }
                        if (
                          questions.q9 !== "" ||
                          questions.q9 !== undefined ||
                          questions.q9 !== -1
                        ) {
                          this.handleFirstCheckBox(questions.q9);
                        }
                        if (
                          questions.q10 !== "" ||
                          questions.q10 !== undefined ||
                          questions.q10 !== -1
                        ) {
                          this.handleSecondCheckBox(questions.q10);
                        }
                        if (
                          questions.q11 !== "" ||
                          questions.q11 !== undefined ||
                          questions.q11 !== -1
                        ) {
                          this.handlethridCheckBox(questions.q11);
                        }
                        this.setState({
                          q1: questions.q1,
                          q2: questions.q2,
                          q3: questions.q3,
                          q4: questions.q4,
                          q6: questions.q6,
                          q7: questions.q7,
                          q8: questions.q8,
                          Questionare:
                            data.optoAdvice.questionnaries != null
                              ? data.optoAdvice.questionnaries
                              : "",
                        });
                      }
                    }
                  }

                  // Camp Advice
                  if (
                    this.state.adviceType === applicationConstants.campAdvice
                  ) {
                    if (data.campDrAdvice) {
                      const adviceItem = JSON.parse(data.campDrAdvice.advice);
                      const referralItem = JSON.parse(
                        data.campDrAdvice.referral
                      );
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArrayList.push(optometristAdviceData[item]);
                        });
                      }
                      if (referralItem !== -1 && referralItem !== "-1") {
                        referralItem.map((item) => {
                          referralArrayList.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        patientId: patientId,
                        patientName: data.registerInfo.patientName,
                        advice:
                          adviceArrayList.length > 0
                            ? adviceArrayList
                            : optometristAdviceData[0],
                        referral:
                          referralArrayList.length > 0
                            ? referralArrayList
                            : "-1",
                        comments: data.campDrAdvice.comments,
                      });
                    }
                  }

                  // Tele Advice
                  if (
                    this.state.adviceType === applicationConstants.teleAdvice
                  ) {
                    if (data.teleDrAdvice) {
                      const adviceItem = JSON.parse(data.teleDrAdvice.advice);
                      const referralItem = JSON.parse(
                        data.teleDrAdvice.referral
                      );
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArrayList.push(optometristAdviceData[item]);
                        });
                      }
                      if (referralItem !== -1 && referralItem !== "-1") {
                        referralItem.map((item) => {
                          referralArrayList.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        patientId: patientId,
                        patientName: data.registerInfo.patientName,
                        advice:
                          adviceArrayList.length > 0
                            ? adviceArrayList
                            : optometristAdviceData[0],
                        referral:
                          referralArrayList.length > 0
                            ? referralArrayList
                            : "-1",
                        comments: data.teleDrAdvice.comments,
                      });
                    }
                  }
                  await this.setState({
                    isLoading: false,
                  });
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    }

    this.setState({
      patientId: patientId,
      patientName: patientName,
      roleId: roleId,
    });

    if (this.state.advice) {
      this.validator.touched("advice");
    }
    // if (this.state.referral) {
    //   this.validator.touched("referral");
    // }
    this.interval = setInterval(
      () => this.setState({ timer: this.state.timer + 1 }),
      1000
    );
  }

  handleoptometristAdvice = (item) => {
    this.validator.touched("advice");
    this.setState({
      advice: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleoptometristReferral = (item) => {
    this.setState({
      referral: item,
      isEdit: true,
      isReset: false,
    });
    //this.validator.touched("referral");
  };

  handleChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
      isEdit: true,
      isReset: false,
    });
  }

  handleComments = (event) => {
    this.validator.addConstraint({
      field: "comments",
      constraints: [
        {
          method: commentsValidation,
          validWhen: true,
          message: "please enter valid character",
        },
      ],
      touched: true,
    });
    this.setState({
      comments: event.target.value,
      isComments: commentsValidation(event.target.value) ? false : true,
    });
  };

  async handleSubmit() {
    clearInterval(this.interval);
    const token = await localStorage.getItem("token");
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    if (errorMessage.length <= 0) {
      const adviceArrayList = [];
      const referralArrayList = [];

      if (this.state.advice) {
        this.state.advice.map((item) => {
          adviceArrayList.push(item.value);
        });
      }

      if (
        this.state.referral !== "-1" &&
        this.state.referral !== null &&
        this.state.referral !== ""
      ) {
        this.state.referral.map((item) => {
          referralArrayList.push(item.value);
        });
      }

      const data = {
        patientId: this.state.patientId,
        advice: adviceArrayList,
        referral:
          referralArrayList.length > 0 ? `[${referralArrayList}]` : "-1",
        comments: this.state.comments,
        adviceType: this.state.adviceType,
        adviceDuration: this.state.adviceDuration + this.state.timer,
      };

      const questionare = JSON.stringify({
        q1: this.state.q1,
        q2: this.state.q2,
        q3: this.state.q3,
        q4: this.state.q4,
        q5: this.state.q5 !== "" ? this.state.q5 : -1,
        q6: this.state.q6,
        q7: this.state.q7,
        q8: this.state.q8,
        q9: this.state.q9 !== "" ? this.state.q9 : -1,
        q10: this.state.q10 !== "" ? this.state.q10 : -1,
        q11: this.state.q11 !== "" ? this.state.q11 : -1,
      });

      await this.setState({
        isLoading: true,
      });
      PatientService.addOrUpdateAdvice(data, token, questionare)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  //console.log(JSON.stringify(result));
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.history.push("/Dashboard");
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  handleBackArrow = () => {
    if (this.state.campStatus !== "0") {
      if (this.state.isEdit) {
        this.setState({
          isConfirmation: !this.state.isConfirmation,
          isReset: false,
        });
      } else {
        this.props.history.push("/Dashboard");
      }
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleCancelBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: false,
    });
  };

  handleResetBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: true,
    });
  };

  handleConfirmBtn = async () => {
    if (this.state.isReset) {
      this.setState({
        advice: -1,
        referral: -1,
        comments: "",
        isConfirmation: !this.state.isConfirmation,
        isReset: false,
      });

      if (this.state.adviceType === applicationConstants.optoAdvice) {
        this.setState({
          q1: "",
          q2: "",
          q3: "",
          q4: "",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          q9: "",
          q10: "",
          q11: "",
          isYes: false,
          isNo: false,
        });
        this.handlethridCheckBox(0);
        this.handleSecondCheckBox(0);
        this.handleFirstCheckBox(0);
      }
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleYesorNo = async (value) => {
    if (value) {
      await this.setState({
        isYes: true,
        isNo: false,
        q5: value,
      });
    } else {
      await this.setState({
        isNo: true,
        isYes: false,
        q5: value,
      });
    }
  };

  handleFirstCheckBox = async (value) => {
    let items = [];
    let obj = {};
    await this.state.firstCheckBoxData.map((item) => {
      if (item.value === value) {
        if (item.isCheck === true) {
          obj = {
            value: item.value,
            isCheck: false,
            name: item.name,
          };
          this.setState({
            q9: '',
          });
        } else {
          obj = {
            value: item.value,
            isCheck: true,
            name: item.name,
          };
          this.setState({
            q9: value,
          });
        }
      } else {
        obj = {
          value: item.value,
          isCheck: false,
          name: item.name,
        };
      }
      items.push(obj);
    });
    await this.setState({
      firstCheckBoxData: items,
    });
  };

  handleSecondCheckBox = async (value) => {
    let items = [];
    let obj = {};
    await this.state.secondCheckBoxData.map((item) => {
      if (item.value === value) {
        if (item.isCheck === true) {
          obj = {
            value: item.value,
            isCheck: false,
            name: item.name,
          };
          this.setState({
            q10: '',
          });
        } else {
          obj = {
            value: item.value,
            isCheck: true,
            name: item.name,
          };
          this.setState({
            q10: value,
          });
        }
      } else {
        obj = {
          value: item.value,
          isCheck: false,
          name: item.name,
        };
      }
      items.push(obj);
    });
    await this.setState({
      secondCheckBoxData: items,
    });
  };

  handlethridCheckBox = async (value) => {
    let items = [];
    let obj = {};
    await this.state.thirdCheckBoxData.map((item) => {
      if (item.value === value) {
        if (item.isCheck === true) {
          obj = {
            value: item.value,
            isCheck: false,
            name: item.name,
          };
          this.setState({
            q11: '',
          });
        } else {
          obj = {
            value: item.value,
            isCheck: true,
            name: item.name,
          };
          this.setState({
            q11: value,
          });
        }
      } else {
        obj = {
          value: item.value,
          isCheck: false,
          name: item.name,
        };
      }
      items.push(obj);
    });

    await this.setState({
      thirdCheckBoxData: items,
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    let disabledBtn = false;
    if (userRoleData[1].label === this.state.roleId) {
      disabledBtn =
        this.state.adviceType === applicationConstants.optoAdvice
          ? false
          : true;
    }
    if (userRoleData[2].label === this.state.roleId) {
      disabledBtn =
        this.state.adviceType === applicationConstants.optoAdvice ||
        this.state.adviceType === applicationConstants.campAdvice
          ? false
          : true;
    }
    return (
      <Strip
        id="tst-adivce"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row className="no-margin mb-4">
          <Col xs={12} sm={12} md={10} lg={12} xl={12} className="m-auto">
            <BackArrow
              title={this.state.title}
              handleBackArrow={this.handleBackArrow}
            />
            <Row className="bg-light-white bg-white p-4">
              {this.state.patientId ? (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="p-0"
                >
                  <p className="p-large internal-page__title text-capitalize align-left title-suvaGrey pat-title">
                    {this.state.patientName} - {this.state.patientId}
                  </p>
                </Col>
              ) : null}
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="bg-white p-0">
                <Form className="form-fields">
                  <FormGroup>
                    <Label className="common-label">Advice *</Label>
                    <div
                      className={IsInputInError(validation.advice.isInvalid)}
                    >
                      <Select
                        value={this.state.advice}
                        onChange={this.handleoptometristAdvice}
                        options={optometristAdviceData}
                        classNamePrefix="common-select-drop-down"
                        name="advice"
                        isMulti
                        isDisabled={
                          userRoleData[1].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[2].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[3].label === this.state.roleId
                            ? disabledBtn
                            : false
                        }
                      />
                      <p className="p-small help-block">
                        {validation.advice.message}
                      </p>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="common-label">Referral</Label>
                    {/* <div
                      className={IsInputInError(validation.referral.isInvalid)}
                    > */}
                    <Select
                      value={this.state.referral}
                      onChange={this.handleoptometristReferral}
                      options={optometristReferralData}
                      classNamePrefix="common-select-drop-down"
                      name="referral"
                      isMulti
                      isDisabled={
                        userRoleData[1].label === this.state.roleId
                          ? disabledBtn
                          : userRoleData[2].label === this.state.roleId
                          ? disabledBtn
                          : userRoleData[3].label === this.state.roleId
                          ? disabledBtn
                          : false
                      }
                    />
                    {/* <p className="p-small help-block">
                        {validation.referral.message}
                      </p>
                    </div> */}
                  </FormGroup>

                  <FormGroup>
                    <Label className="common-label">Comments</Label>

                    <Input
                      type="textarea"
                      placeholder="Comments"
                      onChange={this.handleComments}
                      name="comments"
                      className={`${
                        this.state.isComments ? "brc-danger" : ""
                      } form-control common-form-control `}
                      value={this.state.comments}
                      rows="2"
                      readOnly={
                        userRoleData[1].label === this.state.roleId
                          ? disabledBtn
                          : userRoleData[2].label === this.state.roleId
                          ? disabledBtn
                          : userRoleData[3].label === this.state.roleId
                          ? disabledBtn
                          : false
                      }
                    />
                    {this.state.isComments ? (
                      <p className="p-small help-block">
                        {validation.comments.message}
                      </p>
                    ) : null}
                  </FormGroup>
                  {this.state.adviceType === applicationConstants.optoAdvice ? (
                    <Row className="br-4px">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="bg-white"
                      >
                        <p className="p-large internal-page__title text-black text-capitalize align-left mb-3">
                          Cost-effectiveness-questionnaire
                        </p>
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="bg-white "
                      >
                        <Row>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className=""
                          >
                          <Form className="form-fields">
                           <FormGroup>                             
                              <Label className="common-label">
                                Patient travel time to camp: (In minutes){" "}
                              </Label>
                              <Input
                                type="text"
                                className="form-control common-form-control"
                                name="q1"
                                value={this.state.q1}
                                onChange={this.handleChange}
                              />
                              <Label className="common-label">
                                (one-way)
                              </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label className="common-label">
                                  Patient transportation cost to camp:
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control common-form-control"
                                  name="q2"
                                  value={this.state.q2}
                                  onChange={this.handleChange}
                                />
                                <Label className="common-label">
                                  {" "}
                                  (one-way)
                                </Label>
                            </FormGroup>

                            <FormGroup>
                            
                                <Label className="common-label">
                                  Loss of income (hourly/daily wage):
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control common-form-control"
                                  name="q3"
                                  value={this.state.q3}
                                  onChange={this.handleChange}
                                />
                                <Label className="common-label">
                                  (indicate if hourly or daily)
                                </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label className="common-label">
                                  Monthly income:
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control common-form-control"
                                  name="q4"
                                  value={this.state.q4}
                                  onChange={this.handleChange}
                                />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label display-block">
                                Is patient accompanied:
                              </Label>
                              <div className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={
                                  this.state.isYes
                                    ? faCheckSquare
                                    : faSquare
                                }
                                color={`#72d389`}
                                size={"2x"}
                                className={`cursor-pointer check-box ${
                                  this.state.isYes
                                    ? "sqaure-checked"
                                    : "sqaure-unchecked"
                                }`}
                                onClick={() => this.handleYesorNo(1)}
                              />

                              <font
                                className="common-label pl-12px pr-3"
                                onClick={() => this.handleYesorNo(1)}
                              >
                                Yes
                              </font>

                              <FontAwesomeIcon
                                icon={
                                  this.state.isNo
                                    ? faCheckSquare
                                    : faSquare
                                }
                                color={`#72d389`}
                                size={"2x"}
                                className={`cursor-pointer check-box ${
                                  this.state.isNo
                                    ? "sqaure-checked"
                                    : "sqaure-unchecked"
                                }`}
                                onClick={() => this.handleYesorNo(0)}
                              />

                              <font
                                className="common-label pl-12px"
                                onClick={() => this.handleYesorNo(0)}
                              >
                                No
                              </font>
                              </div>
                            </FormGroup>
                          </Form>
                              
                          
                            {this.state.q5 === 1 ? (
                              <Row className="pb-8px pt-12px">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Label className="common-label title-black main-title">
                                    If patient is accompanied by an attendant,
                                    please fill out the following:
                                  </Label>
                                </Col>
                              </Row>
                            ) : null}
                            <Form className="form-fields">
                            {this.state.q5 === 1 ? (
                              <FormGroup>
                                  <Label className="common-label">
                                    Transportation cost of attendant:
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control common-form-control"
                                    name="q6"
                                    value={this.state.q6}
                                    onChange={this.handleChange}
                                  />
                                  <Label className="common-label">
                                    {" "}
                                    (one-way)
                                  </Label>
                             </FormGroup>
                            ) : null}
                            {this.state.q5 === 1 ? (
                              <FormGroup>
                                  <Label className="common-label">
                                    Loss of Income of attendant (hourly/daily
                                    wage ):
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control common-form-control"
                                    name="q7"
                                    value={this.state.q7}
                                    onChange={this.handleChange}
                                  />
                                  <Label className="common-label">
                                    (hourly or daily?)
                                  </Label>
                              </FormGroup>
                            ) : null}
                            {this.state.q5 === 1 ? (
                              <FormGroup>
                                  <Label className="common-label">
                                    Monthly income:
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control common-form-control"
                                    name="q8"
                                    value={this.state.q8}
                                    onChange={this.handleChange}
                                  />
                                  <Label className="common-label">
                                    {" "}
                                    (one-way)
                                  </Label>
                              </FormGroup>
                            ) : null}

                            </Form>
                            <Row className="align-items-end check-field mb-3">
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex pb-8px"
                              >
                                <Label className="common-label title-black main-title">
                                  How comfortable is patient with
                                  smartphone-based fundus imaging?
                                </Label>
                              </Col>
                              {this.state.firstCheckBoxData.length > 0 ? (
                                <div>
                                  {this.state.firstCheckBoxData.map(
                                    (item, index) => (
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className="d-flex pb-8px"
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            item.isCheck
                                              ? faCheckSquare
                                              : faSquare
                                          }
                                          color={`#72d389`}
                                          size={"2x"}
                                          className={`${
                                            item.isCheck
                                              ? "sqaure-checked"
                                              : "sqaure-unchecked"
                                          }`}
                                          onClick={() =>
                                            this.handleFirstCheckBox(item.value)
                                          }
                                        />
                                        <font
                                          className="common-label pl-12px"
                                          onClick={() =>
                                            this.handleFirstCheckBox(item.value)
                                          }
                                        >
                                          {item.name}
                                        </font>
                                      </Col>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </Row>

                            <Row className="align-items-end check-field mb-3">
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex pb-8px"
                              >
                                <Label className="common-label title-black main-title">
                                  How satisfied is patient with getting
                                  diagnosed from a doctor sitting in another
                                  place?
                                </Label>
                              </Col>
                              {this.state.secondCheckBoxData.length > 0 ? (
                                <div>
                                  {this.state.secondCheckBoxData.map(
                                    (item, index) => (
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className="d-flex pb-8px"
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            item.isCheck
                                              ? faCheckSquare
                                              : faSquare
                                          }
                                          color={`#72d389`}
                                          size={"2x"}
                                          className={`${
                                            item.isCheck
                                              ? "sqaure-checked"
                                              : "sqaure-unchecked"
                                          }`}
                                          onClick={() =>
                                            this.handleSecondCheckBox(
                                              item.value
                                            )
                                          }
                                        />
                                        <font
                                          className="common-label pl-12px"
                                          onClick={() =>
                                            this.handleSecondCheckBox(
                                              item.value
                                            )
                                          }
                                        >
                                          {item.name}
                                        </font>
                                      </Col>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </Row>

                            <Row className="align-items-end check-field">
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex pb-8px"
                              >
                                <Label className="common-label title-black main-title">
                                  How satisfied is patient with getting
                                  diagnosed from a doctor sitting in another
                                  place?
                                </Label>
                              </Col>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex pb-8px"
                              >
                                <Label className="common-label">
                                  (if a clinical examination was done by an
                                  ophthalmologist here in the camp)
                                </Label>
                              </Col>
                              {this.state.thirdCheckBoxData.length > 0 ? (
                                <div>
                                  {this.state.thirdCheckBoxData.map(
                                    (item, index) => (
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className="d-flex pb-8px"
                                        key={index}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            item.isCheck
                                              ? faCheckSquare
                                              : faSquare
                                          }
                                          color={`#72d389`}
                                          size={"2x"}
                                          className={`${
                                            item.isCheck
                                              ? "sqaure-checked"
                                              : "sqaure-unchecked"
                                          }`}
                                          onClick={() =>
                                            this.handlethridCheckBox(item.value)
                                          }
                                        />
                                        <font
                                          className="common-label pl-12px"
                                          onClick={() =>
                                            this.handlethridCheckBox(item.value)
                                          }
                                        >
                                          {item.name}
                                        </font>
                                      </Col>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                  <FormGroup className="list-btns">
                      <Button
                        className="bt custom-grey-btn cancel-btn"
                        onClick={this.handleResetBtn}
                        disabled={
                          this.state.campStatus === "0"
                            ? true
                            : userRoleData[1].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[2].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[3].label === this.state.roleId
                            ? disabledBtn
                            : false
                        }
                      >
                        Reset
                      </Button>

                      <Button
                        className="btn custom-theme-btn save-btn"
                        onClick={this.handleSubmit}
                        disabled={
                          this.state.campStatus === "0"
                            ? true
                            : this.state.advice !== "" &&
                              this.state.advice !== -1 &&
                              // this.state.referral !== "" &&
                              // this.state.referral !== -1 &&
                              // this.state.referral !== null &&
                              this.state.advice !== null &&
                              !this.state.isComments
                            ? false
                            : true
                        }
                      >
                        Save
                      </Button>
                      </FormGroup>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.isConfirmation ? (
          <ConfirmationBackArrow
            isModal={this.state.isConfirmation}
            handleCancelBtn={this.handleCancelBtn}
            handleConfirmBtn={this.handleConfirmBtn}
          />
        ) : null}
        {this.state.isLoading ? <Loader isLoader={false} /> : null}
      </Strip>
    );
  }
}
export default withRouter(Advice);
