import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const HospitalService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getHospitalList: async () => {
    const { baseUrl, getHospital } = ApiConfig;

    const url = baseUrl + getHospital;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const hospitalServicePromise = await Fetch(
      url,
      "GET",
      headers,
      "",
      "services_hospitalService",
      true
    );
    return hospitalServicePromise;
  },

  addOrUpdateHospital: async (data, token) => {
    const { baseUrl, addORUpdateHospital } = ApiConfig;

    const url = baseUrl + addORUpdateHospital;

    const params = JSON.stringify({
      hospitalId: `${data.hospitalId}`,  
      name: `${data.name}`,
      hospitalBranchName: `${data.branchCode}`,
      location: `${data.location}`,
      city: `${data.city}`,
      state: `${data.state}`
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addOrUpdateHospitalServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addOrUpdateHospitalService",
      true
    );
    return addOrUpdateHospitalServicePromise;
  },
};

export default HospitalService;
