// import { base64 } from "base64-img";
import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const FileUpload = (props) => {
  console.log(props)
  const [files, setFiles] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("AcceptedFiles.........", acceptedFiles);
    let item = [];
    let len = acceptedFiles.length;
    acceptedFiles.forEach((file) => {
      // console.log("Initial file.........", file);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onloadend = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };
        item.push(fileInfo);
        setFiles([...files, ...item]);

        if (len == item.length) {
          console.log(item);
          props.handleData([...item]);
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      
    >
      <input {...getInputProps()} />
      <p className="mb-0 p-medium internal-section__title upload-form-control-position">
       file
      </p>
    </div>
  );
};

export default FileUpload;
