import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Strip from "../../../customComponents/Strip/Strip";
import DataTable from "react-data-table-component";
import windowSize from "react-window-size";
import TablesColumns from "./TablesColumns";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../../utils/dataTablesStyledTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../../constants/applicationContants";
import HospitalService from "../../../../../api/services/HospitalService";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";

class HospitalInfoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalList: [],
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: [],
      isLoading: false,
    };
    this.getHospitalItem = this.getHospitalItem.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  async componentDidMount() {
    await this.getHospitalList();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isModal !== prevProps.isModal) {
      this.getHospitalList();
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.getHospitalList();
    }
  }

  getHospitalList = async () => {
    await this.setState({
      isLoading: true
    });

    HospitalService.getHospitalList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                this.setState({
                  hospitalList: data,
                  filterData: data,
                });
                await this.setState({
                  isLoading: false
                });
            
              } else {
                await this.setState({
                  isLoading: false
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
         this.setState({
          isLoading: false
        });
        console.log(err);
      });
  };

  handleAction = (item) => {
    this.props.handleModal(item);
  };

  getHospitalItem = (item) => {
    this.props.handleModal(item);
  };

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };

  filterData = (filterText) => {
    const filteredItems = this.state.filterData.filter(
      (item) =>
        (item.location &&
          item.location.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.branchCode &&
          item.branchCode.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.city &&
          item.city.toLowerCase().includes(filterText.toLowerCase()))
    );

    if (filteredItems.length >= 0) {
      this.setState({
        hospitalList: [...filteredItems],
      });
    } else {
      this.setState({
        hospitalList: [...this.state.filterData],
      });
    }
  };

  handleClear = () => {
    this.setState({
      campsList: [...this.state.filterData],
      filterText: "",
    });
  };

  render() {
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue"
                  onClick={this.props.toggle}
                >
                  <span>+</span> Add Hospital
                </Button>
              </Col>
              <Col xs={12} sm={12} md={8} lg={10} xl={10}>
                <Row className="justify-content-end">
                  <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                    <InputGroup>
                      <Input
                        placeholder="Search"
                        name="filterText"
                        value={this.state.filterText}
                        onChange={this.onChange}
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.handleClear}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={this.state.filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="table-responsive no-margin">
          {this.state.hospitalList.length > 0 ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <DataTable
                title=""
                columns={TablesColumns(this.handleAction)}
                data={this.state.hospitalList}
                pagination
                className="data-table"
                persistTableHead={false}
                customStyles={
                  this.props.windowWidth > applicationConstants.mobileBreakPoint
                    ? dataTablesStyledTheme
                    : dataTablesMobileStyledTheme
                }
                onRowClicked={(data) => this.getHospitalItem(data)}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="br-light-smoke p-5 br-4px">
                {!this.state.isLoading ? (
                  <p className="mb-0 align-center internal-page__title">
                    No Data Found
                  </p>
                ) : (
                  <Loader isLoader={false} />
                )}
              </div>
            </Col>
          )}
        </Row>
      </Strip>
    );
  }
}

export default windowSize(HospitalInfoList);
