import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Navigation from "../customPages/Navigation/Navigation";
import { AppProvider } from "./AppProvider";

function App() { 
  return (
    <div className="App">
      <AppProvider>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </AppProvider>
    </div>
  );

  
}

export default App;
