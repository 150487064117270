import memoizeOne from "memoize-one";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";


const TablesColumns = memoizeOne((handleAction,handleDelete) => [
  {
    name: "Internal ID",
    selector: "id",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.internal_id}</div>,
  },
  {
    name: "Center Name",
    selector: "centerName",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.center_name}</div>,
  },
  {
    name: "ERP Name",
    selector: "erpName",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.erp_name}</div>,
  },
  {
    name: "Address-1",
    selector: "address1",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.address1}</div>,
  },
  {
    name: "Address-2",
    selector: "address2",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.address2}</div>,
  },
  {
    name: "Location",
    selector: "location",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.location}</div>,
  },
  {
    name: "Pincode",
    selector: "pincode",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.pincode}</div>,
  },
  {
    name: "Status",
    selector: "status",
    sortable: false,
    // cell: (row) => <div onClick={() => handleAction(row)}>{row.active_status}</div>,
    cell: (row) => (
      <div>
        {row.active_status === 1 ? (
          <p className="mb-0 active-btn">Active</p>
        ) : (
          <p className="mb-0 inactive-btn">Inactive</p>
        )}
      </div>
    ),
  },
  {
    name: "Action",
    selector: "",
    grow: 0.2,
    sortable: false,
    ignoreRowClick: true,
    allowOverflow: true,
    cell: (row) => (
        <div className="d-flex">
            <div onClick={() => handleDelete(row)}>
               <p className="mb-0 inactive-btn thrash-ico"> <FontAwesomeIcon icon={faTrashAlt} className="profile-icon" /></p>
            </div>
        </div>
    ),
},
]);

export default TablesColumns;
