import React from "react";
import { Row, Col } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../constants/applicationContants";
import DashboardService from "../../../../api/services/DashboardService";



class UserDashboard extends React.Component {

  constructor() {

      super();
      this.state = {
        dashboard_count: [],
        roleId :  localStorage.getItem("roleId"),
        countFlag:0
      };
    }

  async componentDidMount() {
    await this.getDashboardInfo();
  }

  getDashboardInfo = async () => {
    const hospitalName = this.state.roleId !== "Admin" ? await localStorage.getItem("hospitalName") : null;
    if(this.state.roleId === "Admin"){
      this.setState({
        countFlag: 0
      });
    } else {
      this.setState({
        countFlag: 1
      });
    }
    const token = await localStorage.getItem("token");
    await DashboardService.getDashboardCount(token,hospitalName)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                let data = result.data;
                console.log(data)
                this.setState({
                  dashboard_count: data
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <RenderPage
        id=""
        className="render-page"
        containerType="container-fluid"
      >
        
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
        <Row>
          {this.state.countFlag === 0 ? (
          <Col xs={12} sm={12} md={6} lg={3} xl={3} className="relative box-div">         
            <div className="dash-box color1">              
               <div className="dash-ico">                   
                    <img
                      src={applicationConstants.userico}
                      alt=""
                      className=""
                    />
               </div>
               <div className="pat-count">{this.state.dashboard_count.user_count ? this.state.dashboard_count.user_count : '0'}</div>
               <div className="pat-txt">User Details</div>
            </div>
          </Col>) : ""}

          <Col xs={12} sm={12} md={6} lg={3} xl={3} className="relative box-div">         
            <div className="dash-box color2">              
               <div className="dash-ico">
                    <img
                      src={applicationConstants.patcount}
                      alt=""
                      className=""
                    />
               </div>
               <div className="pat-count">{this.state.dashboard_count.patient_count ? this.state.dashboard_count.patient_count : '0'}</div>
               <div className="pat-txt">Patients Count</div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} xl={3} className="relative box-div">         
            <div className="dash-box color3">              
               <div className="dash-ico">
                    <img
                      src={applicationConstants.campusico}
                      alt=""
                      className=""
                    />
               </div>
               <div className="pat-count">{this.state.dashboard_count.camp_count ? this.state.dashboard_count.camp_count : '0'}</div>
               <div className="pat-txt">Camps Details</div>
            </div>
          </Col>
          {this.state.countFlag === 0 ? (
            <>
          <Col xs={12} sm={12} md={6} lg={3} xl={3} className="relative box-div">         
            <div className="dash-box color4">              
               <div className="dash-ico">
                    <img
                      src={applicationConstants.hospico}
                      alt=""
                      className=""
                    />
               </div>
               <div className="pat-count">{this.state.dashboard_count.hospital_count ? this.state.dashboard_count.hospital_count : '0'}</div>
               <div className="pat-txt">Hospital Info</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} xl={3} className="relative box-div">         
            <div className="dash-box color4">              
               <div className="dash-ico">
                    <img
                      src={applicationConstants.hospico}
                      alt=""
                      className=""
                    />
               </div>
               <div className="pat-count">{this.state.dashboard_count.visionCenter_count ? this.state.dashboard_count.visionCenter_count : '0'}</div>
               <div className="pat-txt">Vision Center Info</div>
            </div>
          </Col>
          </>):""}
        </Row>
      </Col>
      </RenderPage>
    );
  }
}




export default UserDashboard;
