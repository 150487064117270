import React from "react";
import { Row, Col } from "reactstrap";
import PatientService from "../../../../api/services/PatientService";
import {
  lensStatusData,
  DRData,
  optometristAdviceData,
  optometristReferralData,
  userRoleData,
  BCVADistanceData,
  BCVANearData,
} from "../.../../../utils/dropdownData";
import { displayMonthNameWithDate } from "../../utils/displayFormatter";
import applicationConstants from "../../constants/applicationContants";
import { alertDangerConfirmMessage } from "../confirmationMessage/ConfirmationMessage";

class ReportPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patientName: "",
      campId: "",
      campLocation: "",
      campDate: "",
      REBCVADistance: "",
      REBCVANear: "",
      RELensStatus: "",
      LEBCVADistance: "",
      LEBCVANear: "",
      LELensStatus: "",
      REDRGrading: "",
      LEDRGrading: "",
      adivce: "",
      referral: "",
      roleId: "",
      readerSummary: "",
      jrReaderLists: "",
      srReaderLists: "",
      reportedBy: "",
      preDilationREMedia: [],
      postDilationREMedia: [],
      preDilationLEMedia: [],
      postDilationLEMedia: [],
      isRE: "",
      isLE: "",
      REReason: "",
      LEReason: "",
      isOptoReview: false,
      isTeleReview: false,
      isCampReview: false,
    };
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const roleId = await localStorage.getItem("roleId");
    if (patientId) {
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data.registerInfo) {
                    this.setState({
                      patientId: data.patientId,
                      campId: data.campId,
                      campLocation: data.campLocation,
                      campDate: data.campDate,
                      patientName: data.registerInfo.patientName,
                    });
                  }

                  if (data.readerGrading.length > 0) {
                    let Jrlists = [];
                    let srList = [];
                    data.readerGrading.map((item) => {
                      if (
                        item.readerType === applicationConstants.juniorReader
                      ) {
                        Jrlists.push(item);
                      } else if (
                        item.readerType === applicationConstants.seniorReader
                      ) {
                        srList.push(item);
                      }
                    });
                    this.setState({
                      jrReaderLists: Jrlists,
                      srReaderLists: srList,
                      readerSummary: data.readerGrading,
                    });
                  }

                  if (data.refractionInfo) {
                    this.setState({
                      REBCVADistance:
                        data.refractionInfo.REBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.REBCVADistance]
                              .label
                          : -1,
                      REBCVANear:
                        data.refractionInfo.REBCVANear !== -1
                          ? BCVANearData[data.refractionInfo.REBCVANear].label
                          : -1,
                      RELensStatus:
                        data.refractionInfo.REBCVANear !== -1
                          ? lensStatusData[data.refractionInfo.REBCVALensStatus]
                              .label
                          : "",
                      LEBCVADistance:
                        data.refractionInfo.LEBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.LEBCVADistance]
                              .label
                          : -1,
                      LEBCVANear:
                        data.refractionInfo.LEBCVANear !== -1
                          ? BCVANearData[data.refractionInfo.LEBCVANear].label
                          : -1,
                      LELensStatus:
                        data.refractionInfo.LEBCVALensStatus !== -1
                          ? lensStatusData[data.refractionInfo.LEBCVALensStatus]
                              .label
                          : "",
                      isRE: data.refractionInfo.REReason !== "" ? true : false,
                      isLE: data.refractionInfo.LEReason !== "" ? true : false,
                      REReason: data.refractionInfo.REReason,
                      LEReason: data.refractionInfo.LEReason,
                    });
                  }
                  //imaging and grading
                  let REDRGradingArray = [];
                  let LEDRGradingArray = [];

                  //optoRE Diagnosis
                  if (data.optoREDiag) {
                    if (
                      data.optoREDiag.REPreDilationImaging != "undefined" &&
                      data.optoREDiag.REPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoREDiag.REPreDilationImaging
                      );
                      let REPreImgList = [];
                      if (
                        preDilationImage !== "[]" &&
                        preDilationImage !== ""
                      ) {
                        preDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            REPreImgList.push(item);
                          }
                        });
                        this.setState({
                          preDilationREMedia: REPreImgList,
                        });
                      }
                    }
                    if (
                      data.optoREDiag.REPostDilationImaging != "undefined" &&
                      data.optoREDiag.REPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoREDiag.REPostDilationImaging
                      );
                      let REPostImgList = [];
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        postDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            REPostImgList.push(item);
                          }
                        });
                        this.setState({
                          postDilationREMedia: REPostImgList,
                        });
                      }
                    }
                  }
                  //OptoLE Diagnosis
                  if (data.optoLEDiag) {
                    if (
                      data.optoLEDiag.LEPreDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoLEDiag.LEPreDilationImaging
                      );
                      let LEPreImgList = [];
                      if (
                        preDilationImage !== "[]" &&
                        preDilationImage !== ""
                      ) {
                        preDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            LEPreImgList.push(item);
                          }
                        });
                        this.setState({
                          preDilationLEMedia: LEPreImgList,
                        });
                      }
                    }
                    if (
                      data.optoLEDiag.LEPostDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoLEDiag.LEPostDilationImaging
                      );
                      let LEPostImgList = [];
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        postDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            LEPostImgList.push(item);
                          }
                        });
                        this.setState({
                          postDilationLEMedia: LEPostImgList,
                        });
                      }
                    }
                  }

                  if (data.teleDrREDiag) {
                    if (
                      data.teleDrREDiag.REDR !== "-1" &&
                      data.teleDrREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.teleDrREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                       // reportedBy: userRoleData[3].label,
                       isTeleReview: true,
                      });
                    }
                  } else if (data.campDrREDiag) {
                    if (
                      data.campDrREDiag.REDR !== "-1" &&
                      data.campDrREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.campDrREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoREDiag) {
                    if (
                      data.optoREDiag.REDR !== "-1" &&
                      data.optoREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.optoREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                       // reportedBy: userRoleData[1].label,
                       isOptoReview: true,
                      });
                    }
                  }

                  if (data.teleDrLEDiag) {
                    if (
                      data.teleDrLEDiag.LEDR !== "-1" &&
                      data.teleDrLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.teleDrLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                       // reportedBy: userRoleData[3].label,
                       isCampReview: true,
                      });
                    }
                  } else if (data.campDrLEDiag) {
                    if (
                      data.campDrLEDiag.LEDR !== "-1" &&
                      data.campDrLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.campDrLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoLEDiag) {
                    if (
                      data.optoLEDiag.LEDR !== "-1" &&
                      data.optoLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.optoLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                       // reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                  }

                  //adivce
                  let adviceArray = [];
                  let referralArray = [];

                  if (data.teleDrAdvice) {
                    if (
                      data.teleDrAdvice.advice !== -1 &&
                      data.teleDrAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.teleDrAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                        //reportedBy: userRoleData[3].label,
                        isCampReview: true,
                      });
                    }
                    if (
                      data.teleDrAdvice.referral !== -1 &&
                      data.teleDrAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(
                        data.teleDrAdvice.referral
                      );
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                       // reportedBy: userRoleData[3].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.campDrAdvice) {
                    if (
                      data.campDrAdvice.advice !== -1 &&
                      data.campDrAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.campDrAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                       // reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                    if (
                      data.campDrAdvice.referral !== -1 &&
                      data.campDrAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(
                        data.campDrAdvice.referral
                      );
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                      //  reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoAdvice) {
                    if (
                      data.optoAdvice.advice !== -1 &&
                      data.optoAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.optoAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                       // reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                    if (
                      data.optoAdvice.referral !== -1 &&
                      data.optoAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(data.optoAdvice.referral);
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                        //reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                  }

                  //imaging for Disc and macula centred
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.setState({
      roleId: roleId,
    });
  }

  render() {
    return (
      <Row className="no-margin">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="no-margin">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="report-print-container"
            >
              <Row className="bg-white br-4px no-margin align-items-center pt-8px pb-8px">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <h2 className="mb-0 internal-section__title title-suvaGrey fw-800">
                    {this.state.campLocation}
                  </h2>
                  <h2 className="mb-0 internal-section__title title-theme-color fw-800">
                    {this.state.campId}
                  </h2>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <h2 className="mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                    {displayMonthNameWithDate(this.state.campDate)}
                  </h2>
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  className="d-flex justify-content-end"
                >
                  <div>
                    <img
                      src={applicationConstants.logoImg}
                      alt="appLogo"
                      className="report-print_logo-container"
                    />
                    <h3 className="h3-medium mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                      SANKARA
                    </h3>
                    <h3 className="h3-medium mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                      EYE HOSPITAL
                    </h3>
                  </div>
                </Col>
              </Row>

              <Row className="bg-light-white pt-16px pb-16px no-margin">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h2 className="mb-0 internal-section__title title-suvaGrey">
                    {this.state.patientName} : {this.state.patientId}
                  </h2>
                </Col>
              </Row>

              <Row className="pt-16px pb-16px bg-white br-4px no-margin">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-12px pb-12px"
                >
                  <h2 className="mb-0 internal-section__title opensans-semiBold title-theme-color">
                    REFRACTION SUMMARY
                  </h2>
                </Col>
                {!this.state.isRE ? (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mt-1"
                      >
                        <h2 className="mb-0 internal-section__title opensans-semiBold title-black">
                          RIGHT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey">
                              BCVA Distance
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.REBCVADistance
                                ? this.state.REBCVADistance
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey">
                              BCVA LogMAR Value
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.REBCVALogMar
                                ? this.state.REBCVALogMar
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              BCVA Near
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.REBCVANear
                                ? this.state.REBCVANear
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              Lens Status
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.RELensStatus
                                ? this.state.RELensStatus
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mt-1"
                      >
                        <h2 className="mb-0 internal-section__title opensans-semiBold title-black">
                          RIGHT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey">
                              REReason
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className="mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className="mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.REReason ? this.state.REReason : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {!this.state.isLE ? (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col className="pt-4px pb-4px">
                        <h2 className="mb-0 internal-section__title opensans-semiBold title-black">
                          LEFT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              BCVA Distance
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.LEBCVADistance
                                ? this.state.LEBCVADistance
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              BCVA LogMAR Value
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.LEBCVALogMar
                                ? this.state.LEBCVALogMar
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              BCVA Near
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.LEBCVANear
                                ? this.state.LEBCVANear
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              Lens Status
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.LELensStatus
                                ? this.state.LELensStatus
                                : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-4px pb-4px"
                      >
                        <h2 className="mb-0 internal-section__title opensans-semiBold title-black">
                          LEFT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              LEReason
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                              {this.state.LEReason ? this.state.LEReason : "NA"}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row className="bg-light-white pt-16px pb-16px no-margin"></Row>

              <Row className="pt-12px pb-12px br-4px bg-white  no-margin">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-12px pb-12px"
                >
                  <h2 className="mb-0 internal__section-title opensans-semiBold title-theme-color">
                    IMAGING SUMMARY
                  </h2>
                </Col>
                {!this.state.isRE ? (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-4px pb-4p"
                      >
                        <h2 className="mb-0 internal__section-title opensans-semiBold title-black">
                          RIGHT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              DR Grading
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col
                            xs={5}
                            sm={5}
                            md={5}
                            lg={5}
                            xl={5}
                            className="d-flex flex-wrap justify-content-end"
                          >
                            {this.state.REDRGrading.length > 0 ? (
                              this.state.REDRGrading.map((item, index) => (
                                <h3
                                  className=" mb-0 internal-section__title title-black fw-800 align-right"
                                  key={index}
                                >
                                  {item.label}
                                  <font className="pr-4px">{`${
                                    this.state.REDRGrading.length - 1 === index
                                      ? ""
                                      : ","
                                  }`}</font>
                                </h3>
                              ))
                            ) : (
                              <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                                NA
                              </h3>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      {this.state.preDilationREMedia.length > 0 ? (
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="pt-12px pb-12px"
                        >
                          <h3 className="mb-0 internal-section__title title-suvaGrey pb-12px">
                            Pre-dilated fundus image
                          </h3>
                          <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                            {this.state.preDilationREMedia.map(
                              (item, index) => (
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className="pl-8px pr-8px d-flex"
                                  key={index}
                                >
                                  <img
                                    src={item.signedUrl}
                                    className="print-img"
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      ) : null}
                      {this.state.postDilationREMedia.length > 0 ? (
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="pt-12px pb-12px"
                        >
                          <h3 className=" mb-0 internal-section__title title-suvaGrey pb-12px">
                            Post-dilated fundus image
                          </h3>
                          <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                            {this.state.postDilationREMedia.map(
                              (item, index) => (
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className="pl-8px pr-8px d-flex"
                                  key={index}
                                >
                                  <img
                                    src={item.signedUrl}
                                    className="print-img"
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                ) : null}
                {!this.state.isLE ? (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-4px pb-4p"
                      >
                        <h2 className="mb-0 internal__section-title opensans-semiBold title-black">
                          LEFT EYE
                        </h2>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-12px pb-12px"
                      >
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey">
                              DR Grading
                            </h3>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                              -
                            </h3>
                          </Col>
                          <Col
                            xs={5}
                            sm={5}
                            md={5}
                            lg={5}
                            xl={5}
                            className="d-flex flex-wrap justify-content-end"
                          >
                            {this.state.LEDRGrading.length > 0 ? (
                              this.state.LEDRGrading.map((item, index) => (
                                <h3
                                  className=" mb-0 internal-section__title title-black fw-800 align-right"
                                  key={index}
                                >
                                  {item.label}
                                  <font className="pr-4px">{`${
                                    this.state.LEDRGrading.length - 1 === index
                                      ? ""
                                      : ","
                                  }`}</font>
                                </h3>
                              ))
                            ) : (
                              <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                                NA
                              </h3>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      {this.state.preDilationLEMedia.length > 0 ? (
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="pt-12px pb-12px"
                        >
                          <h3 className="mb-0 internal-section__title title-suvaGrey pb-12px">
                            Pre-dilated fundus image
                          </h3>
                          <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                            {this.state.preDilationLEMedia.map(
                              (item, index) => (
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className="pl-8px pr-8px d-flex"
                                  key={index}
                                >
                                  <img
                                    src={item.signedUrl}
                                    className="print-img"
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      ) : null}
                      {this.state.postDilationLEMedia.length > 0 ? (
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="pt-12px pb-12px"
                        >
                          <h3 className=" mb-0 internal-section__title title-suvaGrey pb-12px">
                            Post-dilated fundus image
                          </h3>
                          <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                            {this.state.postDilationLEMedia.map(
                              (item, index) => (
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  className="pl-8px pr-8px d-flex"
                                  key={index}
                                >
                                  <img src={item.signedUrl} />
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                ) : null}
              </Row>

              <Row className="bg-light-white pt-16px pb-16px no-margin"></Row>

              <Row className="pt-16px pb-16px bg-white br-4px no-margin">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-12px pb-12px"
                >
                  <h2 className="mb-0 internal__section-title opensans-semiBold title-theme-color">
                    ADVICE
                  </h2>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-12px pb-12px"
                >
                  <Row>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                      <h3 className=" mb-0 internal-section__title title-suvaGrey">
                        Advice
                      </h3>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                        -
                      </h3>
                    </Col>
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      className="d-flex flex-wrap justify-content-end"
                    >
                      {this.state.adivce.length > 0 ? (
                        this.state.adivce.map((item, index) => (
                          <h3
                            className=" mb-0 internal-section__title title-black fw-800 align-right"
                            key={index}
                          >
                            {item.label}
                            <font className="pr-4px">{`${
                              this.state.adivce.length - 1 === index ? "" : ","
                            }`}</font>
                          </h3>
                        ))
                      ) : (
                        <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                          NA
                        </h3>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-12px pb-12px"
                >
                  <Row>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                      <h3 className=" mb-0 internal-section__title title-suvaGrey">
                        Referral
                      </h3>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <h3 className=" mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                        -
                      </h3>
                    </Col>
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      className="d-flex flex-wrap justify-content-end"
                    >
                      {this.state.referral.length > 0 ? (
                        this.state.referral.map((item, index) => (
                          <h3
                            className=" mb-0 internal-section__title title-black fw-800 align-right"
                            key={index}
                          >
                            {item.label}
                            <font className="pr-4px">{`${
                              this.state.referral.length - 1 === index
                                ? ""
                                : ","
                            }`}</font>
                          </h3>
                        ))
                      ) : (
                        <h3 className=" mb-0 internal-section__title title-black fw-800 align-right">
                          NA
                        </h3>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="no-margin mb-4">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="bg-light-white pt-16px pb-16px"
                >
                  <h3 className="mb-0 internal-section__title title-suvaGrey fw-800">
                    Note: This report is generated by{" "}
                    {this.state.isTeleReview
                      ? userRoleData[3].label
                      : this.state.isCampReview
                      ? userRoleData[2].label
                      : this.state.isOptoReview
                      ? userRoleData[1].label
                      : ""}{" "}
                    and cannot be used as medico-legal document.
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default ReportPrint;
