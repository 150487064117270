import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const VisionCenterService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getVisionCenterList: async (token) => {
    const { baseUrl, getVisionCenters } = ApiConfig;

    const url = baseUrl + getVisionCenters;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const visionCenterServicePromise = await Fetch(
      url,
      "GET",
      headers,
      "",
      "services_VisionCenterService",
      true
    );
    return visionCenterServicePromise;
  },

  addorUpdateVisionCenter: async (data, token) => {
    const { baseUrl, addVisionCenter, updateVisionCenter } = ApiConfig;
    var url = baseUrl;
    if (!data.id){
      url = url+addVisionCenter;
    } else{
      url = url+updateVisionCenter;
    }
    
    
    const params = JSON.stringify({
      id : data.id,
      internal_id: `${data.internal_id}`,
      center_name: `${data.center_name}`,
      erp_name: `${data.erp_name}`,
      address1: `${data.address1}`,
      address2: `${data.address2}`,
      state: `${data.state}`,
      location: `${data.location}`,
      pincode: data.pincode,
    });
    if (!data.id){
      delete params.id;
    }
    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addVisionCenterServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addVisionCenterService",
      true
    );
    return addVisionCenterServicePromise;
  },

  deleteVisionCenter: async (token, visionCode) => {
    const { baseUrl, deleteVisionCenter } = ApiConfig;

    const url = baseUrl + deleteVisionCenter;
    const params = JSON.stringify({
      id: visionCode
    });
    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });


    const visionCenterDeleteServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_VisionCenterDeleteService",
      true
    );
    return visionCenterDeleteServicePromise;
  },
};

export default VisionCenterService;
