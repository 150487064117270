
import React from "react";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import Select from "react-select";
// import imagebase64 from "base64-img";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes ,faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Strip from "../Strip/Strip";
import { imageQuadrant } from "../../utils/dropdownData";
import { s3UploadFile ,s3UploadPDFFile} from "../../s3Services/S3Services";
import { AwsConfig } from "../../config/AwsConfig";
import { alertDangerConfirmMessage } from "../confirmationMessage/ConfirmationMessage";
import ImageZoom from "../ImageZoom/ImageZoom";
import Loader from "../Loader/Loader";
import axios from "axios";
class PreviewImage extends React.Component {
  constructor(props) {
    console.log("dilationMedia11---111",props.ImgdilationType)
    super(props);
    this.state = {
      modal: props.isModal ? props.isModal : false,
      patientId: props.patientId ? props.patientId : "",
      patientName: props.patientName ? props.patientName : "",
      quadrant: props.quadrant !== -1 ? imageQuadrant[props.quadrant] : "",
      previewItem: props.previewItem ? props.previewItem : null,
      // previewItemAll: props.previewItemAll ? props.previewItemAll : null,
      fileName: props.previewItem ? props.previewItem.fileName : "",
      isDisplayBtn: props.isDisplayBtn ? props.isDisplayBtn : false,
      disabledBtn: props.quadrant !== -1 ? false : true,
      isLoading: false,
      PreMediaData: props.selectPreMedia.length  > 0 ? props.selectPreMedia : [],
      PreMediaLength: props.selectPreMedia.length,
      PostMediaData: props.selectPostMedia.length  > 0 ? props.selectPostMedia : [],
      PostMediaLength: props.selectPostMedia.length,
      dilationType:props.ImgdilationType ? props.ImgdilationType : null,
      isPreviewDisbledBtn: false,
      items : [],
      animating:false,
      activeIndex:0,
     

    };
  }

  async componentDidMount() {
    // this.setState({
    //   previewItem: "",
    // });
    //   if (this.props.duplicateItem.length > 0) {
    //   if (this.props.previewItem.path !== undefined) {
    //     this.props.duplicateItem.map((item) => {
    //       if (item.path !== undefined) {
    //       if (item.isUpdate) {
    //         this.setState({
    //           isPreviewDisbledBtn: false,
    //         });
    //       } else {
    //         if (
    //           item.path.split(",")[1] ===
    //           this.props.previewItem.path.split(",")[1]
    //         ) {
    //           this.setState({
    //             isPreviewDisbledBtn: !item.isUpdate,
    //           });
    //         }
    //       }
    //     }
    //     });
    //   }
    // }
  }

  

  Prenext = async() => {
    // if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.state.PreMediaData.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });

    console.log("dilationMedia11->Next-length", this.state.PreMediaData.length);
    console.log("dilationMedia11->Next", nextIndex);
    console.log("dilationMedia11->Next",this.state.PreMediaData[nextIndex])
    const preview = await this.props.handleFileUploadMulti([this.state.PreMediaData[nextIndex]],this.props.ImgdilationType,this.props.diagnosisType)
    // console.log("DilationMedia11->Next",preview);
    this.setState({
      previewItem: preview,
      fileName:preview.fileName
    });
    // this.state.previewItem.push(preview)
    // this.props.previewItemAll[nextIndex];
    // this.props.handleFileUploadMulti()
  };

  Postnext = async() => {
    // if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.state.PostMediaData.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });

    console.log("dilationMedia11->Next-length", this.state.PostMediaData.length);
    console.log("dilationMedia11->Next", nextIndex);
    console.log("dilationMedia11->Next",this.state.PostMediaData[nextIndex])
    const preview = await this.props.handleFileUploadMulti([this.state.PostMediaData[nextIndex]],this.props.ImgdilationType,this.props.diagnosisType)
    // console.log("DilationMedia11->Next",preview);
    this.setState({
      previewItem: preview,
      fileName:preview.fileName
    });
    // this.state.previewItem.push(preview)
    // this.props.previewItemAll[nextIndex];
    // this.props.handleFileUploadMulti()
  };

  // previous = () => {
  //   // if (this.state.animating) return;
  //   const nextIndex = this.state.activeIndex === 0 ? this.props.selectMedia.length - 1 : this.state.activeIndex - 1;
  //   this.setState({activeIndex: nextIndex});
    
  //   console.log("dilationMedia11->Previous", nextIndex);
  //   console.log("dilationMedia11->Next",this.props.selectMedia[nextIndex])
  //   this.props.handleFileUploadMulti([this.props.selectMedia[nextIndex]],this.props.ImgdilationType,this.props.diagnosisType)
  // };

  // goToIndex = (newIndex) => {
  //   if (this.state.animating) return;
  //   this.setState({
  //     activeIndex: newIndex,
      
  //   });
  //   // setActiveIndex(newIndex);
  // };

  // slides = this.props.previewItemAll[0].map((item,index) => {
  //   console.log("dilationMedia11",item.name)
  //   return (
  //     <CarouselItem
  //       onExiting={() => this.setState({animating: true})}
  //       onExited={() => this.setState({animating: false})}
  //       key={index+1}
  //     >
  //       <ImageZoom
  //                          alt={item.name}  src={
  //                             item.base64 != undefined
  //                               ? item.base64
  //                               : item.path
  //                           }
  //                           imgContainer="zoom-img-container"
  //                         />
        
  //     </CarouselItem>
  //   );
  // });
  handleQuadrant = async (item, previewItem) => {
    const diagnosisType =
      previewItem.diagnosisType != undefined
        ? previewItem.diagnosisType
        : previewItem.fileName.slice(0, 2);
    console.log("--------dia", item);
    const value =
      diagnosisType +
      previewItem.dilationType +
      item.key +
      "_" +
      moment(new Date()).format("YYYYMMDD") +
      "_" +
      Math.floor(Math.random() * 1000);
    await this.setState({
      quadrant: item,
      fileName: value,
      disabledBtn: false,
    });
  };

  
getPdfInfoFromURL = async (pdfUrl) => {
  try {
    // Make an HTTP GET request to the PDF URL
    const response = await axios.head(pdfUrl);

    // Extract information from the response headers
    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];
    const contentLength = response.headers['content-length'];

    // Extract the file name from the content disposition header
    const match = /filename="(.*)"/.exec(contentDisposition);
    const name = match ? match[1] : 'unknown.pdf';

    return {
      name,
      type: contentType,
      size: contentLength,
    };
  } catch (error) {
    console.error('Error retrieving PDF information:', error);
    return null;
  }
};


getPdfBase64FromURL = async (pdfUrl) => {
  try {
    const response = await axios.get(pdfUrl, {
      responseType: 'arraybuffer',
    });

    if (response.status === 200) {
      const arrayBuffer = new Uint8Array(response.data);
      const binary = [].map.call(arrayBuffer, (byte) => String.fromCharCode(byte)).join('');
      const base64 = btoa(binary);
      return base64;
    }
    return null;
  } catch (error) {
    console.error('Error getting PDF base64 data:', error);
    return null;
  }
};



  handleSubmit = async () => {
    this.setState({
      disabledBtn: true,
      isLoading: true,
    });
console.log("handleFileItem-->",this.state.previewItem);
    if (this.state.previewItem != null) {
      if (
        this.state.previewItem.objKey === undefined &&
        this.state.previewItem.signedUrl === undefined
      ) {
        if(this.state.previewItem.deviceType === "FOP" && this.state.previewItem.remidioReport !== ""){
           let file = {
            uri: this.state.previewItem.remidioReport,
            name: `${this.state.patientId}/${
              this.state.previewItem.diagnosisType
            }/${this.state.previewItem.dilationType}/${this.state.fileName}/${this.state.previewItem.date}.pdf`,
          };
       

          s3UploadPDFFile(file)
          .then(async (uploadResult) => {
            try {
              console.log("Sss3",uploadResult)
              if (uploadResult.status === 200) {
                const result = uploadResult;
               
                this.state.previewItem.remidioReport = `${AwsConfig.url}${result.file.name}`;
              }
            } catch (err) {
              this.setState({
                isLoading: false,
              });
              alertDangerConfirmMessage("error");
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            console.log("failed to upload", err);
          });
        }
          var file = {
            uri: `${this.state.previewItem.path}`,
            name: `${this.state.patientId}/${
              this.state.previewItem.diagnosisType
            }/${this.state.previewItem.dilationType}/${
              this.state.fileName
            }.${this.state.previewItem.fileName.split(".").pop()}`,
            type: this.state.previewItem.mime,
            size: this.state.previewItem.size,
          };
         
        
        await s3UploadFile(file)
        .then(async (uploadResult) => {
          try {
            if (uploadResult.status === 200) {
              const result = uploadResult;
              this.state.previewItem.signedUrl = `${AwsConfig.url}${result.file.name}`;
              //  this.state.previewItem.name = this.state.previewItem.fileName;
              this.state.previewItem.objKey = result.file.name;
              this.state.previewItem.fileName = this.state.fileName;
              this.state.previewItem.quadrant = this.state.quadrant.label;
              // this.state.previewItem.deviceType = this.state.deviceType
              // this.state.previewItem.date = this.state.date;
              //console.log("___state", this.state);
              // await this.props.refreshData(this.state.previewItem);
              this.setState({
                isLoading: false,
                PostMediaData:[],
                PreMediaData:[],
              });
              const refdata = await this.props.data(this.state.previewItem);
              await this.props.isDuplicateItem(this.state.previewItem, false);
              
              if(this.state.dilationType === "POST"){
                this.setState({
                  PostMediaData: refdata.mediaPostSelect,
                  PostMediaLength: refdata.mediaPostSelect.length,
                });
                if(this.state.PostMediaData.length === 1 ){
                  const preview = await this.props.handleFileUploadMulti([this.state.PostMediaData[0]],this.props.ImgdilationType,this.props.diagnosisType)
                  this.setState({
                    previewItem: preview,
                    fileName:preview.fileName
                  });
                  }
                
                if(this.state.PostMediaData.length === 0){
                await this.props.toggle();
                }
              }

              if(this.state.dilationType === "PRE"){
                this.setState({
                  PreMediaData: refdata.mediaPreSelect,
                  PreMediaLength: refdata.mediaPreSelect.length,
                });
                if(this.state.PreMediaData.length === 1 ){
                  const preview = await this.props.handleFileUploadMulti([this.state.PreMediaData[0]],this.props.ImgdilationType,this.props.diagnosisType)
                  this.setState({
                    previewItem: preview,
                    fileName:preview.fileName
                  });
                  }
                
                if(this.state.PreMediaData.length === 0){
                await this.props.toggle();
                }
              }
              
              
             
              // console.log("reload",this.state.PostMediaData);
              // console.log("reload-->",this.state.previewItem);
              
            }
          } catch (err) {
            this.setState({
              isLoading: false,
            });
            alertDangerConfirmMessage("error");
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log("failed to upload", err);
        });
       
      } else {
        await this.props.isDuplicateItem(this.state.previewItem, false);
        if (this.state.previewItem.path === undefined) {
          const item = await this.convertURLToBase64(
            this.state.previewItem.signedUrl
          );
          if (item !== "") {
            this.getBase64Data(item);
            this.setState({
              isLoading: false,
            });
          }
        }
        else{
          this.getBase64Data(this.state.previewItem.path);
          this.setState({
            isLoading: false,
          });
        }
      }
       
    }
  };

  convertURLToBase64(url) {
    return new Promise(async function (resolve, reject) {
      // await imagebase64.requestBase64(`${url}`, function (err, res, body) {
      //   resolve(body);
      // });
    });
  }

  handleDiscardItem = async () => {
    if (this.state.previewItem.objKey) {
      await this.props.discardItem(this.state.previewItem, 0);
      await this.props.refreshList(this.state.previewItem);
    } else {
      if (this.props.isDiscard) {
        await this.props.refreshList(this.state.previewItem);
      } else {
        await this.props.discardSelectMediaItem(this.state.previewItem);
      }
    }
    await this.props.toggle();
  };

  getBase64Data = async (path) => {
    let newItem = {
      fileName: this.state.fileName,
      dilationType: this.state.previewItem.dilationType,
      mime: this.state.previewItem.mime,
      //   name: this.state.previewItem.name,
      path: this.state.previewItem.path ? this.state.previewItem.path : path,
      quadrant: this.state.quadrant.label,
      signedUrl: this.state.previewItem.signedUrl,
    };
    this.props.discardItem(this.state.previewItem, 1);
    this.props.updateItem(this.state.previewItem, newItem);
    this.props.toggle();
  };

  render() {
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={10} sm={10} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.patientName} : {this.state.patientId}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
             
                <Row className="no-margin">
                  <Col
                    xs={12}
                    ms={12}
                    md={10}
                    lg={10}
                    xl={10}
                    className="m-auto px-0"
                  >
                    <Row className="no-mobile--margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="m-auto px-0"
                      >
                        <p className="mb-0 internal-section__title title-black fw-800">
                          {this.state.fileName}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-4 no-mobile-mlr">
                    <Col
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        className="m-auto pd-12px br-4px  d-flex justify-content-center"
                      ></Col>
                      <Col
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        className="m-auto pd-12px br-4px br-light-smoke d-flex justify-content-center"
                      >
                        {
                        // this.state.previewItemAll.length > 0 ? (
                        this.state.previewItem.mime === "image/jpeg" ||
                        this.state.previewItem.mime === "image/png" ? (
                         
                      <ImageZoom
                            src={
                              this.state.previewItem.signedUrl != undefined
                                ? this.state.previewItem.signedUrl
                                : this.state.previewItem.path
                            }
                            imgContainer="zoom-img-container"
                          />


                        ) : (
                          <video
                            width="100%"
                            height="100%"
                            controls
                            controlsList="nodownload"
                          >
                            <source
                              src={
                                this.state.previewItem.signedUrl != undefined
                                  ? this.state.previewItem.signedUrl
                                  : this.state.previewItem.path
                              }
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </Col>
                        <Col
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        className="m-auto pd-12px br-4px  d-flex justify-content-center"
                      >
                        { this.state.PreMediaLength > 1 && this.state.dilationType === "PRE" ? (
                    <div className="arrow-container" onClick={this.Prenext}>
                    <FontAwesomeIcon icon={faAngleRight}  style={{ "fontSize": "30px"}} />
                    </div>
                      ) : ("")

                    }
                    { this.state.PostMediaLength > 1 && this.state.dilationType === "POST" ?  (
                    <div className="arrow-container" onClick={this.Postnext}>
                    <FontAwesomeIcon icon={faAngleRight}  style={{ "fontSize": "30px"}} />
                    </div>
                      ) : ("")

                    }
                      </Col>
                      
                     
                    </Row>
                    {this.state.isDisplayBtn ? (
                      <Row className="no-mobile-mlr">
                        {this.state.isPreviewDisbledBtn ? (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="m-auto pb-2"
                          >
                            <p className="mb-0 internal-section__title title-danger fw-800">
                              This media is already selected, please Discard
                            </p>
                          </Col>
                        ) : null}
                      </Row>
                    ) : null}
                    {this.state.isDisplayBtn ? (
                      <Row className="mb-4 no-mobile-mlr">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="m-auto px-0"
                        >
                          <Select
                            value={this.state.quadrant}
                            onChange={(data) =>
                              this.handleQuadrant(data, this.state.previewItem)
                            }
                            options={imageQuadrant}
                            classNamePrefix="common-select-drop-down"
                            isSearchable={false}
                            // isDisabled={
                            //   this.state.previewItem.mime === "image/jpeg" ||
                            //   this.state.previewItem.mime === "image/png"
                            //     ? false
                            //     : true
                            // }
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="m-auto px-0"
                  >
                    {this.state.isDisplayBtn ? (
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={{ offset: 0, size: 10 }}
                          lg={{ offset: 0, size: 10 }}
                          xl={{ offset: 0, size: 10 }}
                          className="m-auto px-0"
                        >
                          <Row className="no-mobile-mlr">
                            <Col
                              xs={!this.state.isPreviewDisbledBtn ? 6 : 12}
                              sm={!this.state.isPreviewDisbledBtn ? 6 : 12}
                              md={!this.state.isPreviewDisbledBtn ? 6 : 12}
                              lg={!this.state.isPreviewDisbledBtn ? 6 : 12}
                              xl={!this.state.isPreviewDisbledBtn ? 6 : 12}
                            >
                              <Button
                                color="secondary"
                                className="btn btn-block custom-grey-btn"
                                onClick={this.handleDiscardItem}
                              >
                                Discard
                              </Button>
                            </Col>
                            {this.state.previewItem.mime === "image/jpeg" ||
                            this.state.previewItem.mime === "image/png" ? (
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Row className="no-margin">
                                  {!this.state.isPreviewDisbledBtn ? (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="pl-0 pr-0"
                                    >
                                      <Button
                                        color="success"
                                        className={`btn btn-block custom-theme-btn`}
                                        onClick={this.handleSubmit}
                                        disabled={this.state.disabledBtn}
                                      >
                                        Select
                                      </Button>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                            ) : (
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Row className="no-margin">
                                  {!this.state.isPreviewDisbledBtn ? (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="pl-0 pr-0"
                                    >
                                      <Button
                                        color="success"
                                        className={`btn btn-block custom-theme-btn`}
                                        onClick={this.handleSubmit}
                                      >
                                        Select
                                      </Button>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default PreviewImage;
