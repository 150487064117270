import React, { useState } from "react";
import {
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
} from "reactstrap";
import { withRouter } from "react-router";
import Select from "react-select";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import PatientService from "../../../../api/services/PatientService";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import { filterData } from "../../utils/dropdownData";
import applicationConstants from "../../constants/applicationContants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faQuestionCircle,
  faTimes,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import QuickHelpMessage from "../../customComponents/confirmationMessage/QuickHelpMessage";
import { StatusColorInfo } from "../../utils/AbbrevationInfo";
import Loader from "../../customComponents/Loader/Loader";

class ReaderPatientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientList: [],
      filterData: [],
      selectedItem: "",
      isfilter: false,
      isQuickHelp: false,
      jrReaderCount: 0,
      srReaderCount: 0,
      pjReaderCount: 0,
      roleId: "",
      filterText: "",
      isLoading: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  async componentDidMount() {
    await this.patientList();
  }

  patientList = async () => {
    const token = await localStorage.getItem("token");
    const hospitalName = await localStorage.getItem("hospitalName");
 
    const roleId = await localStorage.getItem("roleId");
    const data = {
      campId: "",
      status: "",
      startDate: "",
      endDate: "",
      limit: "",
      offset: "",
      hospitalName: hospitalName,
    };
    await this.setState({
      isLoading: true,
    });

    PatientService.getPatinetList(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0 && result.data != undefined) {
                console.log(JSON.stringify(result));
                let list = [];
                result.data.map((item) => {
                  if (
                    item.optoAdviceStatus === 1 ||
                    item.teleDrAdviceStatus === 1 ||
                    item.campDrAdviceStatus === 1
                  ) {
                    list.push(item);
                    //JrReader Count
                    if (
                      item.jrReaderGradingStatus === 0 ||
                      (item.jrReaderGradingStatus === 1 &&
                        item.saveAsSrReader === 0)
                    ) {
                      this.setState({
                        jrReaderCount: this.state.jrReaderCount + 1,
                      });
                    }
                    //SrReader Count
                    if (
                      item.srReaderGradingStatus === 0 &&
                      item.saveAsSrReader === 1
                    ) {
                      this.setState({
                        srReaderCount: this.state.srReaderCount + 1,
                      });
                    }

                    //PjReader Count
                    if (item.prReaderGradingStatus === 0) {
                      if (
                        (item.srReaderGradingStatus === 1 &&
                          item.saveAsSrReader === 1) ||
                        (item.jrReaderGradingStatus === 1 &&
                          item.saveAsSrReader === 0)
                      ) {
                        this.setState({
                          pjReaderCount: this.state.pjReaderCount + 1,
                        });
                      }
                    }
                  }
                });
                this.setState({
                  patientList: list,
                  // filterData: list,
                  selectedItem: filterData[0],
                  filterListData: list,
                  isLoading: false,
                });
              } else {
                await this.setState({
                  isLoading: false,
                });
                //alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
    this.setState({
      roleId: roleId,
    });
  };

  handleFilterItem = (item) => {
    let filterItem = [];
    if (item.label === applicationConstants.juniorReader) {
      this.state.filterListData.map((item) => {
        if((item.jrReaderGradingStatus === 1 && item.saveAsSrReader === 0) || item.jrReaderGradingStatus === 0)
        {
            if(item.prReaderGradingStatus === 1 && item.srReaderGradingStatus === 1){
              filterItem.push(item);
            } 
            else if(item.jrReaderGradingStatus === 0 && item.srReaderGradingStatus === 0 && item.prReaderGradingStatus === 0){
              filterItem.push(item);
            }
            else if(item.jrReaderGradingStatus === 1 && item.srReaderGradingStatus === 0 && item.saveAsSrReader === 0 && item.prReaderGradingStatus === 0){
              filterItem.push(item);
            }
        }
        // else if(item.jrReaderGradingStatus === 1 ){
        //   filterItem.push(item);
        // }
      });
      this.setState({
        patientList: filterItem,
        selectedItem: item,
      });
      console.log("juniorReader", filterItem)

    }
    if (item.label === applicationConstants.All) {
      this.setState({
        patientList: [...this.state.filterListData],
        selectedItem: item,
      });
    }
    if (item.label === applicationConstants.seniorReader) {
      this.state.filterListData.map((item) => {
        if (item.srReaderGradingStatus === 0 && item.saveAsSrReader === 1) {
          filterItem.push(item);
        }
      });
      this.setState({
        patientList: filterItem,
        selectedItem: item,
      });
      console.log("seniorReader", filterItem)
    }
    if (item.label === applicationConstants.ProjectReader) {
      this.state.filterListData.map((item) => {
        if (item.prReaderGradingStatus === 0) {
          if (
            (item.srReaderGradingStatus === 1 && item.saveAsSrReader === 1) ||
            (item.jrReaderGradingStatus === 1 && item.saveAsSrReader === 0)
          ) {
            filterItem.push(item);
          }
        }
      });
      this.setState({
        patientList: filterItem,
        selectedItem: item,
      });
      console.log("ProjectReader", filterItem)
    }

    if (item.label === applicationConstants.completed) {
      this.state.filterListData.map((item) => {
        if (
          item.prReaderGradingStatus === 1 &&
          ((item.srReaderGradingStatus === 1 && item.saveAsSrReader === 1) ||
          (item.srReaderGradingStatus === 0 && item.saveAsSrReader === 0)) &&
          item.jrReaderGradingStatus === 1
        ) {
          filterItem.push(item);
        }
      });
      this.setState({
        patientList: filterItem,
        selectedItem: item,
      });
      console.log("completed", filterItem)
    }
  };

  handleFilterStatus = () => {
    this.setState({
      isfilter: !this.state.isfilter,
    });
  };

  handleClick = async (patientId) => {
    await localStorage.setItem("patientId", `${patientId}`);
    this.props.history.push("/Report");
  };

  handleQuickHelp = () => {
    this.setState({
      isQuickHelp: !this.state.isQuickHelp,
    });
  };

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterListData(event.target.value);
  };

  filterListData = (filterText) => {
    const filteredItems = this.state.filterListData.filter(
      (item) =>
        (item.patientId &&
          item.patientId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.patientName &&
          item.patientName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.campId &&
          item.campId.toLowerCase().includes(filterText.toLowerCase()))
    );

    if (filteredItems.length >= 0) {
      this.setState({
        patientList: [...filteredItems],
        selectedItem: filterData[0],
      });
    } else {
      this.setState({
        patientList: [...this.state.filterListData],
        selectedItem: filterData[0],
      });
    }
  };

  handleClear = () => {
    this.setState({
      patientList: [...this.state.filterListData],
      filterText: "",
    });
  };

  render() {
    return (
      <RenderPage
        id="patinet-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-patient-list"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin">
            <Col xs={12} sm={12} md={10} lg={10} xl={6} className="m-auto">
              <Row className="no-margin">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-0 bg-theme-color br-top-lr-4px pt-12px pb-12px"
                >
                  <Row className="no-margin align-items-center">
                    <Col xs={8} sm={8} md={8} lg={10} xl={10}>
                      <p className="p-large mb-0 internal-page__title title-white align-left">
                        Patients
                      </p>
                    </Col>
                    <Col
                      xs={2}
                      sm={2}
                      md={1}
                      lg={1}
                      xl={1}
                      className="d-flex justify-content-end cursor-pointer"
                      onClick={() => this.handleQuickHelp()}
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        color={`#fff`}
                        size="2x"
                      />
                    </Col>
                    <Col
                      xs={2}
                      sm={2}
                      md={2}
                      lg={1}
                      xl={1}
                      onClick={() => this.handleFilterStatus()}
                      className="cursor-pointer"
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        color={`#fff`}
                        size="2x"
                        className="cursor-pointer"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="mt-12px mb-12px">
                <Row className="align-items-center">
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    xl={4}
                    lg={4}
                    className={`${
                      this.state.isfilter ? "dp-block" : "dp-none"
                    }`}
                  >
                    <p className="p-medium mb-0 internal-page__title title-black align-left">
                      Total Count - {this.state.patientList.length}
                    </p>
                  </Col>
                  <Col
                    xs={this.state.isfilter ? 8 : 12}
                    sm={this.state.isfilter ? 8 : 12}
                    md={this.state.isfilter ? 8 : 12}
                    xl={this.state.isfilter ? 8 : 12}
                    lg={this.state.isfilter ? 8 : 12}
                  >
                    <Row className="justify-content-end mb-3">
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        xl={6}
                        lg={6}
                        className={`${
                          this.state.isfilter ? "dp-block" : "dp-none"
                        }`}
                      >
                        <Select
                          value={this.state.selectedItem}
                          onChange={this.handleFilterItem}
                          options={filterData}
                          classNamePrefix="common-select-drop-down"
                          placeholder="Select"
                          isSearchable={false}
                        />
                      </Col>
                      <Col xs={6} sm={6} md={6} xl={6} lg={6}>
                        <InputGroup>
                          <Input
                            placeholder="Search"
                            name="filterText"
                            value={this.state.filterText}
                            onChange={this.onChange}
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={this.handleClear}
                          >
                            <InputGroupText className="cursor-pointer bg-theme-color">
                              <FontAwesomeIcon
                                icon={
                                  this.state.filterText ? faTimes : faSearch
                                }
                                color="white"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.patientList.length > 0 ? (
                  <Row className="pb-8px no-margin">
                    {this.state.patientList.map((item, index) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="card-contianer"
                        key={index}
                        onClick={() => this.handleClick(item.patientId)}
                      >
                        <Row className="align-items-center">
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p
                              className={`title mb-0 
                              ${
                                item.jrReaderGradingStatus == 1 &&
                                ((item.saveAsSrReader == 1 && item.srReaderGradingStatus == 1) ||
                                (item.srReaderGradingStatus == 0 && item.saveAsSrReader == 0)) &&
                                item.prReaderGradingStatus == 1
                                  ? "title-dimGrey"
                                  : item.jrReaderGradingStatus == 1 &&
                                    item.saveAsSrReader == 1 &&
                                    item.srReaderGradingStatus == 1
                                  ? "title-theme-color"
                                  : item.jrReaderGradingStatus == 1 &&
                                    item.saveAsSrReader == 1
                                  ? "title-danger"
                                  : item.jrReaderGradingStatus == 1 &&
                                    item.saveAsSrReader == 0
                                  ? "title-black"
                                  : "title-lightBlue"
                              }
                          
                           `}
                            >
                              {item.patientId}
                            </p>
                            <p className="sub-title mb-0">{item.patientName}</p>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p className="sub-title mb-0 align-right">
                              {item.campId}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Row className="pt-8px pb-8px no-margin align-items-center card-container__position br-light-smoke br-4px">
                    {!this.state.isLoading ? (
                      <Col>
                        <p className="mb-0 internal-page__title text-center">
                          No data found
                        </p>
                      </Col>
                    ) : (
                      <Col>
                        <Loader isLoader={false} />
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          {this.state.isQuickHelp ? (
            <QuickHelpMessage
              isModal={this.state.isQuickHelp}
              isIcon={true}
              data={StatusColorInfo}
              toggle={this.handleQuickHelp}
            />
          ) : null}
        </Strip>
      </RenderPage>
    );
  }
}

export default withRouter(ReaderPatientList);
