import React from "react";
import {
  Row,
  Col,
} from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import AddHospitalInfo from "./component/AddHospitalInfo";
import HospitalInfoList from "./component/HospitalInfoList";
const countrycitystateHandler = require('countrycitystatejson');


class HospitalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      data: null,
      modal: false,
      stateList:[],
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    await this.getStateList();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      isNew: true,
      data: null,
    });
  }

  getStateList = async () => {
    let data = countrycitystateHandler.getStatesByShort('IN');
    console.log(data);
    let obj={};
    let stateListData=[];
    data.map((item) => {
      obj={
        value: `${item}`,
        label: `${item}`
      };
      stateListData.push(obj);
    })
    this.setState({
      stateList: stateListData
    });
  }
  
  handleModal = async (item) => {    
    const state_name = await this.state.stateList.findIndex(it => it.value === item.state);
    await this.setState({
      modal: !this.state.modal,
      isNew: false,
      data: item,
      state_name:state_name,
    });
  };

  render() {
    return (
      <RenderPage
        id="add-camp-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.state.modal ? (
              <AddHospitalInfo
                toggle={this.toggle}
                isModal={this.state.modal}
                isNew={this.state.isNew}
                data={this.state.data}
                stateList={this.state.stateList}
                state_name={this.state.state_name}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <HospitalInfoList
              handleModal={this.handleModal}
              isModal={this.state.modal}
              toggle={this.toggle}
            />
          </Col>
        </Row>
      </RenderPage>
    );
  }
}

export default HospitalDetails;
