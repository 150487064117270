import React, { useState } from "react";

import {
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import windowSize from "react-window-size";
import XlsExport from "xlsexport";
import Strip from "../../../customComponents/Strip/Strip";
import UserService from "../../../../../api/services/UserService";
import {
  userRoleData,
  genderData,
  statusData,
} from "../../../utils/dropdownData";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../../utils/dataTablesStyledTheme";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSearch,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../../constants/applicationContants";
import memoizeOne from "memoize-one";
import ResetPassword from "../../../customComponents/ResetPassword/ResetPassword";
import Loader from "../../../customComponents/Loader/Loader";
// import { isMobile } from "react-device-detect";

const TablesColumns = memoizeOne((handleAction) => [
  {
    name: "Login Id",
    selector: "loginId",
    sortable: false,
  },
  {
    name: "Full Name",
    selector: "fullName",
    sortable: false,
  },
  {
    name: "Contact Info",
    selector: "mobileNumber",
    sortable: false,
    cell: (row) => (
      <div>
        <p className="p-small mb-0">{row.emailId}</p>
        <p className="p-small mb-0">{row.mobileNumber}</p>
      </div>
    ),
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: false,
  },
  {
    name: "Role Id",
    selector: "roleId",
    sortable: false,
  },
  {
    name: "Hospital Name",
    selector: "hospitalBranchName",
    sortable: false,
  },
  {
    name: "Status",
    selector: "status",
    sortable: false,
    cell: (row) => (
      <div>
        {row.status === "Active" ? (
          <p className="mb-0 active-btn">Active</p>
        ) : (
          <p className="mb-0 inactive-btn">Inactive</p>
        )}
      </div>
    ),
  },
  {
    name: "Reset Password",
    selector: "resetPassword",
    sortable: false,
    cell: (row) => (
      <div className="width-200px">
        <Button
          type="button"
          className="btn btn-outline-success bg-white btn-sm fs-12px"
          onClick={() => handleAction(row)}
        >
          <FontAwesomeIcon icon={faSyncAlt} color="#72d389" />
        </Button>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
]);

function downloadEXcel(array) {
  var xls = new XlsExport([...array], "Users Information");
  xls.exportToXLS("users_information.xls");
}

const Export = ({ onExportExcel }) => {
  return (
    <div>
      <Button
        onClick={() => onExportExcel()}
        className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
      >
        <img
          src={applicationConstants.exicon}
          alt=""
          className="excel-ico"
        /> Export Excel
      </Button>
    </div>
  );
};

const UserList = (props) => {
  const [usersList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isModal, setIsModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [item, setItem] = useState("");
  const [isReset, setIsReset] = useState(false);

  React.useEffect(() => {
    getUserList();
  }, []);

  React.useEffect(() => {
    getUserList();
  }, [props.isModal]);

  const getUserList = async () => {
    const token = await localStorage.getItem("token");
    setIsLoading(true);
    UserService.getUserList(token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                const dataList = [];
                for (var i = 0; i < data.length; i++) {
                  const gender = genderData[data[i].gender].label;
                  const roleId = userRoleData[data[i].roleId].label;
                  const status = statusData[data[i].status].label;
                  let item = {
                    loginId: data[i].loginId,
                    fullName: data[i].fullName,
                    mobileNumber: data[i].mobileNumber,
                    emailId: data[i].emailId,
                    gender: gender,
                    roleId: roleId,
                    status: status,
                    hospitalBranchName: data[i].hospitalBranchName,
                  };
                  dataList.push(item);
                }
                setUserList([...dataList]);
                setFilterData([...dataList]);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getUserItem = (item) => {
    props.handleModal(item);
  };

  const onChange = async (event) => {
    setFilterText(event.target.value)
    await handleFilter(event.target.value);
  };

 const handleFilter = async (filterText) => {
    const filteredItems = await filterData.filter(
      (item) =>
        (item.fullName &&
          item.fullName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.loginId &&
          item.loginId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.mobileNumber &&
          item.mobileNumber.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.roleId &&
          item.roleId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.gender &&
          item.gender.toLowerCase().includes(filterText.toLowerCase()))
    );

    if (filteredItems.length >= 0) {
      setUserList([...filteredItems])
    } else {
      setUserList([...filterData])
    }
  };

  const handleClear = () => {
    setUserList([...filterData]);
    setFilterText('')
  };

  const handleAction = (item) => {
    setItem(item);
    setIsReset(!isReset)
  };

  const toggle = () => {
    setIsReset(!isReset)
  };

  const handleExport = React.useMemo(
    () => (
      <Export
        onExportExcel={() => downloadEXcel(usersList)}
      />
    ),
    [usersList]
  );

  return (
    <Strip
      id="tst-user-list"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="no-mobile--padding"
        >
          <Row className="no-margin pb-3 align-items-center">
            <Col xs={12} sm={12} md={4} lg={2} xl={2}>
              <Button
                className="btn custom-theme-btn custom-btn--small bg-lblue"
                onClick={props.toggle}
              >
                <span>+</span> Add User
              </Button>             
            </Col>
            <Col xs={12} sm={12} md={8} lg={10} xl={10}>
              <Row className="justify-content-end">
                <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                  <InputGroup>
                    <Input
                      placeholder="Search"
                      name="filterText"
                      value={filterText}
                      onChange={onChange}
                    />
                    <InputGroupAddon
                      addonType="append"
                      onClick={handleClear}
                    >
                      <InputGroupText className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={filterText ? faTimes : faSearch}
                          color="white"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="no-margin">
        {usersList.length > 0 ? (
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding data-table-container"
          >
            <DataTable
              title=""
              columns={TablesColumns(handleAction)}
              data={usersList}
              pagination
              className="data-table"
              persistTableHead={false}
              customStyles={
                props.windowWidth > applicationConstants.mobileBreakPoint
                  ? dataTablesStyledTheme
                  : dataTablesMobileStyledTheme
              }
              onRowClicked={(data) => getUserItem(data)}
              // onSelectedRowsChange={this.updateState}
              actions={handleExport}
            />
          </Col>
        ) : (
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <div className="br-light-smoke p-5 br-4px">
              {!isLoading ? (
                <p className="mb-0 align-center internal-page__title">
                  No User
                </p>
              ) : (
                <Loader isLoader={false} />
              )}
            </div>
          </Col>
        )}
      </Row>
      {isReset ? (
        <ResetPassword
          isModal={isReset}
          toggle={toggle}
          item={item}
        />
      ) : null}
    </Strip>
  );
};

export default windowSize(UserList);
