import React from "react";
import { Row, Col } from "reactstrap";

const ImagingReport = (props) => {
  return (
    <Row className="pt-8px pb-8px br-4px bg-white no-margin">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <p className="mb-0 internal__section-title opensans-semiBold title-theme-color">
          IMAGING SUMMARY
        </p>
      </Col>
      {!props.isRE ? (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4p">
            <p className="m-0">RIGHT EYE</p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="pt-4px pb-4px"
          >
            <Row>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                  DR Grading
                </p>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
                <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                  -
                </p>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className="d-flex flex-wrap justify-content-end"
              >
                {props.REDRGrading.length > 0 ? (
                  props.REDRGrading.map((item, index) => (
                    <p
                      className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
                      key={index}
                    >
                      {item.label}
                      <font className="pr-4px">{`${
                        props.REDRGrading.length - 1 === index ? "" : ","
                      }`}</font>
                    </p>
                  ))
                ) : (
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    NA
                  </p>
                )}
              </Col>
            </Row>
          </Col>
          {props.preDilationREMedia.length > 0 ? (
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="p-medium mt-2 mb-1 internal-section__title title-suvaGrey">
                 Pre-dilated fundus image
              </p>
              <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                {props.preDilationREMedia.map((item, index) => (
                  <Col
                    xs={4}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                    className="pl-8px pr-8px d-flex"
                    key={index}
                  >
                    <img
                      src={item.signedUrl}
                      alt="Images"
                      className="report-image-container"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          ) : null}
          {props.postDilationREMedia.length > 0 ? (
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="p-medium mt-1 mb-1 internal-section__title title-suvaGrey">
                 Post-dilated fundus image
              </p>
              <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                {props.postDilationREMedia.map((item, index) => (
                  <Col
                    xs={4}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                    className="pl-8px pr-8px d-flex"
                    key={index}
                  >
                    <img
                      src={item.signedUrl}
                      alt="Images"
                      className="report-image-container"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          ) : null}
        </Row> 
        </Col>
      ) : null}
      {!props.isLE ? (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="pt-4px pb-4px"
          >
            <p className="m-0">LEFT EYE</p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="pt-4px pb-4px"
          >
            <Row>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                  DR Grading
                </p>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
                <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                  -
                </p>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className="d-flex flex-wrap justify-content-end"
              >
                {props.LEDRGrading.length > 0 ? (
                  props.LEDRGrading.map((item, index) => (
                    <p
                      className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
                      key={index}
                    >
                      {item.label}
                      <font className="pr-4px">{`${
                        props.LEDRGrading.length - 1 === index ? "" : ","
                      }`}</font>
                    </p>
                  ))
                ) : (
                  <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                    NA
                  </p>
                )}
              </Col>
              {props.preDilationLEMedia.length > 0 ? (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className="p-medium mt-2 mb-1 internal-section__title title-suvaGrey">
                    Pre-dilated fundus image
                  </p>
                  <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                    {props.preDilationLEMedia.map((item, index) => (
                      <Col
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        className="pl-8px pr-8px d-flex"
                        key={index}
                      >
                        <img
                          src={item.signedUrl}
                          alt="Images"
                          className="report-image-container"
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : null}
              {props.postDilationLEMedia.length > 0 ? (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className="p-medium mt-1 mb-1 internal-section__title title-suvaGrey">
                    Post-dilated fundus image
                  </p>
                  <Row className="no-margin pt-4px pb-4px br-light-smoke br-4px">
                    {props.postDilationLEMedia.map((item, index) => (
                      <Col
                        xs={4}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        className="pl-8px pr-8px d-flex"
                        key={index}
                      >
                        <img
                          src={item.signedUrl}
                          className="report-image-container"
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : null}
            </Row>
          </Col>
          </Row>
          </Col>
      ) : null}
    </Row>
  );
};

export default ImagingReport;
