import React from "react";
import { Row, Col, Input, Form, FormGroup, Label, Button } from "reactstrap";
import Strip from "../../../customComponents/Strip/Strip";
import PatientService from "../../../../../api/services/PatientService";
import applicationConstants from "../../../constants/applicationContants";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import { isOnetoTen } from "../../../utils/customValidators";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";

class ProjectReaderGrade extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "sharpness",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "reflexArtifacts",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "illuminationContrast",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "protocolComplianceGradingMacula",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "superiorTemporalArcade",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "inferiorTemporalArcade",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "superiorNasalArcade",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
      {
        field: "inferiorNasalArcade",
        constraints: [
          {
            method: isOnetoTen,
            validWhen: true,
            message: "Please rate between 1-10",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please rate between 1-10",
            args: [{ max: 2 }],
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
      patientId: "",
      signedUrl: props.signedUrl ? props.signedUrl : "",
      // display_status: true,
      roleId: "",
      sharpness: "",
      reflexArtifacts: "",
      illuminationContrast: "",
      protocolComplianceGradingMacula: "",
      superiorTemporalArcade: "",
      inferiorTemporalArcade: "",
      superiorNasalArcade: "",
      inferiorNasalArcade: "",
    };
    this.isValidate = true;
    this.handleRate = this.handleRate.bind(this);
  }

  async componentDidMount() {
    if (this.state.sharpness) {
      this.validator.touched("sharpness");
    }
    if (this.state.reflexArtifacts) {
      this.validator.touched("reflexArtifacts");
    }
    if (this.state.illuminationContrast) {
      this.validator.touched("illuminationContrast");
    }
    if (this.state.protocolComplianceGradingMacula) {
      this.validator.touched("protocolComplianceGradingMacula");
    }
    if (this.state.superiorTemporalArcade) {
      this.validator.touched("superiorTemporalArcade");
    }
    if (this.state.inferiorTemporalArcade) {
      this.validator.touched("inferiorTemporalArcade");
    }
    if (this.state.superiorNasalArcade) {
      this.validator.touched("superiorNasalArcade");
    }
    if (this.state.inferiorNasalArcade) {
      this.validator.touched("inferiorNasalArcade");
    }

    await this.getPatientBasedOnId();
  }

  getPatientBasedOnId = async () => {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const campId = await localStorage.getItem("campId");
    const roleId = await localStorage.getItem("roleId");

    if (patientId) {
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;

                  if (data.registerInfo) {
                    this.setState({
                      patientName: data.registerInfo.patientName,
                    });
                  }

                  if (data.prReaderGrading.length > 0) {
                    data.prReaderGrading.map((item) => {
                      if (this.props.signedUrl === item.signedUrl) {
                        this.setState({
                          sharpness:
                            item.sharpness != "null" ? item.sharpness : "",
                          reflexArtifacts:
                            item.reflexArtifacts != "null"
                              ? item.reflexArtifacts
                              : "",
                          illuminationContrast:
                            item.illuminationContrast != "null"
                              ? item.illuminationContrast
                              : "",
                          protocolComplianceGradingMacula:
                            item.protocolComplianceGradingMacula != "null"
                              ? item.protocolComplianceGradingMacula
                              : "",
                          superiorTemporalArcade:
                            item.superiorTemporalArcade != "null"
                              ? item.superiorTemporalArcade
                              : "",
                          inferiorTemporalArcade:
                            item.inferiorTemporalArcade != "null"
                              ? item.inferiorTemporalArcade
                              : 0,
                          superiorNasalArcade:
                            item.superiorNasalArcade != "null"
                              ? item.superiorNasalArcade
                              : "",
                          inferiorNasalArcade:
                            item.inferiorNasalArcade != "null"
                              ? item.inferiorNasalArcade
                              : "",
                          signedUrl: item.signedUrl,
                        });
                      }
                    });
                  }
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    this.setState({
      campId: campId,
      patientId: patientId,
      roleId: roleId,
    });
  };

  handleChange = async (event) => {
    event.preventDefault();
    this.validator.touched(event.target.name);
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRate = async () => {
    const token = await localStorage.getItem("token");
    const campId = await localStorage.getItem("currentCamp");
    const userId = await localStorage.getItem("loginId");

    const data = {
      campId: campId,
      patientId: this.state.patientId,
      patientName: this.state.patientName,
      sharpness:
        this.state.sharpness !== "" ? parseInt(this.state.sharpness) : 0,
      reflexArtifacts:
        this.state.reflexArtifacts !== ""
          ? parseInt(this.state.reflexArtifacts)
          : 0,
      illuminationContrast:
        this.state.illuminationContrast !== ""
          ? parseInt(this.state.illuminationContrast)
          : 0,
      protocolComplianceGradingMacula:
        this.state.protocolComplianceGradingMacula !== ""
          ? parseInt(this.state.protocolComplianceGradingMacula)
          : 0,
      superiorTemporalArcade:
        this.state.superiorTemporalArcade !== ""
          ? parseInt(this.state.superiorTemporalArcade)
          : 0,
      inferiorTemporalArcade:
        this.state.inferiorTemporalArcade !== ""
          ? parseInt(this.state.inferiorTemporalArcade)
          : 0,
      superiorNasalArcade:
        this.state.superiorNasalArcade !== ""
          ? parseInt(this.state.superiorNasalArcade)
          : 0,
      inferiorNasalArcade:
        this.state.inferiorNasalArcade !== ""
          ? parseInt(this.state.inferiorNasalArcade)
          : 0,
      signedUrl: this.state.signedUrl,
      userId: userId,
    };

    PatientService.addOrUpdateProjectReaderGrading(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                //console.log(JSON.stringify(result));
                this.props.toggle();
              } else {
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} ms={12} md={10} lg={10} xl={10} className="m-auto">
            <Form>
              <p className="common-label text-right mb-2">
                Grading allowed from 0-10
              </p>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Sharpness
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(validation.sharpness.isInvalid)}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="sharpness"
                      id="sharpness"
                      value={this.state.sharpness}
                    />
                    <p className="p-small help-block">
                      {validation.sharpness.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Reflex Artifacts
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.reflexArtifacts.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="reflexArtifacts"
                      id="reflexArtifacts"
                      value={this.state.reflexArtifacts}
                    />
                    <p className="p-small help-block">
                      {validation.reflexArtifacts.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Illumination &amp; Contrast
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.illuminationContrast.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="illuminationContrast"
                      id="illuminationContrast"
                      value={this.state.illuminationContrast}
                    />
                    <p className="p-small help-block">
                      {validation.illuminationContrast.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Protocol compliance grading Macula
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.protocolComplianceGradingMacula.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="protocolComplianceGradingMacula"
                      id="protocolComplianceGradingMacula"
                      value={this.state.protocolComplianceGradingMacula}
                    />
                    <p className="p-small help-block">
                      {validation.protocolComplianceGradingMacula.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Superior temporal arcade
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.superiorTemporalArcade.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="superiorTemporalArcade"
                      id="superiorTemporalArcade"
                      value={this.state.superiorTemporalArcade}
                    />
                    <p className="p-small help-block">
                      {validation.superiorTemporalArcade.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Inferior temporal arcade
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.inferiorTemporalArcade.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="inferiorTemporalArcade"
                      id="inferiorTemporalArcade"
                      value={this.state.inferiorTemporalArcade}
                    />
                    <p className="p-small help-block">
                      {validation.inferiorTemporalArcade.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Superior nasal arcade
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.superiorNasalArcade.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="superiorNasalArcade"
                      id="superiorNasalArcade"
                      value={this.state.superiorNasalArcade}
                    />
                    <p className="p-small help-block">
                      {validation.superiorNasalArcade.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={9} className="common-label">
                  Inferior nasal arcade
                </Label>
                <Col sm={3}>
                  <div
                    className={IsInputInError(
                      validation.inferiorNasalArcade.isInvalid
                    )}
                  >
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="inferiorNasalArcade"
                      id="inferiorNasalArcade"
                      value={this.state.inferiorNasalArcade}
                    />
                    <p className="p-small help-block">
                      {validation.inferiorNasalArcade.message}
                    </p>
                  </div>
                </Col>
              </FormGroup>
            </Form>
          </Col>

          <Col xs={12} ms={12} md={10} lg={10} xl={10} className="m-auto px-0">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={11}
                lg={11}
                xl={11}
                className="m-auto pt-3"
              >
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="btn btn-block custom-danger-btn custom-btn--small"
                      onClick={() => this.props.toggle()}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      color="success"
                      className="btn btn-block custom-theme-btn custom-btn--small"
                      onClick={this.handleRate}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default ProjectReaderGrade;
