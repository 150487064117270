import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { withRouter } from "react-router";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../constants/applicationContants";
import Strip from "../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faExclamationCircle,
  faEnvelope,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { validPassword } from "../../utils/customValidators";
import {
  alertSuccessMessage,
  alertDangerConfirmMessage,
} from "../../customComponents/confirmationMessage/ConfirmationMessage";
import LoginService from "../../../../api/services/LoginService";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      hiddenCurrent: true,
      hiddenNew: true,
      hiddenConfirm: true,
      invalid: false,
      token: "",
      resetPassword: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.showPasswordConfirm = this.showPasswordConfirm.bind(this);
    this.showPasswordNew = this.showPasswordNew.bind(this);
  }

  async componentDidMount() {
    const params = window.location.search;
    const token = params.split("=")[1]
    if (params !== "") {
      this.setState({
        token: token.split("%")[0],
      });
    }
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleConfirmPassword(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    this.confirmValidation();
  }

  async handlePassword(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    this.confirmValidation();
  }

  confirmValidation = async () => {
    if (this.state.newPassword && this.state.confirmPassword) {
      if (this.state.newPassword === this.state.confirmPassword) {
        await this.setState({
          isMatching: false,
          isValid: true,
        });
      } else {
        await this.setState({
          isMatching: true,
          isValid: false,
        });
      }
    } else {
      await this.setState({
        isMatching: false,
        isValid: false,
      });
    }
  };

  // ---------- displaying password ---------------

  showPasswordNew() {
    this.setState({
      hiddenNew: !this.state.hiddenNew,
    });
  }

  showPasswordConfirm() {
    this.setState({
      hiddenConfirm: !this.state.hiddenConfirm,
    });
  }

  handleSubmit = () => {
    const data = {
      token: this.state.token,
      password: this.state.newPassword,
    };

    if (this.state.newPassword !== "") {
      LoginService.resetLinkPassword(data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                 // alertSuccessMessage(`${result.message}`);
                  // await this.props.resetLink();
                  // await this.props.history.push("/");
                  this.setState({
                    resetPassword: true,
                  });
                } else {
                  alertDangerConfirmMessage(
                    `New Password not created, please try again`
                  );
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage("please enter new password");
    }
  };


  render() {
    const { confirmPassword, isMatching, isValid } = this.state;
    return (
      <RenderPage
        id="forgot-password-page"
        className="mt-0"
        containerType="container-fluid"
      >
        <Strip
          id="tst-forgot-password"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Form>
            <Row className="m-0 justify-content-center align-items-center full-height">
              <Col
                xs={10}
                sm={10}
                md={8}
                lg={6}
                xl={3}
                className="shadow-lg p-3 bg-white rounded"
              >
                {!this.state.resetPassword ? (
                  <div>
                    <Row>
                      <Col
                        xs={10}
                        sm={10}
                        md={10}
                        lg={8}
                        xl={8}
                        className="align-center"
                      >
                        <img
                          src={applicationConstants.logoImg}
                          alt="teleoptics-logo"
                          className="logo-container"
                        />
                      </Col>
                    </Row>
                    <FormGroup row className="align-items-center ">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label fw-800">
                          New Password *
                        </Label>
                        <InputGroup>
                          <Input
                            type={this.state.hiddenNew ? "password" : "text"}
                            placeholder="New Password"
                            id="newPassword"
                            name="newPassword"
                            value={this.state.newPassword}
                            onChange={this.handlePassword}
                            className="form-control common-form-control"
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={this.showPasswordNew}
                          >
                            <InputGroupText className="cursor-pointer bg-theme-color">
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                color="white"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <p
                          className={`p-small help-block ${
                            this.state.newPassword != "" &&
                            validPassword(this.state.newPassword)
                              ? "dp-block"
                              : "dp-none"
                          }`}
                        >
                          Password sholud be min 6 character, atleast one
                          uppercase character, alteast one lowercase character
                          and altleast one special character
                        </p>
                      </Col>
                    </FormGroup>

                    <FormGroup row className="align-items-center ">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label fw-800">
                          Confirm Password
                        </Label>
                        <InputGroup>
                          <Input
                            type={
                              this.state.hiddenConfirm ? "password" : "text"
                            }
                            placeholder="Confirm Password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.handleConfirmPassword}
                            invalid={isMatching}
                            valid={isValid}
                            className="form-control common-form-control"
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={this.showPasswordConfirm}
                          >
                            <InputGroupText className="cursor-pointer bg-theme-color">
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                color="white"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <p
                          className={`p-small help-block ${
                            this.state.confirmPassword != "" && isMatching
                              ? "dp-block"
                              : "dp-none"
                          }`}
                        >
                          Password not matching
                        </p>
                      </Col>
                    </FormGroup>

                    <div className="pt-3 pb-3">
                      <Button
                        className="btn btn-block custom-theme-btn"
                        size="lg"
                        onClick={this.handleSubmit}
                        disabled={isMatching}
                      >
                        Create Password
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Row className="pt-4 pb-3">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="text-center pt-4"
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        size={"6x"}
                        color="#72d389"
                        className="icon-envelope"
                      />
                      <p className="internal-page__title title-black text-center pt-2">
                       Password created successfully
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        </Strip>
      </RenderPage>
    );
  }
}

export default withRouter(ForgotPassword);
