import React from "react";
import { Col, Row } from "reactstrap";
import {
  insufOrSufficient,
  YesOrNo,
  DRData,
  suggestedStrategyData,
  othersData,
} from "../../utils/dropdownData";

class ReaderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data ? props.data : "",
      readerType: props.readerType ? props.readerType : "",
    };
  }

  renderDRItem = (data) => {
    const list = JSON.parse(data);
    if (list !== "[]" && list !== "-1" && list !== "-1" && list.length > 0) {
      return list.map((item, index) => (
        <p
          className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
          key={index}
        >
          {DRData[item].label}
          <font className="pr-4px">{`${
            list.length - 1 === index ? "" : ","
          }`}</font>
        </p>
      ));
    } else {
      return (
        <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
          NA
        </p>
      );
    }
  };

  renderSuggestedStrategyItem = (data) => {
    const list = JSON.parse(data);
    if (list !== "[]" && list !== "-1" && list !== "-1" && list.length > 0) {
      return list.map((item, index) => (
        <p
          className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
          key={index}
        >
          {suggestedStrategyData[item].label}
          <font className="pr-4px">{`${
            list.length - 1 === index ? "" : ","
          }`}</font>
        </p>
      ));
    } else {
      return (
        <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
          NA
        </p>
      );
    }
  };

  renderotherItem = (data) => {
    const list = JSON.parse(data);
    if (list !== "[]" && list !== "-1" && list !== "-1" && list.length > 0) {
      return list.map((item, index) => (
        <p
          className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
          key={index}
        >
          {othersData[item].label}
          <font className="pr-4px">{`${
            list.length - 1 === index ? "" : ","
          }`}</font>
        </p>
      ));
    } else {
      return (
        <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
          NA
        </p>
      );
    }
  };

  render() {
    return (
      <Row className="pt-8px pb-8px bg-white br-4px no-margin">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
          <p className="mb-0 internal-section__title opensans-semiBold title-theme-color">
            {`${this.state.readerType} Summary`}
          </p>
        </Col>
        {this.state.data.map((item, index) => (
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="align-items-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="pt-4px pb-4px"
              >
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="pt-8px"
                  >
                    <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                      {`Reviewed by`}
                      <font className="fw-800 pl-4px">{item.userName}</font>
                    </p>
                    <hr className="br-light-smoke mt-1 mb-1" />
                  </Col>
                </Row>
              </Col>
              {!this.props.isRE ? (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="p-0 mb-1">RIGHT EYE</p>
                    </Col>
                    {/* <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Image Quality
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                            {item.REimageQuality != "-1" &&
                            item.REimageQuality != -1
                              ? insufOrSufficient[item.REimageQuality].label
                              : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    */}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Retinal Diseases
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                            {item.REretinalDisease != "-1" &&
                            item.REretinalDisease != -1
                              ? YesOrNo[item.REretinalDisease].label
                              : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            DR Grade
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderDRItem(item.REgradeDR)}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Suggested Strategy
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderSuggestedStrategyItem(
                            item.REsuggestedStrategy
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Others
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderotherItem(item.REOthers)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : null}

              {!this.state.isLE ? (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="p-0 mb-1">LEFT EYE</p>
                    </Col>
                    {/* <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Image Quality
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                            {item.LEimageQuality != "-1" &&
                            item.LEimageQuality != -1
                              ? insufOrSufficient[item.LEimageQuality].label
                              : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    */}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Retinal Diseases
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                            {item.LEretinalDisease != "-1" &&
                            item.LEretinalDisease != -1
                              ? YesOrNo[item.LEretinalDisease].label
                              : "NA"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            DR Grade
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderDRItem(item.LEgradeDR)}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Suggested Strategy
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderSuggestedStrategyItem(
                            item.LEsuggestedStrategy
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-4px pb-4px"
                    >
                      <Row>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey">
                            Others
                          </p>
                        </Col>
                        <Col
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className="no-mobile-plr"
                        >
                          <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                            -
                          </p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="d-flex flex-wrap justify-content-end"
                        >
                          {this.renderotherItem(item.LEOthers)}
                        </Col>
                      </Row>
                    </Col>
                    {this.state.data.length - 1 === index ? null : (
                      <Col className="pb-12px">
                        <hr className="br-light-smoke" />
                      </Col>
                    )}
                  </Row>
                </Col>
              ) : null}
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
}

export default ReaderSummary;
