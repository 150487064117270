import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";
const FormData = require('form-data');

const VideoService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getVideoCategories: async (token) =>{
    const { baseUrl, getVideoCategories } = ApiConfig;
    const url = baseUrl + getVideoCategories;

    const headers = new Headers({
      "Content_Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "token": `${token}`,
    });

    const dropdowngetVideoCat = await Fetch(
      url,
      "GET",
      headers,
      "",
      "dropdownVideoCat",
      true
    );
    return dropdowngetVideoCat;
  },

  getVideoList: async (token) => {
    const { baseUrl, getVideoTutorials } = ApiConfig;

    const url = baseUrl + getVideoTutorials; 

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "token": `${token}`,
    });
   

    const videoServicePromise = await Fetch(
      url,
      "GET",
      headers,
      "",
      "services_VideoService",
      true
    );
    return videoServicePromise;
  },

  addOrUpdateVideoTut: async (data, token) => {
    const { baseUrl, addVideoTutorial, updateVideoTutorial } = ApiConfig;

    var url = baseUrl;
    var params = new FormData();
    params.append("tut_name", data.tut_name);
    params.append("tut_description", data.tut_description);
    params.append("tut_category", data.tut_category);
    params.append("video_type", Number(data.video_type));
    params.append("video_url", data.video_url);
    params.append("youtube_url", data.youtube_url);
    params.append("thumbnail", data.thumbnail);
    console.log(params) 
    if(data.id){      
      url+=updateVideoTutorial;     
    params.append("id", data.id);
    console.log(params)
    }else{
      url+=addVideoTutorial;      
    }
    console.log(url)
    

    const headers = new Headers({
      "Access-Control-Allow-Origin": "*",
      "token": `${token}`,
    })

    const addOrUpdatevideoTutorials = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addOrUpdatevideoTutorials",
      true
    );
    return addOrUpdatevideoTutorials;
  }, 

  deleteVideoTut: async (token, id) => {
    const { baseUrl, deleteVideoTutorial } = ApiConfig;

    const url = baseUrl + deleteVideoTutorial;

    const params = JSON.stringify({
      id: `${id}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });


    const deleteVideotutss = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_deleteVideotutss",
      true
    );
    return deleteVideotutss;
  },
};

export default VideoService;
