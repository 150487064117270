import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import AddUser from "./component/AddUser";
import UserList from "./component/UserList";
import { genderData, userRoleData, statusData } from "../../utils/dropdownData";
import HospitalService from "../../../../api/services/HospitalService";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewUser: true,
      data: null,
      modal: false,
      assignedHospitalList:[],
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount(){
    await this.getHospitalList();
  }

  getHospitalList = async () => {
    HospitalService.getHospitalList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                let data = result.data;
                let obj={};
                let assignedHospital=[];
                data.forEach(item => {
                  obj={
                    value: `${item.name}`,
                    label: `${item.name}`
                  };
                  assignedHospital.push(obj);
                })
                this.setState({
                  assignedHospitalList: assignedHospital
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
      isNewUser: true,
      data: null,
    });
  }

  handleModal = async (item) => {
    genderData.find((data) => {
      if (data.label === item.gender) {
        item.gender = data.value;
      }
    });

    userRoleData.find((data) => {
      if (data.label === item.roleId) {
        item.roleId = data.value;
      }
    });

    statusData.find((data) => {
      if (data.label === item.status) {
        item.status = data.value;
      }
    });

    this.state.assignedHospitalList.find((data, index) => {
      if(data.label === item.hospitalBranchName){
        item.hospitalBranchName = index;
      }else if(item.hospitalBranchName === null){
        item.hospitalBranchName = 0;
      }
    });
   
    await this.setState({
      modal: !this.state.modal,
      isNewUser: false,
      data: item,
    });
  };

  render() {
    return (
      <RenderPage
        id="user-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.state.modal ? (
              <AddUser
                toggle={this.toggle}
                isModal={this.state.modal}
                isNewUser={this.state.isNewUser}
                data={this.state.data}
                hospitalList={this.state.assignedHospitalList}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <UserList
              handleModal={this.handleModal}
              isModal={this.state.modal}
              toggle={this.toggle}
            />
          </Col>
        </Row>
      </RenderPage>
    );
  }
}

export default UserDetails;
