import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import Strip from "../../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../../../../api/services/UserService";
import applicationConstants from "../../../constants/applicationContants";
import {
  userRoleData,
  genderData,
  statusData,
} from "../../../utils/dropdownData";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import { isMobileNumber, validPassword, userFullName } from "../../../utils/customValidators";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "loginId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter login Id",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "loginId should be  max 50 characters",
            args: [{ max: 50 }],
          },
        ],
        touched: false,
      },
      {
        field: "emailId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Email-Id is required.",
          },
          {
            method: "isEmail",
            validWhen: true,
            message: "That is not a valid email-Id.",
          },
        ],
        touched: false,
      },
      {
        field: "password",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter password",
          },
          {
            method: validPassword,
            validWhen: false,
            message:
              "Password should be min 6 characters long, at least one uppercase, one lowercase, and one special char",
          },
        ],
        touched: false,
      },
      {
        field: "fullName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter full name",
          },
          {
            method: userFullName,
            validWhen: true,
            message: "Please use letters only",
          }
        ],
        touched: false,
      },
      {
        field: "mobileNumber",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter contact number",
          },
          {
            method: isMobileNumber,
            validWhen: true,
            message: "Please enter valid contact number",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid contact number",
            args: [{ max: 10 }],
          },
        ],
        touched: false,
      },
      {
        field: "gender",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select gender",
          },
        ],
        touched: false,
      },
      {
        field: "assignedHospital",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select assigned Hospital option",
          },
        ],
        touched: false,
      },
      {
        field: "userRole",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select user role",
          },
        ],
        touched: false,
      },
      {
        field: "status",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select status",
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
      loginId: props.data ? (props.data.loginId ? props.data.loginId : "") : "",
      password: props.data
        ? props.data.password
          ? props.data.password
          : ""
        : "",
      fullName: props.data
        ? props.data.fullName
          ? props.data.fullName
          : ""
        : "",
      mobileNumber: props.data
        ? props.data.mobileNumber.substring(3)
          ? props.data.mobileNumber.substring(3)
          : ""
        : "",
      gender: props.data
        ? genderData[props.data.gender].label
          ? genderData[props.data.gender]
          : ""
        : "",
      userRole: props.data
        ? userRoleData[props.data.roleId].label
          ? userRoleData[props.data.roleId]
          : ""
        : "",
      status: props.data
        ? statusData[props.data.status].label
          ? statusData[props.data.status]
          : ""
        : "",
      modal: props.isModal ? props.isModal : false,
      isNewUser: props.isNewUser ? props.isNewUser : false,
      countryCode: props.data
        ? props.data.mobileNumber.substring(0, 3)
          ? props.data.mobileNumber.substring(0, 3)
          : "+91"
        : "+91",
      emailId: props.data ? (props.data.emailId ? props.data.emailId : "") : "",
      //assignedHospital: '',
      assignedHospital: props.data
        ? props.hospitalList[props.data.hospitalBranchName] !== undefined
          ? props.hospitalList[props.data.hospitalBranchName]
          : ""
        : "",
      hospitalList: props.hospitalList ? props.hospitalList : [],
      isLoading: false,
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.loginId) {
      this.validator.touched("loginId");
    }
    if (this.state.fullName) {
      this.validator.touched("fullName");
    }
    if (this.state.mobileNumber) {
      this.validator.touched("mobileNumber");
      this.validationContactNumber(
        this.props.data.mobileNumber.substring(0, 3)
      );
    }
    if (this.state.gender) {
      this.validator.touched("gender");
    }
    if (this.state.userRole) {
      this.validator.touched("userRole");
    }
    if (this.state.assignedHospital) {
      this.validator.touched("assignedHospital");
    }
    if (this.state.status) {
      this.validator.touched("status");
    }
    if (this.state.emailId) {
      this.validator.touched("emailId");
    }
    if (!this.state.isNewUser) {
      this.validator.removeConstraint("password");
    }
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleUserRole = (item) => {
    this.validator.touched("userRole");
    this.setState({
      userRole: item,
    });
  };

  handleGender = (item) => {
    this.validator.touched("gender");
    this.setState({
      gender: item,
    });
  };

  handleMobileNumber = (countryCode) => {
    this.setState({
      countryCode: countryCode,
    });
    this.validationContactNumber(countryCode);
  };

  handleStatus = (item) => {
    this.validator.touched("status");
    this.setState({
      status: item,
    });
  };

  handleAssignedHospital = (item) => {
    this.validator.touched("assignedHospital");
    this.setState({
      assignedHospital: item,
    });
  };

  validationContactNumber = async (countryCode) => {
    this.validator.removeConstraint("mobileNumber");
    this.validator.addConstraint({
      field: "mobileNumber",
      constraints: [
        {
          method: "isEmpty",
          validWhen: false,
          message: "Please enter contact number",
        },
        {
          method: isMobileNumber,
          validWhen: true,
          message: "Please enter valid contact number",
        },
        {
          method: "isLength",
          validWhen: true,
          message: "Please enter valid contact number",
          args: [
            {
              min: countryCode === "+91" ? 10 : 8,
              max: countryCode === "+91" ? 10 : 12,
            },
          ],
        },
      ],
      touched: false,
    });
  };

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");

    if (errorMessage.length <= 0) {
      const data = {
        loginId: this.state.loginId,
        password: this.state.password,
        fullName: this.state.fullName,
        mobileNumber: `${this.state.countryCode}${this.state.mobileNumber}`,
        gender: this.state.gender.value,
        roleId: this.state.userRole.value,
        status: this.state.status.value,
        emailId: this.state.emailId,
        hospitalBranchName: this.state.assignedHospital.value,
      };

      const url = this.state.isNewUser
        ? applicationConstants.addUser
        : applicationConstants.updateUser;

      await this.setState({
        isLoading: true,
      });

      UserService.addOrUpdateUser(data, token, url)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  //console.log(JSON.stringify(result));
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.toggle();
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    console.log(this.props, this.state);
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.isNewUser ? "Add User" : "Update User"}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form autoComplete="off">
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Login-Id*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.loginId.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Login ID"
                              id="loginID"
                              name="loginId"
                              value={this.state.loginId}
                              className="form-control common-form-control"
                              onChange={this.handleChange}
                              autoComplete="new-loginId"
                              readOnly={this.state.isNewUser ? false : true}
                            />
                            <p className="p-small help-block">
                              {validation.loginId.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Email-Id*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.emailId.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="emailId"
                              id="emailId"
                              name="emailId"
                              value={this.state.emailId}
                              className="form-control common-form-control"
                              onChange={this.handleChange}
                              autoComplete="new-emailId"
                            />
                            <p className="p-small help-block">
                              {validation.emailId.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      {this.props.isNewUser ? (
                        <FormGroup
                          row
                          className={`mx-0 ${
                            validation.password.isInvalid
                              ? ""
                              : "align-items-center"
                          }`}
                        >
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="text-position"
                          >
                            <Label
                              className={`common-label ${
                                validation.password.isInvalid ? "mt-2" : ""
                              }`}
                            >
                              Password*:
                            </Label>
                          </Col>
                          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div
                              className={IsInputInError(
                                validation.password.isInvalid
                              )}
                            >
                              <Input
                                type="password"
                                placeholder="Password"
                                id="password"
                                name="password"
                                value={this.state.password}
                                className="form-control common-form-control"
                                onChange={this.handleChange}
                                readOnly={!this.state.isNewUser}
                                autoComplete="new-password"
                              />
                              <p className="p-small help-block">
                                {validation.password.message}
                              </p>
                            </div>
                          </Col>
                        </FormGroup>
                      ) : null}
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Full Name*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={
                              this.state.isNewUser
                                ? IsInputInError(validation.fullName.isInvalid)
                                : ""
                            }
                          >
                            <Input
                              type="text"
                              placeholder="Full Name"
                              id="fullName"
                              name="fullName"
                              value={this.state.fullName}
                              className="form-control common-form-control"
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.fullName.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">
                            Mobile Number*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.mobileNumber.isInvalid
                            )}
                          >
                            <InputGroup>
                              <InputGroupAddon
                                addonType="prepend"
                                className="input-form-group-prepend"
                              >
                                <Dropdown
                                  direction="down"
                                  isOpen={this.state.btnDropright}
                                  toggle={() => {
                                    this.setState({
                                      btnDropright: !this.state.btnDropright,
                                    });
                                  }}
                                  className="input-btn input-dropdown"
                                >
                                  <DropdownToggle
                                    caret
                                    className="input-dropdown-toggle"
                                  >
                                    {this.state.countryCode}
                                  </DropdownToggle>
                                  <DropdownMenu className="input-dropdown-menu">
                                    <DropdownItem
                                      className="input-dropdown-menu-item"
                                      onClick={() =>
                                        this.handleMobileNumber("+91")
                                      }
                                    >
                                      +91
                                    </DropdownItem>
                                    <DropdownItem
                                      className="input-dropdown-menu-item"
                                      onClick={() =>
                                        this.handleMobileNumber("+49")
                                      }
                                    >
                                      +49
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                placeholder="Mobile Number"
                                name="mobileNumber"
                                id="mobileNumber"
                                className="form-control common-form-control common-form-control--left-radius-none"
                                value={this.state.mobileNumber}
                                onChange={this.handleChange}
                              />
                            </InputGroup>

                            <p className="p-small help-block">
                              {validation.mobileNumber.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Gender*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.gender.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.gender}
                              onChange={this.handleGender}
                              options={genderData}
                              classNamePrefix="common-select-drop-down"
                              name="gender"
                              id="gender"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.gender.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">
                            Assigned Hospital*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.assignedHospital.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.assignedHospital}
                              onChange={this.handleAssignedHospital}
                              options={this.state.hospitalList}
                              classNamePrefix="common-select-drop-down"
                              name="status"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.assignedHospital.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Role*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.userRole.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.userRole}
                              onChange={this.handleUserRole}
                              options={userRoleData}
                              classNamePrefix="common-select-drop-down"
                              name="userRole"
                              id="userRole"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.userRole.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Status*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.status.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.status}
                              onChange={this.handleStatus}
                              options={statusData}
                              classNamePrefix="common-select-drop-down"
                              name="status"
                              id="status"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.status.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            {this.state.isNewUser ? (
                              <Button
                                color="success"
                                className="btn btn-block custom-theme-btn custom-btn--small"
                                onClick={this.handleSubmit}
                                disabled={!this.validator.canSubmit(this.state)}
                              >
                                Add
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                className="btn btn-block custom-theme-btn custom-btn--small"
                                onClick={this.handleSubmit}
                                //  disabled={!this.validator.canSubmit(this.state)}
                              >
                                Update
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default AddUser;
