import React from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import BackArrow from "../../customComponents/BackArrow/BackArrow";
import {
  insufOrSufficient,
  DRData,
  suggestedStrategyData,
  YesOrNo,
  othersData,
  DRDataOne,
  DRDataTwo,
} from "../../utils/dropdownData";
import Select from "react-select";
import PatientService from "../../../../api/services/PatientService";
import applicationConstants from "../../constants/applicationContants";
import Loader from "../../customComponents/Loader/Loader";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";

class ImagingAndGrading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patientName: "",
      roleName: "",
      campId: "",
      REImageQuality: -1,
      RERetinalDiseases: -1,
      REGradeDR: [],
      RESuggestedStrategy: [],
      REOthers: [],
      LEImageQuality: -1,
      LERetinalDiseases: -1,
      LEGradeDR: [],
      LESuggestedStrategy: [],
      LEOthers: [],
      userName: "",
      userId: "",
      saveAsSrReader: 0,
      DrRESingleSelect: -1,
      DrREMultiSelect: -1,
      DrLESingleSelect: -1,
      DrLEMultiSelect: -1,
      isRE: false,
      isLE: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const roleId = await localStorage.getItem("roleId");
    const userId = await localStorage.getItem("loginId");
    const userName = await localStorage.getItem("userName");
    if (patientId) {
      await this.setState({
        isLoading: true
      })
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data.registerInfo) {
                    this.setState({
                      patientId: data.patientId,
                      patientName: data.registerInfo.patientName,
                      campId: data.campId,
                     
                    });
                  }
                  if (data.refractionInfo) {
                    this.setState({
                      isRE: data.refractionInfo.REReason !== "" ? true : false,
                      isLE: data.refractionInfo.LEReason !== "" ? true : false,
                    });
                  }

                  if (data.readerGrading.length > 0) {
                    data.readerGrading.map((item) => {
                      if (item.userId === userId) {
                        this.setState({
                          saveAsSrReader: item.saveAsSrReader,
                        });

                        if (
                          item.REimageQuality !== -1 &&
                          item.REimageQuality !== "-1"
                        ) {
                          this.setState({
                            REImageQuality:
                              insufOrSufficient[item.REimageQuality],
                          });
                        }

                        if (
                          item.LEimageQuality !== -1 &&
                          item.LEimageQuality !== "-1"
                        ) {
                          this.setState({
                            LEImageQuality:
                              insufOrSufficient[item.LEimageQuality],
                          });
                        }

                        if (
                          item.REretinalDisease !== -1 &&
                          item.REretinalDisease !== "-1"
                        ) {
                          this.setState({
                            RERetinalDiseases: YesOrNo[item.REretinalDisease],
                          });
                        }

                        if (
                          item.LEretinalDisease !== -1 &&
                          item.LEretinalDisease !== "-1"
                        ) {
                          this.setState({
                            LERetinalDiseases: YesOrNo[item.LEretinalDisease],
                          });
                        }

                        if (
                          item.REgradeDR !== "[]" &&
                          item.REgradeDR !== "-1"
                        ) {
                          //  let REGradeDRList = [];
                          // const REGradeData = JSON.parse(item.REgradeDR);

                          // if (REGradeData) {
                          //   REGradeData.map((item) => {
                          //     REGradeDRList.push(DRData[item]);
                          //   });
                          // }
                          const DRREMulti = [];
                          const REGradeData = JSON.parse(item.REgradeDR);
                          if (REGradeData) {
                            REGradeData.map((value) => {
                              if (
                                value === 0 ||
                                value === 1 ||
                                value === 2 ||
                                value === 3 ||
                                value === 4
                              ) {
                                this.setState({
                                  DrRESingleSelect: DRDataOne[value],
                                });
                              }
                              if (value === 5 || value === 6) {
                                DRREMulti.push(DRDataTwo[value === 5 ? 0 : 1]);
                              }
                            });
                          }
                          this.setState({
                            DrREMultiSelect: DRREMulti,
                          });
                        }

                        if (
                          item.LEgradeDR !== "[]" &&
                          item.LEgradeDR !== "-1"
                        ) {
                          const DRLEMulti = [];
                          const LEGradeData = JSON.parse(item.LEgradeDR);
                          if (LEGradeData) {
                            LEGradeData.map((value) => {
                              if (
                                value === 0 ||
                                value === 1 ||
                                value === 2 ||
                                value === 3 ||
                                value === 4
                              ) {
                                this.setState({
                                  DrLESingleSelect: DRDataOne[value],
                                });
                              }
                              if (value === 5 || value === 6) {
                                DRLEMulti.push(DRDataTwo[value === 5 ? 0 : 1]);
                              }
                            });
                          }
                          this.setState({
                            DrLEMultiSelect: DRLEMulti,
                          });
                          // let LEGradeDRList = [];
                          // const LEGradeData = JSON.parse(item.LEgradeDR);
                          // if (LEGradeData) {
                          //   LEGradeData.map((item) => {
                          //     LEGradeDRList.push(DRData[item]);
                          //   });
                          // }
                          // this.setState({
                          //   LEGradeDR: LEGradeDRList,
                          // });
                        }

                        if (
                          item.REsuggestedStrategy !== "[]" &&
                          item.REsuggestedStrategy !== "-1"
                        ) {
                          let REsuggestedStrategyList = [];
                          const REsuggestedStrategyData = JSON.parse(
                            item.REsuggestedStrategy
                          );
                          if (REsuggestedStrategyData) {
                            REsuggestedStrategyData.map((item) => {
                              REsuggestedStrategyList.push(
                                suggestedStrategyData[item]
                              );
                            });
                          }
                          this.setState({
                            RESuggestedStrategy: REsuggestedStrategyList,
                          });
                        }

                        if (
                          item.LEsuggestedStrategy !== "[]" &&
                          item.LEsuggestedStrategy !== "-1"
                        ) {
                          let LEsuggestedStrategyList = [];
                          const LEsuggestedStrategyData = JSON.parse(
                            item.LEsuggestedStrategy
                          );
                          if (LEsuggestedStrategyData) {
                            LEsuggestedStrategyData.map((item) => {
                              LEsuggestedStrategyList.push(
                                suggestedStrategyData[item]
                              );
                            });
                          }
                          this.setState({
                            LESuggestedStrategy: LEsuggestedStrategyList,
                          });
                        }
                        if (item.REOthers !== "[]" && item.REOthers !== "-1") {
                          let REOthersList = [];
                          const REOthersData = JSON.parse(item.REOthers);
                          if (REOthersData) {
                            REOthersData.map((item) => {
                              REOthersList.push(othersData[item]);
                            });
                          }
                          this.setState({
                            REOthers: REOthersList,
                          });
                        }

                        if (item.LEOthers !== "[]" && item.LEOthers !== "-1") {
                          let LEOthersList = [];
                          const LEOthersData = JSON.parse(item.LEOthers);
                          if (LEOthersData) {
                            LEOthersData.map((item) => {
                              LEOthersList.push(othersData[item]);
                            });
                          }
                          this.setState({
                            LEOthers: LEOthersList,
                          });
                        }
                      }
                    });
                  }
                  await this.setState({
                    isLoading: false
                  })
                } else {
                  await this.setState({
                    isLoading: false
                  })
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              this.setState({
                isLoading: false
              })
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          })
          console.log(err);
        });
    }
    this.setState({
      roleName: roleId,
      userName: userName,
      userId: userId,
    });
  }

  handleREImageQuality = (item) => {
    this.setState({
      REImageQuality: item,
    });
  };

  handleRERetinalDiseases = (item) => {
    this.setState({
      RERetinalDiseases: item,
    });
  };

  handleREGradeDR = (item) => {
    this.setState({
      REGradeDR: item,
    });
  };

  handleREGradeSuggestesStrategy = (item) => {
    this.setState({
      RESuggestedStrategy: item,
    });
  };

  handleREOther = (item) => {
    this.setState({
      REOthers: item,
    });
  };

  handleLEImageQuality = (item) => {
    this.setState({
      LEImageQuality: item,
    });
  };

  handleLERetinalDiseases = (item) => {
    this.setState({
      LERetinalDiseases: item,
    });
  };

  handleLEGradeDR = (item) => {
    this.setState({
      LEGradeDR: item,
    });
  };

  handleLEGradeSuggestesStrategy = (item) => {
    this.setState({
      LESuggestedStrategy: item,
    });
  };

  handleLEOther = (item) => {
    this.setState({
      LEOthers: item,
    });
  };

  handleDrRESingleSelect = async (item) => {
    await this.setState({
      DrRESingleSelect: item,
    });
  };

  handleDrREMultiSelect = async (item) => {
    await this.setState({
      DrREMultiSelect: item,
    });
  };

  handleDrLESingleSelect = async (item) => {
    await this.setState({
      DrLESingleSelect: item,
    });
  };

  handleDrLEMultiSelect = async (item) => {
    await this.setState({
      DrLEMultiSelect: item,
    });
  };

  handleSubmt = async (value) => {
    const token = await localStorage.getItem("token");

    const REOthersList = [];
    if (this.state.REOthers) {
      if (this.state.REOthers.length > 0) {
        this.state.REOthers.map((item) => {
          REOthersList.push(item.value);
        });
      }
    }

    const LEOthersList = [];
    if (this.state.LEOthers) {
      if (this.state.LEOthers.length > 0) {
        this.state.LEOthers.map((item) => {
          LEOthersList.push(item.value);
        });
      }
    }

    const REGradeDRList = [];
    let REList = [];
    let DRRESingle = [];
    let DRREMulti = [];
    if (this.state.DrRESingleSelect !== -1) {
      DRRESingle.push(this.state.DrRESingleSelect.value);
    }
    if (this.state.DrREMultiSelect != null) {
      if (
        this.state.DrREMultiSelect !== -1 &&
        this.state.DrREMultiSelect !== "-1"
      ) {
        DRREMulti.push(this.state.DrREMultiSelect.value);
      }
    }

    REList = [...DRRESingle, ...DRREMulti];
    if (REList.length > 0) {
      REList.map((item) => {
        if (item != undefined) {
          REGradeDRList.push(item);
        }
      });
    }

    const LEGradeDRList = [];
    let LEList = [];
    let DRLESingle = [];
    let DRLEMulti = [];

    if (this.state.DrLESingleSelect !== -1) {
      DRLESingle.push(this.state.DrLESingleSelect.value);
    }
    if (this.state.DrLEMultiSelect != null) {
      if (
        this.state.DrLEMultiSelect !== -1 ||
        this.state.DrLEMultiSelect !== "-1"
      ) {
        DRLEMulti.push(this.state.DrLEMultiSelect.value);
      }
    }

    LEList = [...DRLESingle, ...DRLEMulti];
    if (LEList.length > 0) {
      LEList.map((item) => {
        if (item != undefined) {
          LEGradeDRList.push(item);
        }
      });
    }

    const RESuggestedStrategyList = [];
    if (this.state.RESuggestedStrategy) {
      if (this.state.RESuggestedStrategy.length > 0) {
        this.state.RESuggestedStrategy.map((item) => {
          RESuggestedStrategyList.push(item.value);
        });
      }
    }

    const LESuggestedStrategyList = [];
    if (this.state.LESuggestedStrategy) {
      if (this.state.LESuggestedStrategy.length > 0) {
        this.state.LESuggestedStrategy.map((item) => {
          LESuggestedStrategyList.push(item.value);
        });
      }
    }
    const data = {
      patientId: this.state.patientId,
      campId: this.state.campId,
      readerType: this.state.roleName,
      REImageQuality:
        this.state.REImageQuality !== "-1" && this.state.REImageQuality !== -1
          ? this.state.REImageQuality.value
          : -1,
      RERetinalDiseases:
        this.state.RERetinalDiseases !== "-1" &&
        this.state.RERetinalDiseases !== -1
          ? this.state.RERetinalDiseases.value
          : -1,
      REGradeDR: REGradeDRList.length > 0 ? JSON.stringify(REGradeDRList) : -1,
      RESuggestedStrategy:
        RESuggestedStrategyList.length > 0
          ? JSON.stringify(RESuggestedStrategyList)
          : -1,
      REOthers: REOthersList.length > 0 ? JSON.stringify(REOthersList) : -1,
      LEImageQuality:
        this.state.LEImageQuality !== "-1" && this.state.LEImageQuality !== -1
          ? this.state.LEImageQuality.value
          : -1,
      LERetinalDiseases:
        this.state.LERetinalDiseases !== "-1" &&
        this.state.LERetinalDiseases !== -1
          ? this.state.LERetinalDiseases.value
          : -1,
      LEGradeDR: LEGradeDRList.length > 0 ? JSON.stringify(LEGradeDRList) : -1,
      LESuggestedStrategy:
        LESuggestedStrategyList.length > 0
          ? JSON.stringify(LESuggestedStrategyList)
          : -1,
      LEOthers: LEOthersList.length > 0 ? JSON.stringify(LEOthersList) : -1,
      saveAsSrReader: value,
      userId: this.state.userId,
      userName: this.state.userName,
    };
    await this.setState({
      isLoading: true
    })
    PatientService.addOrUpdateReader(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                await this.setState({
                  isLoading: false
                })
                //console.log(JSON.stringify(result));
                this.props.history.push("/PatientList");
              } else {
                await this.setState({
                  isLoading: false
                })
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false
            })
            console.log(err);
          }
        }
      })
      .catch((err) => {
         this.setState({
          isLoading: false
        })
        console.log(err);
      });
  };

  handleBackArrow = () => {
    this.props.history.push("/ImagingAndGrading");
  };

  render() {
    return (
      <RenderPage
        id="imaging-grading-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-imaging-grading"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={10} lg={10} xl={6} className="m-auto">
              <BackArrow
                title={`Grading`}
                handleBackArrow={this.handleBackArrow}
              />

              <Row className="bg-light-white pd-12px br-4px">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pb-4px pt-4px"
                >
                  <p className="p-large internal-page__title  mb-0 text-capitalize align-left title-suvaGrey">
                    {this.state.patientName} - {this.state.patientId}
                  </p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-0 br-4px"
                >
                  <Form className="pd-12px">
                    {!this.state.isRE ? (
                      <Row className="bg-white pt-12px pb-4px pl-16px pr-16px br-4px mb-3 no-margin">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="px-0"
                        >
                          <p className="mb-2 p-large internal-page__title title-theme-color align-left">
                            RIGHT EYE
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="px-0"
                        >
                          {/* <FormGroup>
                            <Label className="common-label">
                              Image Quality
                            </Label>
                            <Select
                              value={this.state.REImageQuality}
                              onChange={this.handleREImageQuality}
                              options={insufOrSufficient}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isSearchable={false}
                            />
                          </FormGroup> */}

                          <FormGroup>
                            <Label className="common-label">
                              Retinal Diseases
                            </Label>
                            <Select
                              value={this.state.RERetinalDiseases}
                              onChange={this.handleRERetinalDiseases}
                              options={YesOrNo}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">Grade DR</Label>
                            {/* <Select
                            value={this.state.REGradeDR}
                            onChange={this.handleREGradeDR}
                            options={DRData}
                            classNamePrefix="common-select-drop-down"
                            placeholder="Select"
                            isMulti
                          /> */}
                            <Row>
                              <Col>
                                <Select
                                  value={this.state.DrRESingleSelect}
                                  onChange={this.handleDrRESingleSelect}
                                  options={DRDataOne}
                                  classNamePrefix="common-select-drop-down"
                                  placeholder="Select Grade"
                                />
                              </Col>
                              <Col>
                                <Select
                                  value={this.state.DrREMultiSelect}
                                  onChange={this.handleDrREMultiSelect}
                                  options={DRDataTwo}
                                  classNamePrefix="common-select-drop-down"
                                  placeholder="Select Grade"
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Suggested Strategy
                            </Label>
                            <Select
                              value={this.state.RESuggestedStrategy}
                              onChange={this.handleREGradeSuggestesStrategy}
                              options={suggestedStrategyData}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isMulti
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Other Diagnosis{" "}
                            </Label>
                            <Select
                              value={this.state.REOthers}
                              onChange={this.handleREOther}
                              options={othersData}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    {/* --------------- Left EYE---------------- */}
                    {!this.state.isLE ? (
                      <Row className="bg-white pt-12px pb-4px pl-16px pr-16px br-4px mb-3 no-margin">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="px-0"
                        >
                          <p className="mb-2 p-large internal-page__title title-theme-color align-left">
                            LEFT EYE
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="px-0"
                        >
                          {/* <FormGroup>
                            <Label className="common-label">
                              Image Quality
                            </Label>
                            <Select
                              value={this.state.LEImageQuality}
                              onChange={this.handleLEImageQuality}
                              options={insufOrSufficient}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isSearchable={false}
                            />
                          </FormGroup> */}

                          <FormGroup>
                            <Label className="common-label">
                              Retinal Diseases
                            </Label>
                            <Select
                              value={this.state.LERetinalDiseases}
                              onChange={this.handleLERetinalDiseases}
                              options={YesOrNo}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">Grade DR</Label>
                            {/* <Select
                            value={this.state.LEGradeDR}
                            onChange={this.handleLEGradeDR}
                            options={DRData}
                            classNamePrefix="common-select-drop-down"
                            placeholder="Select"
                            isMulti
                          /> */}
                            <Row>
                              <Col>
                                <Select
                                  value={this.state.DrLESingleSelect}
                                  onChange={this.handleDrLESingleSelect}
                                  options={DRDataOne}
                                  classNamePrefix="common-select-drop-down"
                                  placeholder="Select Grade"
                                />
                              </Col>
                              <Col>
                                <Select
                                  value={this.state.DrLEMultiSelect}
                                  onChange={this.handleDrLEMultiSelect}
                                  options={DRDataTwo}
                                  classNamePrefix="common-select-drop-down"
                                  placeholder="Select Grade"
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Suggested Strategy
                            </Label>
                            <Select
                              value={this.state.LESuggestedStrategy}
                              onChange={this.handleLEGradeSuggestesStrategy}
                              options={suggestedStrategyData}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isMulti
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Other Diagnosis
                            </Label>
                            <Select
                              value={this.state.LEOthers}
                              onChange={this.handleLEOther}
                              options={othersData}
                              classNamePrefix="common-select-drop-down"
                              placeholder="Select"
                              isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    {this.state.roleName ===
                    applicationConstants.seniorReader ? (
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            className="btn btn-block custom-theme-btn btn btn-secondary"
                            onClick={() => this.handleSubmt(1)}
                          >
                            SAVE
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Button
                            className="btn btn-block custom-theme-btn btn btn-secondary"
                            onClick={() => this.handleSubmt(0)}
                          >
                            SAVE
                          </Button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Button
                            className="btn btn-block custom-theme-btn btn btn-secondary"
                            onClick={() => this.handleSubmt(1)}
                          >
                            {`${
                              this.state.roleName ===
                              applicationConstants.juniorReader
                                ? "SAVE WITH SR GRADE"
                                : "SAVE GRADE"
                            }`}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </Col>
              </Row>

              {this.state.isLoading ? <Loader isLoader={false} /> : null}
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    );
  }
}
export default ImagingAndGrading;
