import memoizeOne from "memoize-one";
import React from "react";

const TablesColumns = memoizeOne((handleAction) => [
  {
    name: "Hospital Name",
    selector: "name",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.name}</div>,
  },
  {
    name: "Branch Name",
    selector: "hospitalBranchName",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.hospitalBranchName}</div>,
  },
  {
    name: "City",
    selector: "city",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.city}</div>,
  },
  {
    name: "State",
    selector: "state",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.state}</div>,
  },{
    name: "Location",
    selector: "location",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.location}</div>,
  },
]);

export default TablesColumns;
