import React from "react";
import { Row, Col } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import AddCamp from "./component/AddCamp";
import CampList from "./component/CampList";
import HospitalService from "../../../../api/services/HospitalService";
import VisionCenterService from "../../../../api/services/VisionCenterService";
const countrycitystateHandler = require('countrycitystatejson');

class CampDetails extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      isNewCamp: true,
      data: null,
      modal: false,
      assignedHospitalList: [],
      visionCenterList: [],
      hospitalName: "",
      stateList:[],
      assignedHospital: null,
      visionCenterId:null
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    const hospitalName = await localStorage.getItem("hospitalName");
    this.setState({
      hospitalName: hospitalName,
    });
    await this.getHospitalList();
    await this.getVisionCenterList();
    await this.getStateList();
  }

  getStateList = async () => {
    let data = countrycitystateHandler.getStatesByShort('IN');
    console.log(data);
    let obj={};
    let stateListData=[];
    data.forEach(item => {
      obj={
        value: `${item}`,
        label: `${item}`,
      };
      stateListData.push(obj);
    })
    this.setState({
      stateList: stateListData
    });
    console.log(this.stateList)
  }
  getHospitalList = async () => {
   await HospitalService.getHospitalList()
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              console.log(res)
              let result = JSON.parse(res);
              if (result.code === 0) {
                let data = result.data;
                let obj={};
                let assignedHospital=[];
                data.map(item => {
                  obj={
                    value: `${item.name}`,
                    label: `${item.name}`,
                    state: `${item.state}`,
                  };
                  assignedHospital.push(obj);
                })
                this.setState({
                  assignedHospitalList: assignedHospital
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getVisionCenterList = async () => {
    const token = await localStorage.getItem("token");
    await VisionCenterService.getVisionCenterList(token)
       .then(async (res) => {
         if (res.status === 200) {
           try {
             res.text().then(async (res) => {
              console.log(res)
               let result = JSON.parse(res);
               if (result.code === 0) {
                 let data = result.data;
                 let obj={};
                 let visionCenter=[];
                 data.map(item => {
                   obj={
                     value: `${item.internal_id}`,
                     label: `${item.center_name}`,
                     state: `${item.state}`,
                   };
                   console.log(obj.value, obj.label)
                   visionCenter.push(obj);
                 })
                 this.setState({
                  visionCenterList: visionCenter
                 });
               }
             });
           } catch (err) {
             console.log(err);
           }
         }
       })
       .catch((err) => {
         console.log(err);
       });
   };

  async toggle() {
    const hospitalName = await localStorage.getItem("hospitalName");
    const index = await this.state.assignedHospitalList.findIndex(item => item.value === hospitalName);
    this.setState({
      modal: !this.state.modal,
      isNewCamp: true,
      data: null,
      hospitalName: hospitalName,
      assignedHospital: index,
    });
  }

  handleModal = async (item) => {
    console.log("santhosh",item)
    const index = item.hospitalName !== "undefined" && item.hospitalName !== null ? await this.state.assignedHospitalList.filter(it => it.value === item.hospitalName) : null; 
    const vision_index = item.visionCentreId !== "undefined" && item.visionCentreId !== null ? await this.state.visionCenterList.filter(it => it.value === item.visionCentreId) : null;
    const state_name = item.state_name !== "undefined" && item.state_name !== null ? await this.state.stateList.filter(it => it.value === item.state_name) : null;
    console.log("HANDLEMODAL", state_name, index, vision_index)
    await this.setState({ 
      modal: !this.state.modal,
      isNewCamp: false,
      data: item,
      hospitalName:
        item.hospitalName !== "undefined" && item.hospitalName !== ""
          ? item.hospitalName
          : this.state.hospitalName,
      assignedHospital: index,
      visionCenterId: vision_index,
      state_name:state_name,
    });
   
  };

  render() {
    return (
      <RenderPage
        id="add-camp-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.state.modal ? (
              <AddCamp
                toggle={this.toggle}
                isModal={this.state.modal}
                isNewCamp={this.state.isNewCamp}
                data={this.state.data}
                hospitalName={this.state.hospitalName}
                hospitalList={this.state.assignedHospitalList}
                visionCenterList={this.state.visionCenterList}
                assignedHospital={this.state.assignedHospital}
                stateList={this.state.stateList}
                center_name={this.state.visionCenterId}
                state_name={this.state.state_name}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CampList
              handleModal={this.handleModal}
              isModal={this.state.modal}
              toggle={this.toggle}
            />
          </Col>
        </Row>
      </RenderPage>
    );
  }
}

export default CampDetails;
