import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PatientService from "../../../../api/services/PatientService";  
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import BackArrow from "../../customComponents/BackArrow/BackArrow";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";

import applicationConstants from "../../constants/applicationContants";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      patientId: "",
      patientName: "",      
    };    
  }

  async componentDidMount() {
    await this.getPatientStatus();
  }

  getPatientStatus = async () => {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    if (patientId) {
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  this.setState({
                    data: result.data,
                    patientId: patientId,
                    patientName: result.data.registerInfo.patientName,
                  });
                  localStorage.setItem(
                    "patientName",
                    result.data.registerInfo.patientName
                  );
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        patientId: patientId,
        patientName: "",
      });
    }
  };

  handleBackArrow = () => {
    // let navFrom =localStorage.getItem("navFrom");
    // if(navFrom=="PatientTable"){
    //   this.props.history.push("/PatientTable");
    // }else{
    //   this.props.history.push("/PatientList");
    // }
    this.props.history.push("/PatientList");
  };

  render() {
    const { data } = this.state;
    const REDisbled =
      data.refractionInfo != null && data.refractionInfo.REReason !== ""
        ? true
        : false;
    const LEDisbled =
      data.refractionInfo != null && data.refractionInfo.LEReason !== ""
        ? true
        : false;
    return (
      <RenderPage
        id="dashboard-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-dashboard"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-mobile--margin">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="m-auto no-mobile--padding"
            >
              <BackArrow
                className="no-margin"
                title={
                  this.state.patientId
                    ? `${this.state.patientName} - ${this.state.patientId}`
                    : "New Patient"
                }
                handleBackArrow={this.handleBackArrow}
                pathName="patientList"
              />
              <div className="">
              <div className="row">
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="">
                <Row className="bg-light-white mt-2 no-margin no-mobile-plr bg-white py-3">
                  <Col xs={12} sm={6} md={6} lg={12} xl={12} className="mb-4">
                    <Link
                      to={`/Registration`}
                      className={`d-flex justify-content-center pat-list`}
                    >
                      <div className="pat-icon">   
                        <img
                        src={applicationConstants.regicon}
                        alt=""
                        className=""
                        />
                      </div>
                      <p
                        className={`mb-0 common-status-btn dash-btn  ${
                          this.state.patientId
                            ? data.registerInfo != null &&
                              data.registerInfo.registrationStatus === 1
                              ? "success-btn"
                              : "processing-btn"
                            : "processing-btn"
                        }`}
                      >                   
                        REGISTRATION

                        <span className="num-bg">01</span>
                      </p>
                    </Link>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={12} xl={12} className="mb-4">
                    <Link
                      to={`/HealthDetails`}
                      className={`d-flex justify-content-center pat-list ${
                        this.state.patientId ? "" : "disabled"
                      }`}
                    >
                      <div className="pat-icon">   
                        <img
                        src={applicationConstants.helicon}
                        alt=""
                        className=""
                        />
                      </div>
                      <p
                        className={`mb-0 common-status-btn dash-btn ${
                          this.state.patientId
                            ? data.healthInfo != null &&
                              data.healthInfo.healthInfoStatus === 1
                              ? "success-btn"
                              : "processing-btn"
                            : "disabled-btn"
                        }`}
                      >
                        HEALTH INFO
                        <span className="num-bg">02</span>
                      </p>
                    </Link>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Link
                      to={`/Refraction`}
                      className={`d-flex justify-content-center pat-list ${
                        this.state.patientId ? "" : "disabled"
                      }`}
                    >
                      <div className="pat-icon">   
                        <img
                        src={applicationConstants.reficon}
                        alt=""
                        className=""
                        />
                      </div>

                      <p
                        className={`mb-0 common-status-btn dash-btn ${
                          this.state.patientId
                            ? data.refractionInfo != null &&
                              data.refractionInfo.refractionStatus === 1
                              ? "success-btn"
                              : "processing-btn"
                            : "disabled-btn"
                        }`}
                      >
                        REFRACTION
                        <span className="num-bg">03</span>
                      </p>
                    </Link>
                  </Col> 
                </Row>
              </Col>


              <Col xs={12} sm={12} md={6} lg={8} xl={8} className="">
              <Row className="bg-light-white mt-2 no-margin no-mobile-plr bg-white py-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className="mb-0 internal-page__title title-theme-color text-center pb-3">
                    IMAGING &#38; GRADING
                  </p>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Link
                    to={`/RightEye`}
                    className={`d-flex justify-content-center pat-list ${
                      REDisbled
                        ? "disabled"
                        : this.state.patientId
                        ? ""
                        : "disabled"
                    }`}
                  >

                    <div className="pat-icon">   
                      <img
                      src={applicationConstants.reicon}
                      alt=""
                      className=""
                      />
                    </div>

                    <p
                      className={`mb-0 common-status-btn dash-btn ${
                        REDisbled
                          ? "disabled-btn"
                          : this.state.patientId
                          ? data.optoREDiag != null &&
                            data.optoREDiag.optoREStatus === 1
                            ? "success-btn"
                            : "processing-btn"
                          : "disabled-btn"
                      }`}
                    >
                      RIGHT EYE
                    </p>
                  </Link>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Link
                    to={`/LeftEye`}
                    className={`d-flex justify-content-center pat-list ${
                      LEDisbled
                        ? "disabled"
                        : this.state.patientId
                        ? ""
                        : "disabled"
                    }`}
                  >
                    <div className="pat-icon">   
                      <img
                      src={applicationConstants.leicon}
                      alt=""
                      className=""
                      />
                    </div>

                    <p
                      className={`mb-0 common-status-btn dash-btn ${
                        LEDisbled
                          ? "disabled-btn"
                          : this.state.patientId
                          ? data.optoLEDiag != null &&
                            data.optoLEDiag.optoLEStatus === 1
                            ? "success-btn"
                            : "processing-btn"
                          : "disabled-btn"
                      }`}
                    >
                      LEFT EYE
                    </p>
                  </Link>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="">
                  <Link
                    to={`/OptometristAdvice`}
                    className={`d-flex justify-content-center pat-list ${
                      this.state.patientId ? "" : "disabled"
                    }`}
                  >
                    <div className="pat-icon">   
                      <img
                      src={applicationConstants.opicon}
                      alt=""
                      className=""
                      />
                    </div>

                    <p
                      className={`mb-0 common-status-btn dash-btn ${
                        this.state.patientId
                          ? data.optoAdvice != null &&
                            data.optoAdvice.adviceStatus === 1
                            ? "success-btn"
                            : "processing-btn"
                          : "disabled-btn"
                      }`}
                    >
                      OPTOMETRIST ADVICE
                    </p>
                  </Link>
                </Col>
              </Row>

              <Row className="bg-light-white mb-3 no-margin py-3 no-mobile-plr bg-white border-top">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className="mb-0 internal-page__title title-theme-color text-center pb-3 pt-2">
                    DOCTOR GRADING
                  </p>
                </Col>
                <Col xs={12} sm={6} md={12} lg={6} xl={6} className="grading-box d-flex justify-content-center pat-list disabled">
               
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className=" mb-3 internal-page__title txt-black text-center pb-3">
                        CAMP-GRADING
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={4} className="">
                      <Link
                        to={`/AtCampRE`}
                        className={`d-flex justify-content-center pat-list ${
                          REDisbled
                            ? "disabled"
                            : this.state.patientId
                            ? ""
                            : "disabled"
                        }`}
                      >
                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.reicon}
                      alt=""
                      className="" />
                      </div>

                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            REDisbled
                              ? "disabled-btn"
                              : this.state.patientId
                              ? data.campDrREDiag != null &&
                                data.campDrREDiag.campDrREStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          RE
                        </p>
                      </Link>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={4} className="">
                      <Link
                        to={`/AtCampLE`}
                        className={`d-flex justify-content-center pat-list ${
                          LEDisbled
                            ? "disabled"
                            : this.state.patientId
                            ? ""
                            : "disabled"
                        }`}
                      >
                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.leicon}
                      alt=""
                      className="" />
                      </div>

                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            LEDisbled
                              ? "disabled-btn"
                              : this.state.patientId
                              ? data.campDrLEDiag != null &&
                                data.campDrLEDiag.campDrLEStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          LE
                        </p>
                      </Link>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      
                    >
                      <Link
                        to={`/AtCampAdvice`}
                        className={`d-flex justify-content-center pat-list ${
                          this.state.patientId ? "" : "disabled"
                        }`}
                      >

                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.adicon}
                      alt=""
                      className="" />
                      </div>

                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            this.state.patientId
                              ? data.campDrAdvice != null &&
                                data.campDrAdvice.adviceStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          ADVICE
                        </p>
                      </Link>
                    </Col>
                  </Row>
                
                </Col>
               
                <Col xs={12} sm={6} md={12} lg={6} xl={6} className="grading-box d-flex justify-content-center pat-list disabled">
                
                  <Row>
                  
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="mb-3 internal-page__title txt-black text-center pb-3 ">
                        TELE-GRADING
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Link
                        to={`/AtTeleRE`}
                        className={`d-flex justify-content-center pat-list ${
                          REDisbled
                            ? "disabled"
                            : this.state.patientId
                            ? ""
                            : "disabled"
                        }`}
                      >
                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.reicon}
                      alt=""
                      className="" />
                      </div>
                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            REDisbled
                              ? "disabled-btn"
                              : this.state.patientId
                              ? data.teleDrREDiag != null &&
                                data.teleDrREDiag.teleDrREStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          RE
                        </p>
                      </Link>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Link
                        to={`/AtTeleLE`}
                        className={`d-flex justify-content-center pat-list ${
                          LEDisbled
                            ? "disabled"
                            : this.state.patientId
                            ? ""
                            : "disabled"
                        }`}
                      >
                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.leicon}
                      alt=""
                      className="" />
                      </div>

                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            LEDisbled
                              ? "disabled-btn"
                              : this.state.patientId
                              ? data.teleDrLEDiag != null &&
                                data.teleDrLEDiag.teleDrLEStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          LE
                        </p>
                      </Link>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      
                    >
                      <Link
                        to={`/AtTeleAdvice`}
                        className={`d-flex justify-content-center pat-list ${
                          this.state.patientId ? "" : "disabled"
                        }`}
                      >

                      <div className="pat-icon">   
                      <img
                      src={applicationConstants.adicon}
                      alt=""
                      className="" />
                      </div>

                        <p
                          className={`mb-0 common-status-btn dash-btn ${
                            this.state.patientId
                              ? data.teleDrAdvice != null &&
                                data.teleDrAdvice.adviceStatus === 1
                                ? "success-btn"
                                : "processing-btn"
                              : "disabled-btn"
                          }`}
                        >
                          ADVICE
                        </p>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-3 no-margin  no-mobile-plr">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                  <Link
                    to={`/Report`}
                    className={`d-flex justify-content-center ${
                      this.state.patientId
                        ? (data.optoAdvice != null &&
                            data.optoAdvice.adviceStatus === 1) ||
                          (data.teleDrAdvice != null &&
                            data.teleDrAdvice.adviceStatus === 1) ||
                          (data.campDrAdvice != null &&
                            data.campDrAdvice.adviceStatus === 1)
                          ? ""
                          : "disabled"
                        : "disabled"
                    }`}
                  >
                    <p
                      className={`mb-0 common-status-btn report-color-btn ${
                        this.state.patientId
                          ? (data.optoAdvice != null &&
                              data.optoAdvice.adviceStatus === 1) ||
                            (data.teleDrAdvice != null &&
                              data.teleDrAdvice.adviceStatus === 1) ||
                            (data.campDrAdvice != null &&
                              data.campDrAdvice.adviceStatus === 1)
                            ? "processing-btn"
                            : "disabled-btn"
                          : "disabled-btn"
                      }`}
                    >
                      INDIVIDUAL REPORT
                    </p>
                  </Link>
                </Col>
              </Row>
              </Col>


              </div>
              </div>
            </Col>
          </Row>
        </Strip>      
      </RenderPage>     
    );
  }
}
export default withRouter(Dashboard);
