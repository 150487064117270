import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import { AppContext } from "../../../layout/AppProvider";
import Strip from "../../../customComponents/Strip/Strip";
import FormValidator from "../../../utils/FormValidator";
import DataTable from "react-data-table-component";
import applicationConstants from "../../../constants/applicationContants";
import { dataTablesStyledTheme } from "../../../utils/dataTablesStyledTheme";
import { dataTablesMobileStyledTheme } from "../../../utils/dataTablesStyledTheme";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";
import TablesCatColumns from "./TablesCatColumns";
// import windowSize from "react-window-size";
import { alertDangerConfirmMessage,alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import IsInputInError from "../../../utils/isInputInError";
import { SearchByType} from "../../../utils/dropdownData";
import CampService from "../../../../../api/services/CampService";
import VisionCenterService from "../../../../../api/services/VisionCenterService";
import ReportService from "../../../../../api/services/ReportService";
import PatientService from "../../../../../api/services/PatientService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { ApiConfig } from "../../../../../api/apiConfig/apiConfig";

// const highlightRowCondition = (row) => {
//   return row.patientId;
// };
const highlightRow = (row) => {
  // return (handleREShowImage(row.optoREDiag) === undefined || handleREShowImage(row.optoREDiag) === "") && (handleLEShowImage(row.optoLEDiag) === undefined || handleLEShowImage(row.optoLEDiag) === "");
  return ((row.teleDrREStatus === 1 || row.teleDrLEStatus === 1) && row.teleDrAdviceStatus === 1) || ((row.campDrREStatus === 1 || row.campDrLEStatus === 1) && row.campDrAdviceStatus === 1)
  
};


const conditionalRowStyles = [
  // {
  //   when: highlightRowCondition,
  //   style: {
  //     backgroundColor: 'rgba(0, 123, 255, 0.15)', // Customize the background color
  //   },
  // },
  {
    when: highlightRow,
    style: {
      backgroundColor: 'rgb(220, 250, 223)', // Customize the background color
      // opacity: '0.1',
      color:'black'
    },
  },{
    when: (row) => !highlightRow(row), // Inverse condition for red highlighting
    style: {
      backgroundColor: '#FFE2E7', // Red color when condition doesn't satisfy
      color: 'black'
    },
  },
];

// const handleREShowImage = (diagPic=null) => {
// if(diagPic!=null){
//   let RePreImaging = JSON.parse(diagPic.REPreDilationImaging);
//   let RePostImaging = JSON.parse(diagPic.REPostDilationImaging);
//   if (RePreImaging.length > 0 && (RePostImaging.length === 0 || RePostImaging === "[]")) {
//     // Get the last image from REPreDilationImaging
//     const lastImage = RePreImaging[RePreImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   } else if ((RePreImaging.length === 0 || RePreImaging === "[]") && RePostImaging.length > 0) {
//     // Get the last image from REPostDilationImaging
//     const lastImage = RePostImaging[RePostImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   } else if (RePreImaging.length > 0 && RePostImaging.length > 0) {
//     // Get the last image from REPostDilationImaging
//     const lastImage = RePostImaging[RePostImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   }
// }
   
//   // if(RePreImaging.length == 0 && RePostImaging.length > 0){
//   //   return RePostImaging[0].signedUrl;
//   // } else if (RePreImaging.length > 0 && RePostImaging.length == 0){
//   //   return RePreImaging[0].signedUrl;
//   // } else if (RePreImaging.length > 0 && RePostImaging.length > 0){
//   //   return RePostImaging[0].signedUrl;
//   // } else{
//   //   return null;
//   // }
// }

// const handleLEShowImage = (diagPic=null) => {
//   if(diagPic!=null){
  
//   let LePreImaging = JSON.parse(diagPic.LEPreDilationImaging);
//   let LePostImaging = JSON.parse(diagPic.LEPostDilationImaging);
//   if (LePreImaging.length > 0 && (LePostImaging.length === 0 || LePostImaging.length === "[]")) {
//     // Get the last image from REPreDilationImaging
//     const lastImage = LePreImaging[LePreImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   } else if ((LePreImaging.length === 0 || LePreImaging.length === "[]") && LePostImaging.length > 0) {
//     // Get the last image from REPostDilationImaging
//     const lastImage = LePostImaging[LePostImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   }
//   else if (LePreImaging.length > 0 && LePostImaging.length > 0) {
//     // Get the last image from REPostDilationImaging
//     const lastImage = LePostImaging[LePostImaging.length - 1];
//     return lastImage.signedUrl ? lastImage.signedUrl : "";
//   } else {
//     return null;
//   }
// }
// }

class PatientReportList extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    
    super(props);
    this.validator = new FormValidator([
      {
        field: "search_by",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please choose search_by",
          },
        ],
        touched: false,
      },
      {
        field: "visioncenter",
        // constraints: [
        //   {
        //     method: "isEmpty",
        //     validWhen: false,
        //     message: "Please choose Vision Center",
        //   },
        // ],
        touched: false,
      },
      {
        field: "startDate",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Select start date",
          },
        ],
        touched: false,
      },
      {
        field: "endDate",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Select end date",
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
      reportList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      search_by:1,
      filterShow:1,
      isConfirmModal: false,
      deleteItem: '',
      id: '',
      catstatus: 0,
      campDetails: [],
      visionCenterList: [],
      visionCenterData : [],
      campData : [],
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      countCatPatient : [{
        opto_patient_count: 0,
        campDR_patient_count: 0,
        teleDr_patient_count: 0
      }],
      date: props.data
        ? props.data.date
          ? props.data.date
          : new Date()
        : new Date(),           
    };
    this.campList = this.campList.bind(this);
    this.getVisionCenterList = this.getVisionCenterList.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleClear = this.handleClear.bind(this);    
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
    this.handleSearchBy = this.handleSearchBy.bind(this);    
  }

  async componentDidMount() {
    const catstatus = await localStorage.getItem("catstatus");
    console.log(this.context)
    this.campList();
    this.getVisionCenterList();
    await this.setState({
      isLoading: false,
      catstatus: catstatus,
      filterShow:0
    })
    
    
    if(this.context.state.patientReport.length > 0){
      this.handleSearchBy(this.context.state.patientReportStateData.search_by);
      this.setState({
        filterData: this.context.state.patientReport,
        reportList:this.context.state.patientReport,
        countCatPatient : this.context.state.patientReportCount,
        visionCenterData : this.context.state.patientReportStateData.visionCenterData,
        startDate:this.context.state.patientReportStateData.startDate,
        endDate:this.context.state.patientReportStateData.endDate,
      });
      this.handleCampChange(this.context.state.patientReportStateData.campData);
      this.handleVisionChange(this.context.state.patientReportStateData.visionCenterData);
      
    } else {
      this.handleSearchBy(SearchByType[0]);
    }

    

    if (this.state.search_by) {
      this.validator.touched("search_by");
    }
    // if (this.state.visioncenter) {
    //   this.validator.touched("visioncenter");
    // }
   
    // const currentCamp = await localStorage.getItem("isLogin");
    // console.log("dasd",currentCamp);
    // if(currentCamp){
    //   console.log(currentCamp);
      
    

    // }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isModal !== prevProps.isModal) {
     
      this.campList();
      this.getVisionCenterList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
     
      this.campList();
      this.getVisionCenterList();
    }
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }  

  handleClear = async() => {
    this.handleSearchBy(SearchByType[0]);
    await this.context.updateValue("patientReport", []);
    await this.context.updateValue("patientReportCount", []);
    await this.context.updateValue("patientReportStateData", []);
    this.setState({
      reportList: [],
      isLoading: false,
      isModal: false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      // search_by:1,
      // filterShow:1,
      isConfirmModal: false,
      deleteItem: '',
      id: '',
      catstatus: 0,
      visionCenterData : [],
      campData : [],
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      countCatPatient : [{
        opto_patient_count: 0,
        campDR_patient_count: 0,
        teleDr_patient_count: 0,
        tot_patient_count: 0,
      }],
    })
  }

  exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Summary Report";
    const headers = [["patientId",
    "patientName",
    "gender",
    "age",
    "campId",
    "visionCentreId",
    "REimagingStatus",
    "LEimagingStatus",
    "prReaderGradingStatus",
    "optoREStatus",
    "optoLEStatus",
    "optoAdviceStatus",
    "campDrREStatus",
    "campDrLEStatus",
    "campDrAdviceStatus",
    "teleDrREStatus",
    "teleDrLEStatus",
    "teleDrAdviceStatus"]];

    const data = this.state.reportList.map(elt=> [elt.patientId,
    elt.patientName,
    elt.gender,
    elt.age,
    elt.campId,
    elt.visionCentreId,
    elt.REimagingStatus,
    elt.LEimagingStatus,
    elt.prReaderGradingStatus,
    elt.optoREStatus,
    elt.optoLEStatus,
    elt.optoAdviceStatus,
    elt.campDrREStatus,
    elt.campDrLEStatus,
    elt.campDrAdviceStatus,
    elt.teleDrREStatus,
    elt.teleDrLEStatus,
    elt.teleDrAdviceStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }


  handleExport = () => {
    return (
      <div>
        <Button
          onClick={() => this.exportPDF()}
          className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
        >
          <img
            src={applicationConstants.exicon}
            alt=""
            className="excel-ico"
          /> Export Excel
        </Button>
      </div>
    );
  };

  filterData = (filterText) => {
    console.log(this.state.filterData)
    const filteredItems = this.state.filterData.filter(
      (item) =>   
        (item.patientId &&
          item.patientId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.patientName &&
          item.patientName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.campId &&
            item.campId.toLowerCase().includes(filterText.toLowerCase()))  
    );
    console.log(filteredItems)

    if (filteredItems.length >= 0) {
      this.setState({
        reportList: [...filteredItems],
      });
    } else {
      this.setState({
        reportList: [...this.state.filterData],
      });
    }
  };

  campList = async () => {
    const token = await localStorage.getItem("token");
    
    CampService.getCampList(token, "")
    .then(async (res) => {
      if (res.status === 200) {
        try {
          res.text().then(async (res) => {
          //  console.log(res)
            let result = JSON.parse(res);
            if (result.code === 0) {
              let data = result.data;
              const currentCamp = await data.find(
                (item) => item.campStatus === 1 && item.currentCamp === 1
              );
              let obj={};
              let camps=[];
              data.forEach(item => {
                obj={
                  value: `${item.campCode}`,
                  label: `${item.campCode} - ${item.location}`,
                };
                camps.push(obj);
              })
              this.setState({
                campDetails: camps
              });
              if (currentCamp) {
                await localStorage.setItem("campLocation",currentCamp.location);
                await localStorage.setItem("campDate", currentCamp.date);
                await localStorage.setItem("currentCamp",currentCamp.campCode);
                // const CurCampInfo = this.state.campDetails.filter((res) => res.value === currentCamp.campCode )
                // this.setState({
                //   campData: CurCampInfo
                // });
                // this.handleSubmit();
              } 
              
                
              

            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  getVisionCenterList = async () => {
    const token = await localStorage.getItem("token");
    await VisionCenterService.getVisionCenterList(token)
       .then(async (res) => {
         if (res.status === 200) {
           try {
             res.text().then(async (res) => {
              // console.log("vision",res)
               let result = JSON.parse(res);
               if (result.code === 0) {
                 let data = result.data;
                 let obj={};
                 let visionCenter=[];
                 data.forEach(item => {
                   obj={
                     value: `${item.internal_id}`,
                     label: `${item.center_name}`,
                     state: `${item.state}`,
                   };
                  //  console.log(obj.value, obj.label)
                   visionCenter.push(obj);
                 })
                 this.setState({
                  visionCenterList: visionCenter
                 });
               }
             });
           } catch (err) {
             console.log(err);
           }
         }
       })
       .catch((err) => {
         console.log(err);
       });
   };

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };  

  handleAction = async (item) => {
    const patientId=item.patientId;
    console.log(item.patientId)
    await localStorage.setItem("patientId", `${patientId}`);
    localStorage.setItem("navFrom", 'PatientList');
    this.props.history.push("/Dashboard");
    
  };
  
  handleSearchBy = (item) => {    
    this.validator.touched("search_by");
    this.setState({
      search_by: item,
      filterShow : (item.value === 1) ? 1 :  (item.value === 2) ? 2 : 0
    });
  };

  handleVisionChange = (item) => {    
    this.validator.touched("visioncenter");
    this.setState({
      visionCenterData: item,
    });
  };
  handleCampChange = (item) => {    
    console.log(item);
    this.setState({
      campData: item,
    });
  };

  handleStartDate = (date) => {
    console.log(date)
    this.setState({ startDate: date });
  };
  handleEndDate = (date) => {
    this.setState({ endDate : date });
  };

  async handleSubmit() {
    this.setState({
      isLoading: true,
    });
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const visionCenterarr = [];
      if(this.state.visionCenterData.length > 0)
      this.state.visionCenterData.forEach((res)=>{
        visionCenterarr.push(res.value)
      })
      const campDetailsarr = [];
      if(this.state.campData.length > 0)
      this.state.campData.forEach((res)=>{
        campDetailsarr.push(res.value)
      })
      const data = {
        filterBy: this.state.search_by.value, 
        campId: campDetailsarr,//["AF20210628","AG20210629","AH20210901"],
        visionCentreId: visionCenterarr,//["19","20"],
        status: "", 
        startDate: `${moment(this.state.startDate).format('DD/MM/YYYY')}`, //      dateYMD: Moment("01-07-1994").format('YYYY-MM-DD')
        endDate:`${moment(this.state.endDate).format('DD/MM/YYYY')}`, 
        limit: "",
        offset: "",
        hospitalName: "",      
      }; 
      console.log(this.state)  
      
      // this.context.updateValue("patientReport" ,);
      await this.setState({
        reportList : []
      });        
      
      
      ReportService.getPatientReport(token,data)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                console.log(result)
                if (result.code === 0 && result.message === "Success") {            
                  await this.setState({
                    isLoading: false,
                    reportList:result.data,
                    countCatPatient:result.reportInfo
                  })
                  await this.context.updateValue("patientReport", result.data);
                  await this.context.updateValue("patientReportCount", result.reportInfo);
                  await this.context.updateValue("patientReportStateData", this.state);
                  
                  // this.clear();
                  this.setState({
                    filterData: result.data,
                  });
                                         
                } else {
                  this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);                  
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });   
      } 
      else {
        alertDangerConfirmMessage(`${errorMessage[0]}`);
      }
  } 

  handleBackArrow = () => {;}  

  handleClick = async () => {
    await localStorage.setItem("navFrom", 'PatientTable');
    await localStorage.setItem("patientId",'');
    this.props.history.push("/Dashboard");
  };

  
  handleDelete = async (item) => {
    this.setState({
      isConfirmModal: true,
      deleteItem: item.patientId,
      id:item.patientId,
    })
  }

 
  // handleDownload = async (item) => {
  //   console.log(item);
  //   // this.setState({
  //   //   isConfirmModal: true,
  //   //   deleteItem: item.patientId,
  //   //   id:item.patientId,
  //   // })
  // }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }
  
  handleConfirmBtn = () => {
    const token = localStorage.getItem("token");
    if (this.state.id) {
      PatientService.deletePatient(token, this.state.id)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  this.setState({
                    isConfirmModal: false,
                    id:"",
                    deleteItem:""
                  })
                  alertSuccessMessage(`Patient deleted successfully`)
                  this.handleSubmit();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleDownload = (patientId) => {
    // const patientId = data.patientId;
    // console.log(patientId);
    const token = localStorage.getItem("token");
   

    ReportService.getPatientInfoDownloadZip(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                 console.log("CSV",result);
                //  window.open(ApiConfig.baseUrl+result.data.downloadLink)
                const downloadUrl = ApiConfig.baseUrl + result.path.replace('downloadedFiles/', 'downloadzip/');

                    // Fetch the file content from the URL
                    fetch(downloadUrl)
                      .then(response => response.blob())
                      .then(blob => {
                        // Create a temporary URL for the blob
                        const blobUrl = URL.createObjectURL(blob);

                        // Create an anchor element
                        const anchor = document.createElement('a');
                        anchor.href = blobUrl;

                        // Specify the filename for download (optional)
                        anchor.download = result.path.replace('downloadedFiles/', '') // Change to your desired filename

                        // Programmatically trigger a click event on the anchor
                        anchor.click();

                        // Revoke the temporary URL to free up memory
                        URL.revokeObjectURL(blobUrl);
                      })
                      .catch(error => {
                        console.error('Error fetching or downloading the file:', error);
                      });
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <RenderPage
        id="registration-page"
        className="render-page top"
        containerType="container-fluid"
        style={{marginTop:'0px'}}
      >
        <Strip
          id="tst-registration"
          className="strip strip-no-padding video-table"
          containerType="container-fluid" 
        >
          <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn-pad"
                  onClick={this.handleClick}
                >
                  <span>+</span> Add Patient
                </Button>
              </Col>
              <Col xs={12} sm={12} md={8} lg={10} xl={10}>
                <Row className="justify-content-end">
                  <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                    <InputGroup>
                      <Input
                        placeholder="search"
                        name="filterText"
                        value={this.state.filterText}
                        onChange={this.onChange}
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.handleClear}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={this.state.filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
              <Row className="bg-light-white bg-white p-4">               
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="bg-white no-mobile--padding p-0"
                >
                  <Form className="form-fields field-col-4">
                    <FormGroup>
                      <Label className="common-label">Search By <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.search_by.isInvalid
                      )}
                      >
                        <Select
                              value={this.state.search_by}
                              onChange={this.handleSearchBy}
                              options={SearchByType}
                              classNamePrefix="common-select-drop-down"
                              name="search_by"
                              isSearchable={false}
                            />
                        <p className="p-small help-block">
                          {validation.search_by.message}
                        </p>
                      </div>
                    </FormGroup>
                   
                    {(this.state.filterShow === 1) && ( <FormGroup><Label className="common-label">Camp Details <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.visioncenter.isInvalid
                      )}
                      >
                        <MultiSelect
                          style={{ width: "50px"}}
                          options={this.state.campDetails}
                          value={this.state.campData}
                          onChange={this.handleCampChange}
                          name="camp_is"
                          labelledBy="Select"
                        />
                        <p className="p-small help-block">
                          {validation.visioncenter.message}
                        </p>
                      </div></FormGroup>)}
                      {(this.state.filterShow === 2) && ( <FormGroup><Label className="common-label">Vision Center <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.visioncenter.isInvalid
                      )}
                      >
                        <MultiSelect
                        style={{ width: "50px"}}
                          options={this.state.visionCenterList}
                          value={this.state.visionCenterData}
                          onChange={this.handleVisionChange}
                          name="visioncenter"
                          labelledBy="Select"
                        />
                        <p className="p-small help-block">
                          {validation.visioncenter.message}
                        </p>
                      </div></FormGroup>)}
                     
                    


                    <FormGroup>
                      <Label className="common-label">Start Date <sup>*</sup></Label>
                      
                     
                            <DatePicker
                              onChange={this.handleStartDate}
                              value={new Date(this.state.startDate)}
                              format="dd/MM/yyyy"
                              className="date-picker-container"
                             // minDate={new Date()}
                            />
                         
                    </FormGroup>
                    <FormGroup>
                      <Label className="common-label">End Date <sup>*</sup></Label>
                      
                            <DatePicker
                              onChange={this.handleEndDate}
                              value={new Date(this.state.endDate)}
                              format="dd/MM/yyyy"
                              className="date-picker-container"
                              //minDate={new Date()}
                            />
                    </FormGroup>
                    <FormGroup className="list-btns">

                      <Button
                        className="btn custom-theme-btn custom-btn--small bg-lyellow btn btn-secondary"
                        onClick={this.handleSubmit}                       
                      >
                       
                        Search
                      </Button>
                      <Button
                        className="btn custom-theme-btn custom-btn--small bg-lyellow btn btn-secondary"
                        onClick={this.handleClear}                       
                      >
                       
                        Clear
                      </Button>

                      
                      
                    </FormGroup>

                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
         
          <Row className="table-responsive no-margin">
          
            {this.state.reportList.length > 0 ? (<>
              {/* <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="text-right"> 
                <Button
                  onClick={() => this.exportPDF()}
                  className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
                >
                  <img
                    src={applicationConstants.pdficon}
                    alt=""
                    className="excel-ico"
                  /> Export to PDF
                </Button>
              </div>
            </Col> */}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="text-left mb-2 total-list"> {/* Use the "text-right" class to align content to the right */}

<Label className="common-label"><p>Total no of patients graded by Opto </p> : <b>{`${this.state.countCatPatient.opto_patient_count}/${this.state.countCatPatient.tot_patient_count}`}</b></Label>
<Label className="common-label"><p>Total no of patients graded by Tele DR </p> : <b>{`${this.state.countCatPatient.teleDr_patient_count}/${this.state.countCatPatient.tot_patient_count}`}</b> </Label>
<Label className="common-label"><p>Total no of patients graded by Camp DR </p> : <b>{`${this.state.countCatPatient.campDR_patient_count}/${this.state.countCatPatient.tot_patient_count}`}</b></Label>

 

</div>
              
            </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <DataTable
                  title=""
                  columns={TablesCatColumns(this.handleAction, this.handleDelete, this.handleDownload)}
                  data={this.state.reportList}
                  pagination
                  className="data-table"
                  persistTableHead={false}
                  conditionalRowStyles={conditionalRowStyles}
                  customStyles={
                    this.props.windowWidth > applicationConstants.mobileBreakPoint
                      ? dataTablesStyledTheme
                      : dataTablesMobileStyledTheme
                  }
                  // actions={handleExport}
                  />
              </Col></>
            ) : (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <div className="br-light-smoke bg-white p-5 br-4px">
                  {!this.state.isLoading ? (
                    <p className="mb-0 align-center internal-page__title">
                      No Data Found
                    </p>
                  ) : (
                    <Loader isLoader={false} />
                  )}
                </div>
              </Col>
            )}
          </Row>
          {this.state.isConfirmModal ? (
            <MessageConfirmation
              isModal={this.state.isConfirmModal}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
              message={`Are you sure you want to delete this patient?`}
            />
          ) : null}
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
          {/* {this.state.isLoading ? <Loader isLoader={false} /> : null} */}
        </Strip>
      </RenderPage>
    );
  }
}

export default withRouter(PatientReportList);
