import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const DashboardService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getDashboardCount: async (token,hospitalName) => {
    const { baseUrl, getDashboardCount } = ApiConfig;

    const url = hospitalName !== null ? baseUrl + getDashboardCount + "?hospitalName="+hospitalName : baseUrl + getDashboardCount;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const DashboardServicePromise = await Fetch(
      url,
      "GET",
      headers,
      "",
      "services_DashboardCountService",
      true
    );
    return DashboardServicePromise;
  },


};

export default DashboardService;
