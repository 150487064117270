const AwsConfig = {
    bucketRegion: "ap-south-1",
    // accessKeyId: 'AKIA4WIASLA7ZJDS4MDM',
    // secretAccessKey: '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',
    accessKeyId: 'AKIA4WIASLA77VPIEHPJ',
    secretAccessKey: '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',
    bucketName: 'teleopticsapp', //live
    // bucketName: 'teleopticstesting', //dev
   // dirName: 'image',
    url: 'https://teleopticsapp.s3.ap-south-1.amazonaws.com/',
  };
  
  export {AwsConfig};
  