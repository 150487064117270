import React from "react";
import { Row, Col, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import FileBase64 from "react-file-base64";
import { faPhotoVideo, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../constants/applicationContants";
import FileUpload from "../FileUpload/FileUpload";

class Dilation extends React.Component {
  constructor(props) {
    console.log("dilationMedia",props.dilationMedia);
    super(props);
    this.state = {
      diagnosisType: props.diagnosisType ? props.diagnosisType : "",
      dilationMedia: props.dilationMedia.length > 0 ? props.dilationMedia : "",
      title: props.title ? props.title : "",
      remidioType: props.remidioType ? props.remidioType : "",
      dilationType: props.dilationType ? props.dilationType : "",
      isDisplay: props.diagnosisType ? false : false,
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.dilationMedia.length !== prevProps.dilationMedia.length) {
      this.setState({
        dilationMedia: this.props.dilationMedia,
        diagnosisType: this.props.diagnosisType,
        dilationType: this.props.dilationType,
        title: this.props.title,
      });
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        dilationMedia: this.props.dilationMedia,
        diagnosisType: this.props.diagnosisType,
        dilationType: this.props.dilationType,
        title: this.props.title,
      });
    }
  }

  // handleFile = async (data, dilationType, diagnosisType) => {

  // };

  handleData = async (data) => {
    console.log("Handle Data",data);
    this.setState({
      isDisplay: true,
    });
    this.props.handleFileUpload(
      data,
      this.state.dilationType,
      this.state.diagnosisType
    );
  };


  render() {
    return (
      <Row className="no-margin">
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className="px-0">
          <p className="mb-2 internal-page__title text-black align-left">
            {this.state.title} 
          </p>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="px-0">
          <p className="mb-2 internal-page__title text-black align-left">
            <div style={{ cursor: "pointer"}}onClick={() => this.props.handleRemidio(this.state.dilationType,this.state.diagnosisType)}>
              <p className="mb-0 inactive-btn">Fetch Remidio</p>
             </div>
          </p>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="p-0"
        >
          <div className="d-flex img-view-container scoll-bar m-0">
            {this.state.diagnosisType === applicationConstants.optoRE ||
            this.state.diagnosisType === applicationConstants.optoLE ? (
              <Form>
                <div className="img-container">
                  {/* <FileBase64
                    multiple={true}
                    onDone={async (data) =>
                      this.handleFile(
                        data,
                        this.state.dilationType,
                        this.state.diagnosisType
                      )
                    }
                    ClassNames="upload-form-control"
                  /> */}
                  <FileUpload handleData={this.handleData} />
                  <FontAwesomeIcon
                    icon={faPhotoVideo}
                    size={"4x"}
                    color={`#72d389`}
                    className="upload-icon__container"
                  />
                </div>
              </Form>
            ) : null}

            {this.state.dilationMedia.length > 0 ? (
              <div className="d-flex">
                {this.state.dilationMedia.map((item, index) => (
                  <div className="img-container" key={index}>
                    {item.mime === "image/jpeg" || item.mime === "image/png" ? (
                      <img
                        src={`${item.signedUrl ? item.signedUrl : item.path}`}
                        alt="PreviewImages"
                        onClick={() =>
                          this.props.handlePreviewImage(item, true)
                        }
                        className="cursor-pointer"
                      />
                    ) : (
                      <div className="position-relative">
                        <img
                        alt="images"
                          src={applicationConstants.videoImg}
                          className="cursor-pointer"
                        />
                        <div
                          className="video__container"
                          onClick={() =>
                            this.props.handlePreviewImage(item, true)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPlayCircle}
                            size={"2x"}
                            color={`#72d389`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="img-container">
                <p className="mb-0 internal-page__title">No Media</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default Dilation;
