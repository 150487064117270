import {
  faClinicMedical,
  faUsers,
  faKey,
  faInfoCircle,
  faUserCircle,
  faHospitalSymbol,
  faGripHorizontal,
  faVideo,
  faEye,
  faChartPie,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const menuData = [
  // {
  //   index: 0,
  //   label: "User Dashboard",
  //   icon: faClinicMedical,
  //   iconWidth: "width-24px",
  //   link: "userDashboard",
  //   activeClassName: true
  // },
  {
    index: 0,
    label: "Dashboard",
    icon: faGripHorizontal,
    iconWidth: "width-24px",
    link: "UserDashboard",
    activeClassName: true
  },
  {
    index: 6,
    label: "Patients",
    icon: faUsers,
    iconWidth: "width-24px",
    link: "PatientList"
  },
  // {
  //   index: 10,
  //   label: "Patient Details",
  //   icon: faChartLine,
  //   iconWidth: "width-24px",
  //   link: "PatientTable"
  // },  
  {
    index: 1,
    label: "Camp List",
    icon: faClinicMedical,
    iconWidth: "width-24px",
    link: "campList",
    // activeClassName: true
  },
  {
    index: 8,
    label: "Vision Center",
    icon: faEye,
    iconWidth: "width-24px",
    link: "VisionInfoList",
  },

  {
    index: 3,
    label: "Hospital Info",
    icon: faHospitalSymbol,
    iconWidth: "width-24px",
    link: "hosptialInfo"
  },

  {
    index: 4,
    label: "User Details",
    icon: faUserCircle,
    iconWidth: "width-24px",
    link: "userList"
  },

  {
    index: 2,
    label: "Video Details",
    icon: faVideo,
    iconWidth: "width-24px",
    link: "videoList",
    // activeClassName: true
  },
  
 
  
  {
    index: 5,
    label: "Information",
    icon: faInfoCircle,
    iconWidth: "width-24px",
    link: "info"
  },
  
 
  // {
  //   index: 3,
  //   label: "Settings",
  //   icon: faCogs,
  //   iconWidth: "width-24px",
  //   link: "Settings"
  // },
  {
    index: 7,
    label: "Change Password",
    icon: faKey,
    iconWidth: "width-24px",
    link: "ChangePassword"
  },
  {
    index: 10,
    label: "Profile Settings",
    icon: faKey,
    iconWidth: "width-24px",
    link: "ProfileSettings"
  },
  {
    index: 9,
    label: "Report",
    icon: faChartPie,
    iconWidth: "width-24px",
    link: "SummaryReport"
  }
];

export default menuData;
