import React from "react";
import Diagnosis from "../../../customComponents/Diagnosis/Diagnosis";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../../constants/applicationContants";

class LeftEye extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosisType: applicationConstants.optoLE,
      title: applicationConstants.LEDiagnosis +' '+ applicationConstants.DiagnosisTitle,
      imageType: "LEFT",
    };
  }

  render() {
    return (
      <RenderPage
        id="patinet-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Diagnosis
          title={this.state.title}
          diagnosisType={this.state.diagnosisType}
          imageType={this.state.imageType}
        />
      </RenderPage>
    );
  }
}
export default LeftEye;
