import React from "react";
import memoizeOne from "memoize-one";
import Moment from 'moment';

const TablesCatColumns = memoizeOne((handleAction, handleDelete) => [
  {
    name: "Video CategoryCode",
    selector: "category_code",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.category_code}</div>,
  },
  {
    name: "Video CategoryName",
    selector: "category_name",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.category_name}</div>,
  },
  {
    name: "Created Date",
    selector: "created_date",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{Moment(row.created_date).format('DD-MM-YYYY')}</div>,
  },  
  {
    name: "Action",
    selector: "",
    grow: 0.2,
    sortable: false,
    ignoreRowClick: true,
    allowOverflow: true,
    cell: (row) => (
        <div className="d-flex">
            <div onClick={() => handleDelete(row)}>
               <p className="mb-0 inactive-btn">Delete</p>
            </div>
        </div>
    ),
},
]);

export default TablesCatColumns;
