function isNumeric(value) {
  const constraint = /^[0-9\b]+$/;
  return constraint.test(value);
}
function isWeight(value) {
  const constraint = /^[1-9]+[0-9]*$/;
  return constraint.test(value);
}
// mobile num start with country code
function isText(value) {
  const constraint = /^[a-z\b]+$/;
  return constraint.test(value);
}
function isMobileNumber(value) {
  const constraint = /^[0-9]+$/;
  // const constraint = /^\+?([0-9]{2})?([0-9]{10})$/;
  return constraint.test(value);
}
// Alpha numeric values + white spaces
function isAlphaNumeric(value) {
  const constraint = /^[A-Za-z0-9- ]+$/;
  return constraint.test(value);
}
// number between 0 to 10
function isOnetoTen(value) {
  const constraint = /^([1-9]|10|0)$/;
  return constraint.test(value);
}

function isAboutText(value) {
  return true;
}

function isAddressText(value) {
  // const constraint = /^[A-Za-z0-9- ,:.-_ #'/! &]+$/;
  return true;
}

function isPasswordMatch(value, value2) {
  const { newPassword, reEnterPassword } = value2;
  return newPassword === reEnterPassword;
}

function isDecimal(value) {
  const constraint = /^(\+|-)?(\d*\.?\d*)$/;
  return constraint.test(value);
}

function ageLimit(value) {
  // const constraint = /^(11[0]|1[0-1][0-9]|[1-9]?[0-9]|1[0-9])$/;
  const constraint = /^(11[0]|10[0-9]|[1-9]?[0-9]|1[0-9])$/;
  return constraint.test(value);
}

function validPassword(value) {
  const constraint = !/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))/.test(
    value
  );
  return constraint;
}

function isAlpha(value) {
  const constraint = /^[A-Za-z ]+$/;
  return constraint.test(value);
}

function commentsValidation(value){
  if(value !== ''){
  const constraint = /^[a-zA-Z0-9\s,.$_\-+:#;%&@]+$/;
  return constraint.test(value);
  }else{
    return true
  }
}

function isBloodSugar(value){
  const constraint  = /^\d{1,3}(?:\.\d{1,3})?$/;
  console.log(constraint.test(value))
  return constraint.test(value) 
}

function isBloodPressure(value){
  const constraint  = /^\d{2,3}\/\d{2,3}$/;
  console.log(constraint.test(value))
  return constraint.test(value) 
}

function userFullName(value){
  const constraint = /^[a-zA-Z]+$/;
    return constraint.test(value);
}

// function thumbnailValidate(value){
//   const constraint = /image\/(jpg|jpeg)/i;
//   console.log(constraint.test(value))
//   return constraint.test(value)
// }

function isValidFileSize(fi, size) {
  for (let i = 0; i <= fi.length - 1; i++) {
         
    let fsize = fi.item(i).size;
    let file = Math.round((fsize / 1024) / 1024);

    if (file >= size) {
      return false;
    }
  }

  return true;
}

export {
  isNumeric,
  isAlphaNumeric,
  isAddressText,
  isPasswordMatch,
  isAboutText,
  isDecimal,
  isMobileNumber,
  isText,
  ageLimit,
  isOnetoTen,
  isWeight,
  validPassword,
  isBloodSugar,
  isAlpha,
  commentsValidation,
  isValidFileSize,
  userFullName,
  isBloodPressure
  // thumbnailValidate
};
