import React from "react";
import { Row, Col } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import PatientReportList from "./component/PatientReportList";

class PatientSummaryReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      modal: false,      
      
    };
    }  


  render() {
    return (
      <RenderPage
        id="add-camp-page"
        className="render-page"
        containerType="container-fluid"
      >      

        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <PatientReportList
              handleModal={this.handleModal}
              isModal={this.state.modal}      
            />
          </Col>
        </Row>       
      </RenderPage>
    );
  }
}

export default PatientSummaryReports;
