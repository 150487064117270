import React, { Component } from "react";
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import { BrowserRouter } from "react-router-dom";
import applicationConstants from "../../../constants/applicationContants";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggle: false,
    };
    this.mobileMenu = this.mobileMenu.bind(this);
  }
  

  logoutHandler = async (e) => {
    localStorage.setItem("isLogin", false);
    await this.props.history.push("/");
  };

  mobileMenu = async () => {
    await this.setState((prevState) => ({
      isToggle: !prevState.isToggle,
    }));
    this.props.data(this.state.isToggle);
  };

  getSnapshotBeforeUpdate(prevState) {
    if (prevState.isToggle != this.props.isToggle) {
      this.setState((prevState) => ({
        isToggle: !prevState.isToggle,
      }));
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState((prevState) => ({
        isToggle: !prevState.isToggle,
      }));
    }
  }

  componentDidMount = () =>{
    
    const unloadCallback = (event) => {
      console.log(event)
      event.preventDefault();
      event.returnValue = "";
      if(localStorage.getItem("isLogin")===false){
        this.logoutHandler(event);
      }      
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);       
  }  

  render() {
    return (
      <BrowserRouter>
        <Row className="align-items-center min-height-48px">
          <Col xs={7} sm={7} md={7} lg={10} xl={10}>
            <Row className="align-items-center no-margin">
              {this.props.windowWidth < applicationConstants.mobileBreakPoint ?
              <div onClick={this.mobileMenu}>
                <FontAwesomeIcon
                  icon={faBars}
                  color={'#fff'}
                  className="m-1 menu-icon"
                />
              </div>: null} 
              <h5 className="internal-section__title opensans-Bold text-capitalize mb-0 pl-2">
                {this.props.title === 'Information' ? 'DB Update Time Log' : this.props.title}
              </h5>
            </Row>
          </Col>
          <Col xs={5} sm={5} md={5} lg={2} xl={2}>
            <div className="d-flex">
              <div>
                <UncontrolledButtonDropdown>
                  <DropdownToggle
                    caret
                    className="btn btn-transparent d-flex align-items-center"
                  >
                    <div className="d-flex align-items-center">
                      <div className="profile-container mr-1">
                        <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
                      </div>
                      <div>
                        <p className="p-small title-white title-blue mb-0 text-left opensans-semiBold text-capitalize pt-4px">
                          {`${this.props.userName}`}
                        </p>
                        <p className="p-small title-white title-blue mb-0 text-left opensans-semiBold text-capitalize pt-2px pb-2px">
                          {`${this.props.mobileNo}`}
                        </p>
                        <p className="p-small title-white title-blue mb-0 text-left opensans-semiBold text-capitalize pt-2px pb-2px">
                          {`${this.props.roleId}`}
                        </p>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="common-dropdown-menu">
                    <DropdownItem onClick={(e) => this.logoutHandler(e)}>
                      <a href="" className="d-flex dropdown-menu__link">
                        <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
                        <font className="pl-2">Logout</font>
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </Col>
        </Row>
      </BrowserRouter>
    );
  }
}

export default withRouter(HeaderComponent);
