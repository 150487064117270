import React from "react";
import PropTypes from "prop-types";

function Loader(props) {
  return (
    <>
      {props.isLoader ? (
        <div className="loader-container">
          <div className="loader-5 center">
            <span></span>
          </div>
        </div>
      ) : (
        <div className="circle-loader">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}

Loader.defaultProps = {
  isLoader: true,
};

Loader.propTypes = {
  isLoader: PropTypes.bool,
};

export default Loader;
