import React from "react";
import memoizeOne from "memoize-one";
// import Moment from 'moment';
import {BCVADistanceData,BCVANearData,YesOrNoUnknowData,durationData,medicationTypeData,comorbiditiesData,QuestionareData,optometristAdviceData,optometristReferralData } from "../../../utils/dropdownData";

const handleGender = (value) => {
 
  if(value === 0){
    return "Male";
  } else if(value === 1){
    return "Female";
  } else if(value === 2){
    return "Trans";
  } else{
    return null;
  }

}
const handleYesOrNoUnknowData = (value) => {
  let near = YesOrNoUnknowData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleDuration = (value) => {
  let near = durationData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleMedType = (value) => {
  let near = medicationTypeData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleComorbidities = (value) => {
  let comorbiditiesStr = '';
  let comorbidities = JSON.parse(value)
  for (var i = 0; i < comorbidities.length; i++){
    let near = comorbiditiesData.filter(function (el) { return el.value === comorbidities[i] });
    comorbiditiesStr += (i > 0) ? ", " : "";
    comorbiditiesStr += near[0].label;
  }

  return comorbiditiesStr;
  
}

const handleOptoAdvice = (value) => {
  let optoAdviceStr = '';
  console.log(value);
  const optoAdvice = JSON.parse(value)
  for (var i = 0; i < optoAdvice.length; i++){
    let near = optometristAdviceData.filter(function (el) { return el.value === optoAdvice[i] });
    optoAdviceStr += (i > 0) ? ", " : "";
    optoAdviceStr += near[0].label;
  }

  return optoAdviceStr;
  
}

const handleOptoReferral = (value) => {
  let optoReferralStr = '';
  console.log(value);
  const optoReferral = JSON.parse(value)
  for (var i = 0; i < optoReferral.length; i++){
    let near = optometristReferralData.filter(function (el) { return el.value === optoReferral[i] });
    optoReferralStr += (i > 0) ? ", " : "";
    optoReferralStr += near[0].label;
  }

  return optoReferralStr;
  
}




const handleBVCADistance = (value) => {
  console.log("distance",value);
  let distance = BCVADistanceData.filter(function (el) {
    return el.value === value;}
  );
  return distance[0].label;
}

const handleBVCANear = (value) => {
  let near = BCVANearData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleQuestionareData = (value) => {
  console.log(value)
  let Questionar = QuestionareData.filter(function (el) {
    return el.value === value;}
  );
  return Questionar[0].name;
}


const handleREShowImage = (diagPic, val) => {
if(val==1){
  let RePreImaging = JSON.parse(diagPic.REPreDilationImaging);
  if (RePreImaging.length > 0){
    const lastImage = RePreImaging[RePreImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  }  else{
    return null;
  }
} else {
  let RePostImaging = JSON.parse(diagPic.REPostDilationImaging);
  if (RePostImaging.length > 0){
    const lastImage = RePostImaging[RePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  }  else{
    return null;
  }
}
}

const handleLEShowImage = (diagPic,val) => {
  if(val==1){
  let LePreImaging = JSON.parse(diagPic.LEPreDilationImaging);
  if (LePreImaging.length > 0 ){
    const lastImage = LePreImaging[LePreImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else{
    return null;
  }
  } else {
  let LePostImaging = JSON.parse(diagPic.LEPostDilationImaging);
  if (LePostImaging.length > 0 ){
    const lastImage = LePostImaging[LePostImaging.length - 1];
    return lastImage.signedUrl ? lastImage.signedUrl : "";
  } else{
    return null;
  }
}
}

const TablesCatColumns = memoizeOne((handleAction, handleDelete) => [
  {
    name: "Date of Examination",
    selector: "registration_date",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.dateOfExamination}</div>,
  },
  {
    name: "Name",
    selector: "patientName",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.patientName}</div>,  
  },
  {
    name: "VC-Name",
    selector: "visionCentreId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.visionCentreId !== "" && row.visionCenterName !== "") ? row.visionCentreId+'-'+row.visionCenterName : ""}</div>,
  },
  {
    name: "Camp-Name",
    selector: "campId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.campId !== "" && row.campName !== "") ? row.campId+'-'+row.campName : "" }</div>,
  },
  {
    name: "Patient ID",
    selector: "patientId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.patientId}</div>,
  },
  {
    name: "MocDoc ID",
    selector: "mocDocPhId",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.mocDocPhid !== "null" && row.mocDocPhid !== "" ? row.mocDocPhid : ""}</div>,
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{handleGender(row.gender)}</div>,
  },
  {
    name: "Age",
    selector: "age",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.age}</div>,
  },
  // {
  //   name: "Address",
  //   selector: "address",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.address}</div>,
  // },
  {
    name: "Mobile",
    selector: "contact_no",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.contactNo}</div>,
  },
  {
    name: "HBP",
    selector: "hypertention",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_hypertension !== "" ? handleYesOrNoUnknowData(row.healthInfo_hypertension) : ""}</div>,
  },
  {
    name: "DM",
    selector: "diabetes",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_diabetes !== "" ? handleYesOrNoUnknowData(row.healthInfo_diabetes) : ""}</div>,
  },
  {
    name: "Duration",
    selector: "duration_of_diabetes",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_duration !== "" ? handleDuration(row.healthInfo_duration) : ""}</div>,
  },
  {
    name: "Medication",
    selector: "diabetes_medication_group",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_medicationType !== "" ? handleMedType(row.healthInfo_medicationType) : ""}</div>,
  },
  {
    name: "Comorbidities",
    selector: "systemic_abnormality",
    sortable: false,
    width:"150px",
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_coMorbidities !== "" ? handleComorbidities(row.healthInfo_coMorbidities) : ""}</div>,
  },
  {
    name: "BP",
    selector: "blood_pressure",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_bloodPressure}</div>,
  },
  {
    name: "BS",
    selector: "random_blood",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.healthInfo_bloodSugar}</div>,
  },
  {
    name: "RE BCVA D/N",
    selector: "RE_visual_acuity",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.refractionInfo_REBCVADistance !== "" && row.refractionInfo_REBCVADistance !== -1) ? handleBVCADistance(row.refractionInfo_REBCVADistance) : ""}</div>,
  },
  {
    name: "LE BCVA D/N",
    selector: "LE_isual_acuity",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.refractionInfo_LEBCVADistance !== "" && row.refractionInfo_LEBCVADistance !== -1) ? handleBVCADistance(row.refractionInfo_LEBCVADistance) : ""}</div>,
  },
  {
    name: "RE Lens",
    selector: "RE_lens_status",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.refractionInfo_REBCVALensStatus !== "" && row.refractionInfo_REBCVALensStatus !== -1) ? handleBVCANear(row.refractionInfo_REBCVALensStatus) : ""}</div>,
  },
  {
    name: "LE Lens",
    selector: "LE_Lens_Status",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.refractionInfo_LEBCVALensStatus !== "" && row.refractionInfo_LEBCVALensStatus !== -1) ? handleBVCANear(row.refractionInfo_LEBCVALensStatus) : ""}</div>,
  },
  {
    name: "RE Pre-Dilation Imaging",
    selector: "RE_Pre-Dilation_Imaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}><img alt="" src={row.optoREDiag ? handleREShowImage(row.optoREDiag,1) : ""}/></div>,
  },
  {
    name: "RE Post-Dilation Imaging",
    selector: "RE_Post-Dilation_Imaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}><img alt="" src={row.optoREDiag ? handleREShowImage(row.optoREDiag,2) : ""}/></div>,
  },
  {
    name: "LE Pre-Dilation Imaging",
    selector: "LE_Pre-Dilation_Imaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}><img alt="" src={row.optoLEDiag ? handleLEShowImage(row.optoLEDiag,1) : ""}/></div>,
  },
  {
    name: "LE Post-Dilation Imaging",
    selector: "LE_Post-Dilation_Imaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}><img alt="" src={row.optoLEDiag ? handleLEShowImage(row.optoLEDiag,2) : ""}/></div>,
  },
  {
    name: "OPTO RE Comments",
    selector: "OPTO_RE_Comments",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoREDiag_REOther}</div>,
  },
  {
    name: "OPTO LE Comments",
    selector: "OPTO_LE_Comments",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoLEDiag_LEOther}</div>,
  },
  // {
  //   name: "Optometist RE DR Grade",
  //   selector: "optometist_RE_DR_Grade",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrREDiag_REDR}</div>,
  // },
  // {
  //   name: "Optometist LE DR Grade",
  //   selector: "optometis_LE_DR_Grade",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrLEDiag_LEDR}</div>,
  // },
  // {
  //   name: "Optometist - RE DME",
  //   selector: "optometist_RE_DME",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrREDiag_REDME}</div>,
  // },
  // {
  //   name: "Optometist - LE DME",
  //   selector: "optometist_LE_DME",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrLEDiag_LEDME}</div>,
  // },
  {
    name: "RE DR Grade - Doctor",
    selector: "re_dr_grade_doctor",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrREDiag_REDR}</div>,
  },
  {
    name: "LE DR Grade - Doctor",
    selector: "le_dr_grade_doctor",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrLEDiag_LEDR}</div>,
  },
  {
    name: "Doctor  - RE DME",
    selector: "doctor_re_DME",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrREDiag_REDME}</div>,
  },
  {
    name: "Doctor  - LE DME",
    selector: "doctor_le_DME",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrLEDiag_LEDME}</div>,
  },
  {
    name: "Tele DR -Referal For",
    selector: "tele_dr_referal_for",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrAdvice && row.teleDrAdvice.referral !== "[]" && row.teleDrAdvice.referral !== "" && row.teleDrAdvice.referral !== undefined ? handleOptoReferral(row.teleDrAdvice.referral) : ""}</div>,
  },
  {
    name: "Camp DR -Referal For",
    selector: "camp_dr_referal_for",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrAdvice && row.campDrAdvice.referral !== "[]" && row.campDrAdvice.referral !== "" && row.campDrAdvice.referral !== undefined ? handleOptoReferral(row.campDrAdvice.referral) : ""}</div>,
  },
  {
    name: "OPTO - Referal For",
    selector: "opto_referal_for",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice && row.optoAdvice.referral !== "[]" && row.optoAdvice.referral !== "" && row.optoAdvice.referral !== undefined ? handleOptoReferral(row.optoAdvice.referral) : ""}</div>,
  },
  {
    name: "OPTO - Advice",
    selector: "opto_advice",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice && row.optoAdvice.advice !== "[]" && row.optoAdvice.advice !== "" && row.optoAdvice.advice !== undefined ? handleOptoAdvice(row.optoAdvice.advice) : ""}</div>,
  },
  {
    name: "Tele DR  - Advice",
    selector: "tele_dr_advice",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.teleDrAdvice && row.teleDrAdvice.advice !== "[]" && row.teleDrAdvice.advice !== "" && row.teleDrAdvice.advice !== undefined ? handleOptoAdvice(row.teleDrAdvice.advice) : ""}</div>,
  },
  {
    name: "Camp DR  - Advice",
    selector: "camp_dr_advice",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.campDrAdvice && row.campDrAdvice.advice !== "[]" && row.campDrAdvice.advice !== "" && row.campDrAdvice.advice !== undefined ? handleOptoAdvice(row.campDrAdvice.advice) : ""}</div>,
  },
  {
    name: "Pt Travel Time",
    selector: "travel_time",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice_q1}</div>,
  },
  {
    name: "Pt Travel Cost",
    selector: "patient_transport",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice_q2}</div>,
  },
  {
    name: "Attender Daily Wage in Rs",
    selector: "daily_wage",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice_q3}</div>,
  },
  {
    name: "Annul Income in Rs",
    selector: "annul_income",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{row.optoAdvice_q4}</div>,
  },
  {
    name: "Attender Accompaning",
    selector: "attender_accompaning",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.optoAdvice_q5 !== "" && row.optoAdvice_q5 !== -1 && row.optoAdvice_q5 !== undefined) ? handleYesOrNoUnknowData(row.optoAdvice_q5):""}</div>,
  },
  {
    name: "Tel Consultation",
    selector: "doctor_sitting",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.optoAdvice_q10 !== "" && row.optoAdvice_q10 !== -1 && row.optoAdvice_q10 !== undefined) ? handleQuestionareData(row.optoAdvice_q10) : ""}</div>,
  },
  {
    name: "Fundus Imaging",
    selector: "fundus_imaging",
    sortable: false,
    cell: (row) => <div onClick={() => handleAction(row)}>{(row.optoAdvice_q11 !== "" && row.optoAdvice_q11 !== -1 && row.optoAdvice_q11 !== undefined) ? handleQuestionareData(row.optoAdvice_q11) : ""}  </div>,
  },
  // {
  //   name: "RE Duration",
  //   selector: "re_duration",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.REDuration}</div>,
  // },
  // {
  //   name: "LE Duration",
  //   selector: "le_duration",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.LEDuration}</div>,
  // },
  // {
  //   name: "Advice",
  //   selector: "advice_duration",
  //   sortable: false,
  //   cell: (row) => <div onClick={() => handleAction(row)}>{row.adviceDuration}</div>,
  // },

//   {
//     name: "Action",
//     selector: "",
//     grow: 0.2,
//     sortable: false,
//     ignoreRowClick: true,
//     allowOverflow: true,
//     cell: (row) => (
//         <div className="d-flex">
//             <div onClick={() => handleDelete(row)}>
//                <p className="mb-0 inactive-btn">Delete</p>
//             </div>
//         </div>
//     ),
// },
]);

export default TablesCatColumns;
