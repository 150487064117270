import React from "react";
import { Row, Col } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import AddVideo from "./component/AddVideo";
import VideoList from "./component/VideoList";
import VideoService from "../../../../api/services/VideoService";


class VideoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewCamp: true,
      data: null,
      modal: false,
      modal1: false,      
      videocategory: [],
      dropdownList: [],     
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {     
    await this.getDropdownList();
  }
 

  getDropdownList = async () => {
    const token = await localStorage.getItem("token");
    await VideoService.getVideoCategories(token)
       .then(async (res) => {
         if (res.status === 200) {
           try {
             res.text().then(async (res) => {
              console.log(res)
               let result = JSON.parse(res);
               if (result.code === 0) {
                 let data = result.data;
                 let obj={};
                 let DropdownData=[];
                 data.forEach((item) =>  {
                   obj={
                     value: `${item.category_code}`,
                     label: `${item.category_name}`
                   };
                   console.log(obj.value, obj.label)
                   DropdownData.push(obj);
                 })
                 this.setState({
                  dropdownList: DropdownData
                 });
                 console.log(DropdownData)
               }
             });
           } catch (err) {
             console.log(err);
           }
         }
       })
       .catch((err) => {
         console.log(err);
       });
   };


  async toggle() {  
    this.setState({
      modal: !this.state.modal,
      isNewCamp: true,
      data: null,    
    });
  }
 

  handleModal = async (item) => {
    console.log(item)    
    const dropdownIndex = await this.state.dropdownList.findIndex(it => it.value === item.category_code);    
    await this.setState({
      modal: !this.state.modal,
      isNewCamp: false,
      data: item,      
      category_code: dropdownIndex,
      videos_url : item.video_url,
      thumbnail_url : item.thumbnail,     
    });
  };

  render() {
    return (
      <RenderPage
        id="add-camp-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.state.modal ? (
              <AddVideo
                toggle={this.toggle}
                isModal={this.state.modal}
                isNewCamp={this.state.isNewCamp}
                data={this.state.data}              
                dropdownList={this.state.dropdownList}             
                category_name={this.state.category_code}
                thumbnail_urlvalue={this.state.thumbnail_url}
                video_urlvalue={this.state.videos_url}
              />
            ) : null}
          </Col>          
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <VideoList
              handleModal={this.handleModal}
              isModal={this.state.modal}
              toggle={this.toggle}
            />
          </Col>
        </Row>       
      </RenderPage>
    );
  }
}

export default VideoDetails;
