import React from "react";
import Select from "react-select";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import PatientService from "../../../../../api/services/PatientService";
import {
  lensStatusData,
  anteriorChamberData,
  BCVADistanceData,
  BCVANearData,
} from "../../../utils/dropdownData";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import BackArrow from "../../../customComponents/BackArrow/BackArrow";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import QuickHelpMessage from "../../../customComponents/confirmationMessage/QuickHelpMessage";
import { RefractionAbbrevationInfo } from "../../../utils/AbbrevationInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../customComponents/Loader/Loader";

class Refraction extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "REBCVADistance",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please Enter Right Eye BCVA Distance",
          },
        ],
        touched: false,
      },
      {
        field: "REBCVALensStatus",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please Enter Right Eye BCVA Lens Status",
          },
        ],
        touched: false,
      },
      {
        field: "LEBCVADistance",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please Enter Left Eye BCVA Distance",
          },
        ],
        touched: false,
      },
      {
        field: "LEBCVALensStatus",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please Enter Left Eye BCVA Lens Status",
          },
        ],
        touched: false,
      },
    ]);
    this.state = {
      REBCVADistance: "",
      REBCVANear: -1,
      REBCVALensStatus: "",
      REAnteriorChamber: -1,
      LEBCVADistance: "",
      LEBCVANear: "",
      LEBCVALensStatus: "",
      LEAnteriorChamber: -1,
      patientName: "",
      isConfirmation: false,
      isEdit: false,
      isReset: false,
      isQuickHelp: false,
      timer: 0,
      campStatus: 0,
      isRE: false,
      isLE: false,
      REReason: "",
      LEReason: "",
      refractionInfoDuration: 0,
      isLoading: false,
      isLELogMAR: false,
      isRELogMAR: false,
      LELogMAR: "",
      RELogMAR: "",
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const campStatus = await localStorage.getItem("campStatus");
    if (patientId) {
      await this.setState({
        isLoading: true,
        campStatus: campStatus,
      });
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data) {
                    this.setState({
                      campStatus: data.campId || data.visionCentreId ? "1" : "0",
                    });
                  }
                  if (data.refractionInfo) {
                    this.setState({
                      patientId: patientId,
                      patientName: data.registerInfo.patientName,
                    });

                    if (data.refractionInfo.REReason === "") {
                      this.setState({
                        REBCVADistance:
                          data.refractionInfo.REBCVADistance !== -1
                            ? BCVADistanceData[
                                data.refractionInfo.REBCVADistance
                              ]
                            : -1,
                        RELogMAR:
                          data.refractionInfo.REBCVADistance !== -1
                            ? BCVADistanceData[
                                data.refractionInfo.REBCVADistance
                              ].logmar
                            : "",
                        REBCVANear:
                          data.refractionInfo.REBCVANear !== -1
                            ? BCVANearData[data.refractionInfo.REBCVANear]
                            : -1,
                        REBCVALensStatus:
                          data.refractionInfo.REBCVALensStatus === -1
                            ? -1
                            : lensStatusData[
                                data.refractionInfo.REBCVALensStatus
                              ],
                        REAnteriorChamber:
                          data.refractionInfo.REAnteriorChamber === -1
                            ? -1
                            : anteriorChamberData[
                                data.refractionInfo.REAnteriorChamber
                              ],
                        refractionInfoDuration: parseInt(
                          data.refractionInfo.refractionInfoDuration
                        ),
                      });
                    } else {
                      this.setState({
                        isRE: true,
                        REReason: data.refractionInfo.REReason,
                      });
                    }
                    if (data.refractionInfo.LEReason === "") {
                      this.setState({
                        LEBCVADistance:
                          data.refractionInfo.LEBCVADistance !== -1
                            ? BCVADistanceData[
                                data.refractionInfo.LEBCVADistance
                              ]
                            : -1,
                        LELogMAR:
                          data.refractionInfo.LEBCVADistance !== -1
                            ? BCVADistanceData[
                                data.refractionInfo.LEBCVADistance
                              ].logmar
                            : "",
                        LEBCVANear:
                          data.refractionInfo.LEBCVANear !== -1
                            ? BCVANearData[data.refractionInfo.LEBCVANear]
                            : -1,
                        LEBCVALensStatus:
                          data.refractionInfo.LEBCVALensStatus === -1
                            ? -1
                            : lensStatusData[
                                data.refractionInfo.LEBCVALensStatus
                              ],
                        LEAnteriorChamber:
                          data.refractionInfo.LEAnteriorChamber === -1
                            ? -1
                            : anteriorChamberData[
                                data.refractionInfo.LEAnteriorChamber
                              ],
                      });
                    } else {
                      this.setState({
                        isLE: true,
                        LEReason: data.refractionInfo.LEReason,
                      });
                    }
                  } else {
                    this.setState({
                      patientId: data.patientId,
                      patientName: data.registerInfo.patientName,
                      isRE: false,
                      isLE: false,
                    });
                  }
                  this.handleRESection();
                  this.handleLESection();
                  await this.setState({
                    isLoading: false,
                  });
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    }
    this.interval = setInterval(
      () => this.setState({ timer: this.state.timer + 1 }),
      1000
    );
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
      isEdit: true,
      isReset: false,
    });
  }

  handleREBCVALensStatus = (item) => {
    this.validator.touched("REBCVALensStatus");
    this.setState({
      REBCVALensStatus: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleLEBCVALensStatus = (item) => {
    this.validator.touched("LEBCVALensStatus");
    this.setState({
      LEBCVALensStatus: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleREAnteriorChamber = (item) => {
    this.setState({
      REAnteriorChamber: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleLEAnteriorChamber = (item) => {
    this.setState({
      LEAnteriorChamber: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleREBCVADistance = (item) => {
    this.setState({
      REBCVADistance: item,
      RELogMAR: item.logmar,
    });
  };

  handleLEBCVADistance = (item) => {
    this.setState({
      LEBCVADistance: item,
      LELogMAR: item.logmar,
    });
  };

  handleREBCVANear = (item) => {
    this.setState({
      REBCVANear: item,
    });
  };

  handleLEBCVANear = (item) => {
    this.setState({
      LEBCVANear: item,
    });
  };

  async handleSubmit() {
    clearInterval(this.interval);
    const token = await localStorage.getItem("token");
    const currentCamp = await localStorage.getItem("currentCamp");
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);

    if (errorMessage.length <= 0) {
      const data = {
        patientId: this.state.patientId,
        campId: currentCamp,
        REBCVADistance:
          this.state.REBCVADistance !== -1 && this.state.REBCVADistance !== ""
            ? this.state.REBCVADistance.value
            : -1,
        REBCVANear:
          this.state.REBCVANear !== -1 ? this.state.REBCVANear.value : -1,
        REBCVALensStatus:
          this.state.REBCVALensStatus !== -1 &&
          this.state.REBCVALensStatus !== ""
            ? this.state.REBCVALensStatus.value
            : -1,
        REAnteriorChamber:
          this.state.REAnteriorChamber !== -1
            ? this.state.REAnteriorChamber.value
            : -1,
        LEBCVADistance:
          this.state.LEBCVADistance !== -1 && this.state.LEBCVADistance !== ""
            ? this.state.LEBCVADistance.value
            : -1,
        LEBCVANear:
          this.state.LEBCVANear !== -1 && this.state.LEBCVANear != '' ? this.state.LEBCVANear.value : -1,
        LEBCVALensStatus:
          this.state.LEBCVALensStatus !== -1 &&
          this.state.LEBCVALensStatus !== ""
            ? this.state.LEBCVALensStatus.value
            : -1,
        LEAnteriorChamber:
          this.state.LEAnteriorChamber !== -1
            ? this.state.LEAnteriorChamber.value
            : -1,
        refractionInfoDuration:
          this.state.refractionInfoDuration + this.state.timer,
        REReason: this.state.isRE ? this.state.REReason : "",
        LEReason: this.state.isLE ? this.state.LEReason : "",
      };

      await this.setState({
        isLoading: true,
      });

      PatientService.addOrUpdateRefraction(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  // console.log(JSON.stringify(result));
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.history.push("/Dashboard");
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  handleBackArrow = () => {
    if (this.state.campStatus !== "0") {
      if (this.state.isEdit) {
        this.setState({
          isConfirmation: !this.state.isConfirmation,
          isReset: false,
        });
      } else {
        this.props.history.push("/Dashboard");
      }
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleCancelBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: false,
    });
  };

  handleResetBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: true,
    });
  };

  handleConfirmBtn = async () => {
    if (this.state.isReset) {
      this.setState({
        REBCVADistance: "",
        REBCVANear: -1,
        REBCVALensStatus: "",
        REAnteriorChamber: -1,
        LEBCVADistance: "",
        LEBCVANear: -1,
        LEBCVALensStatus: "",
        LEAnteriorChamber: -1,
        isConfirmation: !this.state.isConfirmation,
        isReset: false,
        isLELogMAR: false,
        isRELogMAR: false,
      });
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleQuickHelp = () => {
    this.setState({
      isQuickHelp: !this.state.isQuickHelp,
    });
  };

  handleRE = () => {
    this.setState({
      isRE: !this.state.isRE,
    });
    setTimeout(() => {
      this.handleRESection();
    }, 300);
  };

  handleRESection = () => {
    if (this.state.isRE) {
      this.setState({
        REBCVADistance: "",
        REBCVANear: -1,
        REBCVALensStatus: "",
        REAnteriorChamber: -1,
      });
      this.validator.removeConstraint("REBCVADistance");
      this.validator.removeConstraint("REBCVALensStatus");
      this.validator.addConstraint({
        field: "REReason",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Right Eye Reason",
          },
        ],
        touched: false,
      });
    } else {
      this.validator.addConstraint({
        field: "REBCVADistance",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Right Eye BCVA Distance",
          },
        ],
        touched: false,
      });
      this.validator.addConstraint({
        field: "REBCVALensStatus",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Right Eye BCVA Lens status",
          },
        ],
        touched: false,
      });
      this.validator.removeConstraint("REReason");
    }
    console.log(this.validator);
  };

  handleLE = () => {
    this.setState({
      isLE: !this.state.isLE,
    });
    setTimeout(() => {
      this.handleLESection();
    }, 300);
  };

  handleLESection = () => {
    if (this.state.isLE) {
      this.setState({
        LEBCVADistance: "",
        LEBCVANear: -1,
        LEBCVALensStatus: "",
        LEAnteriorChamber: -1,
      });
      this.validator.removeConstraint("LEBCVADistance");
      this.validator.removeConstraint("LEBCVALensStatus");
      this.validator.addConstraint({
        field: "LEReason",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Left Eye Reason",
          },
        ],
        touched: false,
      });
    } else {
      this.validator.addConstraint({
        field: "LEBCVADistance",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Left Eye BCVA Distance",
          },
        ],
        touched: false,
      });
      this.validator.addConstraint({
        field: "LEBCVALensStatus",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Left Eye BCVA Lens status",
          },
        ],
        touched: false,
      });
      this.validator.removeConstraint("LEReason");
    }
    console.log(this.validator);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleRELogMAR = async () => {
    await this.setState({
      isRELogMAR: !this.state.isRELogMAR,
    });
  };

  handleLELogMAR = async () => {
    await this.setState({
      isLELogMAR: !this.state.isLELogMAR,
    });
  };

  render() {
    // const validation = this.isValidate
    //   ? this.validator.validate(this.state)
    //   : this.state.validation;

    return (
      <RenderPage
        id="refraction-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-refraction"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={10} lg={12} xl={12} className="m-auto">
              <BackArrow
                title="Refraction"
                handleBackArrow={this.handleBackArrow}
                isQuick={true}  
                handleQuickHelp={this.handleQuickHelp}
              />

              <Row className="bg-light-white bg-white p-4">
                {this.state.patientId ? (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="p-0"
                  >
                    <p className="p-large internal-page__title  mb-3 text-capitalize align-left title-suvaGrey pat-title">
                      {this.state.patientName} - {this.state.patientId}
                    </p>
                  </Col>
                ) : null}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="p-0"
                      >
                        <Row>
                          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <p className="mb-3 internal-page__title text-black align-left">
                              RIGHT EYE
                            </p>
                          </Col>
                          <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <FontAwesomeIcon
                              icon={this.state.isRE ? faCheckSquare : faSquare}
                              color={`#72d389`}
                              size={"2x"}
                              className={`cursor-pointer ${
                                this.state.isRE
                                  ? "sqaure-checked"
                                  : "sqaure-unchecked"
                              }`}
                              onClick={() => this.handleRE()}
                            />
                            <font
                              className="common-label pl-12px"
                              onClick={() => this.handleRE()}
                            >
                              NA
                            </font>
                          </Col>
                        </Row>
                        {this.state.isRE ? (
                          <FormGroup className="full-width">
                            <Label className="common-label">Reason <sup>*</sup></Label>
                            <Input
                              type="text"
                              placeholder="Reason"
                              onChange={this.handleChange}
                              name="REReason"
                              className="form-control common-form-control"
                              value={this.state.REReason}
                            />
                          </FormGroup>
                        ) : (
                          <div>
                            
                            <Form className="form-fields field-col-4">  
                            <FormGroup>
                              <Label className="common-label">
                                BCVA Distance <sup>*</sup>
                              </Label>
                              <Select
                                value={this.state.REBCVADistance}
                                onChange={this.handleREBCVADistance}
                                options={BCVADistanceData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">LogMAR Value</Label>
                              <Input
                                type="text"
                                placeholder="logmar"
                                name="RELogMAR"
                                //onChange={this.handleChange}
                                className="form-control common-form-control"
                                value={this.state.RELogMAR}
                                readOnly
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">BCVA Near</Label>
                              <Select
                                value={this.state.REBCVANear}
                                onChange={this.handleREBCVANear}
                                options={BCVANearData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">
                                Lens <sup>*</sup>
                              </Label>
                              <Select
                                value={this.state.REBCVALensStatus}
                                onChange={this.handleREBCVALensStatus}
                                options={lensStatusData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                name="REBCVALensStatus"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">
                                Anterior Chamber
                              </Label>
                              <Select
                                value={this.state.REAnteriorChamber}
                                onChange={this.handleREAnteriorChamber}
                                options={anteriorChamberData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>
                            </Form>
                            
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="my-4"></div>
                    <Row>
                      <Col
                        xs={12}
                        ms={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="bg-white p-0"
                      >
                        <Row>
                          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <p className="mb-3 internal-page__title text-black align-left">
                              LEFT EYE
                            </p>
                          </Col>
                          <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <FontAwesomeIcon
                              icon={this.state.isLE ? faCheckSquare : faSquare}
                              color={`#72d389`}
                              size={"2x"}
                              className={`cursor-pointer ${
                                this.state.isLE
                                  ? "sqaure-checked"
                                  : "sqaure-unchecked"
                              }`}
                              onClick={() => this.handleLE()}
                            />
                            <font
                              className="common-label pl-12px"
                              onClick={() => this.handleLE()}
                            >
                              NA
                            </font>
                          </Col>
                        </Row>
                        {this.state.isLE ? (
                          <FormGroup className="full-width">
                            <Label className="common-label">Reason <sup>*</sup></Label>
                            <Input
                              type="text"
                              placeholder="Reason"
                              onChange={this.handleChange}
                              name="LEReason"
                              className="form-control common-form-control"
                              value={this.state.LEReason}
                            />
                          </FormGroup>
                        ) : (
                          <div>
                           
                          <Form className="form-fields field-col-4">
                            <FormGroup>
                              <Label className="common-label">
                                BCVA Distance <sup>*</sup>
                              </Label>
                              <Select
                                value={this.state.LEBCVADistance}
                                onChange={this.handleLEBCVADistance}
                                options={BCVADistanceData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup>
                            <Label className="common-label">LogMAR Value</Label>
                            <Input
                              type="text"
                              placeholder="logmar"
                              //onChange={this.handleChange}
                              name="LELogMAR"
                              className="form-control common-form-control"
                              value={this.state.LELogMAR}
                              readOnly
                            />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">BCVA Near</Label>
                              {/* <Input
                            type="text"
                            placeholder="0"
                            onChange={this.handleChange}
                            name="LEBCVANear"
                            className="form-control common-form-control"
                            value={this.state.LEBCVANear}
                          /> */}
                              <Select
                                value={this.state.LEBCVANear}
                                onChange={this.handleLEBCVANear}
                                options={BCVANearData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">
                                Lens <sup>*</sup>
                              </Label>
                              {/* <div
                                className={IsInputInError(
                                  validation.LEBCVALensStatus.isInvalid
                                )}
                              > */}
                              <Select
                                value={this.state.LEBCVALensStatus}
                                onChange={this.handleLEBCVALensStatus}
                                options={lensStatusData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                              {/* <p className="p-small help-block">
                                  {validation.LEBCVALensStatus.message}
                                </p>
                              </div> */}
                            </FormGroup>

                            <FormGroup>
                              <Label className="common-label">
                                Anterior Chamber
                              </Label>
                              <Select
                                value={this.state.LEAnteriorChamber}
                                onChange={this.handleLEAnteriorChamber}
                                options={anteriorChamberData}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select"
                                isSearchable={false}
                              />
                            </FormGroup>

                            <FormGroup className="list-btns">
                              <Button
                                className="btn custom-grey-btn cancel-btn"
                                onClick={this.handleResetBtn}
                                disabled={
                                  this.state.campStatus === "0" ? "disabled" : ""
                                }
                              >
                                Reset
                              </Button>

                              <Button
                                className="btn custom-theme-btn save-btn"
                                onClick={this.handleSubmit}
                                disabled={
                                  // this.state.REBCVADistance != "" &&
                                  // this.state.LEBCVADistance != "" &&
                                  // this.state.REBCVALensStatus != "" &&
                                  // this.state.LEBCVALensStatus != "" &&
                                  this.state.campStatus !== "0" ? false : true
                                }
                              >
                                Save
                              </Button>
                            </FormGroup>
                          </Form>

                          </div>
                        )}
                        <Row className="mt-4 mb-3">
                          <Col>
                            
                          </Col>
                          <Col>
                          
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
          {this.state.isQuickHelp ? (
            <QuickHelpMessage
              isIcon={false}
              isModal={this.state.isQuickHelp}
              data={RefractionAbbrevationInfo}
              toggle={this.handleQuickHelp}
            />
          ) : null}
          {this.state.isLoading ? <Loader isLoader={false} /> : null}
        </Strip>
      </RenderPage>
    );
  }
}

export default Refraction;
