import React from "react";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Diagnosis from "../../../customComponents/Diagnosis/Diagnosis";
import applicationConstants from "../../../constants/applicationContants";

class RightEye extends React.Component {
  constructor(props) {
    super(props);
    console.log("dia",props)
    this.state = {
      diagnosisType: applicationConstants.optoRE,
      title: applicationConstants.REDiagnosis +' '+ applicationConstants.DiagnosisTitle,
      imageType: "RIGHT"
    };
  }

  render() {
    return (
      <RenderPage
        id="patinet-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Diagnosis 
        title={this.state.title}
        diagnosisType={this.state.diagnosisType}
        imageType={this.state.imageType}
        />
      </RenderPage>
    );
  }
}
export default RightEye;
