import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import CampService from "../../../../../api/services/CampService";
import Strip from "../../../customComponents/Strip/Strip";
import DataTable from "react-data-table-component";
import windowSize from "react-window-size";
import TablesColumns from "./TablesColumns";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../../utils/dataTablesStyledTheme";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../../constants/applicationContants";
import Loader from "../../../customComponents/Loader/Loader";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";

class CampList extends React.Component {  
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      campsList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      isConfirmModal: false,
      deleteItem: '',
    };
    this.campList = this.campList.bind(this);
    this.getCampItem = this.getCampItem.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
  }

  componentDidMount() {
    this.campList();
  }

  getSnapshotBeforeUpdate(prevProps) {  
    console.log(prevProps)
    if (this.props.isModal !== prevProps.isModal) {
      this.campList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.campList();
    }
  }

  campList = async () => {
    const token = await localStorage.getItem("token");
    const hospitalName = await localStorage.getItem("hospitalName");
    const roleId = await localStorage.getItem("roleId");
    const data = roleId === applicationConstants.admin ? "" : hospitalName;

    await this.setState({
      isLoading: true,
    });

    CampService.getCampAll(token, data)
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          try {           
            res.text().then(async (res) => {
              console.log(res)  
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                console.log(data)
                const currentCamp = await data.find(
                  (item) => item.campStatus === 1 && item.currentCamp === 1
                );
                console.log(currentCamp)
                const isAllInActiveCampLen = await data.filter(
                  (item) => item.campStatus === 0 && item.currentCamp === 0
                );
                console.log(isAllInActiveCampLen)
                if (currentCamp) {
                  await localStorage.setItem(
                    "campLocation",
                    currentCamp.location
                  );
                  await localStorage.setItem("campDate", currentCamp.date);
                  await localStorage.setItem(
                    "currentCamp",
                    currentCamp.campCode
                  );
                  this.setState({
                    campsList: data,
                  });
                } else if (isAllInActiveCampLen.length === data.length) {
                  await localStorage.removeItem("campLocation");
                  await localStorage.removeItem("campDate");
                  await localStorage.removeItem("currentCamp");
                  this.setState({
                    campsList: data,
                  });
                } else {
                  let campListItem = [];
                  let isActive = true;
                  data.map((item) => {
                    if (
                      item.campStatus === 1 &&
                      item.hospitalName === hospitalName
                    ) {
                      if (isActive) {
                        localStorage.setItem("campLocation", item.location);
                        localStorage.setItem("campDate", item.date);
                        localStorage.setItem("currentCamp", item.campCode);
                        item.currentCamp = 1;
                        isActive = false;
                      }
                      campListItem.push(item);
                    } else {
                      campListItem.push(item);
                    }
                  });
                  this.setState({
                    campsList: campListItem,
                  });
                }

                this.setState({
                  filterData: data,
                });
                await this.setState({
                  isLoading: false,
                });
              } else {
                await this.setState({
                  isLoading: false,
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  getCampItem = (item) => {
      this.props.handleModal(item);
  };
  

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };

  filterData = (filterText) => {
    console.log(filterText)
    const filteredItems = this.state.filterData.filter(
      (item) =>   
        (item.location &&
          item.location.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.campCode &&
          item.campCode.toLowerCase().includes(filterText.toLowerCase()))
    );
    console.log(filteredItems)

    if (filteredItems.length >= 0) {
      this.setState({
        campsList: [...filteredItems],
      });
    } else {
      this.setState({
        campsList: [...this.state.filterData],
      });
    }
  };

  handleClear = () => {
    this.setState({
      campsList: [...this.state.filterData],
      filterText: "",
    });
  };

  handleAction = (item) => {
    this.props.handleModal(item);
  };


  handleDelete = async (item) => {
    this.setState({
      isConfirmModal: true,
      deleteItem: item,
    })
  }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }

  handleConfirmBtn = async () => {
    const campCode = this.state.deleteItem ? this.state.deleteItem.campCode : '';
    const token = await localStorage.getItem("token");
    if (campCode) {
      CampService.deleteCamp(token, campCode)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  this.setState({
                    isConfirmModal: false
                  });
                  alertSuccessMessage(`Camp deleted  successfully`)
                  this.campList();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn-pad"
                  onClick={this.props.toggle}
                >
                  <span>+</span> Add Camp
                </Button>
              </Col>
              <Col xs={12} sm={12} md={8} lg={10} xl={10}>
                <Row className="justify-content-end">
                  <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                    <InputGroup>
                      <Input
                        placeholder="search"
                        name="filterText"
                        value={this.state.filterText}
                        onChange={this.onChange}
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.handleClear}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={this.state.filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="table-responsive no-margin">
          {this.state.campsList.length > 0 ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <DataTable
                title=""
                columns={TablesColumns(this.handleAction, this.handleDelete)}
                data={this.state.campsList}
                pagination
                className="data-table"
                persistTableHead={false}
                customStyles={
                  this.props.windowWidth > applicationConstants.mobileBreakPoint
                    ? dataTablesStyledTheme
                    : dataTablesMobileStyledTheme
                }
                onRowClicked={(data) => this.getCampItem(data)}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="br-light-smoke bg-white p-5 br-4px">
                {!this.state.isLoading ? (
                  <p className="mb-0 align-center internal-page__title">
                    No Camps
                  </p>
                ) : (
                  <Loader isLoader={false} />
                )}
              </div>
            </Col>
          )}
        </Row>
        {this.state.isConfirmModal ? (
          <MessageConfirmation
            isModal={this.state.isConfirmModal}
            handleCancelBtn={this.handleCancelBtn}
            handleConfirmBtn={this.handleConfirmBtn}
            message={`Are you sure you want to delete this camp?`}
          />
        ) : null}
      </Strip>
    );
  }
}

export default windowSize(CampList);
