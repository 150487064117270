import React from "react";
import Select from "react-select";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import PatientService from "../../../../../api/services/PatientService";
import {
  medicationTypeData,
  medicationData,
  YesOrNoUnknowData,
  durationData,
  comorbiditiesData,
} from "../../../utils/dropdownData";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import { isNumeric, isWeight, isBloodSugar,isBloodPressure } from "../../../utils/customValidators";
import BackArrow from "../../../customComponents/BackArrow/BackArrow";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import QuickHelpMessage from "../../../customComponents/confirmationMessage/QuickHelpMessage";
import { HealthInfoAbbrevationInfo } from "../../../utils/AbbrevationInfo";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";

class HealthDetails extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "height",
        constraints: [
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid height",
            args: [{ max: 3 }],
          },
        ],
        touched: false,
      },
      {
        field: "weight",
        constraints: [
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid weight",
            args: [{ max: 3 }],
          },
          {
            method: isWeight,
            validWhen: true,
            message: "Please enter valid weight",
            args: [{ max: 3 }],
          },
        ],
        touched: false,
      },
      {
        field: "bloodPressure",
        constraints: [
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid blood pressure",
            args: [{ max: 6 }],
          },
          {
            method: isBloodPressure,
            validWhen: true,
            message: "Please enter blood pressure",
          },
        ],
        touched: false,
      },
      {
        field: "bloodSugar",
        constraints: [
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid blood sugar",
            args: [{ max: 10 }],
          },
          // {
          //   method: isBloodSugar,
          //   validWhen: true,
          //   message: "Please enter valid blood sugar",
          // },
        ],
        touched: false,
      },
    ]);
    this.state = {
      patientId: "",
      height: 0,
      weight: 0,
      bloodPressure: 0,
      bloodSugar: 0,
      hypertension: YesOrNoUnknowData[0],
      diabetes: YesOrNoUnknowData[0],
      duration: durationData[5],
      familyHistory: YesOrNoUnknowData[0],
      medication: medicationData[0],
      medicationType: medicationTypeData[0],
      // coMorbidities: comorbiditiesData[ ],
      coMorbidities: "",
      patientName: "",
      isConfirmation: false,
      isEdit: false,
      isReset: false,
      isQuickHelp: false,
      timer: 0,
      campStatus: 0,
      healthInfoDuration: 0,
      isLoading: false,
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const campStatus = await localStorage.getItem('campStatus');
    if (patientId) {
      await this.setState({
        isLoading: true,
        campStatus: campStatus
      })
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if(data){
                    this.setState({
                      campStatus: data.campId || data.visionCentreId ? "1" : "0"
                    });
                  }
                  if (data.healthInfo) {
                    const coMorbiditiesArrayList = [];
                    const coMorbiditiesItem = JSON.parse(
                      data.healthInfo.coMorbidities
                    );
                    if (coMorbiditiesItem) {
                      coMorbiditiesItem.map((item) => {
                        coMorbiditiesArrayList.push(comorbiditiesData[item]);
                      });
                    }
                    this.setState({
                      patientId: patientId,
                      patientName: data.registerInfo.patientName,
                      height: data.healthInfo.height,
                      weight: data.healthInfo.weight,
                      bloodPressure: data.healthInfo.bloodPressure,
                      bloodSugar: data.healthInfo.bloodSugar,
                      hypertension:
                        YesOrNoUnknowData[data.healthInfo.hypertension],
                      diabetes: YesOrNoUnknowData[data.healthInfo.diabetes],
                      duration: durationData[data.healthInfo.duration],
                      familyHistory:
                        YesOrNoUnknowData[data.healthInfo.familyHistory],
                      medication: medicationData[data.healthInfo.medication],
                      medicationType:
                        medicationTypeData[data.healthInfo.medicationType],
                      coMorbidities:
                        coMorbiditiesArrayList.length > 0
                          ? coMorbiditiesArrayList
                          : this.state.coMorbidities,
                          healthInfoDuration: parseInt(data.healthInfo.healthInfoDuration),
                          isLoading: false,
                    });
                  } else {
                    this.setState({
                      patientId: data.patientId,
                      patientName: data.registerInfo.patientName,
                      isLoading: false
                    });
                  }
                } else {
                  await this.setState({
                    isLoading: false,
                  })
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              })
              console.log(err);
            }
          }
        })
        .catch((err) => {
           this.setState({
            isLoading: false,
          })
          console.log(err);
        });
    }
    if (this.state.height) {
      this.validator.touched("height");
    }
    if (this.state.weight) {
      this.validator.touched("weight");
    }
    if (this.state.bloodPressure) {
      this.validator.touched("bloodPressure");
    }
    if (this.state.bloodSugar) {
      this.validator.touched("bloodSugar");
    }
    this.interval = setInterval(
      () => this.setState({ timer: this.state.timer + 1 }),
      1000
    );
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
      isEdit: true,
      isReset: false,
    });
  }

  handleHypertension = (item) => {
    this.setState({
      hypertension: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleDiabetes = (item) => {
    this.setState({
      diabetes: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleDuration = (item) => {
    this.setState({
      duration: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleFamilyHistory = (item) => {
    this.setState({
      familyHistory: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleMedication = (item) => {
    this.setState({
      medication: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleMedicationType = (item) => {
    this.setState({
      medicationType: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleComorbidities = (item) => {
    this.setState({
      coMorbidities: item,
      isEdit: true,
      isReset: false,
    });
  };

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    console.log(errorMessage);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
    clearInterval(this.interval);
    const token = await localStorage.getItem("token");
    const currentCamp = await localStorage.getItem("currentCamp");

    const coMorbiditiesArrayList = [];
    if (this.state.coMorbidities) {
      this.state.coMorbidities.map((item) => {
        coMorbiditiesArrayList.push(item.value);
      });
    }
    // console.log(coMorbiditiesArrayList);

    const data = {
      campId: currentCamp,
      patientId: this.state.patientId,
      height: `${this.state.height}` === "" ? 0 : this.state.height,
      weight: `${this.state.weight}` === "" ? 0 : this.state.weight,
      bloodPressure:
        `${this.state.bloodPressure}` === "" ? 0 : this.state.bloodPressure,
      bloodSugar: `${this.state.bloodSugar}` === "" ? 0 : this.state.bloodSugar,
      hypertension: this.state.hypertension.value,
      diabetes: this.state.diabetes.value,
      duration: this.state.duration.value,
      familyHistory: this.state.familyHistory.value,
      medication: this.state.medication.value,
      medicationType: this.state.medicationType.value,
      coMorbidities: coMorbiditiesArrayList,
      healthInfoDuration: this.state.healthInfoDuration + this.state.timer,
    };

    await this.setState({
      isLoading: true,
    })

    PatientService.addOrUpdateHealthInfo(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                //   console.log(JSON.stringify(result));
                await this.setState({
                  isLoading: false,
                })
                this.props.history.push("/Dashboard");
              } else {
                await this.setState({
                  isLoading: false,
                })
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            })
            console.log(err);
          }
        }
      })
      .catch((err) => {
         this.setState({
          isLoading: false,
        })
        console.log(err);
      });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  handleBackArrow = () => {
    if(this.state.campStatus !== "0"){
    if (this.state.isEdit) {
      this.setState({
        isConfirmation: !this.state.isConfirmation,
        isReset: false,
      });
    } else {
      this.props.history.push("/Dashboard");
    }
  }else{
    this.props.history.push("/Dashboard"); 
  }
  };

  handleCancelBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: false,
    });
  };

  handleResetBtn = async () => {
    await this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: true,
    });
  };

  handleConfirmBtn = async () => {
    if (this.state.isReset) {
      this.setState({
        height: 0,
        weight: 0,
        bloodPressure: 0,
        bloodSugar: 0,
        hypertension: YesOrNoUnknowData[0],
        diabetes: YesOrNoUnknowData[0],
        duration: durationData[0],
        familyHistory: YesOrNoUnknowData[0],
        medication: medicationData[0],
        medicationType: medicationTypeData[0],
        // coMorbidities: comorbiditiesData[5],
        coMorbidities: "",
        isConfirmation: !this.state.isConfirmation,
        isReset: false,
      });
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleQuickHelp = () => {
    this.setState({
      isQuickHelp: !this.state.isQuickHelp,
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <RenderPage
        id="health-info-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-hleath-info"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
              <BackArrow
                title=" Health Info"
                handleBackArrow={this.handleBackArrow}
                isQuick={true}
                handleQuickHelp={this.handleQuickHelp}
              />

              <Row className="bg-light-white bg-white p-4">
                {this.state.patientId ? (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="p-0"
                  >
                    <p className="p-large internal-page__title text-capitalize align-left title-suvaGrey pat-title">
                      {this.state.patientName} - {this.state.patientId}
                    </p>
                  </Col>
                ) : null}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="bg-white p-0"
                      >
                        <p className="mb-3 internal-page__title text-black align-left">
                          PHYSICAL EXAMINATION
                        </p>
                        <Form className="form-fields field-col-4">  
                        <FormGroup>
                          <Label className="common-label">Height (cm)</Label>
                          <div
                            className={IsInputInError(
                              validation.height.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Height"
                              onChange={this.handleChange}
                              name="height"
                              id="height"
                              value={this.state.height}
                              className="form-control common-form-control"
                            />
                            <p className="p-small help-block">
                              {validation.height.message}
                            </p>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="common-label">Weight (Kg)</Label>
                          <div
                            className={IsInputInError(
                              validation.weight.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Weight"
                              onChange={this.handleChange}
                              name="weight"
                              id="weight"
                              value={this.state.weight}
                              className="form-control common-form-control"
                            />
                            <p className="p-small help-block">
                              {validation.weight.message}
                            </p>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="common-label">
                            Blood Pressure (mm/Hg)
                          </Label>
                          <div
                            className={IsInputInError(
                              validation.bloodPressure.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Blood Pressure"
                              name="bloodPressure"
                              id="bloodPressure"
                              onChange={this.handleChange}
                              value={this.state.bloodPressure}
                              className="form-control common-form-control"
                            />
                            <p className="p-small help-block">
                              {validation.bloodPressure.message}
                            </p>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label className="common-label">
                            Blood Sugar (mg/dl)
                          </Label>
                          <div
                            className={IsInputInError(
                              validation.bloodSugar.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Blood Sugar"
                              name="bloodSugar"
                              id="bloodSugar"
                              onChange={this.handleChange}
                              value={this.state.bloodSugar}
                              className="form-control common-form-control"
                            />
                            <p className="p-small help-block">
                              {validation.bloodSugar.message}
                            </p>
                          </div>
                        </FormGroup>
                        </Form>
                      </Col>
                    </Row>

                    <div className="my-4"></div>

                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="bg-white p-0"
                      >
                        <p className="mb-3 internal-page__title text-black align-left">
                          SYSTEMIC HISTORY
                        </p>

                        <Form className="form-fields field-col-4">  
                          <FormGroup>
                            <Label className="common-label">Hypertension</Label>
                            <Select
                              value={this.state.hypertension}
                              onChange={this.handleHypertension}
                              options={YesOrNoUnknowData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">Diabetes</Label>
                            <Select
                              value={this.state.diabetes}
                              onChange={this.handleDiabetes}
                              options={YesOrNoUnknowData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Duration of Diabetes
                            </Label>
                            <Select
                              value={this.state.duration}
                              onChange={this.handleDuration}
                              options={durationData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Family History of Diabetes
                            </Label>
                            <Select
                              value={this.state.familyHistory}
                              onChange={this.handleFamilyHistory}
                              options={YesOrNoUnknowData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">Medication</Label>

                            <Select
                              value={this.state.medication}
                              onChange={this.handleMedication}
                              options={medicationData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">
                              Medication Type
                            </Label>
                            <Select
                              value={this.state.medicationType}
                              onChange={this.handleMedicationType}
                              options={medicationTypeData}
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="common-label">Other comorbidities</Label>
                            <Select
                              value={this.state.coMorbidities}
                              onChange={this.handleComorbidities}
                              options={comorbiditiesData}
                              isMulti
                              classNamePrefix="common-select-drop-down"
                              isSearchable={false}
                            />
                          </FormGroup>

                          <FormGroup className="list-btns">
                              <Button
                                className="btn custom-grey-btn cancel-btn"
                                onClick={this.handleResetBtn}
                                disabled={
                                  this.state.campStatus === "0"
                                    ? "disabled"
                                    : ""
                                }
                              >
                                Reset
                              </Button>
                              <Button
                                className="btn custom-theme-btn save-btn"
                                onClick={this.handleSubmit}
                                disabled={
                                  this.state.campStatus === "0"
                                    ? "disabled"
                                    : ""
                                }
                              >
                                Save
                              </Button>
                          </FormGroup>

                        </Form>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
          {this.state.isQuickHelp ? (
            <QuickHelpMessage
              isIcon={false}
              isModal={this.state.isQuickHelp}
              data={HealthInfoAbbrevationInfo}
              toggle={this.handleQuickHelp}
            />
          ) : null}
          {this.state.isLoading ?
          <Loader isLoader={false}/> : null}
        </Strip>
      </RenderPage>
    );
  }
}

export default HealthDetails;
