import React from "react";
import { withRouter } from "react-router";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import applicationConstants from "../../constants/applicationContants";
import FormValidator from "../../utils/FormValidator";
import IsInputInError from "../../utils/isInputInError";
import LoginService from "../../../../api/services/LoginService";
import { AppContext } from "../../layout/AppProvider";
import { userRoleData } from "../../utils/dropdownData";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../customComponents/Loader/Loader";

class login extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "userName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter login Id",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "loginId should be  max 50 characters",
            args: [{ max: 50 }],
          },
        ],
        touched: false,
      },
      {
        field: "emailId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "EmailId is required.",
          },
          {
            method: "isEmail",
            validWhen: true,
            message: "That is not a valid email Id.",
          },
        ],
        touched: false,
      },
      {
        field: "password",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter password",
          },
          // {
          //   method: "isLength",
          //   validWhen: true,
          //   message: "password should be  max 15 characters",
          //   args: [{ max: 15 }],
          // },
        ],
        touched: false,
      },
    ]);
    this.state = {
      userName: "",
      password: "",
      isForgotPwd: false,
      emailId: "",
      isResetPwd: false,
      isLoading: false,
      
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount = async () => {
    if (localStorage.getItem("isLogin") == "false") {
      localStorage.clear();
    }
    if (this.state.loginId) {
      this.validator.touched("loginId");
    }
    if (this.state.password) {
      this.validator.touched("password");
    }
  };

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleKeyPress(target) {
    if (target.charCode === 13) {
      await this.handleLogin();
    }
  }

  handleForgotPwd = () => {
    this.setState({
      isForgotPwd: !this.state.isForgotPwd,
    });
  };

  async handleSubmit() {
    await this.handleLogin();
  }

  handleLogin = async () => {
    await this.setState({
      isLoading: true,
    });
    await LoginService.login(this.state.userName, this.state.password)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                await this.context.updateValue("token", result.data.token);
                await localStorage.setItem("token", result.data.token);
                await localStorage.setItem("isLogin", true);
                await localStorage.setItem("loginId", result.data.loginId);
                await localStorage.setItem("userName", result.data.fullName);
                await localStorage.setItem(
                  "hospitalName",
                  result.data.hospitalBranchName
                    ? result.data.hospitalBranchName
                    : ""
                );
                await localStorage.setItem(
                  "roleId",
                  userRoleData[result.data.roleId].label
                );
                // if (userRoleData[result.data.roleId]) {
                //   if (
                //     userRoleData[result.data.roleId].label !==
                //     applicationConstants.admin
                //   ) {
                //     await localStorage.setItem(
                //       "hospitalName",
                //       result.data.hospitalBranchName
                //         ? result.data.hospitalBranchName
                //         : ""
                //     );
                //     await localStorage.setItem("roleName", userRoleData[result.data.roleId].label);
                //   } else {
                //     await localStorage.setItem(
                //       "hospitalName",
                //       result.data.hospitalBranchName
                //         ? result.data.hospitalBranchName
                //         : ""
                //     );
                //     await localStorage.setItem("roleName", userRoleData[result.data.roleId].label);
                //   }
                // }
                await localStorage.setItem(
                  "mobileNumber",
                  result.data.mobileNumber
                );
               // await this.context.getCampList();
                await setTimeout(async () => {
                  this.props.onSubmit();
                  this.props.history.push("/UserDashboard");
                  await this.setState({
                    isLoading: false,
                  });
                }, 1000);
              } else {
                // alert(`${result.message}`);
                await this.setState({
                  isLoading: false,
                });

                alertDangerConfirmMessage(
                  `please check your user id / password`
                );
              }
            });
          } catch (err) {
            console.log(err);
            await this.setState({
              isLoading: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleEmailSubmit = async () => {
    await this.setState({
      isLoading: true,
    });
    await LoginService.sentEmail(this.state.emailId)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                // alertSuccessMessage(`${result.message}`);
                this.setState({
                  isResetPwd: true,
                  isLoading: false,
                });
              } else {
                // alert(`${result.message}`);
                await this.setState({
                  isLoading: false,
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  onIdeal = () => {
    console.log('user is ideal')
  }

  render() {
    const { userName, password } = this.state;
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <RenderPage
        id="login-page"
        className="mt-0"
        containerType="container-fluid"
      >
        <Strip
          id="tst-login"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >        
          <Form>
            <Row className="m-0 justify-content-center align-items-center full-height">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={6}
                className="shadow-lg p-0 bg-white rounded row"
              >                
                <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="strip-no-padding lo-lf"
                  >
                  <img
                    src={applicationConstants.loginImg}
                    alt="teleoptics-logo"
                    className="login-img"
                  />
                </Col>
                
                <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="p-4"
                      >

                  {!this.state.isResetPwd ? (
                    <div>
                      <Row className="bg-grey-medium">
                        <Col
                          xs={12}
                          sm={12}
                          md={10}
                          lg={8}
                          xl={8}
                          className="m-auto align-center"
                        >
                          <img
                            src={applicationConstants.logoImg}
                            alt="teleoptics-logo"
                            className="logo-container"
                          />
                        </Col>
                      </Row>
                      {!this.state.isForgotPwd ? (
                        <div>
                          <FormGroup>
                            <Label className="common-label title-theme-color">
                              Login ID
                            </Label>
                            <div
                              className={IsInputInError(
                                validation.userName.isInvalid
                              )}
                            >
                              <Input
                                type="text"
                                placeholder="Login ID"
                                id="userName"
                                name="userName"
                                value={this.state.userName}
                                className="form-control common-form-control"
                                onChange={this.handleChange}
                              />
                              <p className="p-small help-block">
                                {validation.userName.message}
                              </p>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="common-label title-theme-color">
                              Password
                            </Label>
                            <div
                              className={IsInputInError(
                                validation.password.isInvalid
                              )}
                            >
                              <Input
                                type="password"
                                placeholder="Password"
                                id="password"
                                name="password"
                                className="form-control common-form-control"
                                value={this.state.password}
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeyPress}
                              />
                              <p className="p-small help-block">
                                {validation.password.message}
                              </p>
                            </div>
                          </FormGroup>
                        </div>
                      ) : (
                        <FormGroup>
                          <Label className="common-label title-theme-color">
                            Email Id*
                          </Label>
                          <div
                            className={IsInputInError(
                              validation.emailId.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="email Id"
                              id="emailId"
                              name="emailId"
                              className="form-control common-form-control"
                              value={this.state.emailId}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.emailId.message}
                            </p>
                          </div>
                        </FormGroup>
                      )}
                      <div className="mb-3">
                        <p
                          className="common-label title-dimGrey text-right cursor-pointer text-underline active-link"
                          onClick={() => this.handleForgotPwd()}
                        >
                          {!this.state.isForgotPwd ? `Forgot Password?` : ""}
                        </p>
                      </div>
                      {!this.state.isForgotPwd ? (
                        <Button
                          className="btn btn-block mb-2 custom-theme-btn"
                          size="lg"
                          onClick={this.handleSubmit}
                          disabled={userName && password ? false : true}
                        >
                          Login
                        </Button>
                      ) : (
                        <div className="mt-5 mb-2 ">
                          <Button
                            className="btn btn-block mb-2 custom-theme-btn"
                            size="lg"
                            onClick={this.handleEmailSubmit}
                            disabled={this.state.emailId !== "" ? false : true}
                          >
                            Submit
                          </Button>
                          <p
                            className="common-label title-dimGrey mt-3 mb-2 text-center cursor-pointer text-underline active-link"
                            size="lg"
                            onClick={() => this.handleForgotPwd()}
                          >
                            Login
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Row className="pt-4 pb-3">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h4 className="internal-page__title title-black justify-content-center d-flex align-items-center">
                          To reset your password
                        </h4>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="text-center pt-4"
                      >
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size={"6x"}
                          color="#72d389"
                          className="icon-envelope"
                        />
                        <p className="internal-page__title title-black text-center pt-2">
                          Please check your email inbox
                        </p>
                        <Row>
                          <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                            className="m-auto"
                          >
                            <p className="p-medium internal-page__title title-black text-center title-charcoal-grey pt-3">
                              If you provided a valid email address for your
                              Teleoptics account, we've sent you an email with a
                              link to reset your password.
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
                
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </Col>
            </Row>
          </Form>
        </Strip>
      </RenderPage>
    );
  }
}

export default withRouter(login);
