import React from "react";
import Diagnosis from "../../../customComponents/Diagnosis/Diagnosis";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../../constants/applicationContants";

class AtCampRE extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      diagnosisType: applicationConstants.AtCampRE,
      title: applicationConstants.REDiagnosis +' '+ applicationConstants.CampTitle,
    }
  }

  render() {
    return (
      <RenderPage
        id="patinet-list-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Diagnosis 
        title={this.state.title}
        diagnosisType={this.state.diagnosisType}/>
      </RenderPage>
    );
  }
}
export default AtCampRE;
