import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ReportService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getPatientReport: async (token, data) => {
    const { baseUrl, getPatientsReport } = ApiConfig;

    const url = baseUrl + getPatientsReport;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const params = JSON.stringify({
        filterBy:`${data.filterBy}`, 
        campId: data.campId, //["AF20210628","AG20210629","AH20210901"],
        visionCentreId : data.visionCentreId, //["19","20"],
        status: `${data.status}`,
        startDate: `${data.startDate}`,
        endDate: `${data.endDate}`,
        limit: `${data.limit}`,
        offset: `${data.offset}`,
        hospitalName: `${data.hospitalName}`,
    });
    if (data.campId.length === 0){
      delete params.campId;
    }

    console.log(params);

    const reportServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_ReportService",
      true
    );
    return reportServicePromise;
  },

  getPatientCSV: async (token, data) => {
    const { baseUrl, getPatientsReportCSV } = ApiConfig;

    const url = baseUrl + getPatientsReportCSV;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const params = JSON.stringify({
        filterBy:`${data.filterBy}`, 
        campId: data.campId, //["AF20210628","AG20210629","AH20210901"],
        visionCentreId : data.visionCentreId, //["19","20"],
        status: `${data.status}`,
        startDate: `${data.startDate}`,
        endDate: `${data.endDate}`,
        limit: `${data.limit}`,
        offset: `${data.offset}`,
        hospitalName: `${data.hospitalName}`,
    });
    if (data.campId.length === 0){
      delete params.campId;
    }

    console.log(params);

    const reportServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_ReportService",
      true
    );
    return reportServicePromise;
  },

  getPatientInfoDownloadZip: async (token, patientId) => {
    const { baseUrl, getPatientimages } = ApiConfig;

    const url = baseUrl + getPatientimages;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const params = JSON.stringify({
      patientId:patientId, 
    });
    
    console.log(params);

    const reportServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_ReportService",
      true
    );
    return reportServicePromise;
  },

};

export default ReportService;
