import React from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import Strip from "../Strip/Strip";

function AddConfirmation(props) {
  return (
    <Strip
      id="tst-camp-list"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row>
        <Col xs={10} sm={10} md={12} lg={12} xl={12}>
          <Modal
            isOpen={props.isModal}
            //  toggle={props.toggle}
            className="modal-container modal-md modal-dialog-centered"
          >
            <ModalBody className="confirmation-content__container">
              <Row className="no-margin">
                <Col xs={12} ms={12} md={10} lg={10} xl={10} className="m-auto">
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="m-auto px-0 pb-3"
                    >
                      <p className="mb-0 internal-section__title title-black fw-800 text-center">
                      Are you sure to save this patient record? Once done, you won't able to modify the VC/Camp ID later
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="m-auto px-0 bg-light-white pd-12px"
                >
                  <p className="mb-0 internal-section__title title-danger fw-800 text-center" onClick={() => props.handleCancelBtn()}>
                    Cancel
                  </p>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="m-auto px-0 bg-theme-color pd-12px"
                >
                  <p className="mb-0 internal-section__title title-white fw-800 text-center"  onClick={() => props.handleConfirmBtn()}>
                    Confirm
                  </p>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Strip>
  );
}

export default AddConfirmation;
