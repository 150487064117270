import React from "react";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import BackArrow from "../../customComponents/BackArrow/BackArrow";
import PatientService from "../../../../api/services/PatientService";
import ImageList from "./component/ImageList";
import PreviewAnnonation from "./component/PreviewAnnonation";
import { Link } from "react-router-dom";
import applicationConstants from "../../constants/applicationContants";
import Loader from "../../customComponents/Loader/Loader";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";

class ImagingAndGrading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preDilationREMedia: [],
      postDilationREMedia: [],
      preDilationLEMedia: [],
      postDilationLEMedia: [],
      patientId: "",
      patientName: "",
      isPreview: false,
      previewItem: "",
      roleId: "",
      isLoading: false,
      isRE: false,
      isLE: false,
    };
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const roleId = await localStorage.getItem("roleId");
    if (patientId) {
      this.setState({
        isLoading: true,
      });
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data.registerInfo) {
                    this.setState({
                      patientId: data.patientId,
                      patientName: data.registerInfo.patientName,
                    });
                  }

                  if (data.refractionInfo) {
                    this.setState({
                      isRE: data.refractionInfo.REReason !== "" ? true : false,
                      isLE: data.refractionInfo.LEReason !== "" ? true : false,
                    });
                  }

                  //optoRE Diagnosis
                  if (data.optoREDiag) {
                    if (
                      data.optoREDiag.REPreDilationImaging != "undefined" &&
                      data.optoREDiag.REPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoREDiag.REPreDilationImaging
                      );

                      if (preDilationImage != "[]" && preDilationImage != "") {
                        this.setState({
                          preDilationREMedia: preDilationImage,
                        });
                      }
                    }
                    if (
                      data.optoREDiag.REPostDilationImaging != "undefined" &&
                      data.optoREDiag.REPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoREDiag.REPostDilationImaging
                      );
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        this.setState({
                          postDilationREMedia: postDilationImage,
                        });
                      }
                    }
                  }
                  //OptoLE Diagnosis
                  if (data.optoLEDiag) {
                    if (
                      data.optoLEDiag.LEPreDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoLEDiag.LEPreDilationImaging
                      );

                      if (preDilationImage != "[]" && preDilationImage != "") {
                        this.setState({
                          preDilationLEMedia: preDilationImage,
                        });
                      }
                    }
                    if (
                      data.optoLEDiag.LEPostDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoLEDiag.LEPostDilationImaging
                      );
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        this.setState({
                          postDilationLEMedia: postDilationImage,
                        });
                      }
                    }
                  }

                  await setTimeout(() => {
                    this.setState({
                      isLoading: false,
                    });
                  }, 500);
                } else {
                  await setTimeout(() => {
                    this.setState({
                      isLoading: false,
                    });
                  }, 500);
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await setTimeout(() => {
                this.setState({
                  isLoading: false,
                });
              }, 500);
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    }
    this.setState({
      roleId: roleId,
    });
  }

  toggle = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });
  };

  handlePreviewItem = (item) => {
    this.setState({
      isPreview: !this.state.isPreview,
      previewItem: item,
    });
  };

  handleBackArrow = () => {
    this.props.history.push("/Report");
  };

  render() {
    return (
      <RenderPage
        id="imaging-grading-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-imaging-grading"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={10} lg={10} xl={6} className="m-auto">
              <BackArrow
                title={`Imaging & Grading`}
                handleBackArrow={this.handleBackArrow}
              />
              <Row className="bg-light-white pd-12px br-4px">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pb-4px pt-4px"
                >
                  <p className="p-large internal-page__title  mb-0 text-capitalize align-left title-suvaGrey">
                    {this.state.patientName} - {this.state.patientId}
                  </p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-0 br-4px"
                >
                  <Form className="pd-12px">
                    {!this.state.isRE ? (
                      <FormGroup>
                        <Row className="no-margin bg-white p-2">
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="px-0"
                          >
                            <p className="mb-2 p-large internal-page__title title-theme-color align-left">
                              RIGHT EYE
                            </p>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className=" br-light-smoke br-4px"
                          >
                            <ImageList
                              title="Pre-dilated fundus image"
                              dilationImage={this.state.preDilationREMedia}
                              handlePreviewItem={this.handlePreviewItem}
                            />
                            <ImageList
                              title="Post-dilated fundus image"
                              dilationImage={this.state.postDilationREMedia}
                              handlePreviewItem={this.handlePreviewItem}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : null}
                    {!this.state.isLE ? (
                      <FormGroup>
                        <Row className="no-margin bg-white p-2">
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="px-0 "
                          >
                            <p className="mb-2 p-large internal-page__title title-theme-color align-left">
                              LEFT EYE
                            </p>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className=" br-light-smoke br-4px"
                          >
                            <ImageList
                              title="Pre-dilated fundus image"
                              dilationImage={this.state.preDilationLEMedia}
                              handlePreviewItem={this.handlePreviewItem}
                            />
                            <ImageList
                              title="Pre-dilated fundus image"
                              dilationImage={this.state.postDilationLEMedia}
                              handlePreviewItem={this.handlePreviewItem}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : null}
                    {this.state.roleId !==
                    applicationConstants.ProjectReader ? (
                      <FormGroup>
                        <Link
                          to={`/Grading`}
                          className={`d-flex justify-content-center`}
                        >
                          <Button className="btn btn-block custom-theme-btn">
                            Grade
                          </Button>
                        </Link>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Link
                          to={`/PatientList`}
                          className={`d-flex justify-content-center`}
                        >
                          <Button className="btn btn-block custom-theme-btn">
                            Continue
                          </Button>
                        </Link>
                      </FormGroup>
                    )}
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.isPreview ? (
            <PreviewAnnonation
              isModal={this.state.isPreview}
              patientId={this.state.patientId}
              patientName={this.state.patientName}
              toggle={this.toggle}
              previewItem={this.state.previewItem}
            />
          ) : null}
          {this.state.isLoading ? <Loader isLoader={false}/> : null}
        </Strip>
      </RenderPage>
    );
  }
}
export default ImagingAndGrading;
