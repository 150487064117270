import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import VisionCenterService from "../../../../../api/services/VisionCenterService";
import Strip from "../../../customComponents/Strip/Strip";
import DataTable from "react-data-table-component";
import windowSize from "react-window-size";
import TablesColumns from "./TablesColumns";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../../utils/dataTablesStyledTheme";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../../constants/applicationContants";
import Loader from "../../../customComponents/Loader/Loader";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";

class VisionInfoList extends React.Component {  
  constructor(props) {
    // console.log(props)
    super(props);
    this.state = {
      visioncentersList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      isConfirmModal: false,
      deleteItem: '',
    };
    this.visioncenterList = this.visioncenterList.bind(this);
    this.getVisionCenterItem = this.getVisionCenterItem.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
  }

  componentDidMount() {
    this.visioncenterList();
  }

  getSnapshotBeforeUpdate(prevProps) {  
    if (this.props.isModal !== prevProps.isModal) {
      this.visioncenterList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.visioncenterList();
    }
  }

  visioncenterList = async () => {
    const token = await localStorage.getItem("token");
    await this.setState({
      isLoading: true,
    });

    VisionCenterService.getVisionCenterList(token)
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          try {           
            res.text().then(async (res) => {
              console.log(res)  
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                this.setState({
                  visioncentersList: data,
                  filterData: data,
                });
                await this.setState({
                  isLoading: false,
                });
              } else {                
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  getVisionCenterItem = (item) => {
      this.props.handleModal(item);
  };
  

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };

  filterData = (filterText) => {
    console.log(filterText)
    const filteredItems = this.state.filterData.filter(
      (item) =>   
        (item.center_name &&
          item.center_name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.erp_name &&
          item.erp_name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.address1 &&
            item.address1.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.address2 &&
              item.address2.toLowerCase().includes(filterText.toLowerCase())) ||
              (item.location &&
                item.location.toLowerCase().includes(filterText.toLowerCase()))               
    );


    if (filteredItems.length >= 0) {
      this.setState({
        visioncentersList: [...filteredItems],
      });
    } else {
      this.setState({
        visioncentersList: [...this.state.filterData],
      });
    }
  };

  handleClear = () => {
    this.setState({
      visioncentersList: [...this.state.filterData],
      filterText: "",
    });
  };

  handleAction = (item) => {
    this.props.handleModal(item);
  };


  handleDelete = async (item) => {
    this.setState({
      isConfirmModal: true,
      deleteItem: item,
    })
  }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }

  handleConfirmBtn = async () => {
    const VisionCode = this.state.deleteItem ? this.state.deleteItem.id : '';
    const token = await localStorage.getItem("token");
    if (VisionCode) {
      VisionCenterService.deleteVisionCenter(token, VisionCode)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  this.setState({
                    isConfirmModal: false
                  });
                  alertSuccessMessage(`Vision Center deleted successfully`)
                  this.visioncenterList();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn-pad btn-padd"
                  onClick={this.props.toggle}
                >
                  <span>+</span> Add VisionCentre
                </Button>
              </Col>
              <Col xs={12} sm={12} md={8} lg={10} xl={10}>
                <Row className="justify-content-end">
                  <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                    <InputGroup>
                      <Input
                        placeholder="search"
                        name="filterText"
                        value={this.state.filterText}
                        onChange={this.onChange}
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.handleClear}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={this.state.filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="table-responsive no-margin">
          {this.state.visioncentersList.length > 0 ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <DataTable
                title=""
                columns={TablesColumns(this.handleAction, this.handleDelete)}
                data={this.state.visioncentersList}
                pagination
                className="data-table"
                persistTableHead={false}
                customStyles={
                  this.props.windowWidth > applicationConstants.mobileBreakPoint
                    ? dataTablesStyledTheme
                    : dataTablesMobileStyledTheme
                }
                onRowClicked={(data) => this.getVisionCenterItem(data)}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="br-light-smoke bg-white p-5 br-4px">
                {!this.state.isLoading ? (
                  <p className="mb-0 align-center internal-page__title">
                    No VisionInfo
                  </p>
                ) : (
                  <Loader isLoader={false} />
                )}
              </div>
            </Col>
          )}
        </Row>
        {this.state.isConfirmModal ? (
          <MessageConfirmation
            isModal={this.state.isConfirmModal}
            handleCancelBtn={this.handleCancelBtn}
            handleConfirmBtn={this.handleConfirmBtn}
            message={`Are you sure you want to delete this Vision center?`}
          />
        ) : null}
      </Strip>
    );
  }
}

export default windowSize(VisionInfoList);
