import React from "react";
import { Row, Col, Modal, ModalBody, Button, Input, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faToggleOn,
  faToggleOff,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Strip from "../../../customComponents/Strip/Strip";
import PatientService from "../../../../../api/services/PatientService";
import applicationConstants from "../../../constants/applicationContants";
import ProjectReaderGrade from "./ProjectReaderGrade";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import ZoomImage from "./ZoomImage";

class PreviewAnnonation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredArea: null,
      modal: props.isModal ? props.isModal : false,
      previewItem: props.previewItem ? props.previewItem : null,
      patientId: props.patientId ? props.patientId : "",
      patientName: props.patientName ? props.patientName : "",
      fileName: props.previewItem ? props.previewItem.fileName : "",
      comments: "",
      MAP: {
        name: "my-map",
        areas: [],
      },
      data: [],
      index: "",
      isAdd: false,
      isEdit: false,
      isAnnotation: false,
      display_status: true,
      roleId: "",
      isLoading: false,
      pointType: "",
      startIndex: 0,
      endIndex: 0,
      count: 0,
      tooltipOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToolTip = this.handleToolTip.bind(this);
    this.handleOuterToolTip = this.handleOuterToolTip.bind(this);
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const roleId = await localStorage.getItem("roleId");
    if (patientId) {
      await this.setState({
        setState: true,
      });
      PatientService.getAnnotationByPatientId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data.length > 0) {
                    await data.map((item) => {
                      if (item.signedUrl === this.state.previewItem.signedUrl) {
                        if (item.annotationPoint !== "[]") {
                          let data = JSON.parse(item.annotationPoint);
                          if (data) {
                            this.setState({
                              data: data,
                            });
                          }
                        }
                      }
                    });
                    await this.getCoordinatePoints();
                    await setTimeout(() => {
                      this.setState({
                        isLoading: false,
                      });
                    }, 5000);
                  }
                } else {
                  await setTimeout(() => {
                    this.setState({
                      isLoading: false,
                    });
                  }, 500);
                  //alert(`${result.message}`);
                }
              });
            } catch (err) {
              await setTimeout(() => {
                this.setState({
                  isLoading: false,
                });
              }, 500);
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    }

    this.setState({
      roleId: roleId,
      pointType: roleId,
    });
  }

  handleChange = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
    if (!this.state.isEdit) {
      await this.addNewComments();
    } else {
      await this.updateComments();
    }
  };

  addNewComments = async () => {
    if (this.state.data.length >= 0) {
      if (this.state.count === 1) {
        await this.state.data.map((item) => {
          if (this.state.data.length - 1 === this.state.index) {
            item.text = this.state.comments;
          }
        });
        await this.setState({
          count: 0,
        });
      } else {
        for (var i = this.state.startIndex; i <= this.state.data.length; i++) {
          this.state.data[i - 1].text = this.state.comments;
          if (this.state.data.length === i) {
            this.setState({
              count: 0,
            });
          }
        }
      }

      await this.setState({
        isAdd: false,
      });
      await this.getCoordinatePoints();
    }
  };

  updateComments = async () => {
    if (this.state.data.length >= 0) {
      console.log(this.state.startIndex);
      // await this.state.data.map((item, index) => {
      //   if(this.state.startIndex <= index){
      //     item.text = this.state.comments;

      //     // if (this.state.startIndex <= index && this.state.endIndex >= index) {
      //     //   debugger
      //     //   console.log(item);
      //     // }
      //   }
      // });
      if (this.state.count === 0) {
        await this.state.data.map((item, index) => {
          if (this.state.index === index) {
            item.text = this.state.comments;
          }
        });
      } else {
        for (var i = this.state.startIndex; i <= this.state.data.length; i++) {
          this.state.data[i - 1].text = this.state.comments;
          if (this.state.data.length === i) {
            this.setState({
              count: 0,
            });
          }
        }
      }
      await this.getCoordinatePoints();
    }
  };

  handleSetPoint = async (e, point, pageX, pageY) => {
    this.setState({
      comments: "",
    });
    const coords = {
      text: "",
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      iconType: this.state.roleId,
    };
    let array = [];
    array.push(coords);

    await this.setState({
      data: [...this.state.data, ...array],
      isAdd: true,
      isEdit: false,
    });
    await this.getCoordinatePoints();
    await this.handleCommitPoint();
  };

  handleCommitPoint = () => {
    if (this.state.count <= 0) {
      this.setState({
        startIndex: this.state.data.length,
        count: this.state.count + 1,
      });
    } else {
      this.setState({
        endIndex: this.state.data.length,
        count: this.state.count + 1,
      });
    }
  };

  handleGetPoint = async (area, index) => {
    await this.setState({
      comments: area.name,
      index: index,
      count: 0,
      pointType: area.icon,
      isEdit: true,
      isAdd: false,
    });
  };

  getCoordinatePoints = async () => {
    let annotationPointArray = [];
    let annotationPointObj = {};
    if (this.state.data.length >= 0) {
      this.state.MAP.areas = [];
      this.state.data.map((item) => {
        if (item.pageX !== undefined) {
          annotationPointObj = {
            name: item.text,
            left: item.x - 17,
            top: item.y - 17,
            bottom: item.y - 17,
            right: item.x - 17,
            text: "",
            icon: item.iconType,
          };
        } else {
          annotationPointObj = {
            name: item.text,
            left: item.x - 17,
            top: item.y - 17,
            bottom: item.y - 17,
            right: item.x - 17,
            text: "",
            icon: item.iconType,
          };
        }
        annotationPointArray.push(annotationPointObj);
      });
      let array = [...this.state.MAP.areas, ...annotationPointArray];
      let obj = { name: "my-map", areas: array };
      await this.setState({
        MAP: obj,
      });
    }
  };

  toggle = () => {
    this.setState({
      isEdit: false,
    });
    this.props.toggle();
  };

  handleAnnonationMode = async () => {
    await this.setState((prevState) => ({
      isAnnotation: !prevState.isAnnotation,
    }));
  };

  handleDisplay = async () => {
    await this.setState({
      display_status: !this.state.display_status,
    });
  };

  deleteAnnonation = async (deleteItemIndex) => {
    if (deleteItemIndex !== "") {
      if (this.state.data.length >= 0) {
        await this.state.data.map((item, index) => {
          if (deleteItemIndex === index) {
            this.state.data.splice(index, 1);
          }
        });
      }
      await this.getCoordinatePoints();
    }
    await this.setState({
      comments: "",
      isAdd: false,
      isEdit: false,
    });
  };

  handleSubmit = async () => {
    const readerType = await localStorage.getItem("roleId");
    const token = await localStorage.getItem("token");
    const data = {
      patientId: this.state.patientId,
      annotationPoint: this.state.data.length > 0 ? this.state.data : [],
      signedUrl: this.state.previewItem.signedUrl,
      readerType: readerType,
    };
    let isBtnSubmit = true;
    if (this.state.data.length > 0) {
      await this.state.data.map((item, index) => {
        if (item.text === "") {
          isBtnSubmit = false;
        } else {
          isBtnSubmit = true;
        }
      });
    }
    //console.log(data);
    if (isBtnSubmit) {
      this.setState({
        isLoading: true,
      });
      PatientService.addORUpdateAnnotation(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  this.setState({
                    isEdit: false,
                    isAnnotation: !this.state.isAnnotation,
                  });
                  //console.log(JSON.stringify(result));
                  this.setState({
                    isLoading: false,
                  });
                  this.props.toggle();
                } else {
                  alert(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
              this.setState({
                isLoading: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      alertDangerConfirmMessage("please enter feedback");
    }
  };

  handleToolTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  handleOuterToolTip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  render() {
    console.log(this.state);
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} className="px-0">
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.patientName} : {this.state.patientId}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody
                className="modal-content-container"
                onClick={() => this.handleOuterToolTip()}
              >
                <div className="tooltip_container">
                  {this.state.display_status ? (
                    <div>
                      <span href="#" id="DisabledAutoHideExample">
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          color={`#72d389`}
                          size="1x"
                          className="fs-22px"
                        />
                      </span>
                      <Tooltip
                        placement="left"
                        isOpen={this.state.tooltipOpen}
                        autohide={false}
                        target="DisabledAutoHideExample"
                        toggle={() => this.handleToolTip()}
                        className="px-0"
                        trigger="click"
                      >
                        <div className="bg-white px-0">
                          <div className="d-flex align-item-center justify-content-between">
                            <div>
                              <p className="p-small mb-0 internal-section__title title-black fw-800 pt-4px">
                                Project Reader
                              </p>
                            </div>
                            <div>
                              <img
                                src={applicationConstants.pjIcon}
                                className="tooltip-img"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-item-center justify-content-between">
                            <div>
                              <p className="p-small mb-0 internal-section__title title-danger fw-800 pt-4px">
                                Senior Reader
                              </p>
                            </div>
                            <div>
                              <img
                                src={applicationConstants.srIcon}
                                className="tooltip-img"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-item-center justify-content-between">
                            <div>
                              <p className="p-small mb-0 internal-section__title title-theme-color fw-800 pt-4px">
                                Junior Reader
                              </p>
                            </div>
                            <div>
                              <img
                                src={applicationConstants.jrIcon}
                                className="tooltip-img"
                              />
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                {this.state.display_status ? (
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      ms={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="m-auto px-0"
                    >
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                          <p className="mb-0 internal-section__title title-black fw-800">
                            {this.state.fileName}
                          </p>
                        </Col>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="text-right cursor-pointer"
                          onClick={this.handleAnnonationMode}
                        >
                          <FontAwesomeIcon
                            icon={
                              !this.state.isAnnotation
                                ? faToggleOn
                                : faToggleOff
                            }
                            size="2x"
                            color={`${
                              !this.state.isAnnotation ? "#72d389" : "#000000"
                            }`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <p className="mb-0 internal-section__title title-theme-color fw-800 align-right pb-12px pt-8px">
                            {!this.state.isAnnotation ? "Annotation" : "View"}
                          </p>
                        </Col>
                      </Row>

                      <Row className="mt-2 mb-4 m-auto">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="m-auto pd-12px br-4px br-light-smoke max-width-100 no-margin d-flex justify-content-center"
                        >
                          <ZoomImage
                            src={
                              this.state.previewItem.signedUrl != undefined
                                ? this.state.previewItem.signedUrl
                                : this.state.previewItem.path
                            }
                            isAnnotation={this.state.isAnnotation}
                            mappingPoint={this.state.MAP}
                            setPoint={(e, point, pageX, pageY) =>
                              this.handleSetPoint(e, point, pageX, pageY)
                            }
                            getPoint={(area, index) =>
                              this.handleGetPoint(area, index)
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-4 mt-4">
                        {this.state.comments !== "" ? (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={11}
                            xl={11}
                            className="m-auto px-0"
                          >
                            {this.state.roleId === this.state.pointType ? (
                              <p className="mb-0 title-danger align-right pb-8px">
                                <font
                                  className="cursor-pointer opensans-semiBold"
                                  onClick={() =>
                                    this.deleteAnnonation(this.state.index)
                                  }
                                >
                                  Delete
                                </font>
                              </p>
                            ) : null}
                          </Col>
                        ) : null}
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="m-auto"
                        >
                          <Input
                            type="text"
                            placeholder="add annotation"
                            onChange={this.handleChange}
                            name="comments"
                            className="form-control"
                            value={this.state.comments}
                            readOnly={
                              this.state.roleId === this.state.pointType
                                ? false
                                : true
                            }
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={12}
                      ms={12}
                      md={10}
                      lg={10}
                      xl={10}
                      className="m-auto px-0"
                    >
                      <Row className="no-margin">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="m-auto px-0"
                        >
                          {this.state.roleId ===
                          applicationConstants.ProjectReader ? (
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block custom-grey-btn custom-btn--small"
                                  onClick={this.handleDisplay}
                                >
                                  Grade
                                </Button>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block  custom-theme-btn custom-btn--small"
                                  onClick={this.handleSubmit}
                                >
                                  Save{" "}
                                </Button>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                  className="btn btn-block custom-theme-btn custom-btn--small"
                                  onClick={this.handleSubmit}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <ProjectReaderGrade
                    signedUrl={this.state.previewItem.signedUrl}
                    toggle={this.handleDisplay}
                  />
                )}
                {this.state.isLoading ? <Loader /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default PreviewAnnonation;
