const DashboardAbbrevationInfo = [
  {
    label: "REG",
    value: "Registration",
  },
  {
    label: "HI",
    value: "Health Information",
  },
  {
    label: "REF",
    value: "Refraction",
  },
  {
    label: "IMG",
    value: "Imaging Grading",
  },
  {
    label: "OP-GR",
    value: "Optometrist Grading",
  },
  {
    label: "DR-GR",
    value: "Doctor Grading",
  },
  {
    label: "TELE-GR",
    value: "Tele Grading",
  },
  {
    label: "JR-RD",
    value: "Junior Reader",
  },
  {
    label: "SR-RD",
    value: "Senior Reader",
  },
  {
    label: "PJ-RD",
    value: "Project Reader",
  },
];
const StatusColorInfo = [
  {
    colorCode: "#42afef",
    value: "Ready for Junior Reader",
  },
  {
    colorCode: "#dc3545",
    value: "Forwarded to Senior Reader",
  },
  {
    colorCode: "#72d389",
    value: "Forwarded to Project Reader by Senior Reader",
  },
  {
    colorCode: "#000000",
    value: "Forwarded to Project Reader by Junior Reader",
  },
  {
    colorCode: "#848484",
    value: "Reviewed by all the Readers",
  },
];
const DiagnosisAbbrevationInfo = [
  {
    label: "DR",
    value: "Diabetic Retinopathy",
  },
  {
    label: "RDR",
    value: "Referable Diabetic Retinopathy",
  },
  {
    label: "NDPR",
    value: "Non-proliferative Diabetic Retinopathy",
  },
  {
    label: "PDR",
    value: "Proliferative Diabetic Retinopathy",
  },
  {
    label: "DME",
    value: "Diabetic macular edema",
  },
];

const RefractionAbbrevationInfo = [
  {
    label: "BCVA",
    value: "Best-corrected visual acuity",
  },
  {
    label: "IOL with PCO",
    value: "Intraocular lens with posterior capsular opacification",
  },
  {
    label: "ELC",
    value: "Early lens charges",
  },
  {
    label: "CF",
    value: "Counting fingers",
  },
  {
    label: "PL",
    value: "Perception of light",
  },
  {
    label: "NA",
    value: "Not applicable",
  },
];

const HealthInfoAbbrevationInfo = [
  {
    label: "AYUSH",
    value: "Ayurvedic, Yoga and Naturopathy, Unani, Siddha and Homeopathy.",
  },
];

export {
  DashboardAbbrevationInfo,
  DiagnosisAbbrevationInfo,
  RefractionAbbrevationInfo,
  HealthInfoAbbrevationInfo,
  StatusColorInfo,
};
