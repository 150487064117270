import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Button,
  Row,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import PatientService from "../../../../api/services/PatientService";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faTimes,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardAbbrevationInfo } from "../../utils/AbbrevationInfo";
import QuickHelpMessage from "../../customComponents/confirmationMessage/QuickHelpMessage";
import Loader from "../../customComponents/Loader/Loader";
import CampService from "../../../../api/services/CampService";
import applicationConstants from "../../constants/applicationContants";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import MessageConfirmation from "../../customComponents/MessageConfirmation/MessageConfirmation";

const AllPatientList = (props) => {
  const [patientList, setPatientList] = useState([]);
  const [campLocation, setCampLocation] = useState("");
  const [campDate, setCampDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isQuickHelp, setIsQuickHelp] = useState(false);
  const [campStatus, setCampStatus] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [id, setId] = useState('');
  const [isRefresh, setIsRefresh]= useState(false);

  React.useEffect(() => {
    getCampList();
  }, []);

  React.useEffect(() => {
    getPatinetList();
  },[isRefresh])

  const getCampList = async () => {
    const token = await localStorage.getItem("token");
    const hospitalName = await localStorage.getItem("hospitalName");
    const roleId = await localStorage.getItem("roleId");
    const campStatus = await localStorage.getItem("campStatus");
    const data = roleId === applicationConstants.admin ? "" : hospitalName;
    await setIsLoading(true);
    await CampService.getCampList(token, data)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                const currentCamp = await data.find(
                  (item) => item.campStatus === 1 && item.currentCamp === 1
                );
                const setCurrentCamp = await data.find(
                  (item) => item.campStatus === 1 && item.hospitalName === hospitalName
                );
                if (currentCamp) {
                  await localStorage.setItem(
                    "campLocation",
                    currentCamp.location
                  );
                  await localStorage.setItem("campDate", currentCamp.date);
                  await localStorage.setItem(
                    "currentCamp",
                    currentCamp.campCode
                  );
                  setCampLocation(currentCamp.location);
                  setCampDate(currentCamp.date);
                  setCampStatus(campStatus);
                } else {
                  if (setCurrentCamp) {
                    setCampLocation(setCurrentCamp.location);
                    setCampDate(setCurrentCamp.date);
                    setCampStatus(campStatus);
                    await localStorage.setItem(
                      "campLocation",
                      setCurrentCamp.location
                    );
                    await localStorage.setItem("campDate", setCurrentCamp.date);
                    await localStorage.setItem(
                      "currentCamp",
                      setCurrentCamp.campCode
                    );
                  }
                }
                await getPatinetList();

              } else {
                await setIsLoading(false);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            setIsLoading(false);
            console.log(err);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPatinetList = async () => {

    const token = await localStorage.getItem("token");
    const currentCamp = await localStorage.getItem("currentCamp");
    const data = {
      campId: currentCamp ? currentCamp : "",
      status: "",
      startDate: "",
      endDate: "",
      limit: "",
      offset: "",
      hospitalName: "",
    };
    PatientService.getPatinetList(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0 && result.data !== undefined) {
                setPatientList([...result.data]);
                setFilterData([...result.data]);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                setPatientList([]);
                setFilterData([]);
              }
            });
          } catch (err) {
            setIsLoading(false);
            console.log(err);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleClick = async (patientId) => {
    console.log(patientId)
    await localStorage.setItem("patientId", `${patientId}`);
    props.history.push("/Dashboard");
  };

  const handleQuickHelp = () => {
    setIsQuickHelp(!isQuickHelp);
  };

  const onChange = async (event) => {
    setFilterText(event.target.value);
    await filterList(event.target.value);
  };

  const filterList = (filterText) => {
    const filteredItems = filterData.filter(
      (item) =>
        (item.patientId &&
          item.patientId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.patientName &&
          item.patientName.toLowerCase().includes(filterText.toLowerCase()))
    );

    if (filteredItems.length >= 0) {
      setPatientList([...filteredItems]);
    } else {
      setPatientList([...filterData]);
    }
  };

  const handleClear = () => {
    setPatientList([...filterData]);
    setFilterText("");
  };

  const handleDeletePatient = (id) => {
    setIsConfirmModal(!isConfirmModal);
    setId(id);
  }

  const handleCancelBtn = () => {
    setIsConfirmModal(!isConfirmModal)
  }

  const handleConfirmBtn = async () => {
    const token = await localStorage.getItem("token");
    if (id) {
      PatientService.deletePatient(token, id)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  setIsConfirmModal(!isConfirmModal);
                  setIsRefresh(!isRefresh);
                  setId('');
                  alertSuccessMessage(`Patient deleted  successfully`)
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <RenderPage
      id="patinet-list-page"
      className="render-page"
      containerType="container-fluid"
    >
      <Strip
        id="tst-patient-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row className="no-margin"> 
          <Col xs={12} sm={12} md={10} lg={12} xl={12} className="m-auto">
          <Row className="pt-8px pb-8px">
              <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn btn-secondary"
                  onClick={() => handleClick("")}
                  disabled={campStatus === "0" ? "disabled" : ""}
                >
                  <span>+</span> New Patient
                </Button>
              </Col>
            </Row>

            <Row className="no-margin mt-3">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="bg-theme-color br-top-lr-4px title-sec bg-org"
              >
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={11} xl={11} className="p-0">
                    <p className="p-large p-0 mb-0 internal-page__title title-white align-left">
                      Patient List
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={1}
                    lg={1}
                    xl={1}
                    className="d-flex justify-content-end cursor-pointer q-ico"
                    onClick={() => handleQuickHelp()}
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={`#fff`}
                      size="2x"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="p-3 no-margin bg-white">
              <Col xs={12} sm={8} md={8} lg={8} xl={8} className="px-0 mb-3">
                <p className="p-large internal-page__title mb-0 text-capitalize pat-loc align-left">
                  {campLocation}
                </p>
                <p className="p-large internal-page__title mb-0 align-left pat-date">
                  {campDate}
                </p>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} xl={4} className="px-0">
                <InputGroup>
                  <Input
                    placeholder="Search"
                    name="filterText"
                    value={filterText}
                    onChange={onChange}
                  />
                  <InputGroupAddon addonType="append" onClick={handleClear}>
                    <InputGroupText className="cursor-pointer">
                      <FontAwesomeIcon
                        icon={filterText ? faTimes : faSearch}
                        color="white"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <div className={`card-container__position scoll-bar`}>
              {patientList.length > 0 ? (
                <Row className="pb-8px no-margin bg-white">
                  {patientList.map((item, index) => (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="card-contianer patient-sec"
                      key={index}
                    >
                      <Row className="align-items-center">
                        <Col xs={12} sm={12} md={12} lg={2} xl={2} onClick={() => handleClick(`${item.patientId}`)}>
                          <p className="align-left pat-na">
                            <font className="title">{item.patientName} </font>
                            <font className="sub-title">{item.patientId}</font>
                          </p>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={9} xl={9} onClick={() => handleClick(`${item.patientId}`)}>
                            <div className="d-flex ll-pa-dd">
                              <div>
                                <p
                                  className={`mb-0 ${item.registrationStatus === 1
                                    ? "status-active status-text--small"
                                    : "status-inactive status-text--small"
                                    }`}
                                >
                                  REG
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${item.healthInfoStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  HI
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${item.refractionStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  REF
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${item.REimagingStatus === 1 ||
                                    item.LEimagingStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  IMG
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${item.optoAdviceStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  OP-GR
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${(item.campDrREStatus === 1 ||
                                    item.campDrLEStatus === 1) &&
                                    item.campDrAdviceStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  DR-GR
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`mb-0 ${(item.teleDrREStatus === 1 ||
                                    item.teleDrLEStatus === 1) &&
                                    item.teleDrAdviceStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  TELE-GR
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div>
                              {/* <div>
                                <p
                                  className={`mb-0 ${item.jrReaderGradingStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  JR-RD
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div> */}
                              {/* <div>
                                <p
                                  className={`mb-0 ${item.srReaderGradingStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  SR-RD
                                  <font className="status-divider-plr">|</font>
                                </p>
                              </div> */}
                              {/* <div>
                                <p
                                  className={`mb-0 ${item.prReaderGradingStatus === 1
                                    ? "status-active  status-text--small"
                                    : "status-inactive  status-text--small"
                                    }`}
                                >
                                  PJ-RD
                                </p>
                              </div> */}
                            </div>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={1}
                          xl={1}
                          className="d-flex justify-content-end cursor-pointer delete-ico"
                          onClick={() => handleDeletePatient(item.patientId)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color={`#dc3545`}
                            size="1x"
                          />
                        </Col>
                      </Row>
                    
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="pt-8px pb-8px no-margin align-items-center card-container__position br-light-smoke br-4px">
                  {!isLoading ? (
                    <Col>
                      <p className="mb-0 internal-page__title text-center">
                        No Patients
                      </p>
                    </Col>
                  ) : (
                    <Col>
                      <Loader isLoader={false} />
                    </Col>
                  )}
                </Row>
              )}
            </div>

           
          </Col>
        </Row>
        {isQuickHelp ? (
          <QuickHelpMessage
            isIcon={false}
            isModal={isQuickHelp}
            data={DashboardAbbrevationInfo}
            toggle={handleQuickHelp}
          />
        ) : null}
        {isConfirmModal ? (
          <MessageConfirmation
            isModal={isConfirmModal}
            handleCancelBtn={() => handleCancelBtn()}
            handleConfirmBtn={() => handleConfirmBtn()}
            message={`Are you sure you want to delete this patient?`}
          />
        ) : null}
      </Strip>
    </RenderPage>
  );
};

export default withRouter(AllPatientList);
