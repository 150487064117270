import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Strip from "../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { alertDangerConfirmMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import HospitalService from "../../../../api/services/HospitalService";
import FormValidator from "../../utils/FormValidator";
import IsInputInError from "../../utils/isInputInError";
import Loader from "../../customComponents/Loader/Loader";
import { MocDocType } from "../../utils/dropdownData";//"../../utils/dropdownData";

class MocDoc extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      
    ]);

    this.state = {
      PhId: props.data ? props.data.name : "",
      mobileNo: props.data ? props.data.hospitalBranchName : "",
      isLoading: false,
      filterShow:0,
      search_by:0,
    };
    console.log(this.state)
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
   
  }



  handleChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSearchBy = (item) => {    
    console.log(item)
    this.setState({
      search_by: item,
      filterShow : (item.value === 1) ? 1 :  (item.value === 2) ? 2 : 0
    });
  };
  

  

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              // toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      Fetch Patient Info
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.handleCancelMoc}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form>
                    <FormGroup row className="mx-0 align-items-center">
                    <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Fetch By*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          
                           <Select
                              value={this.state.search_by}
                              onChange={this.handleSearchBy}
                              options={MocDocType}
                              classNamePrefix="common-select-drop-down"
                              name="search_by"
                              isSearchable={false}
                            />
                            
                        </Col>
                    </FormGroup>
                    {(this.state.filterShow === 1) && (
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            PhId*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          {/* <div
                            className={IsInputInError(
                              validation.hospitalName.isInvalid
                            )}
                          > */}
                            <Input
                              type="text"
                              placeholder="PhId"
                              id="PhId"
                              name="PhId"
                              className="form-control common-form-control"
                              value={this.state.PhId}
                              onChange={this.handleChange}
                            />
                            {/* <p className="p-small help-block">
                              {validation.hospitalName.message}
                            </p>
                          </div> */}
                        </Col>
                      </FormGroup>)}
                      {(this.state.filterShow === 2) && (
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Mobile No*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          {/* <div
                            className={IsInputInError(
                              validation.branchCode.isInvalid
                            )}
                          > */}
                            <Input
                              type="text"
                              placeholder="Mobile No"
                              id="mobileNo"
                              name="mobileNo"
                              className="form-control common-form-control"
                              value={this.state.mobileNo}
                              onChange={this.handleChange}
                            />
                            {/* <p className="p-small help-block">
                              {validation.branchCode.message}
                            </p>
                          </div> */}
                        </Col>
                      </FormGroup>
                      )}
                     
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.handleCancelMoc}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={() => this.props.handleConfirm(this.state)}
                              // disabled={!this.validator.canSubmit(this.state)}
                            >
                              {this.state.isNew ? "Add" : "Fetch"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default MocDoc;
