import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Strip from "../../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import HospitalService from "../../../../../api/services/HospitalService";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import Loader from "../../../customComponents/Loader/Loader";

class AddHospitalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "hospitalName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select Hospital Name",
          },
        ],
        touched: false,
      },
      {
        field: "branchCode",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter branch Name",
          },
          // {
          //   method: "isLength",
          //   validWhen: true,
          //   message: "Branch Code should be  max 6 characters",
          //   args: [{ max: 6 }],
          // },
        ],
        touched: false,
      },
      {
        field: "location",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter location",
          },
        ],
        touched: false,
      },
      {
        field: "city",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter city",
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
      hospitalName: props.data ? props.data.name : "",
      branchCode: props.data ? props.data.hospitalBranchName : "",
      location: props.data ? props.data.location : "",
      city: props.data ? props.data.city : "",
      isNew: props.isNew ? props.isNew : false,
      stateList: props.stateList ? props.stateList : [],
      stateListId: props.data
      ? props.stateList[props.state_name] !== undefined
        ? props.stateList[props.state_name]
        : ""
      : "",
      hospitalId: props.data ? props.data.hospitalId : "",
      isLoading: false,
    };
    console.log(this.state)
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.hospitalName) {
      this.validator.touched("hospitalName");
    }
    if (this.state.location) {
      this.validator.touched("location");
    }
    if (this.state.branchCode) {
      this.validator.touched("branchCode");
    }
    if (this.state.city) {
      this.validator.touched("city");
    }
    if (this.state.state) {
      this.validator.touched("state");
    }

  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleStateList = (item) => {
    this.validator.touched("state");
    this.setState({
      stateListId: item,
    });
  };

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const data = {
        hospitalId: this.state.hospitalId,
        name: this.state.hospitalName,
        branchCode: this.state.branchCode,
        location: this.state.location,
        state: this.state.stateListId.value,
        city: this.state.city,
      };
      await this.setState({
        isLoading: true
      })
      HospitalService.addOrUpdateHospital(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  await this.setState({
                    isLoading: false
                  })
                  this.props.toggle(); 
                } else {
                  await this.setState({
                    isLoading: false
                  })
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false
              })
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          })
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.isNew ? "Add Hospital" : "Update Hospital"}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Hospital Name*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.hospitalName.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="hospital Name"
                              id="hospitalName"
                              name="hospitalName"
                              className="form-control common-form-control"
                              value={this.state.hospitalName}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.hospitalName.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Branch Code*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.branchCode.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="branch code"
                              id="branchCode"
                              name="branchCode"
                              className="form-control common-form-control"
                              value={this.state.branchCode}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.branchCode.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">State:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            // className={IsInputInError(
                            //   validation.state.isInvalid
                            // )}
                          >
                            <Select
                              value={this.state.stateListId}
                              onChange={this.handleStateList}
                              options={this.state.stateList}
                              classNamePrefix="common-select-drop-down"
                              name="state"
                              // isDisabled={
                              //   this.state.campStatus.value === 0 ? true : false
                              // }
                              isSearchable={false}
                            />
                            {/* <p className="p-small help-block">
                              {validation.state.message}
                            </p> */}
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Location*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.location.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Location"
                              id="location"
                              name="location"
                              className="form-control common-form-control"
                              value={this.state.location}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.location.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            City*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.city.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="city"
                              id="city"
                              name="city"
                              className="form-control common-form-control"
                              value={this.state.city}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.city.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={this.handleSubmit}
                              disabled={!this.validator.canSubmit(this.state)}
                            >
                              {this.state.isNew ? "Add" : "Update"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default AddHospitalInfo;
