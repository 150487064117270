import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const UserService = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getUserList: async (token) => {
    const { baseUrl, getUsers } = ApiConfig;

    const url = baseUrl + getUsers;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const userServicePromise = Fetch(
      url,
      "GET",
      headers,
      "",
      "services_UserService",
      true
    );
    return userServicePromise;
  },

  addOrUpdateUser: async (data, token, prefixUrl) => {
    const { baseUrl } = ApiConfig;

    const url = baseUrl + prefixUrl;

    const params = JSON.stringify({
      loginId: `${data.loginId}`,
      password: `${data.password}`,
      fullName: `${data.fullName}`,
      mobileNumber: `${data.mobileNumber}`,
      gender: data.gender,
      roleId: data.roleId,
      status: data.status,
      emailId: data.emailId,
      hospitalBranchName: `${data.hospitalBranchName}`
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addOrUpdateCampServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addOrUpdateCampService",
      true
    );
    return addOrUpdateCampServicePromise;
  },

  changePassword: async (data, token) => {
    const { baseUrl, changePassword } = ApiConfig;

    const url = baseUrl + changePassword;

    const params = JSON.stringify({
      loginId: `${data.loginId}`,
      oldPassword: `${data.oldPassword}`,
      newPassword: `${data.newPassword}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const changePasswordServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_changePasswordService",
      true
    );
    return changePasswordServicePromise;
  },

  resetPassword: async (data, token) => {
    const { baseUrl, resetPassword } = ApiConfig;

    const url = baseUrl + resetPassword;

    const params = JSON.stringify({
      loginId: `${data.loginId}`,
      roleId: `${data.roleId}`,
      password: `${data.password}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const resetPasswordServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_resetPasswordService",
      true
    );
    return resetPasswordServicePromise;
  },

  getUserProfile: async (token, loginId) =>{
    
    const {baseUrl, getProfile}=ApiConfig;

    const url = baseUrl + getProfile;

    const params = JSON.stringify({
      loginId: `${loginId}`,
    })

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const userProfilePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "service_ProfileService",
      true
    );
    return userProfilePromise;
  },

  updatePro: async (data, token) =>{
      const { baseUrl, updateProfile} = ApiConfig;
      
      const url = baseUrl + updateProfile;

      const params = JSON.stringify({
        loginId: `${data.loginId}`,
        fullName: `${data.fullName}`,
        emailId: `${data.emailId}`,
        mobileNumber: `${data.mobileNumber}`
      });

      const headers = new Headers({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: `${token}`,
      });

      const updateProfileSettingPromise = Fetch(
        url,
        "POST",
        headers,
        params,
        "service_updateProfileset",
        true
      );
      return updateProfileSettingPromise;
  },


};

export default UserService;
