import React from "react";
import memoizeOne from "memoize-one";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TablesColumns = memoizeOne(( handleDelete) => [
  {
    name: "Video Name",
    selector: "tut_name",
    sortable: false,
    cell: (row) => <div>{row.tut_name}</div>,    
  },
  {
    name: "Video Description",
    selector: "tut_description",
    sortable: false,
    cell: (row) => <div>{row.tut_description}</div>,
  },
  {
    name: "Video Category",
    selector: "tut_category",
    sortable: false,
    cell: (row) => <div>{row.tut_category}</div>,
  },
  {
    name: "Video Source",
    selector: "video_url" || "youtube_url",
    sortable: false,
    cell: (row) => <div>{row.video_url || row.youtube_url}</div>,
  },
  {
    name: "Thumbnail",
    selector: "thumbnail",
    sortable: false,
    cell: (row) => <div>{row.thumbnail}</div>,
  },  
  //    cell: (row) => <div onClick={() => handleAction(row)}>{row.thumbnail}</div>,
  // {
  //   name: "Status",
  //   selector: "status",
  //   sortable: false,   
  //   cell: (row) => (
  //     <div>
  //       {row.active_status === 1 ? (
  //         <p className="mb-0 active-btn">Active</p>
  //       ) : (
  //         <p className="mb-0 inactive-btn">Inactive</p>
  //       )}
  //     </div>
  //   ),
  // },
  {
    name: "Action",
    selector: "",
    grow: 0.2,
    sortable: false,
    ignoreRowClick: true,
    allowOverflow: true,
    cell: (row) => (
        <div className="d-flex">
            <div onClick={() => handleDelete(row)}>
               <p className="mb-0 inactive-btn thrash-ico"> <FontAwesomeIcon icon={faTrashAlt} className="profile-icon" /></p>
            </div>
        </div>
    ),
},
]);

export default TablesColumns;
