import React from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../constants/applicationContants";

class CapturedImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectMedia: props.selectMedia.length > 0 ? props.selectMedia : "",
      title: props.title ? props.title : "",
      diagnosisType: props.diagnosisType ? props.diagnosisType : "",
      dilationType: props.dilationType ? props.dilationType : "",
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.selectMedia != prevProps.selectMedia) {
      this.setState({
        selectMedia: this.props.selectMedia,
        title: this.props.title,
        diagnosisType: this.props.diagnosisType,
        dilationType: this.props.dilationType,
      });
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        selectMedia: this.props.selectMedia,
        title: this.props.title,
        diagnosisType: this.props.diagnosisType,
        dilationType: this.props.dilationType,
      });
    }
  }

  handlePreviewImage = (item, dilationType, diagnosisType) => {
    let previewItem = [];
    previewItem.push(item);
  //  console.log(previewItem, diagnosisType, dilationType);
    this.props.handleFileUpload(previewItem, dilationType, diagnosisType);
  };

  render() {
    return (
      <Row className="no-margin">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
          <p className="mb-0 internal-page__title title-black align-left">
            Select Media for {this.state.title}
          </p>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="px-0 br-light-smoke br-4px bg-grey-light"
        >
          <Row className="no-margin">
            {this.state.selectMedia.length > 0
              ? this.state.selectMedia.map((item) => (
                  <Col xs={6} sm={6} md={3} lg={3} xl={3} className="my-2 pd-1">
                    {item.type === "image/png" || item.type === "image/jpeg" ? (
                      <img
                        src={`${item.base64}`}
                        onClick={() =>
                          this.handlePreviewImage(
                            item,
                            this.state.dilationType,
                            this.state.diagnosisType
                          )
                        }
                        className="cursor-pointer captured-image-container"
                      />
                    ) : (
                      <div className="position-relative">
                        <img
                          src={applicationConstants.videoImg}
                          className="cursor-pointer captured-image-container"
                        />
                        <div
                          className="video__container"
                          onClick={() =>
                            this.handlePreviewImage(
                              item,
                              this.state.dilationType,
                              this.state.diagnosisType
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPlayCircle}
                            size={"2x"}
                            color={`#72d389`}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                ))
              : null}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default CapturedImage;
