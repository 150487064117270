import clear from "../img/clear.png";
import logo from "../img/logo.jpg";
import search from "../img/search.png";
import logoutIcon from "../img/logout.png";
import videoImg from '../img/video.png';
import annonationImg from '../img/annotation_point.png';
import jrIcon from '../img/jricon.png';
import srIcon from '../img/sricon.png';
import pjIcon from '../img/pjicon.png';
import loginImg from '../img/login-img.jpg';
import RegIcon from '../img/reg-ico.png';
import healIcon from '../img/health-info.png';
import reficon from '../img/refraction.png';
import reicon from '../img/re.png';
import leicon from '../img/le.png';
import opicon from '../img/optomerist.png';
import adicon from '../img/advice.png';
import exicon from '../img/excel-ico.svg';
import pdficon from '../img/pdf-ico.svg';
import patcount from '../img/patient-count.png';
import hospico from '../img/hospital.png';
import campusico from '../img/campus.png';
import userico from '../img/user-ico.png';


const applicationConstants = {
  clearIcon: clear,
  logoImg: logo,
  loginImg: loginImg,
  regicon: RegIcon,
  helicon: healIcon,
  reficon: reficon,
  reicon: reicon,
  pdficon:pdficon,
  leicon: leicon,
  opicon: opicon,
  adicon: adicon,
  exicon: exicon,
  patcount: patcount,
  campusico: campusico,
  userico: userico,
  hospico: hospico,
  searchIcon: search,
  logoutIcon: logoutIcon,
  addUser: 'addUser',
  updateUser: 'updateUser',
  admin: 'Admin',
  optometrist: 'Optometrist',
  doctorAtCamp:'Doctor at Camp',
  doctorAtTele: 'Doctor at Tele',
  juniorReader: 'Junior Reader',
  seniorReader: 'Senior Reader',
  ProjectReader: 'Project Reader',
  ApiSuccessCode: 0,
  optoRE: 'optoRE',
  optoLE: 'optoLE',
  AtCampRE: 'AtCampRE',
  AtCampLE: 'AtCampLE',
  CampTitle: '(Camp-Grading)',
  AtTeleRE: 'AtTeleRE',
  AtTeleLE: 'AtTeleLE',
  TeleTitle: '(Tele-Grading)',
  LEDiagnosis: 'LE Diagnosis',
  REDiagnosis: 'RE Diagnosis',
  DiagnosisTitle: '(Optometrist)',
  optoAdvice: 'optoAdvice',
  teleAdvice: 'teleAdvice',
  campAdvice: 'campAdvice',
  optometristAdvice: 'Optometrist Advice',
  AtCampAdvice: 'Camp Doctor’s Advice',
  AtTeleAdvice: 'Teleophthalmologist’s Advice',
  All: 'All',
  completed: 'Completed',
  mobileBreakPoint: 1023,
  videoImg: videoImg,
  annonationImg: annonationImg,
  forgotPassword: 'forgotPassword',
  jrIcon: jrIcon,
  srIcon: srIcon,
  pjIcon: pjIcon,

  registration: 'Registration',
  dashboard: 'Dashboard',
  healthDetails:'HealthDetails',
  refraction: 'Refraction',
  rightEye: 'RightEye',
  leftEye:'LeftEye',
  OptometristAdvice: 'OptometristAdvice',
  atCampAdvice: 'AtCampAdvice',
  atTeleAdvice: 'AtTeleAdvice',
  report: 'Report',
  imagingAndGrading: 'ImagingAndGrading',
  grading: 'Grading',
  patientList: 'PatientList',
};

export default applicationConstants;
