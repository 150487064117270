import React from "react";
import { Row, Col } from "reactstrap";
import PatientService from "../../../../api/services/PatientService";
import {
  lensStatusData,
  DRData,
  optometristAdviceData,
  optometristReferralData,
  userRoleData,
  YesOrNoUnknowData,
  durationData,
  medicationData,
  medicationTypeData,
  comorbiditiesData,
  BCVADistanceData,
  BCVANearData,
} from "../.../../../utils/dropdownData";
import { displayMonthNameWithDate } from "../../utils/displayFormatter";
import applicationConstants from "../../constants/applicationContants";
import { alertDangerConfirmMessage } from "../confirmationMessage/ConfirmationMessage";
import ReaderSummary from "../ReaderSummary/ReaderSummary";
import Loader from "../../customComponents/Loader/Loader";
import HealthInfoReport from "./Component/HealthInfoReport";
import RefractionReport from "./Component/RefractionReport";
import AdviceReport from "./Component/AdviceReport";
import ImagingReport from "./Component/ImagingReport";

class ReportDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patientName: "",
      campId: "",
      campLocation: "",
      campDate: "",
      REBCVADistance: "",
      REBCVALogMar: "",
      REBCVANear: "",
      RELensStatus: "",
      LEBCVADistance: "",
      LEBCVALogMar: "",
      LEBCVANear: "",
      LELensStatus: "",
      REDRGrading: "",
      LEDRGrading: "",
      adivce: "",
      referral: "",
      roleId: "",
      readerSummary: "",
      jrReaderLists: "",
      srReaderLists: "",
      reportedBy: "",
      preDilationREMedia: [],
      postDilationREMedia: [],
      preDilationLEMedia: [],
      postDilationLEMedia: [],
      bloodPressure: "",
      bloodSugar: "",
      height: "",
      weight: "",
      hypertension: "",
      diabetes: "",
      duration: "",
      familyHistory: "",
      medication: "",
      medicationType: "",
      coMorbidities: "",
      isRE: false,
      isLE: false,
      REReason: "",
      LEReason: "",
      isLoading: false,
      isOptoReview: false,
      isTeleReview: false,
      isCampReview: false,
    };
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const roleId = await localStorage.getItem("roleId");
    const campDate = await localStorage.getItem("campDate");
    if (patientId) {
      await this.setState({
        isLoading: true,
        campDate: campDate,
      });
      PatientService.getPatientBasedOnId(token, patientId)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  let data = result.data;
                  if (data.registerInfo) {
                    this.setState({
                      patientId: data.patientId,
                      campId: data.campId,
                      campLocation: data.campLocation,
                      patientName: data.registerInfo.patientName,
                      campDate: data.campDate,
                    });
                  }
                  if (data.healthInfo) {
                    console.log(data.healthInfo);
                    const coMorbiditiesArrayList = [];
                    const coMorbiditiesItem = JSON.parse(
                      data.healthInfo.coMorbidities
                    );
                    if (coMorbiditiesItem) {
                      coMorbiditiesItem.map((item) => {
                        coMorbiditiesArrayList.push(comorbiditiesData[item]);
                      });
                    }
                    this.setState({
                      bloodPressure: data.healthInfo.bloodPressure,
                      bloodSugar: data.healthInfo.bloodSugar,
                      height: data.healthInfo.height,
                      weight: data.healthInfo.weight,
                      hypertension:
                        YesOrNoUnknowData[data.healthInfo.hypertension].label,
                      diabetes:
                        YesOrNoUnknowData[data.healthInfo.diabetes].label,
                      duration: durationData[data.healthInfo.duration].label,
                      familyHistory:
                        YesOrNoUnknowData[data.healthInfo.familyHistory].label,
                      medication:
                        medicationData[data.healthInfo.medication].label,
                      medicationType:
                        medicationTypeData[data.healthInfo.medicationType]
                          .label,
                      coMorbidities:
                        coMorbiditiesArrayList.length > 0
                          ? coMorbiditiesArrayList
                          : this.state.coMorbidities,
                    });
                  }
                  if (data.readerGrading.length > 0) {
                    let Jrlists = [];
                    let srList = [];
                    data.readerGrading.map((item) => {
                      if (
                        item.readerType === applicationConstants.juniorReader
                      ) {
                        Jrlists.push(item);
                      } else if (
                        item.readerType === applicationConstants.seniorReader
                      ) {
                        srList.push(item);
                      }
                    });
                    this.setState({
                      jrReaderLists: Jrlists,
                      srReaderLists: srList,
                      readerSummary: data.readerGrading,
                    });
                  }

                  if (data.refractionInfo) {
                    this.setState({
                      REBCVADistance:
                        data.refractionInfo.REBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.REBCVADistance]
                              .label
                          : -1,
                      REBCVALogMar:
                        data.refractionInfo.REBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.REBCVADistance]
                              .logmar
                          : -1,
                      REBCVANear:
                        data.refractionInfo.REBCVANear !== -1
                          ? BCVANearData[data.refractionInfo.REBCVANear].label
                          : -1,
                      RELensStatus:
                        data.refractionInfo.REBCVANear !== -1
                          ? lensStatusData[data.refractionInfo.REBCVALensStatus]
                              .label
                          : "",
                      LEBCVADistance:
                        data.refractionInfo.LEBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.LEBCVADistance]
                              .label
                          : -1,
                      LEBCVALogMar:
                        data.refractionInfo.LEBCVADistance !== -1
                          ? BCVADistanceData[data.refractionInfo.LEBCVADistance]
                              .logmar
                          : -1,
                      LEBCVANear:
                        data.refractionInfo.LEBCVANear !== -1
                          ? BCVANearData[data.refractionInfo.LEBCVANear].label
                          : -1,
                      LELensStatus:
                        data.refractionInfo.LEBCVALensStatus !== -1
                          ? lensStatusData[data.refractionInfo.LEBCVALensStatus]
                              .label
                          : "",
                      isRE: data.refractionInfo.REReason !== "" ? true : false,
                      isLE: data.refractionInfo.LEReason !== "" ? true : false,
                      REReason: data.refractionInfo.REReason,
                      LEReason: data.refractionInfo.LEReason,
                    });
                  }

                  //imaging and grading
                  let REDRGradingArray = [];
                  let LEDRGradingArray = [];
                  //optoRE Diagnosis
                  if (data.optoREDiag) {
                    if (
                      data.optoREDiag.REPreDilationImaging != "undefined" &&
                      data.optoREDiag.REPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoREDiag.REPreDilationImaging
                      );
                      let REPreImgList = [];
                      if (
                        preDilationImage !== "[]" &&
                        preDilationImage !== ""
                      ) {
                        preDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            REPreImgList.push(item);
                          }
                        });
                        this.setState({
                          preDilationREMedia: REPreImgList,
                        });
                      }
                    }
                    if (
                      data.optoREDiag.REPostDilationImaging != "undefined" &&
                      data.optoREDiag.REPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoREDiag.REPostDilationImaging
                      );
                      let REPostImgList = [];
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        postDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            REPostImgList.push(item);
                          }
                        });
                        this.setState({
                          postDilationREMedia: REPostImgList,
                        });
                      }
                    }
                  }
                  //OptoLE Diagnosis
                  if (data.optoLEDiag) {
                    if (
                      data.optoLEDiag.LEPreDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPreDilationImaging != "null"
                    ) {
                      const preDilationImage = JSON.parse(
                        data.optoLEDiag.LEPreDilationImaging
                      );
                      let LEPreImgList = [];
                      if (
                        preDilationImage !== "[]" &&
                        preDilationImage !== ""
                      ) {
                        preDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            LEPreImgList.push(item);
                          }
                        });
                        this.setState({
                          preDilationLEMedia: LEPreImgList,
                        });
                      }
                    }
                    if (
                      data.optoLEDiag.LEPostDilationImaging != "undefined" &&
                      data.optoLEDiag.LEPostDilationImaging != "null"
                    ) {
                      const postDilationImage = JSON.parse(
                        data.optoLEDiag.LEPostDilationImaging
                      );
                      let LEPostImgList = [];
                      if (
                        postDilationImage != "[]" &&
                        postDilationImage != ""
                      ) {
                        postDilationImage.map((item) => {
                          if (
                            item.quadrant === "Disc Centered" ||
                            item.quadrant === "Macula Centered"
                          ) {
                            LEPostImgList.push(item);
                          }
                        });
                        this.setState({
                          postDilationLEMedia: LEPostImgList,
                        });
                      }
                    }
                  }

                  // DR values
                  if (data.teleDrREDiag) {
                    if (
                      data.teleDrREDiag.REDR !== "-1" &&
                      data.teleDrREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.teleDrREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                        //reportedBy: userRoleData[3].label,
                        isTeleReview: true,
                      });
                    }
                  } else if (data.campDrREDiag) {
                    if (
                      data.campDrREDiag.REDR !== "-1" &&
                      data.campDrREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.campDrREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoREDiag) {
                    if (
                      data.optoREDiag.REDR !== "-1" &&
                      data.optoREDiag.REDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.optoREDiag.REDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          REDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        REDRGrading: REDRGradingArray,
                        //reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                  }

                  if (data.teleDrLEDiag) {
                    if (
                      data.teleDrLEDiag.LEDR !== "-1" &&
                      data.teleDrLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.teleDrLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                        //reportedBy: userRoleData[3].label,
                        isTeleReview: true,
                      });
                    }
                  } else if (data.campDrLEDiag) {
                    if (
                      data.campDrLEDiag.LEDR !== "-1" &&
                      data.campDrLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.campDrLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoLEDiag) {
                    if (
                      data.optoLEDiag.LEDR !== "-1" &&
                      data.optoLEDiag.LEDR !== -1
                    ) {
                      const DRItem = JSON.parse(data.optoLEDiag.LEDR);
                      if (DRItem) {
                        DRItem.map((item) => {
                          LEDRGradingArray.push(DRData[item]);
                        });
                      }
                      this.setState({
                        LEDRGrading: LEDRGradingArray,
                        //reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                  }

                  //adivce
                  let adviceArray = [];
                  let referralArray = [];
                  if (data.teleDrAdvice) {
                    if (
                      data.teleDrAdvice.advice !== -1 &&
                      data.teleDrAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.teleDrAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                        //reportedBy: userRoleData[3].label,
                        isTeleReview: true,
                      });
                    }
                    if (
                      data.teleDrAdvice.referral !== -1 &&
                      data.teleDrAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(
                        data.teleDrAdvice.referral
                      );
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                        //reportedBy: userRoleData[3].label,
                        isTeleReview: true,
                      });
                    }
                  } else if (data.campDrAdvice) {
                    if (
                      data.campDrAdvice.advice !== -1 &&
                      data.campDrAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.campDrAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                    if (
                      data.campDrAdvice.referral !== -1 &&
                      data.campDrAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(
                        data.campDrAdvice.referral
                      );
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                        //reportedBy: userRoleData[2].label,
                        isCampReview: true,
                      });
                    }
                  } else if (data.optoAdvice) {
                    if (
                      data.optoAdvice.advice !== -1 &&
                      data.optoAdvice.advice !== "-1"
                    ) {
                      const adviceItem = JSON.parse(data.optoAdvice.advice);
                      if (adviceItem) {
                        adviceItem.map((item) => {
                          adviceArray.push(optometristAdviceData[item]);
                        });
                      }
                      this.setState({
                        adivce: adviceArray,
                        //reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                    if (
                      data.optoAdvice.referral !== -1 &&
                      data.optoAdvice.referral !== "-1"
                    ) {
                      const referralItem = JSON.parse(data.optoAdvice.referral);
                      if (referralItem) {
                        referralItem.map((item) => {
                          referralArray.push(optometristReferralData[item]);
                        });
                      }
                      this.setState({
                        referral: referralArray,
                        //reportedBy: userRoleData[1].label,
                        isOptoReview: true,
                      });
                    }
                  }
                  await this.setState({
                    isLoading: false,
                  });
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    }
    this.setState({
      roleId: roleId,
    });
  }

  render() {
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="no-mobile-plr">
          <Row className="bg-white br-4px no-margin align-items-center pt-8px pb-8px">
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <p className="mb-0 internal-section__title title-suvaGrey fw-800">
                {this.state.campLocation}
              </p>
              <p className="mb-0 internal-section__title title-theme-color fw-800">
                {this.state.campId}
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <p className="mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                {displayMonthNameWithDate(this.state.campDate)}
              </p>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className="d-flex justify-content-end"
            >
              <div>
                <img
                  src={applicationConstants.logoImg}
                  alt="appLogo"
                  className="report_logo-container"
                />
                <p className="p-very-small mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                  SANKARA
                </p>
                <p className="p-very-small mb-0 internal-section__title title-suvaGrey fw-800 align-center">
                  EYE HOSPITAL
                </p>
              </div>
            </Col>
          </Row>

          <Row className="bg-light-white pt-12px pb-12px no-mobile--margin">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="p-large mb-0 internal-section__title title-suvaGrey">
                {this.state.patientName} : {this.state.patientId}
              </p>
            </Col>
          </Row>

          {/*Health info details */}
          <HealthInfoReport
            bloodPressure={this.state.bloodPressure}
            bloodSugar={this.state.bloodSugar}
            height={this.state.height}
            weight={this.state.weight}
            hypertension={this.state.hypertension}
            diabetes={this.state.diabetes}
            duration={this.state.duration}
            familyHistory={this.state.familyHistory}
            medication={this.state.medication}
            medicationType={this.state.medicationType}
            coMorbidities={this.state.coMorbidities}
          />

          {/*Refraction  details */}
          <RefractionReport
            REBCVADistance={this.state.REBCVADistance}
            REBCVALogMar={this.state.REBCVALogMar}
            REBCVANear={this.state.REBCVANear}
            RELensStatus={this.state.RELensStatus}
            LEBCVADistance={this.state.LEBCVADistance}
            LEBCVALogMar={this.state.LEBCVALogMar}
            LEBCVANear={this.state.LEBCVANear}
            LELensStatus={this.state.LELensStatus}
            REDRGrading={this.state.REDRGrading}
            LEDRGrading={this.state.LEDRGrading}
            isRE={this.state.isRE}
            isLE={this.state.isLE}
            REReason={this.state.REReason}
            LEReason={this.state.LEReason}
            roleId={this.state.roleId}
          />

          <Row className="bg-light-white pt-12px pb-12px no-mobile--margin"></Row>

          {/*IMAGING  details */}
          <ImagingReport
            REDRGrading={this.state.REDRGrading}
            preDilationREMedia={this.state.preDilationREMedia}
            postDilationREMedia={this.state.postDilationREMedia}
            LEDRGrading={this.state.LEDRGrading}
            preDilationLEMedia={this.state.preDilationLEMedia}
            postDilationLEMedia={this.state.postDilationLEMedia}
            isRE={this.state.isRE}
            isLE={this.state.isLE}
          />

          <Row className="bg-light-white pt-12px pb-12px no-mobile--margin"></Row>

          {/*AdviceReport  details */}
          <AdviceReport
            adivce={this.state.adivce}
            referral={this.state.referral}
          />

          {this.state.roleId === applicationConstants.juniorReader ||
          this.state.roleId === applicationConstants.seniorReader ||
          this.state.roleId === applicationConstants.ProjectReader ? (
            <Row>
              {this.state.jrReaderLists.length > 0 ? (
                <Col>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row className="bg-light-white pt-12px pb-12px no-mobile--margin"></Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ReaderSummary
                        data={this.state.jrReaderLists}
                        readerType={applicationConstants.juniorReader}
                        isRE={this.state.isRE}
                        isLE={this.state.isLE}
                      />
                    </Col>{" "}
                  </Row>
                </Col>
              ) : null}
              {this.state.srReaderLists.length > 0 ? (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row className="bg-light-white pt-12px pb-12px no-mobile--margin"></Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ReaderSummary
                        data={this.state.srReaderLists}
                        readerType={applicationConstants.seniorReader}
                        isRE={this.state.isRE}
                        isLE={this.state.isLE}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>
          ) : null}

          <Row className="bg-light-white pt-16px pb-8px no-mobile--margin">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              {this.state.roleId === applicationConstants.juniorReader ||
              this.state.roleId === applicationConstants.seniorReader ||
              this.state.roleId === applicationConstants.ProjectReader ? (
                <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                  Note: This report is generated by{" "}
                  {this.state.srReaderLists.length > 0
                    ? userRoleData[5].label
                    : this.state.jrReaderLists.length > 0
                    ? userRoleData[4].label
                    : this.state.isTeleReview
                    ? userRoleData[3].label
                    : this.state.isCampReview
                    ? userRoleData[2].label
                    : this.state.isOptoReview
                    ? userRoleData[1].label
                    : ""}{" "}
                  and cannot be used as medico-legal document.
                </p>
              ) : (
                <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
                  Note: This report is generated by{" "}
                  {this.state.isTeleReview
                    ? userRoleData[3].label
                    : this.state.isCampReview
                    ? userRoleData[2].label
                    : this.state.isOptoReview
                    ? userRoleData[1].label
                    : ""}{" "}
                  and cannot be used as medico-legal document.
                </p>
              )}
            </Col>
          </Row>
        </Col>
        {this.state.isLoading ? <Loader isLoader={false} /> : null}
      </Row>
    );
  }
}

export default ReportDetails;
