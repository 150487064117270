import React from "react";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import applicationConstants from "../../../constants/applicationContants";
import Advice from "../../../customComponents/Advice/Advice";

class OptometristAdvice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adviceType: applicationConstants.optoAdvice,
      title: applicationConstants.optometristAdvice,
    };
  }

  render() {
    return (
      <RenderPage
        id="advice-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Advice title={this.state.title} adviceType={this.state.adviceType} />
      </RenderPage>
    );
  }
}
export default OptometristAdvice;
