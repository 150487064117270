import React, { useState } from "react";

import {
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import windowSize from "react-window-size";
import XlsExport from "xlsexport";
import memoizeOne from "memoize-one";
import Strip from "../../customComponents/Strip/Strip";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import DataTable from "react-data-table-component";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../utils/dataTablesStyledTheme";
import applicationConstants from "../../constants/applicationContants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import PatientService from "../../../../api/services/PatientService";
import { getTimer } from "../../utils/displayFormatter";
import Loader from "../../customComponents/Loader/Loader";
import MoreInformation from "./MoreInformation";

const TablesColumns = memoizeOne((handleAction) => [
  {
    name: "Name",
    selector: "patientName",
    sortable: false,
  },
  {
    name: "Camp ID",
    selector: "campId",
    sortable: false,
  },
  {
    name: "Registration",
    selector: "REG",
    sortable: false,
  },
  {
    name: "Health Info",
    selector: "HI",
    sortable: false,
  },
  {
    name: "Refraction",
    selector: "REF",
    sortable: false,
  },
  {
    name: "Right Eye",
    selector: "RE",
    sortable: false,
  },
  {
    name: "Left Eye",
    selector: "LE",
    sortable: false,
  },
  {
    name: "Advice",
    selector: "ADVICE",
    sortable: false,
  },
  {
    name: "",
    selector: "More Details",
    sortable: false,
    cell: (row) => (
      <div className="pl-4px pr-4px cursor-pointer">
        <p
          className="mb-0 p-small align-center internal-page__title title-theme-color cursor-pointer text-underline"
          onClick={() => handleAction(row)}
        >
          More Details
        </p>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
]);

function downloadEXcel(array) {
  var xls = new XlsExport([...array], "DB Update Time Log");
  xls.exportToXLS("DB_Update_Time_Log.xls");
}

const Export = ({ onExportExcel }) => {
  return (
    <div>
      <Button
        onClick={() => onExportExcel()}
        className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
      >
        <img
         src={applicationConstants.exicon}
         alt=""
         className="excel-ico"
        />Export Excel
      </Button>
    </div>
  );
};

const Information = (props) => {
  const [patientList, setPatientList] = useState([]);
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [infoData, setInfoData] = useState("");

  React.useEffect(() => {
    getPatientList();
  }, []);

  const handleExport = React.useMemo(
    () => <Export onExportExcel={() => downloadEXcel(excelSheetData)} />,
    [excelSheetData]
  );

  const getPatientList = async () => {
    const token = await localStorage.getItem("token");
    const campId = await localStorage.getItem("currentCamp");
    const data = {
      campId: campId,
      status: "",
      startDate: "",
      endDate: "",
      limit: "",
      offset: "",
      hospitalName: "",
    };

    setIsLoading(true);

    PatientService.getPatinetList(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0 && result.data != undefined) {
                //console.log(JSON.stringify(result));
                let data = [];
                let obj = {};
                let excel={}
                let excelData = [];
                result.data.map((item, index) => {
                  obj = {
                    patientName: item.patientName,
                    campId: item.campId,
                    REG:
                      item.registrationDuration !== "null" &&
                      item.registrationDuration !== "0" &&
                      item.refractionInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.registrationDuration))
                        : "",
                    HI:
                      item.healthInfoDuration !== "null" &&
                      item.healthInfoDuration !== "0" &&
                      item.healthInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.healthInfoDuration))
                        : "",
                    REF:
                      item.refractionInfoDuration !== "null" &&
                      item.refractionInfoDuration !== "0" &&
                      item.refractionInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.refractionInfoDuration))
                        : "",
                    RE:
                      item.REDuration !== "null" &&
                      item.REDuration !== "0" &&
                      item.REDuration !== "undefined"
                        ? getTimer(parseInt(item.REDuration))
                        : "",
                    LE:
                      item.LEDuration !== "null" &&
                      item.LEDuration !== "0" &&
                      item.LEDuration !== "undefined"
                        ? getTimer(parseInt(item.LEDuration))
                        : "",
                    ADVICE:
                      item.adviceDuration !== "null" &&
                      item.adviceDuration !== "0" &&
                      item.adviceDuration !== "undefined"
                        ? getTimer(parseInt(item.adviceDuration))
                        : "",
                    LEPostDeviceId: JSON.parse(item.LEPostDeviceId),

                    LEPreDeviceId: JSON.parse(item.LEPreDeviceId),

                    REPostDeviceId: JSON.parse(item.REPostDeviceId),

                    REPreDeviceId: JSON.parse(item.REPreDeviceId),
                  };
                  excel = {
                    patientName: item.patientName,
                    campId: item.campId,
                    REG:
                      item.registrationDuration !== "null" &&
                      item.registrationDuration !== "0" &&
                      item.refractionInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.registrationDuration))
                        : "",
                    HI:
                      item.healthInfoDuration !== "null" &&
                      item.healthInfoDuration !== "0" &&
                      item.healthInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.healthInfoDuration))
                        : "",
                    REF:
                      item.refractionInfoDuration !== "null" &&
                      item.refractionInfoDuration !== "0" &&
                      item.refractionInfoDuration !== "undefined"
                        ? getTimer(parseInt(item.refractionInfoDuration))
                        : "",
                    RE:
                      item.REDuration !== "null" &&
                      item.REDuration !== "0" &&
                      item.REDuration !== "undefined"
                        ? getTimer(parseInt(item.REDuration))
                        : "",
                    LE:
                      item.LEDuration !== "null" &&
                      item.LEDuration !== "0" &&
                      item.LEDuration !== "undefined"
                        ? getTimer(parseInt(item.LEDuration))
                        : "",
                    ADVICE:
                      item.adviceDuration !== "null" &&
                      item.adviceDuration !== "0" &&
                      item.adviceDuration !== "undefined"
                        ? getTimer(parseInt(item.adviceDuration))
                        : "",
                  };

                  data.push(obj);
                  excelData.push(excel)
                });
                setPatientList([...data]);
                setFilterData([...data]);
                setExcelSheetData([...excelData]);
                setIsLoading(false);
              } else {
                setIsLoading(false);
                console.log(`${result.message}`);
              }
            });
          } catch (err) {
            setIsLoading(false);
            console.log(err);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onChange = async (event) => {
    setFilterText(event.target.value);
    await handlefilterData(event.target.value);
  };

  const handlefilterData = async (filterText) => {
    const filteredItems = await filterData.filter(
      (item) =>
        (item.patientName &&
          item.patientName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.campId &&
          item.campId.toLowerCase().includes(filterText.toLowerCase()))
    );
    if (filteredItems.length >= 0) {
      setPatientList([...filteredItems]);
    } else {
      setPatientList([...filterData]);
    }
  };

  const handleClear = () => {
    setPatientList([...filterData]);
    setFilterText("");
  };

  const handleAction = (item) => {
    setInfoData(item);
    setIsModal(!isModal);
  };

  const toggle = () => {
    setIsModal(!isModal);
  };
  return (
    <RenderPage
      id="patinet-info-page"
      className="render-page"
      containerType="container-fluid"
    >
      <Strip
        id="tst-patient-info"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row className="justify-content-end">
                  <Col xs={10} sm={10} md={8} lg={5} xl={3}>
                    <InputGroup>
                      <Input
                        placeholder="Search"
                        name="filterText"
                        value={filterText}
                        onChange={onChange}
                      />
                      <InputGroupAddon addonType="append" onClick={handleClear}>
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="no-margin">
              {patientList.length > 0 ? (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="data-table-container"
                >
                  <DataTable
                    title=""
                    columns={TablesColumns(handleAction)}
                    data={patientList}
                    pagination
                    className="data-table"
                    persistTableHead={false}
                    customStyles={
                      props.windowWidth > applicationConstants.mobileBreakPoint
                        ? dataTablesStyledTheme
                        : dataTablesMobileStyledTheme
                    }
                    actions={handleExport}
                    // conditionalRowStyles={conditionalRowStyles}
                  />
                </Col>
              ) : (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="no-mobile--padding"
                >
                  <div className="br-light-smoke p-5 br-4px">
                    {!isLoading ? (
                      <p className="mb-0 align-center internal-page__title">
                        No Data Found
                      </p>
                    ) : (
                      <Loader isLoader={false} />
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {isModal ? (
          <MoreInformation
            data={infoData}
            toggle={toggle}
            isModal={isModal}
            windowWidth={props.windowWidth}
          />
        ) : null}
      </Strip>
    </RenderPage>
  );
};

export default windowSize(Information);
