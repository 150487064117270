import AWS from "aws-sdk";
import { decode } from "base64-arraybuffer";
import { AwsConfig } from "../config/AwsConfig";
import axios from "axios";

async function s3UploadFile(file) {
  const credentials = {
    accessKeyId: AwsConfig.accessKeyId,
    secretAccessKey: AwsConfig.secretAccessKey,
    region: AwsConfig.bucketRegion,
  };

  const base64 = file.uri.split(",")[1];
  const arrayBuffer = await decode(base64);

  AWS.config.update({ credentials, region: AwsConfig.bucketRegion });
  const s3 = new AWS.S3({ apiVersion: "2006-03-01", credentials });

  const params = {
    Bucket: AwsConfig.bucketName,
    Key: file.name,
    Body: arrayBuffer,
    ACL: "public-read",
    ServerSideEncryption: "AES256",
    StorageClass: "STANDARD_IA",
  };
  return new Promise(async function (resolve, reject) {
    await s3.putObject(params, function (err, data) {
      if (err) {
        //  Alert.alert('Failure uploaded package', err);
        reject(err);
      } else {
       // console.log("JSO", data);
        //  Alert.alert('Successfully uploaded package.');
        resolve({
          data: data,
          file: file,
          status: 200,
        });
      }
    });
  });
}

async function getObjectList(dirName) {
  const credentials = {
    accessKeyId: AwsConfig.accessKeyId,
    secretAccessKey: AwsConfig.secretAccessKey,
    region: AwsConfig.bucketRegion,
  };

  AWS.config.update({ credentials });
  const s3 = new AWS.S3({ apiVersion: "2006-03-01", credentials });

  const params = {
    Bucket: AwsConfig.bucketName,
    Prefix: dirName,
  };


  return new Promise(function (resolve, reject) {
    s3.listObjectsV2(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve({
          data: data,
          status: 200,
        });
      }
    });
  });
}

async function generatePresignedUrl(key) {
  const credentials = {
    accessKeyId: AwsConfig.accessKeyId,
    secretAccessKey: AwsConfig.secretAccessKey,
    region: AwsConfig.bucketRegion,
  };

  AWS.config.update({ credentials, region: AwsConfig.bucketRegion });
  const s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    credentials,
    signatureVersion: "v4",
    region: AwsConfig.bucketRegion,
  });

  let params = {
    Bucket: AwsConfig.bucketName,
    Key: key,
    // Expires: 60 * 5,
  };

  try {
    const url = await s3.getSignedUrl("getObject", params);
    console.log("presigned url obtained from s3: ", url);
    const data = {
      url: url,
      status: 200,
    };
    return data;
  } catch (err) {
    console.log("error call during call s3 ".concat(err));
    throw err;
  }
}

async function deleteItem(key) {
  const credentials = {
    accessKeyId: AwsConfig.accessKeyId,
    secretAccessKey: AwsConfig.secretAccessKey,
    region: AwsConfig.bucketRegion,
  };

  AWS.config.update({ credentials, region: AwsConfig.bucketRegion });
  const s3 = new AWS.S3({ apiVersion: "2006-03-01", credentials });

  var params = {
    Bucket: AwsConfig.bucketName,
    Key: key,
  };
  return new Promise(function (resolve, reject) {
    s3.deleteObject(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
      //  console.log("_________", data);
      }
      resolve({
        data: data,
        status: 200,
      });
    });
  });
}

async function renameS3File(oldKey, newKey) {
  const credentials = {
    accessKeyId: AwsConfig.accessKeyId,
    secretAccessKey: AwsConfig.secretAccessKey,
    region: AwsConfig.bucketRegion,
  };

  AWS.config.update({ credentials });
  const s3 = new AWS.S3({ apiVersion: "2006-03-01", credentials });
  var params = {
    Bucket: AwsConfig.bucketName,
    CopySource: `${AwsConfig.bucketName}/${oldKey}`,
    Key: newKey,
  };

  return new Promise(function (resolve, reject) {
    s3.copyObject(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
       // console.log("_________", data);
      }
      //console.log("_________newdata", data);
      resolve({
        data: data,
        status: 200,
      });
    });
  });
}


async function s3UploadPDFFile(file) {
  try {
    const s3 = new AWS.S3({
      accessKeyId: AwsConfig.accessKeyId,
      secretAccessKey: AwsConfig.secretAccessKey,
      region: AwsConfig.bucketRegion,
    });

    const response = await axios.get(file.uri, {
      responseType: 'arraybuffer',
    });

    if (response.status === 200) {
      const params = {
        Bucket: AwsConfig.bucketName,
        Key: file.name,
        Body: response.data,
        ACL: 'public-read', // You can adjust the access control here
        ContentType: 'application/pdf', // Set the content type for PDF files
      };

      const result = await s3.upload(params).promise();

      return {
        data: result,
        file: file,
        status: 200, // HTTP status code indicating a successful upload
      };
    } else {
      console.error('Failed to fetch PDF content:', response.status);
      return {
        data: null,
        file: file,
        status: response.status,
      };
    }
  } catch (error) {
    console.error('Error uploading PDF to S3:', error);
    return {
      data: null,
      file: file,
      status: 500, // You can customize the error status code
    };
  }
}


export {
  s3UploadFile,
  getObjectList,
  generatePresignedUrl,
  deleteItem,
  renameS3File,
  s3UploadPDFFile,
};
