import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import Strip from "../../customComponents/Strip/Strip";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import UserService from "../../../../api/services/UserService";
import { userRoleData } from "../../utils/dropdownData";
import LoginService from "../../../../api/services/LoginService";
import { validPassword } from "../../utils/customValidators";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      hiddenCurrent: true,
      hiddenNew: true,
      hiddenConfirm: true,
      invalid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.showPasswordCurrent = this.showPasswordCurrent.bind(this);
    this.showPasswordConfirm = this.showPasswordConfirm.bind(this);
    this.showPasswordNew = this.showPasswordNew.bind(this);
  }

  // ----------------VALIDATION OF INPUT FIELDS---------------------
  handleChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleConfirmPassword(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    this.confirmValidation();
  }

  async handlePassword(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    this.confirmValidation();
  }

  confirmValidation = async () => {
    if (this.state.newPassword && this.state.confirmPassword) {
      if (this.state.newPassword === this.state.confirmPassword) {
        await this.setState({
          isMatching: false,
          isValid: true,
        });
      } else {
        await this.setState({
          isMatching: true,
          isValid: false,
        });
      }
    } else {
      await this.setState({
        isMatching: false,
        isValid: false,
      });
    }
  };

  // ---------- displaying password ---------------
  showPasswordCurrent() {
    this.setState({
      hiddenCurrent: !this.state.hiddenCurrent,
    });
  }

  showPasswordNew() {
    this.setState({
      hiddenNew: !this.state.hiddenNew,
    });
  }

  showPasswordConfirm() {
    this.setState({
      hiddenConfirm: !this.state.hiddenConfirm,
    });
  }
  // -----------Submit function-----------------------

  async handleSubmit() {
    const token = await localStorage.getItem("token");
    const loginId = await localStorage.getItem("loginId");

    const { newPassword, confirmPassword } = this.state;
    if (newPassword !== confirmPassword) {
      await this.setState({
        isMatching: true,
        isValid: false,
      });
    } else if (this.state.oldPassword !== this.state.newPassword) {
      const data = {
        loginId: loginId,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
      };

      UserService.changePassword(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  alertSuccessMessage(`${result.message}`);
                  await setTimeout(async () => {
                    await this.handleLogin(loginId, this.state.newPassword);
                  }, 1000);
                } else {
                  alertDangerConfirmMessage(`Current Password is wrong`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(
        `New password is same as Current password, Please try again`
      );
    }
  }

  handleLogin = async (email, password) => {
    await LoginService.login(email, password)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                await localStorage.setItem("token", result.data.token);
                await localStorage.setItem("isLogin", true);
                await localStorage.setItem("loginId", result.data.loginId);
                await localStorage.setItem("userName", result.data.fullName);
                await localStorage.setItem(
                  "mobileNumber",
                  result.data.mobileNumber
                );

                await localStorage.setItem(
                  "roleId",
                  userRoleData[result.data.roleId].label
                );
                alertSuccessMessage(`login successful`);
                await localStorage.setItem("isLogin", true);
                this.props.history.push("/");
              } else {
                // alert(`${result.message}`);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      isMatching,
      isValid,
    } = this.state;

    return (
      <RenderPage
        id="imaging-grading-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-imaging-grading"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="m-0 justify-content-center align-items-center change-password-container">
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={5}
              xl={5}
              className="align-items-center justify-content-center"
            >
              <Form className="bg-light p-5 change-password-form-container bg-white">
                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">
                      Current Password
                    </Label>
                    <InputGroup>
                      <Input
                        type={this.state.hiddenCurrent ? "password" : "text"}
                        placeholder="Current Password"
                        id="oldPassword"
                        name="oldPassword"
                        value={this.state.oldPassword}
                        onChange={this.handleChange}
                        className="form-control common-form-control"
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.showPasswordCurrent}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon icon={faEyeSlash} color="white" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <p
                      className={`p-small help-block ${
                        this.state.oldPassword != "" &&
                        validPassword(this.state.oldPassword)
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Please enter valid password
                    </p>
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">New Password</Label>
                    <InputGroup>
                      <Input
                        type={this.state.hiddenNew ? "password" : "text"}
                        placeholder="New Password"
                        id="newPassword"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={this.handlePassword}
                        className="form-control common-form-control"
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.showPasswordNew}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon icon={faEyeSlash} color="white" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <p
                      className={`p-small help-block error-txt ${
                        this.state.newPassword != "" &&
                        validPassword(this.state.newPassword)
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Password should be min 6 characters long, at least one uppercase, one lowercase, and one special char
                    </p>
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">
                      Confirm Password
                    </Label>
                    <InputGroup>
                      <Input
                        type={this.state.hiddenConfirm ? "password" : "text"}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={this.handleConfirmPassword}
                        invalid={isMatching}
                        valid={isValid}
                        className="form-control common-form-control"
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.showPasswordConfirm}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon icon={faEyeSlash} color="white" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <p
                      className={`p-small help-block error-txt ${
                        this.state.confirmPassword != "" && isMatching
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Password not matching
                    </p>
                  </Col>
                </FormGroup>

                <Row className="justify-content-center mb-2 mt-4">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Button
                      className="btn btn-block custom-theme-btn"
                      onClick={this.handleSubmit}
                      disabled={
                        oldPassword != "" && newPassword != "" && isValid
                          ? false
                          : true
                      }
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    );
  }
}
export default withRouter(ChangePassword);
