import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import FormValidator from "../../../utils/FormValidator";
import DataTable from "react-data-table-component";
import applicationConstants from "../../../constants/applicationContants";
import { dataTablesStyledTheme } from "../../../utils/dataTablesStyledTheme";
import { dataTablesMobileStyledTheme } from "../../../utils/dataTablesStyledTheme";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";
import TablesCatColumns from "./TablesCatColumns";
import windowSize from "react-window-size";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import IsInputInError from "../../../utils/isInputInError";
import { SearchByType,BCVADistanceData,BCVANearData,YesOrNoUnknowData,durationData,medicationTypeData,comorbiditiesData,QuestionareData,optometristAdviceData,optometristReferralData } from "../../../utils/dropdownData";
import CampService from "../../../../../api/services/CampService";
import VisionCenterService from "../../../../../api/services/VisionCenterService";
import ReportService from "../../../../../api/services/ReportService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ApiConfig } from "../../../../../api/apiConfig/apiConfig";
import moment from "moment";

const handleGender = (value) => {
 
  if(value === 0){
    return "Male";
  } else if(value === 1){
    return "Female";
  } else if(value === 2){
    return "Trans";
  } else{
    return null;
  }

}
const handleYesOrNoUnknowData = (value) => {
  let near = YesOrNoUnknowData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleDuration = (value) => {
  let near = durationData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleMedType = (value) => {
  let near = medicationTypeData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleComorbidities = (value) => {
  let comorbiditiesStr = '';
  let comorbidities = JSON.parse(value)
  for (var i = 0; i < comorbidities.length; i++){
    let near = comorbiditiesData.filter(function (el) { return el.value === comorbidities[i] });
    comorbiditiesStr += (i > 0) ? ", " : "";
    comorbiditiesStr += near[0].label;
  }

  return comorbiditiesStr;
  
}

const handleBVCADistance = (value) => {
  console.log("distance",value);
  let distance = BCVADistanceData.filter(function (el) {
    return el.value === value;}
  );
  return distance[0].label;
}

const handleBVCANear = (value) => {
  let near = BCVANearData.filter(function (el) {
    return el.value === value;}
  );
  return near[0].label;
}

const handleQuestionareData = (value) => {
  console.log(value)
  let Questionar = QuestionareData.filter(function (el) {
    return el.value === value;}
  );
  return Questionar[0].name;
}

const handleOptoAdvice = (value) => {
  let optoAdviceStr = '';
  console.log(value);
  const optoAdvice = JSON.parse(value)
  for (var i = 0; i < optoAdvice.length; i++){
    let near = optometristAdviceData.filter(function (el) { return el.value === optoAdvice[i] });
    optoAdviceStr += (i > 0) ? ", " : "";
    optoAdviceStr += near[0].label;
  }

  return optoAdviceStr;
  
}

const handleOptoReferral = (value) => {
  let optoReferralStr = '';
  console.log(value);
  const optoReferral = JSON.parse(value)
  for (var i = 0; i < optoReferral.length; i++){
    let near = optometristReferralData.filter(function (el) { return el.value === optoReferral[i] });
    optoReferralStr += (i > 0) ? ", " : "";
    optoReferralStr += near[0].label;
  }

  return optoReferralStr;
  
}

class ReportList extends React.Component {
  
  constructor(props) {
    
    super(props);
    this.validator = new FormValidator([
      {
        field: "search_by",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please choose search_by",
          },
        ],
        touched: false,
      },
      {
        field: "visioncenter",
        // constraints: [
        //   {
        //     method: "isEmpty",
        //     validWhen: false,
        //     message: "Please choose Vision Center",
        //   },
        // ],
        touched: false,
      },
      {
        field: "startDate",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Select start date",
          },
        ],
        touched: false,
      },
      {
        field: "endDate",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Select end date",
          },
        ],
        touched: false,
      },
    ]);

    this.state = {
        people: [
          { name: "Keanu Reeves", profession: "Actor" },
          { name: "Lionel Messi", profession: "Football Player" },
          { name: "Cristiano Ronaldo", profession: "Football Player" },
          { name: "Jack Nicklaus", profession: "Golf Player" },
        ],
     
      reportList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: "",
      search_by:0,
      filterShow:0,
      isConfirmModal: false,
      deleteItem: '',
      id: '',
      catstatus: 0,
      campDetails: [],
      visionCenterList: [],
      visionCenterData : [],
      campData : [],
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      date: props.data
        ? props.data.date
          ? props.data.date
          : new Date()
        : new Date(),
           
    };
    this.campList = this.campList.bind(this);
    this.getVisionCenterList = this.getVisionCenterList.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
    this.handleExportExcel = this.handleExportExcel.bind(this); 
  }

  async componentDidMount() {
    const catstatus = await localStorage.getItem("catstatus");
    this.campList();
    this.getVisionCenterList();
    await this.setState({
      isLoading: false,
      catstatus: catstatus,
    })

    if (this.state.search_by) {
      this.validator.touched("search_by");
    }
    // if (this.state.visioncenter) {
    //   this.validator.touched("visioncenter");
    // }
   

  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isModal !== prevProps.isModal) {
     
      this.campList();
      this.getVisionCenterList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
     
      this.campList();
      this.getVisionCenterList();
    }
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }  

  

  exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Summary Report";
    const headers = [ [
      "Registration Date",
      "Name", 
      "VC-Name",
      "Camp-Name",
      "Patient ID",
      "MocDoc ID",
      "Gender",
      "Age",
      "Mobile",
      "HBP",
      "DM",
      "Duration",
      "Comorbidities",
      "BP",
      "BS",
      "RE BCVA D/N",
      "LE BCVA D/N",
      "RE Lens",
      "LE Lens",
      "OPTO RE Comments",
      "OPTO LE Comments",
      // "Optometist RE DR Grade",
      // "Optometist LE DR Grade",
      // "Optometist - RE DME",
      // "Optometist - LE DME",
      "RE DR Grade - Doctor",
      "LE DR Grade - Doctor",
      "Doctor  - RE DME",
      "Doctor  - LE DME",
      "Tele DR -Referal For",
      "Camp DR -Referal For",
      "OPTO  - Referal For",
      "OPTO  - Advice",
      "Tele DR  - Advice",
      "Camp DR  - Advice",
      "Pt Travel Time",
      "Pt Travel Cost",
      "Attender Daily Wage in Rs",
      "Annul Income in Rs",
      "Attender Accompaning",
      "Tel Consultation",
      "Fundus Imaging",      
      "RE Duration",
      "LE Duration",
      "Advice Duration",
  ]];

    const data = this.state.reportList.map(elt=> [elt.dateOfExamination,
              elt.patientName,
              (elt.visionCentreId !== "" && elt.visionCenterName !== "") ? elt.visionCentreId+'-'+elt.visionCenterName : "",
              (elt.campId !== "" && elt.campName !== "") ? elt.campId+'-'+elt.campName : "",
              elt.patientId,
              elt.mocDocPhid !== "null" && elt.mocDocPhid !== "" ? elt.mocDocPhid : "",
              handleGender(elt.gender),
              elt.age,
              elt.contactNo,
              elt.healthInfo_hypertension !== "" ? handleYesOrNoUnknowData(elt.healthInfo_hypertension) : "",
              elt.healthInfo_diabetes !== "" ? handleYesOrNoUnknowData(elt.healthInfo_diabetes) : "",
              elt.healthInfo_duration !== "" ? handleDuration(elt.healthInfo_duration) : "",
              elt.healthInfo_medicationType !== "" ? handleMedType(elt.healthInfo_medicationType) : "",
              elt.healthInfo_coMorbidities !== "" ? handleComorbidities(elt.healthInfo_coMorbidities) : "",
              elt.healthInfo_bloodPressure,
              elt.healthInfo_bloodSugar,
              (elt.refractionInfo_REBCVADistance !== "" && elt.refractionInfo_REBCVADistance !== -1) ? handleBVCADistance(elt.refractionInfo_REBCVADistance) : "",
              (elt.refractionInfo_LEBCVADistance !== "" && elt.refractionInfo_LEBCVADistance !== -1) ? handleBVCADistance(elt.refractionInfo_LEBCVADistance) : "",
              (elt.refractionInfo_REBCVALensStatus !== "" && elt.refractionInfo_REBCVALensStatus !== -1) ? handleBVCANear(elt.refractionInfo_REBCVALensStatus) : "",
              (elt.refractionInfo_LEBCVALensStatus !== "" && elt.refractionInfo_LEBCVALensStatus !== -1) ? handleBVCANear(elt.refractionInfo_LEBCVALensStatus) : "",
              elt.optoREDiag_REOther,
              elt.optoLEDiag_LEOther,
              // elt.teleDrREDiag_REDR,
              // elt.teleDrLEDiag_LEDR,
              // elt.teleDrREDiag_REDME,
              // elt.teleDrLEDiag_LEDME,
              elt.campDrREDiag_REDR,
              elt.campDrLEDiag_LEDR,
              elt.campDrREDiag_REDME,
              elt.campDrLEDiag_LEDME,
              elt.teleDrAdvice && elt.teleDrAdvice.referral !== "[]" && elt.teleDrAdvice.referral !== "" && elt.teleDrAdvice.referral !== undefined ? handleOptoReferral(elt.teleDrAdvice.referral) : "",
              elt.campDrAdvice && elt.campDrAdvice.referral !== "[]" && elt.campDrAdvice.referral !== "" && elt.campDrAdvice.referral !== undefined ? handleOptoReferral(elt.campDrAdvice.referral) : "",
              elt.optoAdvice && elt.optoAdvice.referral !== "[]" && elt.optoAdvice.referral !== "" && elt.optoAdvice.referral !== undefined ? handleOptoReferral(elt.optoAdvice.referral) : "",
              elt.optoAdvice && elt.optoAdvice.advice !== "[]" && elt.optoAdvice.advice !== "" && elt.optoAdvice.advice !== undefined ? handleOptoAdvice(elt.optoAdvice.advice) : "",
              elt.teleDrAdvice && elt.teleDrAdvice.advice !== "[]" && elt.teleDrAdvice.advice !== "" && elt.teleDrAdvice.advice !== undefined ? handleOptoAdvice(elt.teleDrAdvice.advice) : "",
              elt.campDrAdvice && elt.campDrAdvice.advice !== "[]" && elt.campDrAdvice.advice !== "" && elt.campDrAdvice.advice !== undefined ? handleOptoAdvice(elt.campDrAdvice.advice) : "",
              elt.optoAdvice_q1,
              elt.optoAdvice_q2,
              elt.optoAdvice_q3,
              elt.optoAdvice_q4,
              (elt.optoAdvice_q5 !== "" && elt.optoAdvice_q5 !== -1 && elt.optoAdvice_q5 !== undefined) ? handleYesOrNoUnknowData(elt.optoAdvice_q5):"",
              (elt.optoAdvice_q10 !== "" && elt.optoAdvice_q10 !== -1 && elt.optoAdvice_q10 !== undefined) ? handleQuestionareData(elt.optoAdvice_q10) : "",
              (elt.optoAdvice_q11 !== "" && elt.optoAdvice_q11 !== -1 && elt.optoAdvice_q11 !== undefined) ? handleQuestionareData(elt.optoAdvice_q11) : "",
              // elt.REDuration,
              // elt.LEDuration,
              // elt.adviceDuration,
            ]);


    

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }


  handleExport = () => {
    return (
      <div>
        <Button
          onClick={() => this.exportPDF()}
          className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
        >
          <img
            src={applicationConstants.exicon}
            alt=""
            className="excel-ico"
          /> Export Excel
        </Button>
      </div>
    );
  };

  handleExportExcel = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const visionCenterarr = [];
    if(this.state.visionCenterData.length > 0)
    this.state.visionCenterData.forEach((res)=>{
      visionCenterarr.push(res.value)
    })
    const campDetailsarr = [];
    if(this.state.campData.length > 0)
    this.state.campData.forEach((res)=>{
      campDetailsarr.push(res.value)
    })
    const data = {
      filterBy: this.state.search_by.value, 
      campId: campDetailsarr,//["AF20210628","AG20210629","AH20210901"],
      visionCentreId: visionCenterarr,//["19","20"],
      status: "",
      startDate: `${moment(this.state.startDate).format('DD/MM/YYYY')}`, //      dateYMD: Moment("01-07-1994").format('YYYY-MM-DD')
        endDate:`${moment(this.state.endDate).format('DD/MM/YYYY')}`, 
      // startDate: new Date(new Date().setDate(1)),
      // endDate: new Date(),
      limit: "",
      offset: "",
      hospitalName: "",      
    };   
    // const data = {
    //   filterBy: this.state.search_by.value, 
    //   campId: campDetailsarr,//["AF20210628","AG20210629","AH20210901"],
    //   visionCentreId: visionCenterarr,//["19","20"],
    //   status: "", 
    //   startDate: `${moment(this.state.startDate).format('DD/MM/YYYY')}`, //      dateYMD: Moment("01-07-1994").format('YYYY-MM-DD')
    //   endDate:`${moment(this.state.endDate).format('DD/MM/YYYY')}`, 
    //   limit: "",
    //   offset: "",
    //   hospitalName: "",      
    // }; 


    ReportService.getPatientCSV(token, data)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                 console.log("CSV",result);
                //  window.open(ApiConfig.baseUrl+result.data.downloadLink)
                const downloadUrl = ApiConfig.baseUrl + result.data.downloadLink;

                    // Fetch the file content from the URL
                    fetch(downloadUrl)
                      .then(response => response.blob())
                      .then(blob => {
                        // Create a temporary URL for the blob
                        const blobUrl = URL.createObjectURL(blob);

                        // Create an anchor element
                        const anchor = document.createElement('a');
                        anchor.href = blobUrl;

                        // Specify the filename for download (optional)
                        anchor.download = result.data.downloadLink.replace('/download/', '') // Change to your desired filename

                        // Programmatically trigger a click event on the anchor
                        anchor.click();

                        // Revoke the temporary URL to free up memory
                        URL.revokeObjectURL(blobUrl);
                      })
                      .catch(error => {
                        console.error('Error fetching or downloading the file:', error);
                      });
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

  }

  campList = async () => {
    const token = await localStorage.getItem("token");
    
    CampService.getCampList(token, "")
    .then(async (res) => {
      if (res.status === 200) {
        try {
          res.text().then(async (res) => {
           console.log(res)
            let result = JSON.parse(res);
            if (result.code === 0) {
              let data = result.data;
              let obj={};
              let camps=[];
              data.map(item => {
                obj={
                  value: `${item.campCode}`,
                  label: `${item.campCode} - ${item.location}`,
                };
                camps.push(obj);
              })
              console.log(camps);
              this.setState({
                campDetails: camps
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  getVisionCenterList = async () => {
    const token = await localStorage.getItem("token");
    await VisionCenterService.getVisionCenterList(token)
       .then(async (res) => {
         if (res.status === 200) {
           try {
             res.text().then(async (res) => {
              console.log("vision",res)
               let result = JSON.parse(res);
               if (result.code === 0) {
                 let data = result.data;
                 let obj={};
                 let visionCenter=[];
                 data.map(item => {
                   obj={
                     value: `${item.internal_id}`,
                     label: `${item.center_name}`,
                     state: `${item.state}`,
                   };
                   console.log(obj.value, obj.label)
                   visionCenter.push(obj);
                 })
                 this.setState({
                  visionCenterList: visionCenter
                 });
               }
             });
           } catch (err) {
             console.log(err);
           }
         }
       })
       .catch((err) => {
         console.log(err);
       });
   };

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };  

  handleAction = (item) => {   
    console.log(item)
    this.setState({
      category_code:item.category_code,
      category_name:item.category_name,
      id: item.id,
    })
    console.log(this.state)
  };


  handleDelete = async (item) => {
    console.log(item)
    this.setState({
      isConfirmModal: true,
      deleteItem: item,
    })
  }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }

  // handleConfirmBtn = async () => {
  //   const id = this.state.deleteItem ? this.state.deleteItem.id : '';
  //   const token = await localStorage.getItem("token");
  //   if (id) {
  //     console.log(id)
  //     Category.deleteVideoCat(token, id)
  //       .then(async (res) => {
  //         console.log(res)
  //         if (res.status === 200) {
  //           try {
  //             res.text().then(async (res) => {
  //               console.log(res)
  //               let result = JSON.parse(res);
  //               console.log(result)
  //               if (result.code === 0) {
  //                 this.setState({
  //                   isConfirmModal: false
  //                 });
  //                 alertSuccessMessage(`Video Category deleted  successfully`)
  //                 this.categoryList();
  //               } else {
  //                 alertDangerConfirmMessage(`${result.message}`);
  //               }
  //             });
  //           } catch (err) {
  //             console.log(err);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // } 

  // clear = () =>{
  //   this.setState({
  //     category_code: "",
  //     category_name: "",
  //   });
  // }
  handleSearchBy = (item) => {    
    console.log(item)
    this.validator.touched("search_by");
    this.setState({
      search_by: item,
      filterShow : (item.value === 1) ? 1 :  (item.value === 2) ? 2 : 0
    });
  };

  handleVisionChange = (item) => {    
    console.log(item)
    this.validator.touched("visioncenter");
    this.setState({
      visionCenterData: item,
    });
  };
  handleCampChange = (item) => {    
    console.log(item)
    this.setState({
      campData: item,
    });
  };

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  };
  handleEndDate = (date) => {
    this.setState({ endDate : date });
  };

  async handleSubmit() {
    this.setState({
      isLoading: true,
    });
    console.log(this.state);
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const visionCenterarr = [];
      if(this.state.visionCenterData.length > 0)
      this.state.visionCenterData.forEach((res)=>{
        visionCenterarr.push(res.value)
      })
      const campDetailsarr = [];
      if(this.state.campData.length > 0)
      this.state.campData.forEach((res)=>{
        campDetailsarr.push(res.value)
      })
      const data = {
        filterBy: this.state.search_by.value, 
        campId: campDetailsarr,//["AF20210628","AG20210629","AH20210901"],
        visionCentreId: visionCenterarr,//["19","20"],
        status: "",
        // startDate: "",//`${this.state.startDate}`,
        // endDate:"",// `${this.state.endDate}`,
        startDate: `${moment(this.state.startDate).format('DD/MM/YYYY')}`, //      dateYMD: Moment("01-07-1994").format('YYYY-MM-DD')
        endDate:`${moment(this.state.endDate).format('DD/MM/YYYY')}`, 
        limit: "",
        offset: "",
        hospitalName: "",      
      };   
      await this.setState({
        reportList : [] 
      });        
      
      console.log(data);
      ReportService.getPatientReport(token,data)
        .then(async (res) => {
          console.log("santhosh",res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                console.log(res)
                let result = JSON.parse(res);
                console.log("patient",result)
                if (result.code === 0 && result.message === "Success") {            
                  await this.setState({
                    isLoading: false,
                    reportList:result.data
                  })
                  // this.clear();
                                         
                } else {
                  this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);                  
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });   
      } 
      else {
        alertDangerConfirmMessage(`${errorMessage[0]}`);
      }
  } 

  handleBackArrow = () => {;}  
 

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <RenderPage
        id="registration-page"
        className="render-page top"
        containerType="container-fluid"
      >
        <Strip
          id="tst-registration"
          className="strip strip-no-padding video-table"
          containerType="container-fluid" 
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
              <Row className="bg-light-white bg-white p-4">               
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="bg-white no-mobile--padding p-0"
                >
                  <Form className="form-fields field-col-4">
                    <FormGroup>
                      <Label className="common-label">Search By <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.search_by.isInvalid
                      )}
                      >
                        <Select
                              value={this.state.search_by}
                              onChange={this.handleSearchBy}
                              options={SearchByType}
                              classNamePrefix="common-select-drop-down"
                              name="search_by"
                              isSearchable={false}
                            />
                        <p className="p-small help-block">
                          {validation.search_by.message}
                        </p>
                      </div>
                    </FormGroup>
                   
                    {(this.state.filterShow === 1) && ( <FormGroup><Label className="common-label">Camp Details <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.visioncenter.isInvalid
                      )}
                      >
                        <MultiSelect
                          options={this.state.campDetails}
                          value={this.state.campData}
                          onChange={this.handleCampChange}
                          name="camp_is"
                          labelledBy="Select"
                        />
                        <p className="p-small help-block">
                          {validation.visioncenter.message}
                        </p>
                      </div></FormGroup>)}
                      {(this.state.filterShow === 2) && ( <FormGroup><Label className="common-label">Vision Center <sup>*</sup></Label>
                      <div
                      className={IsInputInError(
                        validation.visioncenter.isInvalid
                      )}
                      >
                        <MultiSelect
                          options={this.state.visionCenterList}
                          value={this.state.visionCenterData}
                          onChange={this.handleVisionChange}
                          name="visioncenter"
                          labelledBy="Select"
                        />
                        <p className="p-small help-block">
                          {validation.visioncenter.message}
                        </p>
                      </div></FormGroup>)}
                     
                    


                    <FormGroup>
                      <Label className="common-label">Start Date <sup>*</sup></Label>
                      
                     
                            <DatePicker
                              onChange={this.handleStartDate}
                              value={new Date(this.state.startDate)}
                              format="dd/MM/yyyy"
                              className="date-picker-container"
                             // minDate={new Date()}
                            />
                         
                    </FormGroup>
                    <FormGroup>
                      <Label className="common-label">End Date <sup>*</sup></Label>
                      
                            <DatePicker
                              onChange={this.handleEndDate}
                              value={new Date(this.state.endDate)}
                              format="dd/MM/yyyy"
                              className="date-picker-container"
                              //minDate={new Date()}
                            />
                    </FormGroup>
                    <FormGroup className="list-btns">

                      <Button
                        className="btn custom-theme-btn save-btn"
                        onClick={this.handleSubmit}                       
                      >
                        SUBMIT
                      </Button>
                    </FormGroup>

                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
         
          <Row className="table-responsive no-margin">
          
            {this.state.reportList.length > 0 ? (<>
              <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="text-right"> {/* Use the "text-right" class to align content to the right */}
                <Button
                  onClick={() => this.exportPDF()}
                  className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
                >
                  <img
                    src={applicationConstants.pdficon}
                    alt=""
                    className="excel-ico"
                  /> Export to PDF
                </Button>
                &nbsp;
                <Button
                  onClick={(e) => this.handleExportExcel(e)}
                  className="custom-theme-btn custom-btn--small mb-1 fs-12px bg-org excel-btn"
                >
                  <img
                    src={applicationConstants.exicon}
                    alt=""
                    className="excel-ico"
                  /> Excel Export
                </Button>
              </div>
            </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <DataTable
                  title=""
                  columns={TablesCatColumns(this.handleAction, this.handleDelete)}
                  data={this.state.reportList}
                  pagination
                  className="data-table"
                  persistTableHead={false}
                  customStyles={
                    this.props.windowWidth > applicationConstants.mobileBreakPoint
                      ? dataTablesStyledTheme
                      : dataTablesMobileStyledTheme
                  }
                  // actions={handleExport}
                  />
              </Col></>
            ) : (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="no-mobile--padding"
              >
                <div className="br-light-smoke bg-white p-5 br-4px">
                  {!this.state.isLoading ? (
                    <p className="mb-0 align-center internal-page__title">
                      No Data Found
                    </p>
                  ) : (
                    <Loader isLoader={false} />
                  )}
                </div>
              </Col>
            )}
          </Row>
          {this.state.isConfirmModal ? (
            <MessageConfirmation
              isModal={this.state.isConfirmModal}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
              message={`Are you sure you want to delete this category?`}
            />
          ) : null}
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
          {/* {this.state.isLoading ? <Loader isLoader={false} /> : null} */}
        </Strip>
      </RenderPage>
    );
  }
}

export default windowSize(ReportList);
