import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const Category = {
  /**
   * calling login api
   * @param token user token to access the api
   **/

  getVideoCategories: async (token) => {
    const { baseUrl, getVideoCategories } = ApiConfig;

    const url = baseUrl + getVideoCategories;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "token": `${token}`,
    });    

    const categoryList = await Fetch(
      url,
      "GET",
      headers,
      "",
      "services_CatService",
      true
    );
    return categoryList;
  },

  addVideoCategory: async (data, token) => {
    const { baseUrl, addVideoCategory, updateVideoCategory } = ApiConfig;

    var url = baseUrl;
    if(data.id){      
      url+=updateVideoCategory;
      var category = {      
      // category_code: `${data.category_code}`,
      category_name: `${data.category_name}`,
      id: `${data.id}`,     
      };
    }else{
      url+=addVideoCategory;      
      var category = {      
      // category_code: `${data.category_code}`,
      category_name: `${data.category_name}`,      
      };
    }

    const params = JSON.stringify(category);
    

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const addVideoCategoryServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_addVideoCategoryService",
      true
    );
    return addVideoCategoryServicePromise;
  },


  updateVideoCategory: async (data, token) => {
    const { baseUrl, updateVideoCategory } = ApiConfig;

    const url = baseUrl + updateVideoCategory;

    const params = JSON.stringify({
      // campCode: `${data.campCode}`,
      category_name: `${data.category_name}`,
      id: `${data.id}`,    
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });

    const updateVideoCategoryServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_updateVideoCategoryService",
      true
    );
    return updateVideoCategoryServicePromise;
  },
  

  deleteVideoCat: async (token, id) => {
    const { baseUrl, deleteVideoCategory } = ApiConfig;

    const url = baseUrl + deleteVideoCategory;
    console.log(url)

    const params = JSON.stringify({     
      id: `${id}`,    
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${token}`,
    });


    const videoDeleteServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_VideoCatDeleteService",
      true
    );
    return videoDeleteServicePromise;
  },
};

export default Category;
