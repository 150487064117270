import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getPathName from "../../utils/getPathName";
import applicationConstants from "../../constants/applicationContants";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.getPathName = this.getPathName.bind(this);
  }

  componentDidMount() {
    let pathName = getPathName(this.props.location.pathname);
    let menuItem = this.props.menuItem.find((item) => item.link === pathName);
    if (menuItem) {
      this.props.menuSelect(menuItem.label);
    }
  }

  getPathName(name) {
    this.props.menuSelect(name);
  }

  getActiveItem = (item) => {
    if (
      item === applicationConstants.registration ||
      item === applicationConstants.dashboard ||
      item === applicationConstants.healthDetails ||
      item === applicationConstants.refraction ||
      item === applicationConstants.rightEye ||
      item === applicationConstants.leftEye ||
      item === applicationConstants.OptometristAdvice ||
      item === applicationConstants.AtCampRE ||
      item === applicationConstants.AtCampLE ||
      item === applicationConstants.atCampAdvice ||
      item === applicationConstants.AtTeleRE ||
      item === applicationConstants.AtTeleLE ||
      item === applicationConstants.atTeleAdvice ||
      item === applicationConstants.report ||
      item === applicationConstants.imagingAndGrading ||
      item === applicationConstants.grading ||
      item === ""||
      item === applicationConstants.categoryList
    ) {
      item = applicationConstants.patientList;
    }
    return item;
  };

  render() {
    let pathName = getPathName(this.props.location.pathname);
    const { menuItem, isMobileMenu, roleId } = this.props;
    return (
      <div className="sidenav-menu">
        <Nav
          className={`nav-container ${isMobileMenu ? "title-themeColor" : ""}`}
        >
          {menuItem.map((menu, index) => (
            <NavItem
              className={`${isMobileMenu ? "text-center" : ""}
              ${
                menu.link === "userList" ||
                menu.link === "info" ||
                menu.link === "hosptialInfo"||
                menu.link === "VisionInfoList"||
                menu.link === "videoList"||
                menu.link === "SummaryReport"
                  ? roleId === "Admin"
                    ? "dp-block"
                    : "dp-none"
                  : ""
              }`}
              key={index}
            >
              <Link
                to={`/${menu.link}`}
                className={`nav-link ${
                  menu.link == this.getActiveItem(pathName) ? "active" : ""
                }`}
                onClick={() => this.getPathName(menu.label)}
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={menu.icon} size="lg" />
                  <font className="pl-2">{menu.label === 'Information' ? 'Time Log' : menu.label}</font>
                </div>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    );
  }
}

export default withRouter(MenuNavigation);
