import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import Strip from "../../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import Loader from "../../../customComponents/Loader/Loader";
import Select from "react-select";
import VisionCenterService from "../../../../../api/services/VisionCenterService";

class AddVisioninfo extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "internalId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Internal ID",
          },
        ],
        touched: false,
      },
      {
        field: "centerName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Center Name",
          },
        ],
        touched: false,
      },
      {
        field: "erpName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter ERP Name",
          },
        ],
        touched: false,
      },
      {
        field: "State",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select State",
          },
        ],
        touched: false,
      },
      {
        field: "addressOne",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Address1",
          },
        ],
        touched: false,
      },
      {
        field: "addressTwo",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Address2",
          },
        ],
        touched: false,
      },
      {
        field: "location",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Location",
          },
        ],
        touched: false,
      },
      {
        field: "pinCode",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter pincode",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "pincode should be  max 6 characters",
            args: [{ max: 6 }],
          },
        ],
        touched: false,
      },
      
    ]);
    this.state = {
      id: props.data ? props.data.id : "",
      internalId: props.data ? props.data.internal_id : "",
      centerName: props.data ? props.data.center_name : "",
      erpName: props.data ? props.data.erp_name : "",
      State: props.stateList ? props.stateList : [],
      stateListId: props.data
      ? props.stateList[props.state_name] !== undefined
        ? props.stateList[props.state_name]
        : ""
      : "",
      addressOne: props.data ? props.data.address1 : "",
      addressTwo: props.data ? props.data.address2 : "",
      location: props.data ? props.data.location : "",
      pinCode: props.data ? props.data.pincode : "",
      isNew: props.isNewVision ? props.isNewVision : false,
      isLoading: false,
    };
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (this.state.internalId) {
      this.validator.touched("internalId");
    }
    if (this.state.centerName) {
      this.validator.touched("centerName");
    }
    if (this.state.erpName) {
      this.validator.touched("erpName");
    }
    if (this.state.State) {
      this.validator.touched("State");
    }
    if (this.state.addressOne) {
        this.validator.touched("addressOne");
      }
      if (this.state.addressTwo) {
        this.validator.touched("addressTwo");
      }
      if (this.state.location) {
        this.validator.touched("location");
      }
      if (this.state.pinCode) {
        this.validator.touched("pinCode");
      }
  }

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleState = (item) => {
    this.validator.touched("State");
    this.setState({
      stateListId: item,
    });
  };

  async handleSubmit() {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    if (errorMessage.length <= 0) {
      const data = {
        id: this.state.id,
        internal_id: this.state.internalId,
        erp_name: this.state.erpName,
        center_name: this.state.centerName,
        state: this.state.stateListId.value,
        address1: this.state.addressOne,
        address2: this.state.addressTwo,
        location: this.state.location,
        pincode: this.state.pinCode,
      };
      console.log(data);
      
      await this.setState({
        isLoading: true
      })
      VisionCenterService.addorUpdateVisionCenter(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  await this.setState({
                    isLoading: false
                  })
                  this.props.toggle(); 
                } else {
                  await this.setState({
                    isLoading: false
                  })
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false
              })
              // console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          })
          // console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.isNew ? "Add VisionCentre" : "Update VisionCentre"}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                          Internal ID*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.internalId.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Internal ID"
                              id="internalId"
                              name="internalId"
                              className="form-control common-form-control"
                              value={this.state.internalId}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.internalId.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                          Center Name*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.centerName.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Center Name"
                              id="centerName"
                              name="centerName"
                              className="form-control common-form-control"
                              value={this.state.centerName}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.centerName.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                          ERP name*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.erpName.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="ERP Name"
                              id="erpName"
                              name="erpName"
                              className="form-control common-form-control"
                              value={this.state.erpName}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.erpName.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">State:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.State.isInvalid
                            )}
                          >
                            <Select
                              value={this.state.stateListId}
                              onChange={this.handleState}
                              options={this.state.State}
                              classNamePrefix="common-select-drop-down"
                              name="State"
                              isSearchable={false}
                            />
                            <p className="p-small help-block">
                              {validation.State.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>          

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Address1*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.addressOne.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Address 1"
                              id="addressOne"
                              name="addressOne"
                              className="form-control common-form-control"
                              value={this.state.addressOne}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.addressOne.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Address2*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.addressTwo.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Address 2"
                              id="addressTwo"
                              name="addressTwo"
                              className="form-control common-form-control"
                              value={this.state.addressTwo}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.addressTwo.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Location*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.location.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Location"
                              id="location"
                              name="location"
                              className="form-control common-form-control"
                              value={this.state.location}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.location.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Pin Code*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.pinCode.isInvalid
                            )}
                          >
                            <Input
                              type="number"
                              placeholder="Pin Code"
                              id="pinCode"
                              name="pinCode"
                              className="form-control common-form-control"
                              value={this.state.pinCode}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.pinCode.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>        

                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={this.handleSubmit}
                              disabled={!this.validator.canSubmit(this.state)}
                            >
                              {this.state.isNew ? "Add" : "Update"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default AddVisioninfo;
