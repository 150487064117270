import React from "react";
import { Row, Col } from "reactstrap";

const AdviceReport = (props) => {
  return (
    <Row className="pt-8px pb-8px bg-white br-4px no-margin">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <p className="mb-0 internal__section-title opensans-semiBold title-theme-color">
          ADVICE
        </p>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Advice
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-wrap justify-content-end"
          >
            {props.adivce.length > 0 ? (
              props.adivce.map((item, index) => (
                <p
                  className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
                  key={index}
                >
                  {item.label}
                  <font className="pr-4px">{`${
                    props.adivce.length - 1 === index ? "" : ","
                  }`}</font>
                </p>
              ))
            ) : (
              <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                NA
              </p>
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Referral
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-wrap justify-content-end"
          >
            {props.referral.length > 0 ? (
              props.referral.map((item, index) => (
                <p
                  className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
                  key={index}
                >
                  {item.label}
                  <font className="pr-4px">{`${
                    props.referral.length - 1 === index ? "" : ","
                  }`}</font>
                </p>
              ))
            ) : (
              <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                NA
              </p>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdviceReport;
