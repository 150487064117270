import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import Strip from "../../../customComponents/Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import VideoService from "../../../../../api/services/VideoService";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import { isValidFileSize } from "../../../utils/customValidators";
// import { YesOrNo, statusData, videoCategoryData } from "../../../utils/dropdownData";
import Select from "react-select";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";


class AddVideo extends React.Component {
  constructor(props) {
    //console.log(props)
    super(props);   
    this.validator = new FormValidator([
      {
        field: "tut_name",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter video name",
          },
        ],
        touched: false,
      },
      {
        field: "tut_description",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter video description",
          },
        ],
        touched: false,
      },
      {
        field: "tut_category",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select video category",
          },
        ],
        touched: false,
      },
      // {
      //   field: "videosource",
      //   constraints: [
      //     {
      //       method: "isEmpty",
      //       validWhen: false,
      //       message: "please select Video source",
      //     },
      //   ],
      //   touched: false,
      // },

      {
        field: "thumbnail",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "please select thumbnail",
          },
          // {
          //   method: thumbnailValidate,
          //   validWhen: true,
          //   message: "please select JPEG file only",
          // },
        ],
        touched: false,
      },

    ]); 
    console.log(props)
    this.state = {   
      tut_name: props.data ? props.data.tut_name : "",      
      tut_description: props.data ? props.data.tut_description: "",
      videocategories : props.dropdownList ? props.dropdownList : [],
      tut_category : props.data ? props.dropdownList[props.category_name] !== undefined ? props.dropdownList[props.category_name] : "" : "",
      video_type: props.data ? props.data.video_type : "",
      // videocategory: props.data ? videoCategoryData[props.data.videocategory].label ? videoCategoryData[props.data.videocategory] : "" : videoCategoryData[0],
      // videosource: "",
      videosource: props.data ? props.data.youtube_url ? props.data.youtube_url : "" : "" || props.data ? props.data.video_url ? props.data.video_url : "" : "",
      thumbnail: null,
      video_url:null,
      youtube_url:"",
      video_url_valid:"",
      modal: props.isModal ? props.isModal : false,      
      isNewCamp: props.isNewCamp ? props.isNewCamp : false,    
      isLoading: false,     
      roleId: "",
      isYes: false,
      isNo: false,
      id: props.data ? props.data.id : "",
      CheckBoxOptionSelect : false,
      fileupload_edit: props.data ? props.data.video_urlvalid ? props.data.video_urlvalid : "" : "",
      thumbnailupload_edit: props.data ? props.data.thumbnail_url ? props.data.thumbnail_url : "" : "",      
      // CheckBoxOptionSelectOther: false,      
    };
    console.log("THISSTATEDES", this.state.fileupload_edit, this.state.thumbnailupload_edit)
    console.log("TUT-Name", this.state.tut_name)
    this.isValidate = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 

  async componentDidMount() {
    if (this.state.tut_name) {
      this.validator.touched("tut_name");
    }
    if (this.state.tut_description) {
      this.validator.touched("tut_description");
    }
    if (this.state.tut_category) {
      this.validator.touched("tut_category");
    }
    if (this.state.youtube_url) {
      this.validator.touched("youtube_url");
    }else if(this.state.video_url){
      this.validator.touched("video_url");
    }
    if (this.state.thumbnail) {
      this.validator.touched("thumbnail");
    }   
  } 
 
  handleChange(event) {
    
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.files ? event.target.files[0] : event.target.value,
    });
   // console.log("HANDLECHANge", [event.target.name], event.target.value)

  }

  handleChangefile = (event) =>  {   
    // if(this.state.id){
    //   console.log(this.state.id)
    //   this.setState({thumbnailupload_edit : event.target.files[0]})
    // }
    this.setState({thumbnail : event.target.files[0]});
  }

  handleChangeVideofile = (event) =>  {
    
    this.setState({video_url : event.target.files[0]});
    if (isValidFileSize(event.target.files, 100))
    {
      this.setState({
        video_url_valid : "file_size_validated"
      })
    }
  //  if(this.state.id){
  //   console.log("ID", this.state.id)
  //   this.setState({fileupload_edit: event.target.files[0]})
  //  }  
  }

  handleChangeThumb = event =>{   
    this.setState({
      thumbnail: URL.createObjectURL(event.target.files[0])
    })
  };

  handleChangeVideo = event =>{
    this.setState({
      videosource: event.target.files[0],
    })
  }

  

  // handleChangefile(event) {
  //   console.log(event.target.files);
  //   this.setState(URL.createObjectURL(event.target.files[0]));
  // }

  handleVideoCategory = (item) => {    
    console.log(item)
    this.validator.touched("tut_category");
    this.setState({
      tut_category: item,
    });
  };
  
  

  async handleSubmit() {
    // return false;
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    const token = await localStorage.getItem("token");
    //console.log(this.state)
    if (errorMessage.length <= 0) {
      const data = {  
        tut_name: this.state.tut_name,
        tut_description: this.state.tut_description,
        tut_category: this.state.tut_category.value,
        youtube_url: this.state.youtube_url,
        video_url: this.state.video_url,
        video_type: this.state.CheckBoxOptionSelect,
        // videourl: this.state.videourl,
        // youtubeurl: this.state.youtube_url,
        thumbnail: this.state.thumbnail,      
        category_code: this.state.videocategories.value,
        id: this.state.id,
        // fileupload_edit: this.state.fileupload_edit,
        // thumbnailupload_edit: this.state.thumbnailupload_edit       
      };
      console.log(data)
      
      VideoService.addOrUpdateVideoTut(data, token)
        .then(async (res) => {
          console.log(data)
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                console.log(result)
                if (result.code === 0) {
                  //console.log(JSON.stringify(result));                 
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.toggle();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }    
  } 

  handleYesorNo = async (value) => {
    //console.log(value)
    if (value) {
      this.validator.removeConstraint("video_url")
      this.validator.removeConstraint("video_url_valid")
      this.validator.addConstraint({
        field: "youtube_url",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter Youtube url code",
          },
          {
            method: "isURL",
            validWhen: true,
            message: "Please enter valid Youtube url code",
          },
        ],
        touched: false,
      })
      await this.setState({
        CheckBoxOptionSelect: value,
        // CheckBoxOptionSelectOther: value,        
        isYes: true,
        isNo: false,        
      })     
    } else {
      this.validator.removeConstraint("youtube_url")
      this.validator.addConstraint({
        field: "video_url_valid",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select file to upload within 100MB",
          },
        ],
        touched: false,
      })
      await this.setState({
        CheckBoxOptionSelect: value,
        // CheckBoxOptionSelectOther: value,        
        isNo: true,
        isYes: false,       
      });
    }
  };

  myfunction() {
    console.log("CLICKED");
}


  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      {this.state.isNewCamp ? "Add Video" : "Update Video"}
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Video Name*:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.tut_name.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Video Name"
                              id="tut_name"
                              name="tut_name"
                              className="form-control common-form-control"
                              value={this.state.tut_name}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.tut_name.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Video Description*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.tut_description.isInvalid
                            )}
                          >
                           
                            <Input
                              type="text"
                              placeholder="Video Description"
                              id="tut_description"
                              name="tut_description"
                              className="form-control common-form-control"
                              value={this.state.tut_description}
                              onChange={this.handleChange}
                            />
                            <p className="p-small help-block">
                              {validation.tut_description.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Video Category*:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.tut_category.isInvalid
                            )}
                          >
                            {/* {console.log(this.state.dropdownValues)} */}
                            
                            <Select
                              value={this.state.tut_category}
                              onChange={this.handleVideoCategory}
                              options={this.state.videocategories}
                              classNamePrefix="common-select-drop-down"
                              name="tut_category"
                              isSearchable={false}
                            />
                          
                            <p className="p-small help-block">
                              {validation.tut_category.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                      <Row className="pb-8px pt-8px">
                      <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                              <Label className="common-label display-block">
                              Video Source*:
                              </Label>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                              <Row className="no-margin">
                              <div className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={
                                  this.state.isYes
                                    ? faCheckSquare
                                    : faSquare
                                }
                                color={`#72d389`}
                                size={"2x"}
                                className={`cursor-pointer check-box ${
                                  this.state.isYes
                                    ? "sqaure-checked"
                                    : "sqaure-unchecked"
                                }`}
                                onClick={() => this.handleYesorNo(1)}
                              />

                              <font
                                className="common-label pl-12px pr-3"
                                onClick={() => this.handleYesorNo(1)}
                                name="videosource"
                              >
                                youtube
                              </font>

                              <FontAwesomeIcon
                                icon={
                                  this.state.isNo
                                    ? faCheckSquare
                                    : faSquare
                                }
                                color={`#72d389`}
                                size={"2x"}
                                className={`cursor-pointer check-box ${
                                  this.state.isNo
                                    ? "sqaure-checked"
                                    : "sqaure-unchecked"
                                }`}
                                onClick={() => this.handleYesorNo(0)}
                              />

                              <font
                                className="common-label pl-12px"
                                onClick={() => this.handleYesorNo(0)}
                                name="videosource"
                              >
                                Video File
                              </font>                              
                              </div>
                              </Row>
                              </Col>
                              </Row>
                            </FormGroup>                    
                     
                      
                      {this.state.CheckBoxOptionSelect === 1 ? (
                      <FormGroup row className="mx-0 align-items-center mt-2">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Youtube url:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.youtube_url.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Youtube URL"
                              id="youtube_url"
                              name="youtube_url"
                              className="form-control common-form-control"
                              value={this.state.youtube_url}
                              onChange={this.handleChange}
                            />                           
                            {/* <button><img src="./img/google.png" alt="my image" width="10px" height="15px" onClick={this.myfunction} /></button> */}
                            <p className="p-small help-block">
                              {validation.youtube_url.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      ) : null}

                  {this.state.CheckBoxOptionSelect === 0 ? (
                      <FormGroup row className="mx-0 align-items-center mt-2">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            File upload:
                          </Label>
                        </Col>                       
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.video_url_valid.isInvalid
                            )}
                          >                             
                            <Input
                              id="exampleFile"
                              name="video_url"
                              type="file"
                              onChange={this.handleChangeVideofile}
                            />                             
                            {/* <button><img src="./img/google.png" alt="my image" onClick={this.myfunction} /></button>                           */}
                            <Input
                              name="video_url_valid"
                              type="hidden"
                              value={this.state.video_url_valid}
                            />                        
                            <p className="p-small help-block">
                              {validation.video_url_valid.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                         ) : null}

                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            Thumbnail*:
                          </Label>
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div>
                            <Input
                              id="exampleFile"
                              name="thumbnail"
                              type="file"
                              accept="image/jpeg"   /**accept="image/x-png,image/gif,image/jpeg" */
                              onChange={this.handleChangefile}
                            />
                             {/* <img src={this.state.thumbnail} />                            */}
                            <p className="p-small help-block">
                              {validation.thumbnail.message}
                            </p>
                          </div>
                        </Col>

                      </FormGroup>
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={this.handleSubmit}
                              disabled={
                                this.state.tut_name !== "" &&
                                  this.state.tut_description !== "" &&
                                  this.state.tut_category !== "" && 
                                  (this.state.CheckBoxOptionSelect === 1 ? this.state.youtube_url !== "" : this.state.video_url !== null) &&
                                  this.state.thumbnail !== null
                                  ? false
                                  : true
                              }
                            >
                              {this.state.isNewCamp ? "Add" : "Update"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.isLoading ? <Loader isLoader={false} /> : null}
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default AddVideo;
