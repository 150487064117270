import React from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { DateFormat, dateToString } from "../../../utils/displayFormatter";
import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import PatientService from "../../../../../api/services/PatientService";
import { genderData, educationData } from "../../../utils/dropdownData";
import FormValidator from "../../../utils/FormValidator";
import IsInputInError from "../../../utils/isInputInError";
import {
  isMobileNumber,
  isText,
  ageLimit,
  isAlpha,
} from "../../../utils/customValidators";
import BackArrow from "../../../customComponents/BackArrow/BackArrow";
import ConfirmationBackArrow from "../../../customComponents/confirmationMessage/ConfirmationBackArrow";
import { alertDangerConfirmMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import AddConfirmation from "../../../customComponents/confirmationMessage/AddConfirmation";
import Loader from "../../../customComponents/Loader/Loader";
import HospitalService from "../../../../../api/services/HospitalService";
import CampService from "../../../../../api/services/CampService";
import VisionCenterService from "../../../../../api/services/VisionCenterService";
import MocDocService from "../../../../../api/services/MocDocService";
import applicationConstants from "../../../constants/applicationContants";
import MocDoc from "../../../customComponents/MocDoc/MocDoc";

const countrycitystateHandler = require('countrycitystatejson');

class Registration extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.validator = new FormValidator([
      {
        field: "patientName",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter patient name",
          },
        ],
        touched: false,
      },
      {
        field: "age",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter age",
          },
          {
            method: isText,
            validWhen: false,
            message: "Please enter valid age",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid age",
            args: [{ max: 3 }],
          },
          {
            method: ageLimit,
            validWhen: true,
            message: "Patient age should be under 1 to 110",
          },
        ],
        touched: false,
      },
      {
        field: "contactNo",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter contact number",
          },
          {
            method: isMobileNumber,
            validWhen: true,
            message: "Please enter valid contact number",
          },
          {
            method: "isLength",
            validWhen: true,
            message: "Please enter valid contact number",
            args: [{ min: 10, max: 10 }],
          },
        ],
        touched: false,
      },
      {
        field: "gender",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select gender option",
          },
        ],
        touched: false,
      },
      {
        field: "stateListId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select state option",
          },
        ],
        touched: false,
      },
      {
        field: "assignedHospital",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please select Hospital option",
          },
        ],
        touched: false,
      },
      // {
      //   field: "campData",
      //   constraints: [
      //     {
      //       method: "isEmpty",
      //       validWhen: false,
      //       message: "Please select camp option",
      //     },
      //   ],
      //   touched: false,
      // },
      {
        // Custom validation rule to check that at least one of campData or visionCenters is selected
        field: "campData",
        constraints: [
          {
            method: (value, state) => {
              const { campData, visionCenters } = state;
              return !!(campData || visionCenters);
            },
            validWhen: true,
            message: "Please select either camp option or vision center",
          },
        ],
        touched: false,
      },

    ]);
    this.state = {
      model:false,
      mocDocPhId: "",
      patientId: "",
      patientName: "",
      name: "",
      guardianOrParent: "",
      gender: "",
      aadharNumber: "",
      age: "",
      address: "",
      contactNo: "",
      occupation: "",
      education: educationData[3]!=""?educationData[3]:"",
      campId: "",
      dateOfExamination: new Date().toDateString(),
      isConfirmation: false,
      isAddConfirmation: false,
      isEdit: false,
      isReset: false,
      isaddhar: false,
      isOccupation: false,
      countryCode: "+91",
      timer: 0,
      campStatus: 0,
      registrationDuration: 0,
      isCurrentCamp: false,
      assignedHospital: '',
      assignedHospitalList: [],
      visionCenterList: [],
      visionCenters:'',
      visionCenterName:'',
      campName:'',
      stateList:[],
      hospitalList: [],
      visionCenter:[],
      campDetailList: [],
      campDetails: [],
      campData: 0,
      hospitalName: "",
      roleId: "",
      // stateList: props.stateList ? props.stateList : [],
      // stateListId: "",
      stateList: [],
      stateListId: "",
    };
    console.log(this.state)
    this.isValidate = true;
    this.campList = this.campList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    const patientId = await localStorage.getItem("patientId");
    const campId = await localStorage.getItem("currentCamp");
    // const campStatus = await localStorage.getItem("campStatus");
    const campStatus = 1;
    const roleId = await localStorage.getItem("roleId");

    await this.setState({
      isLoading: true,
      campStatus: campStatus,
    });

    if (patientId) {
      this.getPatientBasedOnId(token, patientId);
      this.validator.removeConstraint("stateListId");
      this.validator.removeConstraint("assignedHospital");
    } else {
      this.setState({
        
        isLoading: false,
      });
    }

    if (this.state.patientName) {
      this.validator.touched("patientName");
    }
    if (this.state.age) {
      this.validator.touched("age");
    }
    if (this.state.contactNo) {
      this.validator.touched("contactNo");
    }

    if (this.state.stateListId) {
      this.validator.touched("stateListId");
    }

    if (this.state.assignedHospital) {
      this.validator.touched("assignedHospital");
    } 
    
    this.interval = setInterval(
      () => this.setState({ timer: this.state.timer + 1 }),
      1000
    );

    if ((campId == null || campId === "") && !patientId) {
      this.setState({
        isCurrentCamp: true,
      });
      alertDangerConfirmMessage(`please the select the current camp`);
    } else {
      this.setState({
        isCurrentCamp: false,
      });
    }
    // const roleId = await localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });

    if(this.props.data){
      this.handleStateList(this.props.state_name[0]);
    }
    if(!patientId){
      const hospitalName = await localStorage.getItem("hospitalName");
      this.setState({
        hospitalName: hospitalName,
      });
      console.log("patientId-->",patientId);
    }
   
    await this.getHospitalList();
    await this.getVisionCenterList();
    await this.getStateList();  
    await this.campList();
  }

  campList = async () => {
    const token = await localStorage.getItem("token");
    
    CampService.getCampList(token, "")
    .then(async (res) => {
      if (res.status === 200) {
        try {
          res.text().then(async (res) => {
           console.log(res)
            let result = JSON.parse(res);
            if (result.code === 0) {
              let data = result.data;
              console.log("camp_info",data);
              let obj={};
              let camps=[{
                value: "",
                label: "Select Camp",
                hospital: "",
                visioncenterId: "",
              }];
              data.map(item => {
                obj={
                  value: `${item.campCode}`,
                  label: `${item.campCode} - ${item.location}`,
                  hospital: `${item.hospitalName}`,
                  visioncenterId: `${item.visionCentreId}`,
                };
                camps.push(obj);
              })
              this.setState({
                campDetailList: camps
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  getStateList = async () => {
    let data = countrycitystateHandler.getStatesByShort('IN');
    console.log(data);
    let obj={};
    let stateListData=[{
      value: "",
      label: "Select State",
    }];
    data.map((item) => {
      obj={
        value: `${item}`,
        label: `${item}`,
      };
      stateListData.push(obj);
    })
    this.setState({
      stateList: stateListData
    });
    console.log(this.stateList)
  }
  getHospitalList = async () => {
   await HospitalService.getHospitalList()
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              console.log(res)
              let result = JSON.parse(res);
              if (result.code === 0) {
                let data = result.data;
                let obj={};
                let assignedHospital=[{
                  value: "",
                  label: "Select Hospital",
                  state: ""
                }];
                data.map(item => {
                  obj={
                    value: `${item.name}`,
                    label: `${item.name}`,
                    state: `${item.state}`,
                  };
                  assignedHospital.push(obj);
                })
                this.setState({
                  assignedHospitalList: assignedHospital
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getVisionCenterList = async () => {
    const token = await localStorage.getItem("token");
    await VisionCenterService.getVisionCenterList(token)
       .then(async (res) => {
         if (res.status === 200) {
           try {
             res.text().then(async (res) => {
              console.log(res)
               let result = JSON.parse(res);
               if (result.code === 0) {
                 let data = result.data;
                 let obj={};
                 let visionCenter=[{
                  value: "",
                  label: "Select Vision Center",
                  state: "",
                }];
                 console.log("vision_center",data);
                 data.map(item => {
                   obj={
                     value: `${item.internal_id}`,
                     label: `${item.center_name}`,
                     state: `${item.state}`,
                   };
                   console.log(obj.value, obj.label)
                   visionCenter.push(obj);
                 })
                 this.setState({
                  visionCenterList: visionCenter
                 });
               }
             });
           } catch (err) {
             console.log(err);
           }
         }
       })
       .catch((err) => {
         console.log(err);
       });
   };

   handleStateList = (item) => {
    console.log(item)
     
    const hostpitals = this.state.assignedHospitalList.filter((el)=> {
     
      return el.state === item.value || el.state === "";
    });
    console.log("hospital_sel",hostpitals)
    const visionCenter = this.state.visionCenterList.filter((el)=> {
      console.log(el)
      return el.state === item.value || el.state === "";
    });
    this.validator.touched("stateListId");
    this.setState({
      hospitalList:hostpitals,
      stateListId: item,
      visionCenter:visionCenter,
      isEdit: true,
      isReset: false,
    });
  };
  

   handleAssignedHospital = (item) => {
    console.log(item)
    this.validator.touched("assignedHospital");
    const campDetails = this.state.campDetailList.filter((el)=> {
      return el.hospital === item.value || el.hospital === "";
    });

    this.setState({
      assignedHospital: item,
      campDetails:campDetails
    });
  };

  handleVisionCenter = (item) => {
    console.log(item)
    this.validator.touched("visionCenter");
    if(item.value === ""){
      this.setState({
        visionCenters: item,
        campDetails:this.state.campDetailList
      });
    } else{
      const campDetails = this.state.campDetailList.filter((el)=> {
        return el.visioncenterId === item.value || el.visioncenterId === "";
      });
      this.setState({
        visionCenters: item,
        campDetails:campDetails
      });
    }
  };

  

  getPatientBasedOnId = async (token, patientId) => {
    PatientService.getPatientBasedOnId(token, patientId)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              console.log(result)
              if (result.code === 0) {
                let data = result.data;
                console.log("patientInfo-->",data)
                if (data.registerInfo) {
                  if(data.campId!=""&&data.campId!="undefined"&&data.campId!=null){
                    this.handleCampChange(data.campId)
                  }
                  if(data.visionCentreId!=""&&data.visionCentreId!="undefined"&&data.visionCentreId!=null){
                    this.handleCampChange(data.visionCentreId)
                  }
                  
                  this.setState({
                    patientId: patientId,
                    campId: (data.campId!=""&&data.campId!="undefined")?data.campId:'',
                    visionCenters:(data.visionCentreId!=""&&data.visionCentreId!="undefined")?data.visionCentreId:'',
                    hospitalName: data.hospitalName,
                    campStatus: data.campId ? "1" : "1",
                    patientName: data.registerInfo.patientName,
                    mocDocPhId: (data.mocDocPhid !== undefined && data.mocDocPhid !== "" && data.mocDocPhid !== null) ?  data.mocDocPhid  : "",
                    name: data.registerInfo.patientName,
                    guardianOrParent: data.registerInfo.guardianOrParent,
                    campName : (data.campId != undefined && data.campId != "" && data.campId != null) ?  data.campId +' - '+data.campName : "",
                    visionCenterName : (data.visionCentreId !== undefined && data.visionCentreId !=="" && data.visionCentreId !==null) ?  data.visionCentreId +' - '+data.visionCenterName : "",
                    aadharNumber: data.registerInfo.aadharNumber,
                    gender: genderData[data.registerInfo.gender],
                    age: data.registerInfo.age,
                    address: data.registerInfo.address,
                    contactNo: data.registerInfo.contactNo.substring(3),
                    countryCode: data.registerInfo.contactNo.substring(0, 3),
                    occupation: data.registerInfo.occupation,
                    education: educationData[data.registerInfo.education],
                    dateOfExamination:
                      data.registerInfo.dateOfExamination === "undefined"
                        ? new Date().toDateString()
                        : dateToString(
                            data.registerInfo.dateOfExamination
                          ).toString(),
                    registrationDuration: parseInt(
                      data.registerInfo.registrationDuration
                    ),
                    isLoading: false,
                  });
                }
                this.validationContactNumber(
                  data.registerInfo.contactNo.substring(0, 3)
                );
              } else {
                await this.setState({
                  isLoading: false,
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  handleDate = (date) => {
    this.setState({ dateOfExamination: date.toDateString(), isEdit: true });
  };

  handleChange(event) {
    event.preventDefault();
    this.validator.touched(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
      isEdit: true,
      isReset: false,
    });
  }
  handleCampChange = (item) => {    
    this.validator.touched("campData");
    this.setState({
      campData: item,
      campId: item.value,
      isEdit: true,
      isReset: false,
    });
  };

  handleAadharNumber = (event) => {
    this.setState({
      aadharNumber: event.target.value,
    });
  };

  handleOccupation = (event) => {
    this.validator.addConstraint({
      field: "occupation",
      constraints: [
        {
          method: isAlpha,
          validWhen: true,
          message: "Numeric character are not allowed",
        },
      ],
      touched: true,
    });
    this.setState({
      occupation: event.target.value,
      isOccupation: isAlpha(event.target.value) ? false : true,
    });
  };

  handleGender = (item) => {
    this.validator.touched("gender");
    this.setState({
      gender: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleEducation = (item) => {
    this.validator.touched("education");
    this.setState({
      education: item,
      isEdit: true,
      isReset: false,
    });
  };

  handleMobileNumber = (countryCode) => {
    this.setState({
      countryCode: countryCode,
    });
    this.validationContactNumber(countryCode);
  };

  validationContactNumber = async (countryCode) => {
    this.validator.removeConstraint("contactNo");
    this.validator.addConstraint({
      field: "contactNo",
      constraints: [
        {
          method: "isEmpty",
          validWhen: false,
          message: "Please enter contact number",
        },
        {
          method: isMobileNumber,
          validWhen: true,
          message: "Please enter valid contact number",
        },
        {
          method: "isLength",
          validWhen: true,
          message: "Please enter valid contact number",
          args: [
            {
              min: countryCode === "+91" ? 10 : 8,
              max: countryCode === "+91" ? 10 : 12,
            },
          ],
        },
      ],
      touched: false,
    });
  };

  async handleSubmit() {
    this.setState({
      isAddConfirmation:false,
      isReset: false
    });
    clearInterval(this.interval);
    const roleId = await localStorage.getItem("roleId");
    const loginId = await localStorage.getItem("loginId");
    const token = await localStorage.getItem("token");
    const currentCampId = await localStorage.getItem("currentCamp");
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    if (errorMessage.length <= 0) {
      const data = {
        // campId: roleId === "Admin" ? this.state.campId : this.state.campId,
        campId: (this.state.campId!=""&&this.state.campId!="undefined")? this.state.campId  : this.state.campId,
        patientId: this.state.patientId,
        mocDocPhid:this.state.mocDocPhId,
        patientName: this.state.patientName,
        gender: this.state.gender.value,
        age: this.state.age,
        guardianOrParent: this.state.guardianOrParent,
        aadharNumber: this.state.aadharNumber,
        address: this.state.address,
        contactNo: `${this.state.countryCode}${this.state.contactNo}`,
        occupation: this.state.occupation,
        education: this.state.education.value,
        dateOfExamination: DateFormat(this.state.dateOfExamination),
        registrationDuration:
        this.state.registrationDuration + this.state.timer,
        visionCenterId: (this.state.visionCenters.value!=""&&this.state.visionCenters.value!="undefined")?this.state.visionCenters.value:'',
        visionCenters:this.state.visionCenters,
        hospitalName:
        this.state.roleId === applicationConstants.admin
            ? this.state.assignedHospital
              ? this.state.assignedHospital.value
              : -1
            : this.state.hospitalName,
        loginId: loginId
           
      };
      
      await this.setState({
        isLoading: true,
      });
      console.log(data);
      PatientService.addOrUpdateRegistration(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  // console.log(JSON.stringify(result));
                  await localStorage.setItem(
                    "patientId",
                    result.data.patientId
                  );
                  await this.setState({
                    isLoading: false,
                  });
                  this.props.history.push("/Dashboard");
                } else {
                  await this.setState({
                    isLoading: false,
                  });
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false,
              });
              console.log(err);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err);
        });
    } else {
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  }

  handleBackArrow = () => {
    if (this.state.campStatus !== "0") {
      if (this.state.isEdit) {
        this.setState({
          isConfirmation: !this.state.isConfirmation,
          isReset: false,
        });
      } else {
        this.props.history.push("/Dashboard");
      }
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  handleAddConfirmation = () => {
    let errorMessage = [];
    errorMessage = this.validator.getErrorMessage(this.state);
    if (errorMessage.length <= 0) {
        this.setState({
          isAddConfirmation:true,
          isReset: false,
        });
    }else{
      alertDangerConfirmMessage(`${errorMessage[0]}`);
    }
  };
 
  handleCancelAddBtn= () => {
    this.setState({
      isAddConfirmation: !this.state.isAddConfirmation,
    });
  };



  handleCancelBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: false,
    });
    if (this.state.aadharNumber === "") {
      this.validator.removeConstraint("aadharNumber");
    }
    if (this.state.occupation === "") {
      this.validator.removeConstraint("occupation");
    }
  };

  handleResetBtn = () => {
    this.setState({
      isConfirmation: !this.state.isConfirmation,
      isReset: true,
    });
  };

  handleCancelMoc = () => {
    this.setState({
      model: !this.state.model,
      isReset: false,
    });
  };
  handleMocDoc = () => {
    this.setState({
      model: !this.state.model,
      isReset: false,
    });
  };

  handleConfirmBtn = async () => {
    console.log(this.state.isReset);
    if (this.state.isReset) {
      if(this.state.patientId){
        const token = await localStorage.getItem("token");
       this.getPatientBasedOnId(token,this.state.patientId);
       this.handleCancelBtn();
      } else {
        this.setState({
          patientName: "",
          guardianOrParent: "",
          gender: "",
          aadharNumber: "",
          age: "",
          address: "",
          contactNo: "",
          occupation: "",
          stateListId: "",
          assignedHospital:"",
          visionCenters: "",
          campData: "",
          mocDocPhId:"",
          education: educationData[3],
          dateOfExamination: new Date().toDateString(),
          isConfirmation: !this.state.isConfirmation,
          isReset: false,
        });
      }
      
    } else {
      this.props.history.push("/Dashboard");
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  async handleConfirm(data) {
    console.log(data);
    // if (!data.PhId || !data.mobileNo) {
    //   alertDangerConfirmMessage("Enter Mobile No or PhId");
    //   return false;
    // }
    const token = await localStorage.getItem("token");
      MocDocService.getPatientList(data, token)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                // let resultKey= result.patientlist;

                // console.log(result.message);
                // console.log(result.status);

                if(result.status === 400){
                  alertDangerConfirmMessage(result.message);
                  return false;
                } 

                if (result.status === '200' && result.status !== 400 && result.patientlist.length !== 0) {
                  const keys = Object.keys(result.patientlist);
                  const firstKey = keys[0];
                  const dynamicObject = result.patientlist[firstKey];
                  const dynamicObjectName=dynamicObject.entitykey+dynamicObject.phid
                  const stateData = this.state.stateList.filter((resp) => resp.value === result.patientlist[dynamicObjectName].address.state)
                  this.setState({
                    model: false,
                    patientName: result.patientlist[dynamicObjectName].name ,
                    guardianOrParent: result.patientlist[dynamicObjectName].guardian.name,
                    age:result.patientlist[dynamicObjectName].agey,
                    gender:(result.patientlist[dynamicObjectName].gender =="M")? genderData[0]:(result.patientlist[dynamicObjectName].gender =="F")?genderData[1]:genderData[2],
                    address:result.patientlist[dynamicObjectName].address.area+" "+result.patientlist[dynamicObjectName].address.street,
                    countryCode : "+"+result.patientlist[dynamicObjectName].isdcode,
                    contactNo : result.patientlist[dynamicObjectName].mobile,
                    occupation:result.patientlist[dynamicObjectName].occupation,
                    stateListId: stateData,
                    mocDocPhId:result.patientlist[dynamicObjectName].phid
                  });

                  console.log("gender:>",(result.patientlist[dynamicObjectName].gender =="M")? genderData[0]:(result.patientlist[dynamicObjectName].gender =="F")?genderData[1]:genderData[2]);

                  this.handleStateList({"label":result.patientlist[dynamicObjectName].address.state,"value":result.patientlist[dynamicObjectName].address.state});
                  
                  console.log(result.patientlist);
                  
                } else {
                  await this.setState({
                      model: false,
                  })
                  alertDangerConfirmMessage("No Data Found");
                }
              });
            } catch (err) {
              await this.setState({
                isLoading: false
              })
              console.log(err);
            }
          }
          
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          })
          console.log(err);
        });
   
  }

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <RenderPage
        id="registration-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-registration"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
              <BackArrow
                title="Patient Registration"
                handleBackArrow={this.handleBackArrow}
              />{" "}
              <Row className="bg-light-white bg-white p-4">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto d-flex justify-content-end">
          <Button
            className="btn custom-theme-btn custom-btn--small bg-lblue"
            onClick={this.handleMocDoc}
          >
            <span>+</span> Fetch Patient Info
          </Button>
        </Col>
      </Row>
              <Row className="bg-light-white bg-white p-4">
                {this.state.patientId ? (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="p-0"
                  >
                    <p className="p-large internal-page__title text-capitalize align-left title-suvaGrey pat-title">
                      {this.state.name} - {this.state.patientId}
                    </p>
                  </Col>
                ) : null}
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="bg-white no-mobile--padding p-0"
                >
                  <Form className="form-fields field-col-4">  
                    <FormGroup>
                      <Label className="common-label">Patient Name <sup>*</sup></Label>
                      <div
                        className={IsInputInError(
                          validation.patientName.isInvalid
                        )}
                      >
                        <Input
                          type="text"
                          placeholder="Name"
                          name="patientName"
                          id="patientName"
                          className="form-control common-form-control"
                          onChange={this.handleChange}
                          value={this.state.patientName}
                        />
                        <p className="p-small help-block">
                          {validation.patientName.message}
                        </p>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Guardian/Parent</Label>
                      <Input
                        type="text"
                        placeholder="Guardian/Parent"
                        name="guardianOrParent"
                        id="guardianOrParent"
                        className="form-control common-form-control"
                        value={this.state.guardianOrParent}
                        onChange={this.handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Adhar/Other ID</Label>
                      <Input
                        type="text"
                        placeholder="Adhar/Other ID"
                        name="aadharNumber"
                        id="aadharNumber"
                        className={`${
                          this.state.isaddhar ? "brc-danger" : ""
                        } form-control common-form-control `}
                        value={this.state.aadharNumber}
                        onChange={this.handleAadharNumber}
                      />
                      {this.state.isaddhar ? (
                        <p className="p-small help-block">
                          {validation.aadharNumber.message}
                        </p>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Gender <sup>*</sup></Label>
                      <div
                        className={IsInputInError(validation.gender.isInvalid)}
                      >
                        <Select
                          value={this.state.gender}
                          onChange={this.handleGender}
                          options={genderData}
                          classNamePrefix="common-select-drop-down"
                          isSearchable={false}
                        />
                        <p className="p-small help-block">
                          {validation.gender.message}
                        </p>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Age <sup>*</sup></Label>
                      <div className={IsInputInError(validation.age.isInvalid)}>
                        <Input
                          type="text"
                          placeholder="Age"
                          name="age"
                          id="age"
                          className="form-control common-form-control"
                          value={this.state.age}
                          onChange={this.handleChange}
                        />
                        <p className="p-small help-block">
                          {validation.age.message}
                        </p>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Address</Label>
                      <Input
                        type="text"
                        placeholder="Address"
                        name="address"
                        id="address"
                        className="form-control common-form-control"
                        value={this.state.address}
                        onChange={this.handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Contact No <sup>*</sup></Label>
                      <div
                        className={IsInputInError(
                          validation.contactNo.isInvalid
                        )}
                      >
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="input-form-group-prepend"
                          >
                            <Dropdown
                              direction="down"
                              isOpen={this.state.btnDropright}
                              toggle={() => {
                                this.setState({
                                  btnDropright: !this.state.btnDropright,
                                });
                              }}
                              className="input-btn input-dropdown"
                            >
                              <DropdownToggle
                                caret
                                className="input-dropdown-toggle"
                              >
                                {this.state.countryCode}
                              </DropdownToggle>
                              <DropdownMenu className="input-dropdown-menu">
                                <DropdownItem
                                  className="input-dropdown-menu-item"
                                  onClick={() => this.handleMobileNumber("+91")}
                                >
                                  +91
                                </DropdownItem>
                                <DropdownItem
                                  className="input-dropdown-menu-item"
                                  onClick={() => this.handleMobileNumber("+49")}
                                >
                                  +49
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Contact No"
                            name="contactNo"
                            id="contactNo"
                            className="form-control common-form-control common-form-control--left-radius-none"
                            value={this.state.contactNo}
                            onChange={this.handleChange}
                          />
                        </InputGroup>

                        <p className="p-small help-block">
                          {validation.contactNo.message}
                        </p>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Occupation</Label>
                      <Input
                        type="text"
                        placeholder="Occupation"
                        name="occupation"
                        id="occupation"
                        className={`${
                          this.state.isOccupation ? "brc-danger" : ""
                        } form-control common-form-control `}
                        value={this.state.occupation}
                        onChange={this.handleOccupation}
                      />
                      {this.state.isOccupation ? (
                        <p className="p-small help-block">
                          {validation.occupation.message}
                        </p>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Education</Label>
                      <div>
                        <Select
                          value={this.state.education}
                          onChange={this.handleEducation}
                          options={educationData}
                          classNamePrefix="common-select-drop-down"
                          isSearchable={false}
                        />
                       
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">DOE</Label>
                      <DatePicker
                        type="text"
                        onChange={this.handleDate}
                        value={new Date(this.state.dateOfExamination)}
                        className="date-picker-container"
                        name="dateOfExamination"
                        id="dateOfExamination"
                        showLeadingZeros={true}
                        maxDate={new Date()}
                      />
                    </FormGroup>
                    {this.state.patientId ==""? (
                    <FormGroup>
                      <Label className="common-label">State <sup>*</sup></Label>
                      {/* <div
                        className={IsInputInError(validation.stateListId.isInvalid)}
                      > */}
                        <Select
                          value={this.state.stateListId}
                          onChange={this.handleStateList}
                          options={this.state.stateList}
                          classNamePrefix="common-select-drop-down"
                          isSearchable={true}
                        />
                        {/* <p className="p-small help-block">
                          {validation.stateListId.message}
                        </p>
                      </div> */}
                    </FormGroup>): null}

                    <FormGroup>
                      <Label className="common-label">Assigned Hospital <sup>*</sup></Label>
                      <div
                        // className={IsInputInError(
                        //   validation.education.isInvalid
                        // )}
                      >
                        {this.state.patientId ==""? (
                        //   <div
                        //   className={IsInputInError(
                        //     validation.assignedHospital.isInvalid
                        //   )}
                        // >
                            <Select
                            value={this.state.assignedHospital}
                            onChange={this.handleAssignedHospital}
                            options={this.state?.hospitalList}
                            classNamePrefix="common-select-drop-down"
                            name="status"
                            isSearchable={true}
                          />
                        //   <p className="p-small help-block">
                        //   {validation.assignedHospital.message}
                        // </p>
                        //     </div>
                          ) : (
                            <>
                            <Input
                              type="text"
                              placeholder="hospitalName"
                              id="hospitalName"
                              name="hospitalName"
                              className="form-control common-form-control"
                              value={this.state.hospitalName}
                              readOnly
                            />
                            </>
                          )}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label className="common-label">Vision Center</Label>
                      {this.state.patientId ==""? (
                      <div>
                        <Select
                          value={this.state.visionCenters}
                          onChange={this.handleVisionCenter}
                          options={this.state.visionCenter}
                          classNamePrefix="common-select-drop-down"
                          name="visionCenter"
                          isSearchable={true}
                        /> </div>)
                        : (<Input
                        type="text"
                        className="form-control common-form-control"
                        value={this.state.visionCenterName}
                        placeholder="visionCenter"
                        name="visionCenter"
                        disabled
                      />)}
                    </FormGroup>
                    <FormGroup>
                      <Label className="common-label">CAMP ID</Label>
                      {this.state.patientId ==""? (
                         <div
                         className={IsInputInError(
                           validation.campData.isInvalid
                         )}
                       >
                        <Select
                        options={this.state.campDetails}
                        value={this.state.campData}
                        onChange={this.handleCampChange}
                        classNamePrefix="common-select-drop-down"
                        isSearchable={true}
                      /> 
                       <p className="p-small help-block">
                      {validation.campData.message}
                    </p> </div>) : (<Input
                        type="text"
                        className="form-control common-form-control"
                        value={this.state.campName}
                        placeholder="Camp"
                        disabled
                      />)}
                    </FormGroup>       
                    <FormGroup>
                      <Label className="common-label">MocDoc PhId </Label>
                      <Input
                        type="text"
                        className="form-control common-form-control"
                        value={this.state.mocDocPhId}
                        placeholder="MocDoc PhId"
                        disabled
                      />
                    </FormGroup> 
                    
                     <FormGroup className="list-btns">
                          <Button
                            className="btn custom-grey-btn cancel-btn"
                            onClick={this.handleResetBtn}
                            disabled={
                              this.state.campStatus === "0" ? "disabled" : ""
                            }
                          >
                            Reset
                          </Button>
                          {this.state.patientId ==""? (
                          <Button
                            className="btn custom-theme-btn save-btn"
                            onClick={this.handleAddConfirmation}
                            disabled={
                              this.state.campStatus === "0" ? "disabled" : ""
                            }
                          >
                            Save
                          </Button> ):(
                            <Button
                            className="btn custom-theme-btn save-btn"
                            onClick={this.handleSubmit}
                            disabled={
                              this.state.campStatus === "0" ? "disabled" : ""
                            }
                          >
                            Update
                          </Button>

                          )}
                                                   
                        </FormGroup>
                  
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.isConfirmation ? (
            <ConfirmationBackArrow
              isModal={this.state.isConfirmation}
              handleCancelBtn={this.handleCancelBtn}
              handleConfirmBtn={this.handleConfirmBtn}
            />
          ) : null}
           {this.state.model === true ? (
          <MocDoc
              isModal={this.state.model}
              handleCancelMoc={this.handleCancelMoc}
              handleConfirm={this.handleConfirm}
            />
            ) : null}
            
            {this.state.isAddConfirmation === true ? (
            <AddConfirmation
              isModal={this.state.isAddConfirmation}
              handleCancelBtn={this.handleCancelAddBtn}
              handleConfirmBtn={this.handleSubmit}
            />
            ):null}

          {this.state.isLoading ? <Loader isLoader={false} /> : null}
        </Strip>
      </RenderPage>
    );
  }
}

export default Registration;
