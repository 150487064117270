import React, { Component } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import applicationConstants from "../../../constants/applicationContants";

class ZoomImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annonateList:
        props.mappingPoint !== null ? props.mappingPoint : {},
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.mappingPoint.areas.length !== prevState.annonateList.areas.length ) {
      this.setState({
        annonateList: this.props.mappingPoint,
      });
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        annonateList: this.props.mappingPoint.areas,
      });
    }
  }

  handleImgClick = (e) => {
    this.props.setPoint(e, e.target.getBoundingClientRect(), e.pageX, e.pageY);
  };

  getPoint = async (areas, index) => {
    this.props.getPoint(areas, index);
  };

  render() {
    return (
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
        options={{
          disabled: !this.props.isAnnotation ? true : false,
          maxScale: 8,
        }}
      >
        <TransformComponent ref={(node) => (this.container = node)}>
          <div
            className={`transform-img-container ${
              !this.props.isAnnotation
                ? "img-annonation-contianer"
                : "zoom-img-container"
            }`}
          >
            {!this.props.isAnnotation ? (
              <figure
                onClick={this.handleImgClick}
                className="custom-cursor img-figure"
              >
                <img
                  src={this.props.src}
                  className="zoom-annontation-img-container"
                />
              </figure>
            ) : (
              <figure className="img-figure">
                <img
                  src={this.props.src}
                  className="zoom-annontation-img-container"
                />
              </figure>
            )}
          </div>
          {!this.props.isAnnotation ? (
            <div>
              {this.state.annonateList.areas.length > 0 &&
                this.state.annonateList.areas.map((item, index) => (
                  <div
                    className={`annonate-img-container`}
                    style={item}
                    key={index}
                    onClick={() => this.getPoint(item, index)}
                  >
                    <img
                      src={`${
                        item.icon === applicationConstants.juniorReader
                          ? applicationConstants.jrIcon
                          : item.icon === applicationConstants.seniorReader
                          ? applicationConstants.srIcon
                          : item.icon === applicationConstants.ProjectReader
                          ? applicationConstants.pjIcon
                          : null
                      }`}
                      alt={item.name}
                    />
                  </div>
                ))}
            </div>
          ) : null}
        </TransformComponent>
      </TransformWrapper>
    );
  }
}

ZoomImage.defaultProps = {};

ZoomImage.propTypes = {};

export default ZoomImage;
