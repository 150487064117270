import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import windowSize from "react-window-size";
import menuData from "../../customComponents/MenuNavigation/menuData";
import Strip from "../../customComponents/Strip/Strip";
import MenuNavigation from "../../customComponents/MenuNavigation/MenuNavigation";
import RoutesComponent from "./RotuesComponent/RoutesComponent";
import HeaderComponent from "./HeaderComponent/HeaderComponent";
import applicationConstants from "../../constants/applicationContants";
import Login from "../login/Login";
import ForgotPassword from "../forgotPassword/ForgotPassword";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuCollapse: false,
      title: "Patients",
      isLogin: false,
      isMobileMenu: false,
      userName: "",
      mobileNo: "",
      roleId: "",
      URL: "",
    };
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleMenuSelect(name) {
    await this.setState({
      title: name,
    });
    if (this.state.isMobileMenu) {
      this.handleMobileMenu(false);
    }
  }

  async componentDidMount() {
    let loginStatus = await localStorage.getItem("isLogin");
    let userName = await localStorage.getItem("userName");
    let mobileNo = await localStorage.getItem("mobileNumber");
    let roleId = await localStorage.getItem("roleId");
    const url = window.location.pathname;

    if (userName != "" && userName != undefined) {
      this.setState({
        userName: userName,
        mobileNo: mobileNo,
        roleId: roleId,
      });
    }

    if(url === '/forgotPassword'){
      loginStatus = false;
      this.setState({
        URL: url.split('/')[1],
      });
    }
    this.getLoginStatus(loginStatus);
  }

  getLoginStatus(loginStatus) {
    if (loginStatus == null) {
      localStorage.setItem("isLogin", this.state.isLogin);
    }else if(loginStatus === false){
      localStorage.clear();
    }
     else if (loginStatus == "true") {
      this.setState({
        isLogin: loginStatus,
      });
    }
  }

  async handleLogin() {
    let userName = await localStorage.getItem("userName");
    let mobileNo = await localStorage.getItem("mobileNumber");
    let roleId = await localStorage.getItem("roleId");
    if (userName != "" && userName != undefined) {
      await this.setState({
        userName: userName,
        mobileNo: mobileNo,
        roleId: roleId,
      });
    }
    await this.setState({
      isLogin: true,
    });
  }

  logout() {
    //localStorage.setItem("isLogin", false);
    let loginStatus = localStorage.getItem("isLogin");
    this.setState({
      isLogin: !loginStatus,
    });
  }

  handleMobileMenu = async () => {
    await this.setState({
      isMobileMenu: !this.state.isMobileMenu,
    });
  };

  resetLink = async () => {
    await this.setState({
      URL: '',
      isLogin: false,
    });
  }

  render() {
    return (
      <Strip className="strip strip-no-padding" containerType="container-fluid">
        {this.state.isLogin ? (
          <Row className="no-margin">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="no-margin">
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="col-no--padding"
                >
                  <div
                    className={`${
                      this.props.windowWidth <
                      applicationConstants.mobileBreakPoint
                        ? "mobile-navigation__container"
                        : "navigation-container bg-theme-color dp-block"
                    } ${this.state.isMobileMenu ? "dp-block" : "dp-none"}`}
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="px-0"
                      >
                        <Row className="justify-content-center no-margin mb-30">
                          <Col
                            xs={9}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            className="text-center"
                          >
                            <img
                              src={applicationConstants.logoImg}
                              alt="Sankara Eye Hospital"
                              className="logo__container"
                            />
                          </Col>
                          <p className="logo-title">Sankara Eye Hospital</p>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col>
                    </Row>
                    <MenuNavigation
                      menuItem={menuData}
                      menuSelect={(label) => this.handleMenuSelect(label)}
                      isMenuCollapse={this.state.isMenuCollapse}
                      isMobileMenu={this.state.isMobileMenu}
                      roleId={this.state.roleId}
                    />
                    <div className="verison-container">
                      <p className="p-small text-center text-white internal-page__title pb-2">Version 0.1</p>
                    </div>
                  </div>
                  <div
                    className={`${
                      this.state.isMenuCollapse
                        ? "menu__collapse-container"
                        : "menu__content-container"
                    }`}
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={`col-no--padding  header-section__contianer box-shadow bg-theme-color bg-white ${
                          this.state.isMenuCollapse
                            ? "header-collapse-width"
                            : "header-width"
                        }`}
                      >
                        <HeaderComponent
                          title={this.state.title}
                          isToggle={this.state.isMobileMenu}
                          data={this.handleMobileMenu}
                          userName={this.state.userName}
                          mobileNo={this.state.mobileNo}
                          roleId={this.state.roleId}
                          windowWidth={this.props.windowWidth}
                        />
                      </Col>
                    </Row>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="col-no--padding pl-32px pr-32px mt-56px"
                      >
                        <RoutesComponent
                          roleId={this.state.roleId}
                          isLogin={this.state.isLogin}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <div>
            {this.state.URL === applicationConstants.forgotPassword ? <ForgotPassword resetLink={this.resetLink}/> : (
              <Login onSubmit={this.handleLogin} />
            )}
          </div>
        )}
      </Strip>
    );
  }
}

export default windowSize(Navigation);
