import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../../dashboard/Dashboard";
import CampDetails from "../../camp/CampDetails";
import UserDetails from "../../user/UserDetails";
import ChangePassword from "../../changePassword/ChangePassword";
import PatientList from "../../dashboard/PatientList";
import Registration from "../../dashboard/component/Registration";
import HealthDetails from "../../dashboard/component/HealthDetails";
import Refraction from "../../dashboard/component/Refraction";
import RightEye from "../../dashboard/component/RightEye";
import LeftEye from "../../dashboard/component/LeftEye";
import AtCampRE from "../../dashboard/component/AtCampRE";
import AtCampLE from "../../dashboard/component/AtCampLE";
import AtTeleLE from "../../dashboard/component/AtTeleLE";
import AtTeleRE from "../../dashboard/component/AtTeleRE";
import OptometristAdvice from "../../dashboard/component/OptometristAdvice";
import AtTeleAdvice from "../../dashboard/component/AtTeleAdvice";
import AtCampAdvice from "../../dashboard/component/AtCampAdvice";
import Report from "../../report/Report";
import ImagingAndGrading from "../../imagingAndGrading/ImagingAndGrading";
import Grading from "../../imagingAndGrading/Grading";
import Information from "../../Information/Information";
import ForgotPassword from "../../forgotPassword/ForgotPassword";
import HospitalDetails from "../../hospitalInfo/HospitalDetails"
import UserDashboard from "../../userDashboard/UserDashboard";
import VideoDetails from "../../videoTutorial/VideoDetails";
import VisioncenterDetails from "../../visionCenter/VisioncenterDetails";
import SummaryReport from "../../summaryReport/SummaryReports";
import CategoryDetails from "../../videoTutorial/CategoryDetails";
import PatientSummaryReports from "../../patientSummaryReport/PatientSummaryReports";
import ProfileSettings from "../../ProfileSettings/ProfileSettings";


class RoutesComponent extends Component {
  render() {
    return (
      <div>
        {this.props.isLogin ? (
          <Switch>
            <Route exact name="patientList" path="/PatientList" component={PatientSummaryReports} />
            <Route exact name="patientTable" path="/PatientTable" component={PatientList} />
            <Route
              exact
              name="campList"
              path="/campList"
              component={CampDetails}
            />           
              <Route
              exact
              name="videoList"
              path="/videoList"
              component={VideoDetails}
              />
              <Route
              name="categoryList"
              path="/categoryList"
              component={CategoryDetails}
              />
            <Route
              exact
              name="userDashboard"
              path="/userDashboard"
              component={UserDashboard}
            />
            <Route
              exact
              name="dashboard"
              path="/dashboard"
              component={Dashboard}
            />
             <Route
              exact
              name="ProfileSettings"
              path="/ProfileSettings"
              component={ProfileSettings}
            />
            {this.props.roleId === "Admin" ? (
              <Route
                exact
                name="AddUser"
                path="/userList"
                component={UserDetails}
              />
            ) : null}
             {this.props.roleId === "Admin" ? (
              <Route
                exact
                name="hospital"
                path="/hosptialInfo"
                component={HospitalDetails}
              />
            ) : null}
           
            <Route exact name="SummaryReport" path="/SummaryReport" component={SummaryReport} />
         
            <Route
              exact
              name="ChangePassword"
              path="/ChangePassword"
              component={ChangePassword}
            />
            {/* <Route exact name="Logout" path="/Dashboard" component={Dashboard} /> */}
            <Route
              name="Registration"
              path="/Registration"
              component={Registration}
            />
            <Route
              name="HealthDetails"
              path="/HealthDetails"
              component={HealthDetails}
            />
            <Route name="RightEye" path="/RightEye" component={RightEye} />
            <Route name="LeftEye" path="/LeftEye" component={LeftEye} />
            <Route
              name="Refraction"
              path="/Refraction"
              component={Refraction}
            />
            <Route name="AtCampRE" path="/AtCampRE" component={AtCampRE} />
            <Route name="AtCampLE" path="/AtCampLE" component={AtCampLE} />
            <Route name="AtTeleRE" path="/AtTeleRE" component={AtTeleRE} />
            <Route name="AtTeleLE" path="/AtTeleLE" component={AtTeleLE} />
            <Route
              name="OptometristAdvice"
              path="/OptometristAdvice"
              component={OptometristAdvice}
            />
            <Route
              name="AtTeleAdvice"
              path="/AtTeleAdvice"
              component={AtTeleAdvice}
            />
            <Route
              name="AtCampAdvice"
              path="/AtCampAdvice"
              component={AtCampAdvice}
            />
            <Route name="Report" path="/report" component={Report} />
            <Route exact name="VisionInfoList" path="/VisionInfoList" component={VisioncenterDetails} />
            <Route
              name="ImagingAndGrading"
              path="/ImagingAndGrading"
              component={ImagingAndGrading}
            />
            <Route name="Grading" path="/Grading" component={Grading} />
            <Route name="info" path="/info" component={Information} />
          </Switch>
        ) : (
          <Route
            name="forgotPassword"
            path="/forgotPassword"
            component={ForgotPassword}
          />
        )}
      </div>
    );
  }
}

export default RoutesComponent;
