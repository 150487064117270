import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  Input,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../confirmationMessage/ConfirmationMessage";
import UserService from "../../../../api/services/UserService";
import { userRoleData } from "../../utils/dropdownData";
import Strip from "../Strip/Strip";
import FormValidator from "../../utils/FormValidator";
import IsInputInError from "../../utils/isInputInError";
import { validPassword } from "../../utils/customValidators";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "loginId",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter loginId",
          },
        ],
        touched: false,
      },
      {
        field: "password",
        constraints: [
          {
            method: "isEmpty",
            validWhen: false,
            message: "Please enter password",
          },
          {
            method: validPassword,
            validWhen: false,
            message: "Password should be min 6 characters long, at least one uppercase, one lowercase, and one special char",
          },
        ],
        touched: false,
      },
    ]);
    this.state = {
      loginId: props.item.loginId ? props.item.loginId : "",
      password: "",
      isModal: props.isModal ? props.isModal : "",
    };
    this.isValidate = true;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.loginId) {
      this.validator.touched("loginId");
    }
    if (this.state.password) {
      this.validator.touched("password");
    }
  }
  handleChange = async (event) => {
    event.preventDefault();
    this.validator.touched(event.target.name);
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    const token = await localStorage.getItem("token");
    const roleId = await localStorage.getItem("roleId");

    const data = {
      loginId: this.state.loginId,
      roleId: userRoleData[0].label === roleId ? 0 : "",
      password: this.state.password,
    };
    
    if(this.state.password !== ''){
    UserService.resetPassword(data, token)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                this.props.toggle();
                alertSuccessMessage(`${result.message}`);
                
              } else {
                alertDangerConfirmMessage(`Reset Password Not done`);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
        alertDangerConfirmMessage('please enter resetPassword')
    }
  };

  render() {
    const validation = this.isValidate
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <Strip
        id="tst-reset-password"
        className="strip strip-no-padding"
        containerType="container-fluid"
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Modal
              isOpen={this.props.isModal}
              toggle={this.props.toggle}
              className="modal-container modal-lg"
            >
              <div className="modal-header-container">
                <Row className="no-margin align-items-center">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <p className="p-large internal-page__title align-left title-white">
                      Reset Password
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="text-right cursor-pointer"
                    onClick={this.props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                  </Col>
                </Row>
              </div>
              <ModalBody className="modal-content-container">
                <Row>
                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Form autoComplete="off">
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label text-left">
                            LoginId:
                          </Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.loginId.isInvalid
                            )}
                          >
                            <Input
                              type="text"
                              placeholder="Login ID"
                              id="loginID"
                              name="loginId"
                              value={this.state.loginId}
                              className="form-control common-form-control"
                              onChange={this.handleChange}
                              autoComplete="new-loginId"
                            />
                            <p className="p-small help-block">
                              {validation.loginId.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="text-position"
                        >
                          <Label className="common-label">Reset Password:</Label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <div
                            className={IsInputInError(
                              validation.password.isInvalid
                            )}
                          >
                            <Input
                              type="password"
                              placeholder="Password"
                              id="password"
                              name="password"
                              value={this.state.password}
                              className="form-control common-form-control"
                              onChange={this.handleChange}
                              autoComplete="new-password"
                            />
                            <p className="p-small help-block">
                              {validation.password.message}
                            </p>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col
                    xs={12}
                    ms={12}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        sm={12}
                        md={{ offset: 4, size: 8 }}
                        lg={{ offset: 4, size: 8 }}
                        xl={{ offset: 4, size: 8 }}
                      >
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="secondary"
                              className="btn btn-block custom-danger-btn custom-btn--small"
                              onClick={this.props.toggle}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              color="success"
                              className="btn btn-block custom-theme-btn custom-btn--small"
                              onClick={this.handleSubmit}
                              disabled={this.state.loginId !== '' && !validPassword(this.state.password)  ? false: true}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Strip>
    );
  }
}

export default ResetPassword;
