import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import VideoService from "../../../../../api/services/VideoService";
import Strip from "../../../customComponents/Strip/Strip";
import DataTable from "react-data-table-component";
import windowSize from "react-window-size";
import TablesColumns from "./TablesColumns";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../../utils/dataTablesStyledTheme";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponents/confirmationMessage/ConfirmationMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import applicationConstants from "../../../constants/applicationContants";
import Loader from "../../../customComponents/Loader/Loader";
import MessageConfirmation from "../../../customComponents/MessageConfirmation/MessageConfirmation";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class VideoList extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      videosList: [],
      isLoading: false,
      isModal: props.isModal ? props.isModal : false,
      resetPaginationToggle: false,
      filterText: "",
      filterData: [],    
      isConfirmModal: false,
      deleteItem: '',
    };
    this.videoList = this.videoList.bind(this);
    // this.getCampItem = this.getCampItem.bind(this);
    // this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleConfirmBtn = this.handleConfirmBtn.bind(this);
    this.handleCancelBtn = this.handleCancelBtn.bind(this);
  }

  componentDidMount() {
    this.videoList();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isModal !== prevProps.isModal) {
      this.videoList();
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.videoList();
    }
  }

  videoList = async () => {
    const token = await localStorage.getItem("token");
    const hospitalName = await localStorage.getItem("hospitalName");
    const roleId = await localStorage.getItem("roleId");
    const data = roleId === applicationConstants.admin ? "" : hospitalName;

    await this.setState({
      isLoading: true,
    });   

    VideoService.getVideoList(token, data )   
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          console.log(res)
          try {
            console.log(data)
            res.text().then(async (res) => {
              console.log(res)
              let result = JSON.parse(res);
              if (result.code === 0) {
                const data = result.data;
                console.log(data)

                  this.setState({
                    videosList : data,
                    filterData: data,
                  });                
                await this.setState({
                  isLoading: false,
                });
              } else {
                await this.setState({
                  isLoading: false,
                });
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            await this.setState({
              isLoading: false,
            });
            console.log(err);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  // getCampItem = (item) => {
  //   this.props.handleModal(item);
  // };    
 

    
  // handleAction = (item) => {
  //   console.log(item)
  //   // this.props.handleModal(item);
  // };


  handleDelete = async (item) => {
    console.log(item)
    this.setState({
      isConfirmModal: true,
      deleteItem: item,
    })
  }

  handleCancelBtn = async () => {
    this.setState({
      isConfirmModal: false
    })
  }

  onChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    await this.filterData(event.target.value);
  };

  filterData = (filterText) => {
    const filteredItems = this.state.filterData.filter(
      (item) =>
        (item.tut_name &&
          item.tut_name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.tut_description &&
          item.tut_description.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.tut_category &&
          item.tut_category.toLowerCase().includes(filterText.toLowerCase()))        
    );

    if (filteredItems.length >= 0) {
      this.setState({
        videosList: [...filteredItems],
      });
    } else {
      this.setState({
        videosList: [...this.state.filterData],
      });
    }
  };

  handleClear = () => {
    this.setState({
      videosList: [...this.state.filterData],
      filterText: "",
    });
  };


  handleConfirmBtn = async () => {
    const id = this.state.deleteItem ? this.state.deleteItem.id : '';
    console.log(id);
    const token = await localStorage.getItem("token");
    if (id) {
      VideoService.deleteVideoTut(token, id)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                console.log(result);
                if (result.code === 0) {
                  this.setState({
                    isConfirmModal: false
                  });
                  alertSuccessMessage(`Video Tutorial deleted  successfully`)
                  this.videoList();
                } else {
                  alertDangerConfirmMessage(`${result.message}`);
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <Strip
        id="tst-camp-list"
        className="strip strip-no-padding video-table"
        containerType="container-fluid"
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12} 
            lg={12}
            xl={12}
            className="no-mobile--padding"
          >
            <Row className="no-margin pb-3 align-items-center">
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn-pad mr-2"
                  onClick={this.props.toggle}
                >
                  <span>+</span> Add Video
                </Button>
                <Link
                      to={`/CategoryList`}                      
                    >
                <Button
                  className="btn custom-theme-btn custom-btn--small bg-lblue btn-pad"
                  onClick={this.props.toggle}
                >
                  Manage Category
                </Button>
                </Link>                
              </Col>             
              <Col xs={12} sm={12} md={6} lg={8} xl={8}>
                <Row className="justify-content-end">
                  <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                    <InputGroup>
                      <Input
                        placeholder="Search"
                        name="filterText"
                        value={this.state.filterText}
                        onChange={this.onChange}
                      />
                      <InputGroupAddon
                        addonType="append"
                        onClick={this.handleClear}
                      >
                        <InputGroupText className="cursor-pointer">
                          <FontAwesomeIcon
                            icon={this.state.filterText ? faTimes : faSearch}
                            color="white"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="table-responsive no-margin">
          {this.state.videosList.length > 0 ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <DataTable
                title=""
                columns={TablesColumns( this.handleDelete)}
                data={this.state.videosList}
                pagination
                className="data-table"
                persistTableHead={false}
                customStyles={
                  this.props.windowWidth > applicationConstants.mobileBreakPoint
                    ? dataTablesStyledTheme
                    : dataTablesMobileStyledTheme
                }
                // onRowClicked={(data) => this.getCampItem(data)}
              />
            </Col>
          ) : (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="no-mobile--padding"
            >
              <div className="br-light-smoke p-5 br-4px">
                {!this.state.isLoading ? (
                  <p className="mb-0 align-center internal-page__title">
                    No Videos Found
                  </p>
                ) : (
                  <Loader isLoader={false} />
                )}
              </div>
            </Col>
          )}
        </Row>
        {this.state.isConfirmModal ? (
          <MessageConfirmation
            isModal={this.state.isConfirmModal}
            handleCancelBtn={this.handleCancelBtn}
            handleConfirmBtn={this.handleConfirmBtn}
            message={`Are you sure you want to delete this Video?`}
          />
        ) : null}
      </Strip>
    );
  }
}

export default windowSize(VideoList);
