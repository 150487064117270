import React from "react";
import memoizeOne from "memoize-one";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

const TablesColumns = memoizeOne((handleAction, handleDelete) => [
  {
    name: "Camp Id",
    selector: "campCode",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {console.log(row)}
        {row.campCode}
      </div>
    ),
  },
  {
    name: "Camp Location",
    selector: "location",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {row.location}
      </div>
    ),
  },
  {
    name: "Hospital Name",
    selector: "hospitalName",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {row.hospitalName}
      </div>
    ),
  },
  {
    name: "Date",
    selector: "date",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {row.date}
      </div>
    ),
  },
  {
    name: "Camp Status",
    selector: "campStatus",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {row.campStatus === 1 ? (
          <p className="mb-0 active-btn">Active</p>
        ) : (
          <p className="mb-0 inactive-btn">Inactive</p>
        )}
      </div>
    ),
  },
  {
    name: "Patient Count",
    selector: "patientCount",
    sortable: false,
    cell: (row) => (
      <div className={`${row.currentCamp === 1 ? "title-theme-color" : ""}`} onClick={() => handleAction(row)}>
        {row.patientCount}
      </div>
    ),
  },
  {
    name: "Action",
    selector: "",
    grow: 0.2,
    sortable: false,
    ignoreRowClick: true,
    allowOverflow: true,
    cell: (row) => (
        <div className="d-flex">
            <div onClick={() => handleDelete(row)}>
               <p className="mb-0 inactive-btn thrash-ico"> <FontAwesomeIcon icon={faTrashAlt} className="profile-icon" /></p>
            </div>
        </div>
    ),
},
]);

export default TablesColumns;
