export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  updatePatient: "updatePatient",
  getPatientList: "getPatients",
  getPatientById: "getPatientById?patientId",
  getPatientsReportCSV:"getPatientsReportCSV",
  getCampAll:"getCampAll",
  getCamps: "getCamps",
  addOrUpdateCamp: "updateCamp",
  getUsers: "getUsers",
  addUser: "addUser",
  updateUser: "updateUser",
  getAnnotationById: "getAnnotationByPatientId?patientId",
  addORUpdateAnnotation: "addORUpdateAnnotation",
  changePassword: "changePassword",
  resetPassword: "resetPassword",
  forgotPassword: 'forgetPassword',
  addORUpdateHospital: 'updateHospital',
  getHospital: 'getHospitals',
  deleteCamp: 'deleteCamp?campCode',
  deletePatient: 'deletePatient?patientId',  
  getVideoCategories: "getVideoCategories", //getVideoCategories
  addVideoCategory: "addVideoCategory",    //addVideoCategory
  updateVideoCategory: "updateVideoCategory",   //updateVideoCategory
  deleteVideoCategory: "deleteVideoCategory", //deleteVideoCategory
  getDashboardCount: "getAdminDashboardInfo",    //getDashboard
  addVideoTutorial: "addVideoTutorial", //AddVideoTutorial
  getVideoTutorials: "getVideoTutorials",    //getVideoTutorials
  deleteVideoTutorial: "deleteVideoTutorial", //DeleteVideoTutorials
  updateVideoTutorial: "updateVideoTutorial", //UpdateVideoTutorials
  getPatientimages:"getPatientimages",
  getRemidioPatientInfo:"getRemidioPatientInfo",
  
  // Vision Center  API Endpoint
  getVisionCenters: "getVisionCenters", 
  updateVisionCenter: "updateVisionCenter", 
  deleteVisionCenter: "deleteVisionCenter", 
  addVisionCenter: "addVisionCenter", 

  // Report API Endpoint
  getPatientsReport: "getPatientsReport", 
  
  // MocDoc API Endpoint
  getMocdocPatientInfo : "getMocdocPatientInfo",

  //Profile Settings
  getProfile : "getProfile",
  updateProfile : "updateProfile",
  getPatientImage:"getPatientImage",
  
  // ============URLs================
  baseUrl: "https://sudarshan.sankaraeye.com/api/v1/",
  // baseUrl: "http://3.7.1.192/api/v1/",
  // baseUrl:"http://localhost:5002/v1/", //Local deve environement
  // baseUrl:"http://3.108.132.228:5002/v1/", //Demo dev environment
  
};
