import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup
} from "reactstrap";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../customComponents/confirmationMessage/ConfirmationMessage";
import Strip from "../../customComponents/Strip/Strip";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import UserService from "../../../../api/services/UserService";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId:"",
      fullName: "",
      emailId: "",
      mobileNumber: "",            
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
    this.handleEmailId = this.handleEmailId.bind(this);
    this.handleMobileNumber = this.handleMobileNumber.bind(this);   
    
  }

  componentDidMount(){
    this.getUserPro();

  }

  // ----------------VALIDATION OF INPUT FIELDS---------------------
  async handleChangeFullName(event) {
    console.log(event)
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleMobileNumber(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });    
  }

  async handleEmailId(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
}

  getUserPro = async () =>{
    const token = await localStorage.getItem("token");
    const loginId = await localStorage.getItem("loginId");
    
    UserService.getUserProfile(token, loginId)
    .then(async(res)=>{
        if(res.status === 200){
          try{
            res.text().then(async(res)=>{
              let result = JSON.parse(res);
              if(result.code === 0){
                let data = result.data;
                console.log(data)

                this.setState({
                  fullName: data[0].fullName,
                  emailId: data[0].emailId,
                  mobileNumber: data[0].mobileNumber,
                })                
              }
            })
          }catch(err){
            console.log(err)
          }
        }
    })
  }
 
  
  // -----------Submit function-----------------------

  async handleSubmit() {
    const token = await localStorage.getItem("token");
    const loginId = await localStorage.getItem("loginId");
    
    const data = {
      loginId: loginId,
      fullName: this.state.fullName,
      emailId: this.state.emailId,
      mobileNumber: this.state.mobileNumber,
    };

    UserService.updatePro(data, token)
    .then(async(res)=>{
      if(res.status === 200){
        try{
          res.text().then(async(res)=>{
            let result = JSON.parse(res);
            console.log("RESULT001", result)
            if(result.code === 0){
              alertSuccessMessage(`${result.message}`);
            }else{
              alertDangerConfirmMessage('Unable to update the records')
            }
          });
        }catch(err){
          console.log(err);
        }
      }
    })
    .catch((err)=>{
      console.log(err)
    });
  }


  render() {
    const {
      loginId,
      fullName,
      emailId,
      mobileNumber,    
    } = this.state;

    return (
      <RenderPage
        id="imaging-grading-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-imaging-grading"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="m-0 justify-content-center align-items-center change-password-container">
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={5}
              xl={5}
              className="align-items-center justify-content-center"
            >
                    
              <Form className="bg-light p-5 change-password-form-container bg-white">
               
                
                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">
                      FullName
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="FullName"
                        id="fullName"
                        name="fullName"                    
                        value={this.state.fullName}
                        onChange={this.handleChangeFullName}
                        className="form-control common-form-control"
                      />                 
                      
                    </InputGroup>
                    {/* <p
                      className={`p-small help-block ${
                        this.state.fullName != "" &&
                        validPassword(this.state.fullName)
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Please enter FullName
                    </p> */}
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">Email</Label>
                    <InputGroup>
                      <Input
                        type="emailId"
                        placeholder="Email"
                        id="emailId"
                        name="emailId"                        
                        value={this.state.emailId}
                        onChange={this.handleEmailId}
                        className="form-control common-form-control"
                      />
                      </InputGroup>                      
                    {/* <p
                      className={`p-small help-block error-txt ${
                        this.state.emailId != "" &&
                        validPassword(this.state.emailId)
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Please enter Valid Email
                    </p> */}
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center ">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Label className="common-label">Mobile Number</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="MobileNumber"
                        id="mobileno"
                        name="mobileNumber"                        
                        value={this.state.mobileNumber}
                        onChange={this.handleMobileNumber}
                        className="form-control common-form-control"
                      />
                      </InputGroup>                      
                    {/* <p
                      className={`p-small help-block error-txt ${
                        this.state.emailId != "" &&
                        validPassword(this.state.emailId)
                          ? "dp-block"
                          : "dp-none"
                      }`}
                    >
                      Please enter Valid Email
                    </p> */}
                  </Col>
                </FormGroup>

                <Row className="justify-content-center mb-2 mt-4">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Button
                      className="btn btn-block custom-theme-btn"
                      onClick={this.handleSubmit}
                      disabled={
                        fullName != "" && emailId != "" && mobileNumber != ""
                          ? false
                          : true
                      }
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>              
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    );
  }
}
export default withRouter(ProfileSettings);
